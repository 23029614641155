import { Retailer, Category, SubCategory, RendersAngles, PolyType, PolygonSpecifications, RetailerBatch, RejectReason } from "../retailer/retailer";
import { KeyValuePair, FormatsType } from "../shared/enums";
import { SafeResourceUrl } from "@angular/platform-browser";
import { User } from "../auth/user";
import { DimensionsUnits } from "../shared/utils";
import { ProductSubCategory } from "../product-categories/product-categories";
import { JobsTypes, Resource } from "../retailer/job-types";
import { CameraControlsState, ResourceJsonParams } from "asset-adjustments";
import { IP } from '../shared/retailer-product-ip/retailer-product-ip.interface';
import { DBSchema } from "@tempfix/idb";
import { feedback_types } from "./feedback";
export class Product {
    brand?: string;
    category_id?: number;
    category?: string;
    category_name_org_lang?: string;
    color?: string;
    complexity: number;
    created_at: Date;
    cs_id: number;
    currency?: string;
    // description?: string;
    description_org_lang?: string;
    notes?: string;
    dimensions?: string;
    width: number;
    height: number;
    length: number;
    units: DimensionsUnits;
    due_date?: Date;
    enabled?: number; // 0/1
    ic: boolean;
    id: number;
    is_archived: boolean;
    material: string;
    mobile_enabled?: number; // 0/1
    // name: string;
    name_org_lang: string;
    ppc_id: number;
    price: number;
    quantity: number;
    retailer_category_id?: number;
    retailer_id: number;
    retailer_sub_category_id?: number;
    // serial_number: string;
    signature: number;
    status: string;
    status_id: number;
    sub_category_id?: number;
    sub_category?: string;
    sub_category_name_org_lang?: string;
    updated_at: Date;
    visible?: number; // 0 or 1
    mobile_traffic?: number;
    desktop_traffic?: number;
    ip: IP;
    ia: boolean; // internally approved
    charged_price?: number;
    qr_url?: string;
    allow_downloads: boolean;
    qr_direct_url?: string;
    is_poc: boolean;
    force_status_id: number; // For updating the status manually
    products_data: Array<ProductData>;
    products_resources: Array<ProductResource>;
    products_specs: Array<any>;
    products_tags: Array<ProductTag>;
    products_urls: Array<ProductUrl>;
    products_audit: Array<ProductAudit>;
    retailers_categories: Array<Category>;
    retailers_sub_categories: Array<SubCategory>;
    retailers: Array<Retailer>;
    products_categories: Array<Category>;
    products_sub_categories: Array<ProductSubCategory>;
    products_resources_types: Array<ProductResourceType>;
    products_identifiers: Array<ProductIdentifier>;
    products_renders_requests?: Array<ProductsRendersRequest>;
    products_polygon_specifications: Array<ProductsPolygonSpecifications>;
    products_attachments: Array<ProductsAttachment>;
    products_batches: Array<RetailerBatch>;
    external_provider: boolean;
    products_reject_reasons?: Array<RejectReason>;
    UI: ProductUi;
    force?: boolean;
    priority?: number;
    priority_type?: number;
    source_pid?: number;
    process_type?: number;
    auto_create_texture_offers: boolean;
    products_resources_submit?: boolean;
    constructor() {
        // this.category_id = null;
        // this.sub_category_id = null;
        // this.category = null;
        // this.sub_category = null;
        // this.color = null;
        // this.created_at = null;
        // this.cs_id = null;
        // this.currency = null;
        // this.description = null;
        // this.dimensions = null;
        // this.due_date = null;
        // this.enabled = null;
        // this.mobile_enabled = null;
        // this.id = null;
        // this.is_archived = null;
        // this.name = null;
        // this.price = null;
        // this.reject_reason = null;
        // this.resource_types = null;
        // this.retailer_category_id = null;
        // this.retailer_sub_category_id = null;
        // this.retailer_id = null;
        // this.serial_number = null;
        // this.status_id = null;
        // this.updated_at = null;
        // this.visible = null;
        this.retailers = [];
        this.products_data = [];
        this.products_resources = [];
        this.products_specs = [];
        this.products_tags = [];
        this.products_urls = [];
        this.products_audit = [];
        this.products_resources_types = [];
        this.products_attachments = [];
        this.products_renders_requests = [
            {

            } as ProductsRendersRequest
        ];
        this.products_polygon_specifications = [
            {

            } as ProductsPolygonSpecifications
        ];
        this.products_batches = [];
    }
    // parents: ProductParents;
    // children: ProductChildren;
}

export interface ProductsPolygonSpecifications extends PolygonSpecifications {
    product_id: number;
}

export class ProductPut extends Product {
    status_changed: boolean;
    status_reason: string;
}

export interface ProductUi {
    images: Array<ImageSet>;
    smallImages: Array<string>;
    checked: boolean;
    safeUrl: SafeResourceUrl;
    mediaTag: MediaTag;
    // quickView: boolean;
}

export interface ProdutUIOptions {
    improviseImageSet: boolean;
}

export interface ImageSet {
    big: string;
    small: string;
    sort_index?: number;
    products_resources_alignments?: Array<ProductsResourcesAlignment>;
}

export interface ImageDialog {
    url: string;
    title?: string;
    copyLink?: string;
    isIframe?: boolean;
}

export interface ProductTag {
    tag: string;
    confidence?: number;
    product_id?: number;
}

// export interface ProductParents {
//     retailers: Array<Retailer>
// }

// export interface ProductChildren {
//     productdatas: Array<ProductData>;
//     productresources: Array<ProductResource>;
//     producturls: Array<ProductUrl>;
//     producttags: Array<any>;
//     productspecs: Array<any>;
// }

export interface ProductData {
    created_at?: Date;
    id?: number;
    product_id?: number;
    scan_tags?: boolean;
    small_image_url?: string;
    updated_at?: Date;
    url: string;
    sort_index?: number;
    products_resources_alignments?: Array<ProductsResourcesAlignment>;
}

export interface ProductsResourcesAlignment {
    id: number;
    created_at: Date;
    updated_at: Date;
    url: string;
    config_json: ProductsResourcesAlignmentConfig;
    public: boolean;
    data_id: number; // the ID of the ProductData
    resource_id?: number;
}

export interface ProductsResourcesAlignmentConfig extends CameraControlsState {
    // phi: number;
    // theta: number;
    // radius: number;
    // fov: number;
    // posx: number;
    // posy: number;
    // posz: number;
    // camposx: number;
    // camposy: number;
    // camposz: number;
}

export enum ProductResourceOriginator {
  Creators = 1,
  CMS = 2
}

export interface ProductResource {
    ajr_id: number;
    created_at: Date;
    id: number;
    product_id: number;
    resource_big: string;
    resource_default: string;
    resource_info: string;
    resource_mobile_order?: number;
    resource_order?: number;
    resource_small?: string;
    resource_text: string;
    resource_tour?: string;
    resource_type: MediaTag; // MediaTag (1: image, 2: 3D model, 3: video, 4: iframe)
    resource_ar_url?: string;
    resource_arcore_url?: string;
    viewer_resource_type?: FormatsType;
    // resource_video: string;
    updated_at: Date;
    resource_enabled: number;// 0 or 1
    artists_jobs_resources: Array<Resource>;
    products_resources_feedback: Array<ProductsResourcesFeedback>;
    products_resources_source_files: Array<ProductsResourcesSourceFile>;
    products_resources_files_details: Array<ProductsResourcesFileSDetail>;
    assets_details: Array<AssetsDetails>;
    resource_poly_type: PolyType;
    json_params?: ResourceJsonParams;
    update_json_params?: boolean;
    viewer_type?: string;
    icon?: string; // UI
    resource_originator: ProductResourceOriginator;
    poly_variation_name?: string;
    serial_number?: string;
    uploadedThisSession?: boolean;
    uploaded_to_client_storage?: boolean;
}

export interface Dimensions {
    width: number;
    height: number;
    length: number;
    units: DimensionsUnits;
}

export interface AssetsDetails extends Dimensions {
    poly_count: number;
    vert_count: number;
}

export interface ProductsResourcesFileSDetail {
    file_usage_type: FILE_USAGE_TYPE;
    file_name: string;
    file_size: string;
}

export enum FILE_USAGE_TYPE {
    ORIGINAL_FILES = 1,
    VIEWER_FILES = 2,
}

export interface ResourceDetails {
    original_files: { [id: string]: string; };
    viewer_files: { [id: string]: string; };
}

export interface ProductsResourcesFeedback {
    id: number;
    created_at: Date;
    updated_at: Date;
    resource_id: number;
    feedback_type: number;
    feedback_sub_type: number;
    feedback_types: Array<feedback_types>;
    feedback_sub_types: Array<feedback_types>;
    opened_by: number;
    title: string;
    screenshot: string;
    notes: string;
    fixed: boolean;
    products_resources_feedback_comments: Array<ProductsResourcesFeedbackComment>;
    users: Array<User>;
    fix_due_date?: Date;
    paused_at?: Date;
}

export interface ProductsResourcesFeedbackComment {
    id: number;
    created_at: Date;
    updated_at: Date;
    feedback_id: number;
    user_id: number;
    comment: string;
    users: Array<User>;
}

export interface ProductAudit {
    id: number;
    product_id: number;
    user_id: number;
    reference_table: string;
    reference_id: number;
    action_type: ProductAuditActionMethod;
    field_name: string;
    old_value: any;
    new_value: any;
    created_at: Date;
    users: Array<User>;
    products?: Array<Product>;
}


export interface ProductAuditFilterOptions {
    retailer_id?: Array<number>;
    offset?: number;
    limit?: number;
}
export interface AllProductsAudit {
    rows: Array<ProductAudit>;
    count: number;
}
export interface AllProductAudit {
    allProductsAudit: AllProductsAudit;
}
export interface ProductAuditUIGroup {
    id?: number;

    created_at: Date;
    items: Array<ProductAuditUI>;
}

export interface ProductAuditUI {
    created_at: Date;
    acronym: string;
    fullName: string;
    action: string;
    avatarUrl: string;
    onClickArgs: Array<any>;
    iconName: string;
    url?: string;
    products?: Array<Product>;
    product_id?: number;
}

export enum ProductAuditActionMethod {
    CREATE = 1,
    UPDATE = 2,
    DELETE = 3,
}

export interface ProductUrl {
    created_at?: Date;
    id?: number;
    path_id?: number;
    product_id?: number;
    updated_at?: Date;
    url: string;
}

export enum PrimeStatus {
    ALL = 0,
    ORDERS = 1,
    APPROVED = 2,
    DELETED = 3,
}

export interface PrimeStatusData {
    primeStatus: PrimeStatus;
    statusList: Array<KeyValuePair>;
    sum: number;
}

export enum ProductViewType {
    LIST = 1,
    GRID = 2,
}

export interface ProductQuery {
    data: ProductQueryData;
}

export interface ProductQueryData {
    allProducts: AllProducts;
}

export interface SingleProductQuery {
    data: SingleProductQueryData;
}
export interface SingleProductQueryData {
    products: Product;
}

export interface FeedbackQuery {
    data: FeedbackQueryData;
}

export interface FeedbackQueryData {
    products_resources_feedback: ProductsResourcesFeedback;
}

export interface ProductAuditQuery {
    data: ProductAuditQueryData;
}

export interface ProductAuditQueryData {
    products_audit: Array<ProductAudit>;
}

export interface AllProducts {
    rows?: Array<Product>;
    count: number;
    resource_count: number;
    summary: SummaryProducts;
}

export interface SummaryProducts {
    property: number;
}

export interface ProductFilterOptions {
    limit?: number;
    offset?: number;
    retailer_id?: Array<number>;
    status_id?: Array<number>;
    render_status_id?: Array<number>;
    cs_id?: Array<number>; // creators status id
    enabled?: number; // 0/1
    mobile_enabled?: number; // 0/1
    serial_number?: Array<string>;
    tags: Array<string>;
    retailer_category_id?: Array<number>;
    retailer_sub_category_id?: Array<number>;
    is_archived?: boolean;
    is_desc?: boolean;
    order_by?: string;
    date_range?: string;
    date_from?: number;
    date_to?: number;
    name?: Array<string>;
    visible: number;
    ia?: boolean;
    id?: number;
    contains_render_request?: boolean;
    viewer_resource_type?: Array<FormatsType>;
}

export interface ProductMassEdit {
    values: Product;
    where: ProductFilterOptions;
    query?: ProductFilterOptions;
}

export interface GifOptions {
    ggwidth: number;
    ggheight: number;
    // ggsampleInterval?: number;
    // ggnumofframes?: number;
    // gginterval?: number;
    // ggrotatespeen?: number;
    // ggtext?: string;
}

export interface PreviewOptions {
    width: number;
    height: number;
    create_images_by_tour: string;//'init_pos'
    compress: boolean;
}

export interface ProductResourceType {
    id: number;
    resources_types: Array<ResourceType>;
    resource_type_id: number;
    product_id: number;
}

export interface ResourceType {
    resource_name: string;
    id: number;
    type?: string;
}

export interface ProductsDefaultsResourcesTypesQueryData {
    products_defaults_resources_types: Array<ProductResourceType>;
}

export interface ProductsResourcesTypesQueryData {
    resources_types: Array<ResourceType>;
}

export interface SourceFileType {
    description: string;
    id: number;
}

export interface SourchFilesTypesQueryData {
    source_files_types: Array<SourceFileType>;
}

export interface MappingNamingConvention {
    id: number;
    mapping_name: string;
}

export interface MappingNamingConventionsQueryData {
    mapping_naming_convention: Array<MappingNamingConvention>;
}

export interface RetailerMappingNamingConvention {
    id: number;
    mapping_id: number;
    mapping_value: string;
    retailer_id: number;
    created_at: Date;
    updated_at: Date;
}

export interface ProductIdentifier {
    id: number;
    serial_number: string;
    variation_id: string;
    identifier_type: string;
    retailer_id: number;
    product_id: number;
    created_at: Date;
    updated_at: Date;
}

export interface ProductsResourcesSourceFile {
    id: number;
    created_at: Date;
    updated_at: Date;
    resource_id: number;
    software_id: number;
    source_files_location: string;
    software_enum: Array<Software>;
  uploaded_to_client_storage: boolean;
}

export interface Software {
    id: number;
    enum_id: number;
    software_name: string;
    software_image_url: string;
}

export interface ProductsRendersRequest {
    id: number;
    product_id: number;
    status_id: ProductsRendersRequestStatus;
    products_renders_requests_types: Array<ProductRenderType>;
    products_renders_requests_angles: Array<RendersAngles>;
    enabled?: number;
    resolution_width?: number;
    background_color?: string;
    duration?: number;
    frames_per_second?: number;
    product_position?: number;
    product_reflection?: boolean;
    product_shadow?: boolean;
    resolution_height?: number;
}

export enum ProductsRendersRequestStatus {
    REQUESTED = 1,
    IN_PROGRESS = 2,
    DELIVERED = 4,
    CLIENT_FEEDBACK = 7,
    OFFLINE = 5,
    ONLINE = 6
}

export enum ProductsStatus {
    REQUESTED = 1,
    IN_PROGRESS = 2,
    DELIVERED = 4,
    REJECTED = 8,
    CLIENT_FEEDBACK = 7,
    OFFLINE = 5,
    ONLINE = 6
}

export enum RenderState {
    CANNOT_CREATE = 1,
    CAN_CREATE = 2,
    CREATING = 3,
    CREATED = 4,
}

export interface ProductRenderType {
    id?: number;
    type_id: RenderType;
    name: string; // UI
}

export enum RenderType {
    IMAGES = 1,
    VIDEO = 2,
    IMAGES_AND_VIDEO = -1,
}

export enum MediaTag {
    // IFRAME = 1,
    // VIDEO = 2,
    // IMAGE = 3,
    IMAGE = 1,
    MODEL = 2,
    VIDEO = 3,
    IFRAME_RENDR = 4, // 360
    IFRAME = 5, // not in use currentlly
}

export enum ResourceDimension {
    TWO = '2d',
    THREE = '3d',
}

export interface UploadResource {
    viewer_type: string;
    uploaded_file_url: string;
    uploaded_file_name: string;
    archive: string;
    file: any;
    resource_type: FormatsType;
    encrypt: boolean;
    compress: boolean;
    mesh_compress: boolean;
    as_base64: boolean;
    target_resource_type: FormatsType;
    json_params?: any;
}

export enum ProductRequestState {
    SETTINGS = 0,
    FEEDBACK = 1,
    ADJUSTMENTS = 2,
    RENDERER = 3,
}

export interface ProductsAttachment {
    id: number;
    created_at: Date;
    updated_at: Date;
    product_id: number;
    title: string;
    attachment: string;
    attachment_type: number;
    sort_index: number;
    job_type: JobsTypes;
}

export interface ProductsAttachmentUI extends ProductsAttachment {
    suffix?: string;
}

export enum ProductProcessTypes {
  RENDER_ONLY = 1
}

export interface ExternalResourceDetails {
  type: FormatsType;
  name: string;
  extension: string;
  base64?: string;
  file: File;
  id?: number;
  submitted?: boolean;
  loading: boolean;
  isInFolder: boolean;
}

export interface ProductDB extends DBSchema {
    'product-request-table': {
      key: number;
      value: any;
      indexes: {'key':[]};
    };
}

export class ScrapedProduct {
    additional_urls: Array<string>;
    category: string;
    sub_category: string;
    images: Array<ScrapedProductImages>;
    language: string;
    offerPriceDetails: ScrapedProductPrice;
    regularPriceDetails: ScrapedProductPrice;
    pageUrl: string;
    products_tags: Array<string>;
    sku: string;
    title: string;
    units: DimensionsUnits;
    width: number;
    height: number;
    length: number;
}

export class ScrapedProductImages {
    url: string;
    sort_index?: number;
}

export class ScrapedProductPrice {
    amount: number;
    symbol: string;
    text: string;
}
