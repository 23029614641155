<form [formGroup]="genForm" novalidate>
  <div fxLayout="row" fxLayoutAlign="space-around center">
    <div fxFlex="50" class="image-gen">
      <!-- <div class="image-wrap float-dir" [style.backgroundImage]="'url(' + resource.resource_big + ')'"> -->
      <div class="image-wrap float-dir">
        <img [src]="resource.resource_big" *ngIf="resource.resource_big" alt="preview">
      </div>
      <div class="details-wrap float-dir">
        <h5 class="h2">preview image</h5>
        <div class="pixels-wrap">
          <span class="capitalize float-dir">
            <p class="text3 text-center">width</p>
            <input type="number" formControlName="previewWidth" class="pixels" min="1" [prevent-keys]="[189]">
          </span>
          <span class="float-dir">
            <mat-icon aria-label="preview width and height" class="lock">lock_outline</mat-icon>
          </span>
          <span class="capitalize float-dir">
            <p class="text3 text-center">height</p>
            <input type="number" formControlName="previewHeight" class="pixels" min="1" [prevent-keys]="[189]">
          </span>
        </div>
        <div class="actions float-dir text-center w-100">
          <div>
            <div class="text5" *ngIf="isCreatePreview">
              Render may take 10-20 sec
            </div>
            <mat-progress-bar color="accent" *ngIf="isCreatePreview" mode="indeterminate"></mat-progress-bar>
            <div *ngIf="!isCreatePreview">
              <button mat-raised-button *ngIf="!isCreatePreview" (click)="createPreview(resource)"
                class="third_button thinnest w-100">
                render
              </button>
              <!-- <span class="link1 no-ul pointer" (click)="createPreview(resource)">
                <mat-icon class="generate" aria-label="generate preview">create</mat-icon>
              </span>
              <span class="link1 pointer" (click)="createPreview(resource)">Generate</span> -->
              <div class="download-wrap">
                <a [href]="previewDownloadLink" download="" target="_blank" class="link1 no-ul" [ngClass]="{'hidden': !previewDownloadLink}">
                  <i class="icon-download"></i>
                </a>
                <a [href]="previewDownloadLink" download="" target="_blank" class="link1" [ngClass]="{'hidden': !previewDownloadLink}">Download
                  preview image</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="50" class="image-gen">
      <!-- <div class="image-wrap float-dir" [style.backgroundImage]="'url(' + resource.resource_small + ')'"> -->
      <div class="image-wrap float-dir">
        <img [src]="resource.resource_small" *ngIf="resource.resource_small" alt="gif">
      </div>
      <div class="details-wrap float-dir">
        <h5 class="h2">gif & mp4</h5>
        <div class="pixels-wrap">
          <span class="capitalize float-dir">
            <p class="text3 text-center">width</p>
            <input type="number" formControlName="gifWidth" class="pixels" min="1" [prevent-keys]="[189]">
          </span>
          <span class="float-dir">
            <mat-icon aria-label="preview width and height" class="lock">lock_outline</mat-icon>
          </span>
          <span class="capitalize float-dir">
            <p class="text3 text-center">height</p>
            <input type="number" formControlName="gifHeight" class="pixels" min="1" [prevent-keys]="[189]">
          </span>
        </div>
        <div class="actions float-dir text-center w-100">
          <div>
            <div class="text5" *ngIf="isCreateGif">
              Render may take 20-30 sec
            </div>
            <mat-progress-bar color="accent" *ngIf="isCreateGif" mode="indeterminate"></mat-progress-bar>
            <div *ngIf="!isCreateGif">
              <button mat-raised-button *ngIf="!isCreateGif" (click)="createGif(resource)"
                class="third_button thinnest w-100">
                render
              </button>
              <div class="download-wrap">
                <a [href]="gifDownloadLink" download="" target="_blank" class="link1 no-ul" [ngClass]="{'hidden': !gifDownloadLink}">
                  <i class="icon-download"></i>
                </a>
                <a [href]="gifDownloadLink" download="" target="_blank" class="link1" [ngClass]="{'hidden': !gifDownloadLink}">Download
                  gif and mp4</a>
              </div>
              <!-- <span class="link1 no-ul pointer" (click)="createGif(resource)">
                <mat-icon class="generate" aria-label="generate preview">create</mat-icon>
              </span>
              <span class="link1 pointer" (click)="createGif(resource)">Generate</span> -->
            </div>

          </div>

          <!-- <div *ngIf="gifDownloadLink">
            <a [href]="gifDownloadLink" download="animated_gif" target="_blank" class="link1 no-ul">
              <i class="icon-download"></i>
            </a>
            <a [href]="gifDownloadLink" download="animated_gif" target="_blank" class="link1">Download animated gif</a>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div fxFlex="33" class="image-gen">
      <div class="image-wrap float-dir">
        <video playsinline loop autoplay [src]="resource.resource_video" alt="video"></video>
      </div>
      <div class="details-wrap float-dir">
        <h5 class="h2">video</h5>
        <div class="pixels-wrap">
          <span class="capitalize float-dir">
            <p class="text3 text-center">width</p>
            <input type="number" disabled="true" formControlName="gifWidth" class="pixels" min="1" [prevent-keys]="[189]">
          </span>
          <span class="float-dir">
            <mat-icon aria-label="preview width and height" class="lock">lock_outline</mat-icon>
          </span>
          <span class="capitalize float-dir">
            <p class="text3 text-center">height</p>
            <input type="number" disabled="true" formControlName="gifHeight" class="pixels" min="1" [prevent-keys]="[189]">
          </span>
        </div>
        <div class="actions float-dir text-center w-100">
          <div>
            <div class="text5" *ngIf="isCreateVideo">
              Render may take 20-30 sec
            </div>
            <mat-progress-bar color="accent" *ngIf="isCreateVideo" mode="indeterminate"></mat-progress-bar>
            <div *ngIf="!isCreateVideo">
              <button mat-raised-button mat-raised-button color="accent" *ngIf="!isCreateVideo" (click)="createVideo(resource)"
                class="thinnest w-100">
                render
              </button>
              <div class="download-wrap">
                <a [href]="videoDownloadLink" download="" target="_blank" class="link1 no-ul" [ngClass]="{'hidden': !videoDownloadLink}">
                  <i class="icon-download"></i>
                </a>
                <a [href]="videoDownloadLink" download="" target="_blank" class="link1" [ngClass]="{'hidden': !videoDownloadLink}">Download
                  video</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</form>