import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GraphqlService } from '../../communication/graphql.service';
import { ProductService } from '../product.service';

@Injectable()
export class ProductsResolve  {

    constructor(
        private gql: GraphqlService,
        private productService: ProductService
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (!this.productService.isSearchRequestInProgress) {
            this.productService.setStateFromURL(route.params);
            const options = this.productService.getSearchQuery();
            return this.gql.products(options) as Observable<any>;
        }
    }
}