<div fxLayout="column" fxLayoutAlign="end center" class="wrap h-100">
  <div fxFlex="44" class="top w-100">
    <div fxLayout="column" fxLayoutAlign="end center" class="h-100">
      <div class="h2">price</div>
      <div class="price">
        <span class="number">{{prService.price | number : '1.0-2'}}</span>
        <span class="currency">$</span>
      </div>
    </div>
  </div>
  <div fxFlex class="w-100 text-center pos-rel">
    <p class="text3">Price above is based on the product details</p>
    <button mat-raised-button class="second_button thin min-button-width fund" (click)="fund()">fund</button>
    <!-- <span class="link1 quote pointer pos-abs text-center w-100" (click)="getQuote()">Get a quote</span> -->
    <span class="link1 no-ul quote pos-abs text-center w-100">Current budget is ${{prService.budget | number }}</span>
  </div>
</div>