import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class GifgenResolve  {

    constructor(
    ) { }

    resolve(
        route: ActivatedRouteSnapshot) {
        let obj = null;
        if (route.params['gifgen']) {
            obj = {
                gifgen: true
            }
            if (route.params['gifgen-force']) {
                obj.gifgenForce = true;
            }
        }
        return obj as Observable<any>;
    }
}