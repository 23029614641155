import { ResourceService } from 'src/app/shared/resource.service';
import { PreviewResourceItem } from '../preview-files/preview-resources.model';
import { ExternalResourceDetails, MediaTag, ProductResource } from '../product';
import { tap } from 'rxjs/operators';
import { DEFAULT_PREVIEW_DIM, THREE_LATEST_VERSION } from 'src/app/shared/enums';
import { RenderingEngine, ResourceJson } from 'asset-adjustments';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { DropZoneFile } from 'src/app/shared/drop-zone/drop-zone.model';
import { ProductRequestService } from '../product-request.service';
import { Retailer } from 'src/app/retailer/retailer';

export class ExternalResourceHandlerService {
    private prService: ProductRequestService;
    private _resource: ExternalResourceDetails;
    private _uploadResponse: ProductResource;
    private _dropZoneFile: DropZoneFile;
    private _failure: boolean;
    constructor(
      prService: ProductRequestService,
      resource: ExternalResourceDetails,
      dropZoneFile: DropZoneFile
    ) {
        this._failure = false;
        this.prService = prService;
        this._resource = resource;
        this._dropZoneFile = dropZoneFile;
        // this.exec();
    }

    get currentRetailer(): Retailer {
      return this.prService.request.retailers[this.prService.retailerIndex];
    }

    exec() {
      return new Promise((resolve, reject) => {
        const previewResourceItem: PreviewResourceItem = {
            resourceType: this.prService.getPreviewResourceType(this._resource.extension),
            src: this._resource.base64,
            big: this._resource.base64,
            loading: true,
            uuid: this.prService.utils.generateUUID()
        };
        const onSuccess = async (res) => {
          const pid = this.prService.request.id;
          const rid = this.currentRetailer.id;
          const query = `?pid=${pid}&rid=${rid}`;
          const payload = {
            resource_type: this._resource.type,
            ignore_validators: true,
            archive: this._resource.file ? 'none' : 'zip',
            storage_key: res.storageKey,
            storageBucket: res.storageBucket,
            use_hexa_naming: false,
            extract_blender_info: false,
          };

          this.prService.rest.productResourceUpload('post', payload, query).toPromise().then(async (uploadResponse: ProductResource) => {
            uploadResponse.uploadedThisSession = true;
            this.prService.request.products_resources.push(uploadResponse);
            this._resource.id = uploadResponse.id;
            this.prService.externalResourcesUploadsIDs.push(uploadResponse.id);
            if (previewResourceItem.resourceType === MediaTag.MODEL) {
              previewResourceItem.src = uploadResponse.resource_default;
              const rs = new ResourceService(
                this.prService.utils
              );
              const preset = this.prService.getPreset();
              if (preset?.preset_json?.urlParats) {
                for (let i in preset.preset_json.urlParats)
                  previewResourceItem.src = this.prService.utils.setUrlParam(previewResourceItem.src, i, preset.preset_json.urlParats[i]);
              }
              previewResourceItem.src = this.prService.utils.setUrlParam(previewResourceItem.src, 'server', rs.getEnvViewerServer());
              uploadResponse.json_params = {
                scene: (await rs.adjustScene(previewResourceItem.src)).resourceJson as any as ResourceJson
              };
              if (preset)
                uploadResponse.json_params = {
                  scene: (await rs.applyPreset(previewResourceItem.src, preset.preset_json as any)).resourceJson as any,
                };
              uploadResponse.resource_default = previewResourceItem.src;
              await this.afterPreset(uploadResponse, uploadResponse.resource_default, previewResourceItem);
              const settings = this.prService.getRetailerSettings();
              const options = {
                width:
                  settings.preload.width ||
                  DEFAULT_PREVIEW_DIM,
                height:
                  settings.preload.height ||
                  DEFAULT_PREVIEW_DIM,
                compress: true,
                create_images_by_tour: 'init_pos',
              };
              uploadResponse.resource_big = await this.prService.resumableUpload.getUrlFromBase64(await rs.createPreview(previewResourceItem.src, options));
              let r = this.prService.externalResources.find((r) => r.id === uploadResponse.id);
              if (r) {
                r.base64 = uploadResponse.resource_big;
                r.loading = false;
                previewResourceItem.big = r.base64;
                this.prService.externalResourcesCounter++;
              }
              uploadResponse.resource_default = previewResourceItem.src;
              this.prService.broadcaster.broadcast('onPreviewResourceItemChange', uploadResponse);
              await this.prService.putResource(uploadResponse);
            }
            this._uploadResponse = uploadResponse;
            this.prService.isUploadExternalResourcesDirty = true;
          })
            .catch((err) => {
              const data: BroadcasterNotification = {
                text: `Failed to upload file '${this._resource.name}'.`,
                type: BroadcasterNotificationType.Error,
                action: 'OK',
              };
              this.prService.broadcaster.broadcast('notifyUser', data);
              this.prService.previewResourceItems.splice(this.prService.previewResourceItems.indexOf(previewResourceItem), 1);
              this.prService.externalResources.splice(this.prService.externalResources.indexOf(this._resource), 1);
              this._failure = true;
              reject();
            })
            .finally(() => {
              this.prService.externalResourcesUploadsInProgress--;
              if (this._uploadResponse) {
                let r = this.prService.externalResources.find((r) => r.id === this._uploadResponse.id);
                if (r) {
                  if (this._uploadResponse.resource_big)
                    r.base64 = this._uploadResponse.resource_big;
                  r.loading = false;
                  previewResourceItem.big = r.base64;
                  previewResourceItem.loading = false;
                  this.prService.externalResourcesCounter++;
                }
              }
              if (!this._failure)
                resolve(this._uploadResponse);
            });
        };
        this.prService.uploadExternalResource(this._resource.file || this._dropZoneFile.file, onSuccess);
        this.prService.externalResources.push(this._resource);
        this.prService.previewResourceItems.push(previewResourceItem);
      });
    }

    async afterPreset(resource: ProductResource, viewerUrl: string, previewResourceItem: PreviewResourceItem) {
      if (this.prService.utils.getRenderingEngine(viewerUrl) === RenderingEngine.THREE)
          resource.resource_default = this.prService.utils.setUrlParam(viewerUrl, 'tv', THREE_LATEST_VERSION);
      resource.resource_default = this.prService.utils.setUrlParam(resource.resource_default, 'json-data', new Date().getTime().toString());
      // resource.json_params = await this.assetAdjustmentsHelperService.onBeforeSave(this.assetAdjustmentsService.getJsonParams());
      resource.update_json_params = true;
      await this.prService.putResource(resource);
    }
}
