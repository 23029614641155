import { Component, Input, OnInit } from '@angular/core';
import { BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { RestService } from 'src/app/communication/rest.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { RetailersMeshConvention } from '../retailer';

@Component({
  selector: 'app-retailer-mesh-names',
  templateUrl: './retailer-mesh-names.component.html',
  styleUrls: ['./retailer-mesh-names.component.scss']
})
export class RetailerMeshNamesComponent implements OnInit {
  @Input('config') config: Array<RetailersMeshConvention>;
  @Input('retailer-id') retailerId: number;
  constructor(
    private rest: RestService,
    private utils: UtilsService
  ) { }

  ngOnInit(): void {
  }

  add() {
    this.config.push({
      retailer_id: this.retailerId
    } as RetailersMeshConvention);
  }

  save() {
    let total = this.config.length;
    this.config.forEach(c => {
      let query = '', method = 'post';
      if (c.id) {
        method = 'put';
        query += `/${c.id}`;
      }
      query += `?rid=${this.retailerId}`;
      this.rest.meshConvention(method, c, query).subscribe((res: RetailersMeshConvention) => {
        c.id = res.id;
        if (--total == 0) {
          this.utils.notifyUser({
            text: 'mesh convention saved',
            type: BroadcasterNotificationType.Success
          });
        }
      },
        err => this.utils.httpErrorResponseHandler(err, 'failure saving mesh naming convention'));
    });
  }

  remove(index: number) {
    if (!confirm('Are you sure you want to delete this mesh naming convention?')) return;
    let c = this.config[index];
    if (!c.id) {
      this.config.splice(index, 1);
      return;
    }
    let query = `/${c.id}?rid=${this.retailerId}`;
    this.rest.meshConvention('delete', null, query).subscribe(() => {
      this.utils.notifyUser({ text: 'mesh naming convention deleted successfully', type: BroadcasterNotificationType.Success });
      this.config.splice(index, 1);
    }, err => this.utils.httpErrorResponseHandler(err, 'failure deleting mesh naming convention'));
  }
}
