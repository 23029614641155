import { Injectable } from '@angular/core';
import { GraphqlService } from '../communication/graphql.service';
import { RejectReason } from '../retailer/retailer';

@Injectable({
  providedIn: 'root'
})
export class RejectReasonService {
  private _rejectReasons: Array<RejectReason>;
  private _stack: Array<Function>;
  constructor(
    private gql: GraphqlService
  ) {
    this._stack = [];
  }

  getRejectReasons(): Promise<Array<RejectReason>> {
    return new Promise(async (resolve, reject) => {
      if (this._rejectReasons) {
        resolve(this._rejectReasons);
        return;
      }
      this._stack.push(resolve);
      if (this._stack.length === 1) {
        this._rejectReasons = await this._getRejectReasons();
        this._stack.forEach(f => f(this._rejectReasons));
      }
    });
  }

  private async _getRejectReasons() {
    return (await this.gql.productsRejectReasons().toPromise()).data.products_reject_reasons;
  }
}
