import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RetailerFilterOptions, RetailerQueryInner } from './retailer';
import { AuthService } from '../auth/auth.service';
import { GraphqlService } from '../communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { ProductService } from '../product/product.service';
import { GlobalsService } from '../shared/globals.service';

@Injectable()
export class RetailerProductResolve  {

    constructor(
        private gql: GraphqlService,
        private auth: AuthService,
        private productService: ProductService,
        private globals: GlobalsService
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        let rid = null;
        if (this.productService.editItem && route.params['rid']) {
            return null;
        }
        if (route.params['rid'])
            rid = route.params['rid'] * 1;
        else if (this.auth && this.auth.user && this.auth.user.retailers_users && this.auth.user.retailers_users[this.auth.retailerIndex]) {
            rid = this.auth.user.retailers_users[this.auth.retailerIndex].retailer_id;
        }
        const options = {
            id: rid
        } as RetailerFilterOptions;
        return this.gql.retailerForProduct(options) as Observable<ApolloQueryResult<RetailerQueryInner>>;
    }
}