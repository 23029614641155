import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HeaderModule } from 'src/app/header/header.module';
import { TermsComponent } from './terms/terms.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { RecoverPasswordResolve } from 'src/app/auth/recover-password/recover-password.resolve';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { UserResolve } from './user-settings/user.resolve';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { RolesManagementComponent } from './roles-management/roles-management.component'; 
import { UserDialog } from './user-card/user-dialog';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { InviteDialog } from './users-summary/invite-dialog';
import { UsersListItemComponent } from './users-list-item/users-list-item.component';
import { UsersListDialog } from './users-summary/users-list-dialog';
import { UsersPipe } from './users.pipe';
import { PrivacyComponent } from './privacy/privacy.component';
import { PrivacyViewComponent } from './privacy-view/privacy-view.component';
import { TermsViewComponent } from './terms-view/terms-view.component';
import { EmailNotificationsComponent } from './email-notifications/email-notifications.component';
import { EmailNotificationsIntervalsPipe } from './email-notifications-intervals.pipe';
import { SelfProfileComponent } from './self-profile/self-profile.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { PaymentStatusResolve } from './payment-status/payment-status.resolve';
import { QrViewComponent } from './qr-view/qr-view.component';
import { SsoOptionsComponent } from './sso-options/sso-options.component';
import { SocialResolve } from './social/social.resolve';

@NgModule({
    imports: [
        CommonModule, SharedModule, HeaderModule
    ],
    providers: [RecoverPasswordResolve, UserResolve, PaymentStatusResolve, SocialResolve],
    declarations: [LoginComponent, RegisterComponent, RecoverPasswordComponent, UserSettingsComponent,
        UserProfileComponent, RolesManagementComponent, InviteUserComponent,
        UsersListItemComponent, UsersPipe, PrivacyViewComponent, TermsViewComponent, EmailNotificationsComponent,
        EmailNotificationsIntervalsPipe, SelfProfileComponent, PaymentStatusComponent, UserDialog, InviteDialog,
        UsersListDialog, TermsComponent, PrivacyComponent, QrViewComponent, SsoOptionsComponent] //, UserDialog, InviteDialog, UsersListDialog, TermsComponent, PrivacyComponent
})
export class AuthModule { }
