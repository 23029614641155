import { Component, Input, OnChanges } from '@angular/core';
import { SubCategory, Category, SubCategoryUI, RetailersSubCategoriesSpecification } from '../retailer';
import { UtilsService } from 'src/app/shared/utils.service';
import { ProductCategoriesService } from 'src/app/product-categories/product-categories.service';
import { ProductCategory, ProductSubCategory } from 'src/app/product-categories/product-categories';
import { RestService } from 'src/app/communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { FileObject } from 'src/app/shared/file';
import { JobTypes } from '../job-types';
import { JobTypesService } from '../job-types.service';
import { ResumableUploadService } from 'src/app/shared/resumable-upload.service';
import { GlobalsService } from 'src/app/shared/globals.service';

@Component({
  selector: 'app-retailer-sub-categories',
  templateUrl: './retailer-sub-categories.component.html',
  styleUrls: ['./retailer-sub-categories.component.scss']
})
export class RetailerSubCategoriesComponent implements OnChanges {
  @Input('retailer-id') retailerId: number;
  @Input('config') subCategories: Array<SubCategory>;
  @Input('retailer-categories') retailerCategories: Array<Category>;
  public pCategories: Array<ProductCategory>;
  public pSubCategories: Array<ProductSubCategory>;
  public items: Array<SubCategory>;
  public uploading: boolean;
  public jobTypes: Array<JobTypes>;
  // public uploaded: boolean;
  constructor(
    private utils: UtilsService,
    private productCategoriesService: ProductCategoriesService,
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private jobTypesService: JobTypesService,
    private resumableUpload: ResumableUploadService,
    private globals: GlobalsService
  ) {
    this.productCategoriesService.getCategories().subscribe(
      (pCategories: Array<ProductCategory>) => this.pCategories = pCategories
    )
    this.getJobTypes();
  }

  async ngOnChanges() {
    this.items = [];
    if (this.subCategories) {
      const items = this.utils.deepCopyByValue(this.subCategories) as Array<SubCategory>;
      for (let j = 0; j < items.length; j++) {
        const i = items[j] as SubCategoryUI;
        if (i.product_sub_category_id) {
          const psc = this.productCategoriesService.subCategoriesDictionaryDictionary[i.product_sub_category_id];
          if (psc)
            i.productsSubCategories = this.productCategoriesService.categoriesDictionaryDictionary[psc.category_id].products_sub_categories;
        }
        this.items.push(i);
        await this.utils.setTimeout();
      }
    }
    this.initItems();
    this.getJobTypes();
  }

  async getJobTypes() {
    this.jobTypes = await this.jobTypesService.getJobTypes();
  }

  initItems() {
    this.items.forEach((item: SubCategory) => {
      if (!item.retailers_sub_categories_specifications)
        item.retailers_sub_categories_specifications = [];
      // if (!item.retailers_sub_categories_specifications[0])
      // item.retailers_sub_categories_specifications[0] = {

      // } as RetailersSubCategoriesSpecification;
    })
  }

  addSpec(item: SubCategory) {
    item.retailers_sub_categories_specifications.push({} as RetailersSubCategoriesSpecification);
  }

  deleteSpec(item: SubCategory, i: number) {
    item.retailers_sub_categories_specifications.splice(i, 1);
  }

  add() {
    this.items.push({
      retailer_id: this.retailerId,
      retailers_sub_categories_specifications: []
    } as SubCategory);
  }

  onProductCategoryChange(item: SubCategoryUI) {
    if (item.productCategoryId) {
      let c = this.productCategoriesService.categoriesDictionaryDictionary[item.productCategoryId];
      if (c) {
        item.productsSubCategories = c.products_sub_categories;
      }
    }
  }

  // onChange(item: SubCategory) {
  //   item.retailer_id = this.retailerId;
  //   if (item.category_id && item.sub_category_name_org_lang) {
  //     const query = (item.id ? `/${item.id}` : '') + '?rid=' + this.retailerId;
  //     this.rest.retailerSubcategory(item.id ? 'put' : 'post', item, query).subscribe(
  //       res => Object.assign(item, res),
  //       err => {
  //         let data: BroadcasterNotification = {
  //           text: err._body ? err._body : 'failure inviting user',
  //           type: BroadcasterNotificationType.Error,
  //           action: 'OK'
  //         };
  //         this.broadcaster.broadcast('notifyUser', data);
  //       }
  //     );
  //   }
  // }

  onTextChange(spec: RetailersSubCategoriesSpecification, text: string) {
    spec.text = text;
  }

  async onFilesChange(fileList: Array<FileObject>, spec: RetailersSubCategoriesSpecification) {
    if (fileList.length > 0) {
      this.uploading = true;
      this.globals.increaseNumOfRequestsInProgress();
      spec.attachment = await this.resumableUpload.file(fileList[0].file);
      this.uploading = false;
      this.globals.decreaseNumOfRequestsInProgress();
    }
  }

  save(item: SubCategoryUI) {
    if (this.uploading) return;
    item.retailer_id = this.retailerId;
    if (item.category_id && item.sub_category_name_org_lang) {
      const query = (item.id ? `/${item.id}` : '') + '?rid=' + this.retailerId;
      this.rest.retailerSubcategory(item.id ? 'put' : 'post', item, query).subscribe(
        res => {
          Object.assign(item, res);
          // if (this.uploaded) {
          const specQuery = `?rscid=${item.id}&rid=${this.retailerId}`;
          const payload = item.retailers_sub_categories_specifications;
          // const payload = [{
          //   attachment: item.retailers_sub_categories_specifications[0].attachment,
          //   title: item.retailers_sub_categories_specifications[0].title
          // }] as Array<RetailersSubCategoriesSpecification>;
          this.rest.retailerSubcategorySpec('put', payload, specQuery).subscribe(
            (specs: Array<RetailersSubCategoriesSpecification>) => {
              let data: BroadcasterNotification = {
                text: 'retailer sub categories successfully saved',
                type: BroadcasterNotificationType.Success,
                action: 'OK'
              };
              this.broadcaster.broadcast('notifyUser', data);
              item.retailers_sub_categories_specifications = specs;
            },
            err => this.utils.httpErrorResponseHandler(err, 'failure saving attachments')
          )
          // this.uploaded = false;
          // }
        },
        err => this.utils.httpErrorResponseHandler(err, 'failure inviting user')
      );
    }
  }
}
