import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'app-simple-slider',
  templateUrl: './simple-slider.component.html',
  styleUrls: ['./simple-slider.component.scss']
})
export class SimpleSliderComponent implements OnInit {
  @Input() images: Array<string>;
  @Input('i-width') width: number;
  @Input('i-height') height: number;
  @Input('support-copy') supportCopy: boolean;
  @ViewChild('wrap') wrap: ElementRef;
  public currentIndex: number;
  public total: number;
  public imm: boolean;
  private xDown: number;
  private yDown: number;
  private lastXDiff: number;
  private lazySides: number;
  private indexBeforeSwipe: number;
  constructor(private sanitizer: DomSanitizer, private utils: UtilsService) {
    this.currentIndex = 0;
    this.lazySides = 1;
    this.lastXDiff = 0;
    this.imm = false;
  }

  ngOnInit() {
    if (this.images)
      this.total = this.images.length;
    else
      this.total = 0;
    this.atachEvents();
  }

  atachEvents() {
    this.wrap.nativeElement.addEventListener('touchstart', this.handleTouchStart.bind(this), false);
    this.wrap.nativeElement.addEventListener('mousedown', this.handleTouchStart.bind(this), false);

    this.wrap.nativeElement.addEventListener('touchmove', this.handleTouchMove.bind(this), false);
    this.wrap.nativeElement.addEventListener('mousemove', this.handleTouchMove.bind(this), false);

    this.wrap.nativeElement.addEventListener('touchend', this.handleTouchEnd.bind(this), false);
    this.wrap.nativeElement.addEventListener('mouseup', this.handleTouchEnd.bind(this), false);
    this.wrap.nativeElement.addEventListener('mouseout', this.handleTouchEnd.bind(this), false);
  }

  handleTouchStart(evt) {
    if (evt.type == 'touchstart') {
      this.xDown = evt.touches[0].clientX;
      this.yDown = evt.touches[0].clientY;
    }
    else {
      this.xDown = evt.clientX;
      this.yDown = evt.clientY;
    }
    this.lazySides = 2;
    this.imm = true;
    this.indexBeforeSwipe = this.currentIndex;
  }

  handleTouchMove(evt) {
    if (!this.xDown || !this.yDown) {
      return;
    }
    var xUp, yUp;
    if (evt.type == 'touchmove') {
      xUp = evt.touches[0].clientX;
      yUp = evt.touches[0].clientY;
    }
    else {
      xUp = evt.clientX;
      yUp = evt.clientY;
    }

    var xDiff = this.xDown - xUp;
    var yDiff = this.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (this.lastXDiff < xDiff)
        this.currentIndex = (Math.floor(this.indexBeforeSwipe) + xDiff / this.width);
      else
        this.currentIndex = (Math.ceil(this.indexBeforeSwipe) + xDiff / this.width);
      this.lastXDiff = xDiff;
    }
    // evt.preventDefault();
  };

  handleTouchEnd() {
    this.xDown = null;
    this.yDown = null;
    if (typeof this.indexBeforeSwipe === 'number') {
      let reminder = this.currentIndex - this.indexBeforeSwipe;
      this.currentIndex = Math.min(Math.max(Math.round(this.currentIndex + reminder), 0), this.total - 1);
    }
    delete this.indexBeforeSwipe;
    this.imm = false;
  }

  getBackground(image, index): SafeStyle {
    // prevent invisible images from unnecessarily loading
    if (Math.abs(this.currentIndex - index) > this.lazySides) return '';
    return this.sanitizer.bypassSecurityTrustStyle(`url(${image})`);
  }

  navLeft() {
    if (this.currentIndex == 0) return;
    this.currentIndex--;
  }

  navRight() {
    if (this.currentIndex == this.total) return;
    this.currentIndex++;
  }
}
