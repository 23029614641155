import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GraphqlService } from 'src/app/communication/graphql.service';
import { ProductAudit, ProductAuditFilterOptions, ProductAuditUIGroup } from '../product';
import { ProductService } from '../product.service';
import { Retailer } from 'src/app/retailer/retailer';
import { UtilsService } from 'src/app/shared/utils.service';
import { RolesHelperService } from 'src/app/auth/roles-helper.service';
import { AuthService } from 'src/app/auth/auth.service';
import { GlobalsService } from 'src/app/shared/globals.service';
import { ScrollHelper } from 'src/app/shared/scroll-helper';

@Component({
  selector: 'app-products-audit',
  templateUrl: './products-audit.component.html',
  styleUrls: ['./products-audit.component.scss']
})
export class ProductsAuditComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('scroll', { static: false }) public scroll: ElementRef;
  public list: Array<ProductAuditUIGroup>;
  public retailers: Array<Retailer>;
  public productsAudit: Array<ProductAudit>;
  public retailer: Retailer;
  public retailerId: number;
  public fullRetailer: Retailer;
  public isSU: boolean;
  private limit: number;
  private offset: number;
  private subTitle: string;
  private scrollHelper: ScrollHelper;
  constructor(private gql: GraphqlService,
    public productService: ProductService,
    public rolesHelper: RolesHelperService,
    public auth: AuthService,
    private utils: UtilsService,
    private globals: GlobalsService) {
    this.list = [];
    this.isSU = rolesHelper.isSuperUserOrObserverLoggedIn();
    this.limit = 25;
    this.offset = 25;
  }

  ngOnInit(): void {
    if(this.isSU){
      this.subTitle = "All retailers";
    }
    this.fetchAudits(this.list.length, this.limit);
      this.globals.setBreadcrumbs();
      this.globals.playgroundClass = null;
  }

  ngAfterViewInit() {
    this.initScrollHelper();
  }

  initScrollHelper() {
      this.scroll = new ElementRef(document.getElementById('scroll'));
      this.scrollHelper = new ScrollHelper(this.scroll.nativeElement);
      this.scrollHelper.onScrollThreshold.subscribe(this.fetchMore.bind(this));
  }

  setHeader(){
    this.globals.setHeaer('Products History', this.subTitle);
  }

  onRetailerChange(retailer: Retailer) {
    if (retailer) {
      this.retailer = retailer;
      this.subTitle = retailer.name;
      this.list = [];
      this.fetchAudits(this.list.length, this.limit);
    }
  }

  fetchMore() {
    this.fetchAudits(this.offset, this.limit);
  }

  fetchAudits(offset: number, limit: number) {
    this.fetchNextChunk(offset, limit).then(chunk => {
      this.list = this.list.concat(chunk);
      this.setHeader();
    },
      (err) => this.utils.httpErrorResponseHandler(err, 'Failure to get products audit'));
  }

  fetchNextChunk(skip: number, limit: number): Promise<Array<ProductAuditUIGroup>> {
    return new Promise((resolve, reject) => {
      let option: ProductAuditFilterOptions = { offset: skip, limit: limit };
      if (!this.isSU) {
        option.retailer_id = [this.auth.user.retailers_users[this.auth.retailerIndex].retailer_id];
      } else if (this.retailer) {
        option.retailer_id = [this.retailer.id];
      }
      this.gql.productsAudit(option).subscribe(
        res => {
          this.productsAudit = res.data.allProductsAudit.rows;
          this.offset += this.productsAudit.length;
          resolve(this.productService.mapAudit(null, this.productsAudit, null));
        },
        err => reject(this.utils.httpErrorResponseHandler(err, 'failure to get products audit'))
      );
    });
  }

  ngOnDestroy() {
    this.scrollHelper.destroy();
  }
}
