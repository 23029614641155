import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CacheService } from './cache.service';
import { EndpointsService } from './endpoints.service';
import { SharedModule } from '../shared/shared.module';
import { BroadcastNotificationsComponent } from './broadcast-notifications/broadcast-notifications.component';

@NgModule({
  imports: [
    CommonModule, SharedModule
  ],
  providers: [CacheService, EndpointsService],
  declarations: [BroadcastNotificationsComponent]
})
export class CommunicationModule { }
