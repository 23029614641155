<div class="wrap">
  <form [formGroup]="payForm" novalidate (ngSubmit)="pay()">
    <div fxLayout="column" [hidden]="wantToPay">
      <mat-form-field fxFill>
        <input matInput type="number" placeholder="Amount" formControlName="amount" required>
        <mat-hint *ngIf="payForm.hasError('required',['amount']) && payForm.dirty" align="start">amount</mat-hint>
      </mat-form-field>
      <div fxFlex *ngIf="vat > 1 && this.payForm.controls.amount.value">
        <div class="text2 warn">
          (payment after VAT: {{this.payForm.controls.amount.value * vat}}$)
        </div>
        <br>
      </div>
      <div fxFlex>
        <button *ngIf="!wantToPay" mat-raised-button class="third_button submit min-button-width" (click)="pay()">
          add funds
        </button>
      </div>
    </div>
    <app-pay *ngIf="wantToPay && payForm.valid" [amount]="payForm.controls.amount.value" [retailer]="retailer"></app-pay>
  </form>
</div>