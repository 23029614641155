import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { environment } from '../../environments/environment';

declare var grecaptcha: any;

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  static SCRIPT = 'https://www.google.com/recaptcha/api.js?render=';
  private _stock: Array<Function>;
  private _loaded: boolean;
  constructor(private utils: UtilsService) {
    this._stock = [];
  }

  private async _beforeRequest() {
    return new Promise(async (resolve, reject) => {
      if (this._loaded) {
        resolve(null);
        return;
      }
      if (this._stock.length > 0) {
        this._stock.push(resolve);
        return;
      }
      this._stock.push(resolve);
      await this.utils.loadScript(
        `${RecaptchaService.SCRIPT}${environment.captchaSiteKey}`
      );
      this._stock.forEach((f) => f());
      this._stock = [];
    });
  }

  execute(action: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      this.utils.cancelEvent();
      await this._beforeRequest();
      grecaptcha.ready(function () {
        grecaptcha
          .execute(environment.captchaSiteKey, { action })
          .then(function (token: string) {
            resolve(token);
          });
      });
    });
  }
}
