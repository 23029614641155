<div fxLayout="column" fxLayoutAlign="space-around">
  <mat-form-field fxFill>
    <textarea matInput placeholder="JavaScript Desktop Config (for power users only!)" [(ngModel)]="container.desktop"></textarea>
  </mat-form-field>
  <mat-form-field fxFill>
    <textarea matInput placeholder="JavaScript Mobile Config (for power users only!)" [(ngModel)]="container.mobile"></textarea>
  </mat-form-field>
  <mat-form-field fxFill>
    <textarea matInput placeholder="JavaScript Integration Config (for power users only!)" [(ngModel)]="container.integration"></textarea>
  </mat-form-field>
  <div fxFill>
    <button mat-raised-button mat-raised-button color="accent" (click)="save()" class="thinner marg-top">
      save integration code
    </button>
  </div>
</div>