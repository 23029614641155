import { Component, OnInit, Input, ChangeDetectorRef, OnChanges, AfterViewInit } from '@angular/core';
import { Product, ImageSet, ProductsResourcesFeedback, ProductResource, MediaTag } from '../product';
import { FeedbackService } from '../feedback.service';
import { RestService } from '../../communication/rest.service';
import { BroadcasterService } from 'ng-broadcaster';
import { BroadcasterNotification, BroadcasterNotificationType } from '../../communication/broadcaster-notifications';
import { ProductRequestService } from '../product-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { EnumsService } from 'src/app/shared/enums.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Walkthrough } from "walkthrough-js/dist/walkthrough";
import { TutorialStage, TutorialOptions } from 'walkthrough-js/dist/interface';
import { ProductService } from '../product.service';
import { FeedbackType } from 'src/app/shared/enums';
import { RolesHelperService } from 'src/app/auth/roles-helper.service';
import { ResumableUploadService } from 'src/app/shared/resumable-upload.service';

@Component({
  selector: 'app-feedback-creation',
  templateUrl: './feedback-creation.component.html',
  styleUrls: ['./feedback-creation.component.scss']
})
export class FeedbackCreationComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() product: Product;
  @Input() resource: ProductResource;
  public model: string;
  public currentImageIndex: number;
  public smallImagesUrls: Array<string>;
  public resourcesImagesUrls: Array<string>;
  public images: Array<ImageSet>;
  public active: boolean;
  public mediaTag: MediaTag;
  public tutorialActive: boolean;
  private w: Walkthrough;
  private isUploading: boolean;
  private saveAfterUpload: boolean;
  public isShowSubType: boolean;
  constructor(
    private rest: RestService,
    private broadcaster: BroadcasterService,
    public feedbackService: FeedbackService,
    private prService: ProductRequestService,
    private pService: ProductService,
    private cdRef: ChangeDetectorRef,
    private utils: UtilsService,
    private enums: EnumsService,
    private auth: AuthService,
    private roles: RolesHelperService,
    private ruService: ResumableUploadService
  ) {
    this.active = true;
  }

  ngOnInit() {
    this.currentImageIndex = 0;
    this.images = this.product.UI.images || [];
    this.smallImagesUrls = [];
    if (this.images.length) {
      this.images.forEach(item => {
        this.smallImagesUrls.push(item.small);
      });
    }
    this.resourcesImagesUrls = [];
    this.prService.enabledResources.forEach(pr => {
      this.resourcesImagesUrls.push(this.enums.getIconByTag(pr.resource_type));
    });
    this.init();
    this.isShowSubType = this.roles.doesUserHasPermission('feedback sub-type');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let stages = [
        {
          title: 'visual comparison:',
          desc: 'Compare the 3D model to the provided images. You can rotate and zoom until the model is aligned with the item\'s position.',
          selector: '.screenshot-wrap'
        },
        {
          title: 'feedback type:',
          desc: 'Select the type of the feedback.',
          selector: '.feedback-types',
          top: (window.innerHeight - 445) + 'px',
          left: '-50px'
        },
        {
          title: 'provide explanation:',
          desc: 'Write what you want us to improve.',
          selector: 'app-feedback-creation app-text-editor',
          top: (window.innerHeight - 380) + 'px',
          left: '-25px'
        },
        {
          title: 'save your feedback:',
          desc: 'Click this button when you are done.',
          selector: 'app-feedback-creation .min-button-width',
          top: '0px',
          left: '-40px'
        }
      ] as Array<TutorialStage>;
      let options = new TutorialOptions();
      options.identifier = 'feedback';
      this.w = new Walkthrough();
      this.tutorialActive = true;
      this.w.setTutorial(stages, options).subscribe(() => this.tutorialActive = false);
    }, 2000);
  }

  ngOnChanges() {
    this.init();
  }

  setMediaTag() {
    this.mediaTag = this.utils.getMediaTagByResource(this.resource);
  }

  init() {
    this.feedbackService.init(this.product, this.resource);
    this.setMediaTag();
    this.setModel();
  }

  setModel() {
    switch (this.mediaTag) {
      case MediaTag.MODEL: {
        this.model = this.resource?.resource_default ? this.utils.setUrlParam(this.resource.resource_default, 'theme-color', '#546994') : null;
        break;
      }
      case MediaTag.IFRAME_RENDR: {
        this.model = this.resource.resource_big || this.resource.resource_small;
        break;
      }
      default: {
        this.model = this.resource?.resource_default ? this.resource.resource_default : null;
        break;
      }
    }
  }

  onTextChange(notes: string) {
    this.feedbackService.currentfeedback.controls['notes'].setValue(notes);
  }

  addRejectWithImage(img: string): void {
    this.feedbackService.currentfeedback.controls['screenshot'].setValue(img);
  }

  setIndex(index: number) {
    this.currentImageIndex = index;
  }

  setResourceIndex(index: number) {
    this.prService.currentResourceIndex = index;
  }

  isVisual() {
    if (!this.feedbackService.currentfeedback)
      return false;
    let type = this.feedbackService.currentfeedback.get('feedback_type').value;
    if (type !== FeedbackType.others) return true;
    return false;
  }

  async create() {
    if (this.isUploading) {
      this.saveAfterUpload = true;
      this.utils.notifyUser({
        text: 'Uploading images. Feedback will be saved shortly.',
        type: BroadcasterNotificationType.Info
      })
      return;
    }
    if (!this.tutorialActive && (!this.feedbackService.currentfeedback.valid || !this.feedbackService.isScreenshotValid()))
      return;
    let payload = this.feedbackService.currentfeedback.getRawValue() as ProductsResourcesFeedback;
    payload.resource_id = this.resource.id;
    if (payload.feedback_type === FeedbackType.lighting_shadow) {
      let textlength = 0;
      if (payload.notes)
        textlength = this.utils.deleteHtmlTags(payload.notes)?.length;
      if (textlength < 2) {
        let data: BroadcasterNotification = {
          text: 'Please add your comment before submission.',
          type: BroadcasterNotificationType.Info,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
        return;
      }
    }
    payload.title = this.feedbackService.types.find(t => t.id == payload.feedback_type).description + ' issue';
    if (payload.screenshot && payload.screenshot.indexOf('data:image/') === 0)
      payload.screenshot = await this.ruService.getUrlFromBase64(payload.screenshot);
    let query = `?rid=${this.auth.getRetailerIdByIndex(this.auth.retailerIndex)}`;
    this.rest.feedback('post', payload, query).subscribe(
     async () => {
        const data: BroadcasterNotification = {
          text: 'feedback sent',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', data);
        this.active = false;
        this.init();
        let product = await this.pService.getProduct(this.prService.request.id);
        if (product)
          this.prService.request = product;
        this.prService.setHeader();
        this.prService.setBreadcrumbs();
        this.prService.refreshAudit();
        this.cdRef.detectChanges();
        this.active = true;
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure uploading image')
    );
  }

  onUploading(isUploading: boolean) {
    this.isUploading = isUploading;
    console.log('isUploading', this.isUploading);
    if (!this.isUploading && this.saveAfterUpload) {
      this.create();
    }
  }

  ngOnDestroy() {
    this.w.destroy(false);
  }
}