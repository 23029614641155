import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { RetailersScraperConfig } from '../retailer';
import { RestService } from '../../communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from '../../communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { UtilsService } from '../../shared/utils.service';

@Component({
  selector: 'app-retailer-scraper-config',
  templateUrl: './retailer-scraper-config.component.html',
  styleUrls: ['./retailer-scraper-config.component.scss']
})
export class RetailerScraperConfigComponent implements OnInit, OnChanges {
  @Input() config: RetailersScraperConfig;
  @Input('retailer-id') retailerId: number;
  constructor(
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    this.setDefaults();
  }

  ngOnChanges() {
    this.setDefaults();
  }

  setDefaults() {
    if (!this.config)
      this.config = {
        retailer_id: this.retailerId
      } as RetailersScraperConfig;
    else
      this.config = this.utils.deepCopyByValue(this.config);
  }

  save() {
    let query = this.config.id ? '/' + this.config.id : '';
    query += '?rid=' + this.retailerId;
    this.rest.retailerScraper(this.config.id ? 'put' : 'post', this.config, query).subscribe(
      data => {
        this.config = data as RetailersScraperConfig;
        let res: BroadcasterNotification = {
          text: 'code updated successfully',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', res);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure updating code')
    );
  }
}
