import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {EndPoints} from './endpoints';


@Injectable({
  providedIn: 'root'
})
export class EndpointsService {
  private endpoints: Map<string, string>;

  constructor() {
    this.endpoints = new Map();
    this.setEndpoints();
  }

  public getEndpointDomain(name) {
    return this.endpoints.get(name);
  }

  possibleHosts(): Array<string> {
    return ['cms.hexa3d.io', 'www.h3dqa.com', 'www.h3ddev.com'];
  }

  isLocalhost(): boolean {
    return location.hostname === 'localhost' || location.hostname === '127.0.0.1';
  }

  private setEndpoints() {
    const cDomain = environment.endPoints.c3dDomain;
    const hDomain = environment.endPoints.cmsDomain;

    this.endpoints.set(EndPoints.CMS, `https://r.${hDomain}`);
    this.endpoints.set(EndPoints.GRAPH, `https://graph.${hDomain}`);
    this.endpoints.set(EndPoints.CAROUSEL, `https://rc.${hDomain}`);
    this.endpoints.set(EndPoints.NOTIF_WEBSOCKETS, `https://notifications.${hDomain}`);
    this.endpoints.set(EndPoints.ANALYTICS, `https://analytics.${hDomain}`);
    this.endpoints.set(EndPoints.UPLOAD, `https://upload.${hDomain}`);
    this.endpoints.set(EndPoints.THREE_JS_VIEWER, `https://${environment.endPoints.viewerDomain}`);
    this.endpoints.set(EndPoints.CREATORS_VIEWER, `https://${environment.endPoints.creatorsViewerDomain}`);
    this.endpoints.set(EndPoints.DOWNLOAD, `https://download.${hDomain}`);
    this.endpoints.set(EndPoints.CREATORS, `https://${cDomain}`);

    if (environment.rootCssClass)
      document.body.classList.add(environment.rootCssClass);
  }
}
