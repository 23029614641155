export enum PlatgroundClass {
    SMALL,
    MEDIUM,
    SIDEBAR,
    FULL,
    OVERFLOW,
}

export interface NavigationLink {
    text: string;
    link: string;
    nativeLink?: string;
    params?: any;
}