import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MulitipleScraperComponent } from './mulitiple-scraper/mulitiple-scraper.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ScraperBatchesComponent } from './scraper-batches/scraper-batches.component';

@NgModule({
  imports: [
    CommonModule, SharedModule
  ],
  declarations: [MulitipleScraperComponent, ScraperBatchesComponent]
})
export class ScraperModule { }
