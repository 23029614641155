import { Component, Input } from '@angular/core';
import { RestService } from '../../communication/rest.service';
import { EnumsService } from '../../shared/enums.service';
import { KeyValuePair } from 'src/app/shared/enums';
import { Retailer } from '../retailer';
import { UtilsService } from '../../shared/utils.service';


@Component({
    selector: 'app-retailer-implementation-info',
    templateUrl: './retailers-implementation-info.component.html',
    styleUrls: ['./retailers-implementation-info.component.scss']
  })
  export class RetailersImplementationInfoComponent {

    @Input('retailer') retailer: Retailer;

    public implementationTypeOptions: Array<KeyValuePair>;
    public preloadModelOptions: Array<KeyValuePair>;
  
    constructor(private enums: EnumsService, 
                private rest: RestService,  
                private utils: UtilsService) {
        this.implementationTypeOptions = this.enums.getImplementationType();
        this.preloadModelOptions = this.getBooleanType();
    }

    /**
     * PUT the implementation type and proload model
     */
    public save() {
        this.rest.retailer('put', this.retailer, '/' + this.retailer.id).subscribe((_) => {},
        err => this.utils.httpErrorResponseHandler(err, 'failure updating retailer'));
    }

   /**
   * Method returns the array of key-value pairs representing the BooleanType enum
   * @returns 
   */
  public getBooleanType(): Array<KeyValuePair> {
    return [
      { key: false, value: 'false' },
      { key: true, value: 'true' }
    ];
  }
}