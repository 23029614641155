import { Component, OnInit, OnChanges, OnDestroy, Input } from '@angular/core';
import { RetailersSourceFilesType } from '../retailer';
import { SourceFileType } from '../../product/product';
import { Subscription } from 'rxjs';
import { SourceFileTypesService } from '../../shared/source-file-types.service';
import { RestService } from '../../communication/rest.service';
import { BroadcasterService } from 'ng-broadcaster';
import { UtilsService } from '../../shared/utils.service';
import { BroadcasterNotificationType, BroadcasterNotification } from '../../communication/broadcaster-notifications';

@Component({
  selector: 'app-retailer-source-files-types',
  templateUrl: './retailer-source-files-types.component.html',
  styleUrls: ['./retailer-source-files-types.component.scss']
})
export class RetailerSourceFilesTypesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() config: Array<RetailersSourceFilesType>;
  @Input('retailer-id') retailerId: number;
  public requiredFormats: Array<number>;
  public allFormats: Array<SourceFileType>;
  private subs: Array<Subscription>;
  constructor(
    private sourceFileTypesService: SourceFileTypesService,
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService
  ) {
    this.subs = [];
    this.allFormats = this.sourceFileTypesService.getCachedTypes();
    if (!this.allFormats) {
      this.subs.push(
        this.broadcaster.on('onSourceFilesTypes').subscribe(
          () => this.allFormats = this.sourceFileTypesService.getCachedTypes()
        )
      );
    }
  }

  ngOnInit() {
    this.setDefaults();
  }

  ngOnChanges() {
    this.setDefaults();
  }

  setDefaults() {
    if (!this.config)
      this.config = [];
    else
      this.config = this.utils.deepCopyByValue(this.config);
    this.requiredFormats = this.config.map(i => i.source_type_id);
  }

  save() {
    this.config = [];
    this.requiredFormats.forEach(rf => {
      this.config.push(
        {
          source_type_id: rf,
          retailer_id: this.retailerId
        } as RetailersSourceFilesType
      );
    });

    this.rest.sourceFileType('put', this.config, '?rid=' + this.retailerId).subscribe(
      data => {
        this.config = data as Array<RetailersSourceFilesType>;
        let res: BroadcasterNotification = {
          text: 'resources file types updated successfully',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', res);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure updating resources file types')
    );
  }

  ngOnDestroy() {
    if (this.subs.length)
      this.subs.forEach(s => s.unsubscribe());
  }
}
