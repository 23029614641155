import { Component, OnInit } from '@angular/core';
import { Retailer } from '../retailer';
import { GlobalsService } from '../../shared/globals.service';
import { GraphqlService } from '../../communication/graphql.service';
import { RestService } from '../../communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from '../../communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { ActivatedRoute } from '@angular/router';
import { RolesHelperService } from 'src/app/auth/roles-helper.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { QaMode } from 'src/app/shared/enums';
import { IP } from 'src/app/shared/retailer-product-ip/retailer-product-ip.interface';

@Component({
  selector: 'app-retailers-management',
  templateUrl: './retailers-management.component.html',
  styleUrls: ['./retailers-management.component.scss']
})
export class RetailersManagementComponent implements OnInit {
  public retailer: Retailer;
  public fullRetailer: Retailer;
  public isRetailerValid: boolean;
  public isGod: boolean;
  constructor(
    private globals: GlobalsService,
    private gql: GraphqlService,
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private activatedRoute: ActivatedRoute,
    public rolesHelper: RolesHelperService,
    private utils: UtilsService
  ) {
    this.isRetailerValid = true;
    this.isGod = this.rolesHelper.isGodLoggedIn();
  }

  ngOnInit() {
    setTimeout(() => {
      this.globals.setHeaer('Retailers Management', 'select a retailer');
      this.globals.setBreadcrumbs();
      this.globals.playgroundClass = null;
    }, 0);

    this.retailer = this.activatedRoute.snapshot.data['retailer']?.data?.retailers;
    if (this.retailer) {
      this.fullRetailer = this.retailer;
      this.refresh();
    }
  }

  clearAllCarouselsCache() {
    if (!confirm(`Are you sure you want to delete all of the retailer's carousel cache?`)) return;
    this.rest.clearAllCarouselsCache('post', {}).subscribe(
      () => {
        let data: BroadcasterNotification = {
          text: `All retailer's carousles cache was deleted`,
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure clearing carousel cache')
    )
  }

  createNewRetailer() {
    this.fullRetailer = {
      retailers_categories: [],
      retailers_configs: [],
      retailers_domains: [],
      retailers_paths: [],
      retailers_scrapers_configs: [],
      retailers_settings: [],
      retailers_sub_categories: [],
      retailers_users: [],
      retailers_contracts: [],
      retailers_specifications:[],
      retailers_batches: [],
      job_qa_mode: QaMode.FM_ONLY,
      ip: IP['Hexa’s IP']
    } as Retailer;
    this.globals.setHeaer('Retailers Management', 'create a new retailer');
    this.globals.setBreadcrumbs();
  }

  onRetailerChange(retailer: Retailer) {
    if (retailer) {
      this.utils.forceRedirectTo('/retailers-management/' + retailer.id);
    }
  }

  onRetailerEdit(retailer: Retailer) {
    this.retailer = retailer;
  }

  onRetailerValid(isValid: boolean) {
    this.isRetailerValid = isValid;
  }

  refresh() {
    setTimeout(() => {
      this.globals.setHeaer('Retailers Management', this.retailer.name);
    }, 0);
    this.globals.setBreadcrumbs();


  }

  save() {
    if (!this.retailer) {
      let data: BroadcasterNotification = {
        text: 'mandatory fields are missing',
        type: BroadcasterNotificationType.Error,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
      return;
    };
    if(this.retailer.studio_type > 0 &&
      confirm('Do you want to define all Amazon related settings for this retailer?')){
      this.retailer.define_amazon_settings = true;
    }  

    this.rest.retailer(this.retailer.id ? 'put' : 'post', this.retailer, this.retailer.id ? '/' + this.retailer.id : '').subscribe(
      data => {
        let emptyRetailer = {
          retailers_categories: [],
          retailers_sub_categories: [],
          retailer_collections: [],
          retailers_configs: [],
          retailers_scrapers_configs: [],
          retailers_domains: [],
          retailers_paths: [],
          retailers_settings: [],
          retailers_users: [],
          retailers_specifications: [],
          retailers_source_files_types: [],
          retailers_resources_types: [],
          retailers_budget: [],
          retailers_renders_settings: [],
          retailers_renders_angles: [],
          retailers_polygon_specifications: [],
          retailers_agreements: [],
          retailers_viewer_params: [],
          retailers_mesh_conventions: [],
          retailers_account_managers: [],
          retailers_contracts: [],
          retailers_batches: []
        } as Retailer;
        this.retailer = data as Retailer;
        this.fullRetailer = Object.assign(emptyRetailer, this.retailer);
        this.refresh();
        let msg: BroadcasterNotification = {
          text: 'Saved successfully!',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', msg);
        this.onRetailerChange(this.retailer);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure saving retailer settings')
    );
  }

  deleteRetailer(retailer: Retailer) {
    if (!confirm(`Are you sure you want to delete ${retailer.name} forever?`))
      return;
    this.rest.retailer('delete', null, `/${retailer.id}`).subscribe(
      () => delete this.fullRetailer,
      err => this.utils.httpErrorResponseHandler(err, 'failure deleting retailer')
    );
  }
}
