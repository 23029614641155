<div fxLayout="column" fxLayoutAlign="space-around">
  <mat-form-field fxFill>
    <mat-select placeholder="Default Source File Types" [(ngModel)]="requiredFormats" [multiple]="true">
      <mat-option *ngFor="let f of allFormats" [value]="f.id">
        {{ f.description }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div fxFill>
    <button mat-raised-button mat-raised-button color="accent" (click)="save()" class="thinner marg-top">
      save default source file types
    </button>
  </div>
</div>