<div class="wrap" fxLayout="column" fxLayoutAlign="space-around center">
  <div fxFlex class="inner">
    <div *ngIf="isSuccess">
      <div class="text-center">
        <mat-icon class="secondary">payment</mat-icon>
      </div>
      <h3 class="h2">payment was successful</h3>
      <div class="text3 text-center" *ngIf="budget">
        <p>
          Your current budget is <b>{{budget}}$</b>
        </p>
        <p>
          You can now create new assets!
        </p>
      </div>
    </div>
    <div *ngIf="!isSuccess">
        <div class="text-center">
        <mat-icon color="warn">payment</mat-icon>
      </div>
      <h3 class="h2">payment was unsuccessful</h3>
    </div>
  </div>
</div>