<mat-card>
  <mat-card-header>
    <div *ngIf="user.avatar_url" mat-card-avatar class="header-image"
      [style.backgroundImage]="'url(' + user.avatar_url + ')'"></div>
    <div *ngIf="!user.avatar_url" mat-card-avatar>
      <mat-icon class="account-circle">account_circle</mat-icon>
    </div>
    <mat-card-title class="ellipsis max-w-c">{{user.firstname}} {{user.lastname}}</mat-card-title>
    <mat-card-subtitle class="ellipsis max-w-c">{{user.email}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <br>
    <mat-divider></mat-divider>
    <br>
    <button mat-button class="outline thinnest edit-profile" *ngIf="useDialog" (click)="openDialog()">edit profile</button>
    <a mat-button routerLink="/settings/{{user.id}}" class="user-profile outline thinnest" *ngIf="!useDialog || roles.isSuperUserOrObserverLoggedIn()">
      edit {{useDialog && roles.isSuperUserOrObserverLoggedIn() ? 'full' : ''}} profile
    </a>
    <button mat-button class="outline thinnest float-op-dir"
      *ngIf="roles.isSuperUserOrObserverLoggedIn() && !(auth.user && auth.user.id == user.id)" (click)="deleteUser()">delete</button>
    <span class="link1 pointer float-op-dir logout-btn" *ngIf="auth.user && auth.user.id == user.id"
      (click)="logout()"><b>Log Out</b></span>
    <div *ngIf="showRetailersSwitch">
      <br>
      <mat-divider></mat-divider>
      <br>
      <div class="retailers-container scrollbar">
        <div class="retailer pointer" *ngFor="let ru of auth.user.retailers_users;let i = index" [ngClass]="{'gray4-bg': i == auth.retailerIndex}" (click)="setRetailer(i)">
          <img class="round" *ngIf="ru.retailers[0].logo_url" [src]="ru.retailers[0].logo_url" alt="retailer logo">
          <span class="name va-top">{{ru.retailers[0].name}}</span>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
