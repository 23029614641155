import { BehaviorSubject } from "rxjs";
import { ElementDimensions } from "../shared/utils";
import { User } from "../auth/user";
import { ProductSubCategory } from "../product-categories/product-categories";
import { JobsTypes } from "./job-types";
import { IP } from '../shared/retailer-product-ip/retailer-product-ip.interface';
import { HdriOptions, SceneParamsOptions, ShadowPlaneOptions, ThreeLight } from 'asset-adjustments';
import { RetailerMappingNamingConvention } from '../product/product';
import {QaMode, StudioType} from '../shared/enums';

export interface Retailer {
    // brands: Array<string>,
    // category: null,
    created_at: Date;
    has_resellers: boolean;
    id: number;
    logo_url?: string;
    name: string;
    // retailer_type: null,
    telephone: string;
    updated_at: Date;
    website: string;
    address: string;
    city: string;
    zip: string;
    country_code: string;
    tax_id: string;
    industry: string;
    allow_downloads: boolean;
    use_watermark: boolean;
    maximum_glb_size: number;
    demo_account?: boolean;
    use_hexa_naming: boolean;
    show_description: boolean;
    users_downloads_count?: number;
    visible_embedded: boolean;
    apply_due_date_grace: boolean;
    realtime_image_render_price: number;
    retailers_categories: Array<Category>;
    retailers_sub_categories?: Array<SubCategory>;
    retailer_collections: Array<any>;
    retailers_configs: Array<RetailersConfig>;
    retailers_scrapers_configs: Array<RetailersScraperConfig>;
    retailers_domains: Array<RetailersDomain>;
    retailers_paths: Array<RetailersPath>;
    retailers_settings: Array<RetailersSettings>;
    retailers_users: Array<RetailersUser>;
    retailers_specifications: Array<RetailersSpecification>;
    retailers_source_files_types: Array<RetailersSourceFilesType>;
    retailers_resources_types: Array<RetailersResourceFileType>;
    retailers_budget: Array<RetailersBudget>;
    retailers_renders_settings: Array<RetailersRendersSettings>;
    retailers_renders_angles: Array<RetailersRendersAngles>;
    retailers_polygon_specifications: Array<RetailersPolygonSpecifications>;
    retailers_agreements: Array<RetailersAgreement>;
    retailers_viewer_params: Array<RetailersViewerParam>;
    retailers_mesh_conventions: Array<RetailersMeshConvention>;
    retailers_mapping_naming_convention: Array<RetailerMappingNamingConvention>;
    retailers_account_managers: Array<RetailersAccountManager>;
    retailers_contracts: Array<RetailersContracts>;
    retailers_batches: Array<RetailerBatch>;
    artists_users_presets?: Array<AdjustmentsPreset>;
    implementation_type: ImplementationType;
    preload_model: boolean;
    retailer_key: string;
    default_category_price: number;
    ip: IP;
    external_provider: boolean;
    auto_create_texture_offers?:boolean;
    source_file_match_approval?:boolean;
    is_artist_contractor?:boolean;
    auto_validate_source_files?:boolean;
    define_amazon_settings?:boolean;
    studio_type?:StudioType;
    expose_adjustments: boolean;
    job_qa_mode?:QaMode;
}

export interface RetailersAgreement {
    id: number;
    created_at: Date;
    updated_at: Date;
    retailer_id: number;
    business_plan_id: BusinessPlan;
    start_at: number;
    end_at: number;
    price_per_api_call: number;
    payment_model: PaymentModel;
    agreement_limit: number;
    agreement_budget: number;
    agreement_budget_type: AgreementBudgetType;
    fixed_payment: number;
    monthly_credit: number;
    credit_type: CreditType;
    minimum_monthly_assets: number;
    maximum_assets_download: number;
    is_poc: boolean;
    sla_due_date: number;
    sla_feedback_due_date: number;
}

export enum AgreementBudgetType {
    MONTHLY = 1,
    SLA_PERIOD = 2,
}

export enum BusinessPlan {
    PayPerAPICall = 1,
    PayPer3DAsset = 2,
}

export enum PaymentModel {
    Limited = 1,
    Unlimited = 2,
}

export enum CreditType {
    Percentage = 1,
    Amount = 2,
}

export interface RetailersUser {
    id: number;
    created_at: Date;
    updated_at: Date;
    retailer_id: number;
    user_id: number;
    retailers: Array<Retailer>;
    users: Array<User>;
}

export interface Category {
    // category_name: string;
    category_name_org_lang: string;
    created_at: Date;
    id: number;
    retailer_id: number;
    updated_at: Date;
    price: number;
    product_category_id: number;
    retailers_categories_renders_angles: Array<RetailersCategoriesRendersAngle>;
    image_render_price: number;
    video_render_price: number;
    process_type?: number;
    text: string;
    // retailers_sub_categories?: Array<SubCategory>;
}

export interface ImageRenderPrice {

}

export interface RetailersCategoriesRendersAngle extends RendersAngles {
    category_id: number;
    created_at: Date;
    id: number;
    updated_at: Date;
}

export interface SubCategory {
    // sub_category_name: string;
    sub_category_name_org_lang: string;
    created_at: Date;
    id: number;
    category_id: number;
    retailer_id: number;
    updated_at: Date;
    product_sub_category_id: number;
    estimated_traffic: number;
    retailers_sub_categories_specifications: Array<RetailersSubCategoriesSpecification>;
    artists_users_presets?: AdjustmentsPreset[];
}

export interface AdjustmentsPreset {
  id?: number;
  artist_user_id: number;
  preset_json?: AdjustmentsPresetJson;
  preset_name: string;
  created_at?: Date;
  updated_at?: Date;
  type_id: ArtistsUsersPresetType;
  sub_category_id?: number;
  retailer_sub_category_id?: number;
  retailer_id?: number;
  sort_index?: number;
  hdriUrl?: string;
}

export interface AdjustmentsPresetJson {
  lights: { [id: string]: Array<ThreeLight> };
  // texturesAnimations: { [id: string]: ITextureAnimatorOptions };
  shadowPlane: ShadowPlaneOptions;
  urlParats: any;
  hdri: HdriOptions;
  params: SceneParamsOptions;

}

export enum ArtistsUsersPresetType {
  PRIVATE = 1,
  PUBLIC = 2,
}

export interface RetailersSubCategoriesSpecification {
    id: number;
    title: string;
    attachment: string;
    sort_index: number;
    job_type: JobsTypes;
    text: string;
}

export interface SubCategoryUI extends SubCategory {
    productCategoryId?: number;
    productsSubCategories?: Array<ProductSubCategory>;
    // title?: string;
    // attachment?: string;
}

export interface RetailersConfig {
    config: string;
    id: number;
    retailer_id: number;
    type: string;
}

export interface RetailersScraperConfig {
    scraper_config: string;
    id: number;
    retailer_id: number;
    created_at: Date;
    updated_at: Date;
}

export interface RetailersDomain {
    created_at: Date;
    id: number;
    retailer_domain: string;
    retailer_id: number;
    updated_at: Date;
}

export interface RetailersPath {
    created_at: Date;
    id: number;
    path: string;
    retailer_id: number;
    parent_retailer_id: number;
    updated_at: Date;
}

export interface RetailersDomainsPaths {
    domains: Array<RetailersDomain>;
    paths: Array<RetailersPath>;
}

export interface RetailersSettings {
    settings: string;
    id: number;
    created_at: Date;
    updated_at: Date;
    retailer_id: number;
    UISettings: RetailersUISettings;
}

export interface RetailersUISettings {
    gif: ElementDimensions;
    preload: ElementDimensions;
}

export interface RetailerFilterOptions {
    limit?: number;
    offset?: number;
    id?: number;
}

export interface RetailersFilterOptions {
    limit?: number;
    offset?: number;
    id?: Array<number>;
    name?: Array<string>;
}

export interface RetailerQueryData {
    data: RetailerQueryInner;
}

export interface RetailerQueryInner {
    retailers: Retailer;
}

export interface RetailersQueryData {
    allRetailers: AllRetailers;
}

export interface AllRetailers {
    rows: Array<Retailer>;
    count: number;
}

export class CategoryNode {
    children: BehaviorSubject<Array<CategoryNode>>;
    category_name_org_lang?: string;
    sub_category_name_org_lang?: string;
    created_at: Date;
    id: number;
    retailer_id: number;
    updated_at: Date;
    retailers_sub_categories?: Array<SubCategory>;
    checked: boolean;
    indeterminate: boolean;
    constructor(public item: any, children?: Array<CategoryNode>) {
        this.children = new BehaviorSubject(children === undefined ? [] : children);
    }
}

export class SelectedCategories {
    constructor() {
        this.categories = [];
        this.subCategories = [];
    }
    categories?: Array<Category>;
    subCategories?: Array<Category>;
    AddToCategories(c: Category) {
        this.categories.concat(c);
    }
    AddToSubCategories(c: Category) {
        this.subCategories.concat(c);
    }
}

export interface RetailersSpecification {
    id: number;
    created_at: Date;
    updated_at: Date;
    retailer_id: number;
    title: string;
    attachment: string;
    text: string;
    job_type: JobsTypes;
}

export interface RetailersResourceFileType {
    id: number;
    created_at: Date;
    updated_at: Date;
    retailer_id: number;
    resource_type_id: number;
}

export interface RetailersSourceFilesType {
    id: number;
    created_at: Date;
    updated_at: Date;
    retailer_id: number;
    source_type_id: number;
}

export interface RetailersBudget {
    id: number;
    retailer_id: number;
    amount: number;
    created_at: Date;
    updated_at: Date;
}

export interface CategoriesQueryData {
    retailersCategories: CategoriesInnerQueryData;
}

export interface CategoriesInnerQueryData {
    retailers_categories: Array<Category>;
    retailers_sub_categories: Array<SubCategory>;
}

export interface RendersSettings extends RendersAngles {
    background_color: string;
    created_at: Date;
    duration: number;
    frames_per_second: number;
    id: number;
    product_position: number;
    product_reflection: boolean;
    product_shadow: boolean;
    resolution_height: number;
    resolution_width: number;
    updated_at: Date;
}

export interface RetailersRendersSettings extends RendersSettings {
    retailer_id: number;
    default_video_render_price: number;
    default_image_render_price: number;
}

export interface RendersAngles {
    angle_x: number;
    angle_y: number;
    screenshot: string;
}

export interface RetailersRendersAngles extends RendersAngles {
    id: number;
    retailer_id: number;
    created_at: Date;
    updated_at: Date;
    category_id?: number;
}

export enum JobType {
    Geo = 1,
    Text = 2,
}

export interface PolygonSpecifications {
    created_at?: Date;
    id?: number;
    min_poly_count: number;
    max_poly_count: number;
    poly_type: PolyType,
    poly_shape_type: PolyShapeType;
    variation_name: string;
    serial_number: string;
    job_type: JobType
    updated_at?: Date;
}

export interface RetailersPolygonSpecifications extends PolygonSpecifications {
    retailer_id: number;
}

export interface RetailersViewerParam {
    viewer_param_key: string;
    viewer_param_value: string;
}

export enum PolyType {
    LOW = 1,
    MID = 2,
    HIGH = 3,
}

export enum ImplementationType {
    SNIPPET,
    API
}

export enum PolyShapeType {
    TRIANGULAR = 1,
    QUADRANGULAR = 2,
}

export interface RetailersMeshConvention {
    id: number;
    created_at: Date;
    updated_at: Date;
    retailer_id: number;
    mesh_name: string;
    mesh_description: string;
}

export interface RetailersAccountManager {
    id?: number;
    user_id: number;
    users: Array<User>;
}

export interface RetailersContracts {
    id?: number;
    total_models: number;
    total_price: number;
    signed_at: Date;
    end_at: Date;
    start_at: Date;
    contract_type: string;
    retailer_index?: number;
    batches: Array<RetailerBatch>;
}

export interface RetailersContractsUI {
    id?: number;
    total_models: number;
    total_price: number;
    signed_at: number;
    end_at: number;
    start_at: number;
    contract_type: string;
    batches: Array<RetailerBatch>;
    retailer_index?: number;
}

export interface RetailersBatchesUI {
    id?: number;
    contract_id: number;
    end_at: number;
    avg_product_price: number;
    total_models: number;
    gross_margin: number;
    contract_index?: number;
    specifications?: Array<Specification>
    sla_feedback_due_date?: number;
    sla_due_date?: number;
    name?: string;
}

export interface RetailerBatch {
    batch_id?: number;
    created_at?: Date;
    id?: number;
    product_id?: number;
    updated_at?: Date;
    contract_id?: number;
    end_at?: Date;
    avg_product_price?: number;
    total_models?: number;
    used_quota?: number;
    gross_margin?: number;
    name?: string;
    displayName?: string;
    contract_index?: number;
    retailer_id?: number;
    sla_feedback_due_date?: number;
    sla_due_date?: number;
    retailers_batches_specifications?: Array<RetailerBatchSpecifications>;
}

export interface RetailerBatchSpecifications {
    id: number;
    title: string;
    attachment: string;
    description: string;
    sort_index: number;
    job_type: JobsTypes;
    text: string;
}

export interface Specification {
    id?: number;
    title?: string;
    attachment?: string;
    sort_index?: number;
    description?: string;
    file_type?: number;
    job_type?: number;
    for_fix?: boolean;
    at_item_level?: boolean;
    retailer_batch_id?: number;
    retailer_id?: number;
    created_at?: Date;
    updated_at?: Date;
}

export interface RejectReasonQueryInner {
    products_reject_reasons: Array<RejectReason>;
}

export interface RejectReason {
    id: number;
    reason: string;
}

export enum ContractType {
    Onetime = "One Time",
    Recurring = "Recurring"
}

