import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RetailersManagementComponent } from './retailers-management/retailers-management.component';
import { SharedModule } from '../shared/shared.module';
import { RetailerConfigComponent } from './retailer-config/retailer-config.component';
import { RetailerSettingsComponent } from './retailer-settings/retailer-settings.component';
import { RetailerDomainsComponent } from './retailer-domains/retailer-domains.component';
import { RetailerEmbedTagComponent } from './retailer-embed-tag/retailer-embed-tag.component';
import { RetailerScraperConfigComponent } from './retailer-scraper-config/retailer-scraper-config.component';
import { RetailerSpecsComponent } from './retailer-specs/retailer-specs.component';
import { RetailerResourceTypesComponent } from './retailer-resource-types/retailer-resource-types.component';
import { RetailerSourceFilesTypesComponent } from './retailer-source-files-types/retailer-source-files-types.component';
import { RetailerMappingNamingConventionsComponent } from './retailer-mapping-naming-conventions/retailer-mapping-naming-conventions.component';
import { RetailerCategoriesComponent } from './retailer-categories/retailer-categories.component';
import { RetailersRendersSettingsComponent } from './retailers-renders-settings/retailers-renders-settings.component';
import { RetailersRendersAnglesComponent } from './retailers-renders-angles/retailers-renders-angles.component';
import { RetailerCardComponent } from './retailer-card/retailer-card.component';
import { RetailersPolygonSpecComponent } from './retailers-polygon-spec/retailers-polygon-spec.component';
import { RetailerSubCategoriesComponent } from './retailer-sub-categories/retailer-sub-categories.component';
import { BusinessModelComponent } from './business-model/business-model.component';
import { BusinessModelsComponent } from './business-models/business-models.component';
import { ViewerParamsComponent } from './viewer-params/viewer-params.component';
import { RetailerMeshNamesComponent } from './retailer-mesh-names/retailer-mesh-names.component';
import { RetailerContractsComponent } from './retailer-contracts/retailer-contracts.component';
import { RetailersImplementationInfoComponent } from './retailers-implementation-info/retailers-implementation-info.component';

@NgModule({
  imports: [
    CommonModule, SharedModule
  ],
  declarations: [RetailersManagementComponent, RetailerConfigComponent, RetailerSettingsComponent, RetailerDomainsComponent, RetailerEmbedTagComponent, RetailerScraperConfigComponent, RetailerSpecsComponent, RetailerResourceTypesComponent, RetailerSourceFilesTypesComponent, RetailerMappingNamingConventionsComponent, RetailerCategoriesComponent, RetailersRendersSettingsComponent, RetailersRendersAnglesComponent, RetailerCardComponent, RetailersPolygonSpecComponent, RetailerSubCategoriesComponent, BusinessModelComponent, BusinessModelsComponent, ViewerParamsComponent, RetailerMeshNamesComponent, RetailerContractsComponent, RetailersImplementationInfoComponent]
})
export class RetailerModule { }
