<div class="details-wrap float-dir">
  <h5 class="h2">animated gif</h5>
  <div class="pixels-wrap">
    <span class="capitalize float-dir">
      <p class="text3 text-center">width</p>
      <input type="number" [(ngModel)]="innerSettings.gif.width" class="pixels">
    </span>
    <span class="float-dir">
      <mat-icon aria-label="preview width and height" class="lock">lock_outline</mat-icon>
    </span>
    <span class="capitalize float-dir">
      <p class="text3 text-center">height</p>
      <input type="number" [(ngModel)]="innerSettings.gif.height" class="pixels">
    </span>
  </div>
</div>
<div class="details-wrap float-dir">
  <h5 class="h2">preview image</h5>
  <div class="pixels-wrap">
    <span class="capitalize float-dir">
      <p class="text3 text-center">width</p>
      <input type="number" [(ngModel)]="innerSettings.preload.width" class="pixels">
    </span>
    <span class="float-dir">
      <mat-icon aria-label="preview width and height" class="lock">lock_outline</mat-icon>
    </span>
    <span class="capitalize float-dir">
      <p class="text3 text-center">height</p>
      <input type="number" [(ngModel)]="innerSettings.preload.height" class="pixels">
    </span>
  </div>
</div>
<div class="float-dir w-100">
  <button mat-raised-button mat-raised-button color="accent" (click)="save()" class="thinner marg-top">
    save images settings
  </button>
</div>