import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RetailerQueryInner, RetailerFilterOptions } from './retailer';
import { GraphqlService } from '../communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client/core';

@Injectable()
export class RetailerSettingsResolve  {

    constructor(
        private gql: GraphqlService
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        let rid = null;
        if (route.params['retailer_id'])
            rid = route.params['retailer_id'];
        if (rid == null || isNaN(rid))
            return null;
       
        const options = {
            id: 1*rid
        } as RetailerFilterOptions;
        return this.gql.retailerForSettings(options) as Observable<ApolloQueryResult<RetailerQueryInner>>;
    }
}