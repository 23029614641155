import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter, ChangeDetectorRef, AfterContentChecked, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { UtilsService } from '../utils.service';
import { ImageSet, ImageDialog } from '../../product/product';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-images-slider',
  templateUrl: './upload-images-slider.component.html',
  styleUrls: ['./upload-images-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadImagesSliderComponent implements OnInit, AfterContentChecked, OnChanges {
  @Input() images: Array<ImageSet>;
  @Input() width: number;
  @Input() height: number;
  @Input('enabled-pin') enabledPin: boolean;
  @Input() counter: number;
  // @Output('index-change') indexChange: EventEmitter<number>;
  @Output('on-sort-change') onSortChange: EventEmitter<Array<ImageSet>>;
  @Output('on-delete') onDelete: EventEmitter<number>;
  @ViewChild('scroller') scroller: ElementRef;
  public currentIndex: number;
  public total: number;
  public isRightNavDisabled: boolean;
  public translateX: number;
  public imm: boolean;
  constructor(
    private utils: UtilsService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog
  ) {
    this.currentIndex = 0;
    this.translateX = 0;
    this.onSortChange = new EventEmitter<Array<ImageSet>>();
    this.onDelete = new EventEmitter<number>();
    // this.indexChange = new EventEmitter<number>();
    this.isRightNavDisabled = false;
  }

  ngOnInit() {
    this.init();
  }

  init() {
    if (this.images)
      this.total = this.images.length;
    else
      this.total = 0;
    this.setRightNavDisabled();
    this.cdr.detectChanges();
  }

  ngAfterContentChecked() {
    this.setRightNavDisabled();
    this.cdr.detectChanges();
  }

  ngOnChanges() {
    this.init();
  }

  onLoadError(image: ImageSet) {
    image.small = image.big;
    this.cdr.detectChanges();
  }

  navLeft() {
    let fdParent = this.utils.getFormattedDim(getComputedStyle(this.scroller.nativeElement.parentElement).width);
    this.translateX += fdParent.size;
    if (this.translateX > 0)
      this.translateX = 0;
    // this.scroller.nativeElement.style.transform = 'translateX(' + this.translateX + 'px)';
    this.setRightNavDisabled();
  }

  navRight() {
    let fdParent = this.utils.getFormattedDim(getComputedStyle(this.scroller.nativeElement.parentElement).width);
    let fd = this.utils.getFormattedDim(getComputedStyle(this.scroller.nativeElement).width);
    this.translateX -= fdParent.size;
    if (-this.translateX > (fd.size - fdParent.size))
      this.translateX = -(fd.size - fdParent.size);
    this.setRightNavDisabled();
  }

  // setIndex(index): void {
  //   if (this.currentIndex == index) return;
  //   this.currentIndex = index;
  //   this.indexChange.emit(this.currentIndex);
  //   this.setRightNavDisabled();
  // }

  setRightNavDisabled(): boolean {
    if (!this.scroller) {
      this.isRightNavDisabled = true;
      return;
    }
    let fdParent = this.utils.getFormattedDim(getComputedStyle(this.scroller.nativeElement.parentElement).width);
    let fd = this.utils.getFormattedDim(getComputedStyle(this.scroller.nativeElement).width);
    if (-this.translateX >= (fd.size - fdParent.size))
      this.isRightNavDisabled = true;
    else
      this.isRightNavDisabled = false;
  }

  deleteImage(index: number): void {
    // this.images.splice(index, 1);
    this.onDelete.next(index);
  }

  // copyImage(index: number): void {
  //   this.utils.copyClipboard(this.images[index].big || this.images[index].small);
  // }

  viewImage(index: number) {
    const src = this.images[index].big || this.images[index].small;
    new Image().src = src;
    const m = {
      title: 'image preview',
      url: src
    } as ImageDialog;
    this.dialog.open(ImageDialogComponent, {
      // width: '500px',
      data: m
    });
  }

  pin(index) {
    this.images.map(i => i.sort_index = null);
    this.images[index].sort_index = 0;
    this.onSortChange.next(this.images);
  }

  getScrollerWidth() {
    let px = ((this.width + 37) * this.total) - 35;
    if (this.enabledPin)
      px += 20;
    return px + 'px'
  }
}
