import { Directive, ElementRef, HostListener } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
    selector: '[matTooltip][showIfTruncated]'
})
export class ShowIfTruncatedDirective {
  /**
    shows tooltip if text inside the element is too long
   make sure the element contains css class with:
     text-overflow: ellipsis;
     overflow: hidden;
     max-widht: ??
   */

    constructor(
        private matTooltip: MatTooltip,
        private elementRef: ElementRef<HTMLElement>
    ) {}

    @HostListener('mouseenter', ['$event'])
    setTooltipState(): void {
      const element = this.elementRef.nativeElement;

      if (element.classList.contains('mat-option-text')) {
        const textElement = element.querySelector('.mat-option-text') as HTMLElement;
        const checkbox = element.querySelector('.mat-pseudo-checkbox') as HTMLElement;
        const checkboxWidth = checkbox ? checkbox.offsetWidth : 0;
        const { paddingLeft, paddingRight } = getComputedStyle(element);
        const paddingWidth = parseInt(paddingLeft, 10) + parseInt(paddingRight, 10);
        const { paddingLeft: checkboxPaddingLeft, paddingRight: checkboxPaddingRight } = getComputedStyle(element);
        const checkboxPaddingWidth = parseInt(checkboxPaddingLeft, 10) + parseInt(checkboxPaddingRight, 10);
        this.matTooltip.disabled = textElement.scrollWidth + checkboxWidth + paddingWidth + checkboxPaddingWidth <= element.clientWidth;
      } else {
        this.matTooltip.disabled = element.scrollWidth <= element.clientWidth;
      }
    }
}
