<div>
  <div fxLayout="column">
    <mat-accordion fxFlex class="customized">
      <mat-expansion-panel *ngFor="let subscription of user.users_mails_subscriptions; let i = index" (opened)="setIndex(i)"
        [expanded]="currentIndex === i">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{emailCategoryDictionary[subscription.mail_category_id]}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div fxLayout="column" fxLayoutAlign="space-between center">
            <div class="w-100" fxFlex *ngFor="let interval of user.users_mails_subscriptions_intervals | emailNotificationsIntervals : subscription.mail_category_id">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex>
                  <mat-icon class="gray">{{emailIntervalDictionaryIcons[interval.interval_id]}}</mat-icon>
                </div>
                <div fxFlex class="text-center">
                  <span class="gray text2 uppercase va-super interval-name">
                    {{emailIntervalDictionary[interval.interval_id]}}
                  </span>
                </div>
                <div fxFlex class="text-op-dir">
                  <app-toggle-checkbox (on-change)="onChange()" ngDefaultControl [on-val]="true" [off-val]="false"
                    [(ngModel)]="interval.subscribed"></app-toggle-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>