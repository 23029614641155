import { Component, Input, OnInit } from '@angular/core';
import { ContractType, RetailerBatch, RetailersBatchesUI, RetailersContracts, RetailersContractsUI } from '../retailer';
import { UtilsService } from 'src/app/shared/utils.service';
import { RestService } from 'src/app/communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { FormGroup, AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FileObject } from 'src/app/shared/file';
import { ResumableUploadService } from 'src/app/shared/resumable-upload.service';
import { GlobalsService } from 'src/app/shared/globals.service';
import { JobTypesService } from '../job-types.service';

@Component({
  selector: 'app-retailer-contracts',
  templateUrl: './retailer-contracts.component.html',
  styleUrls: ['./retailer-contracts.component.scss']
})
export class RetailerContractsComponent implements OnInit {
  @Input('retailer-id') retailerId: number;
  @Input() contracts: Array<RetailersContracts>;
  @Input() batches: Array<RetailerBatch>;
  @Input('sla_due_date') slaDueDate: number;
  @Input('sla_feedback_due_date') slaFeedbackDueDate: number;
  public contractTypesArr;
  public items: Array<RetailersContracts>;
  public today: Date;
  public contractsForm: any;
  public uploading: boolean;
  public jobTypes: any;

  constructor(private utils: UtilsService,
              private broadcaster: BroadcasterService,
              private rest: RestService,
              private resumableUpload: ResumableUploadService,
              private jobTypesService: JobTypesService,
              private globals: GlobalsService) {
    this.today = new Date();
    this.contractTypesArr = [];
    Object.keys(ContractType).map(k => {
        this.contractTypesArr.push(ContractType[k]);
      }
    );

    this.contractsForm = new UntypedFormGroup({
      contracts: new UntypedFormArray([])
    });
  }

  ngOnInit(): void {
    this.getJobTypes();
  }

  ngOnChanges() {
    if (this.batches)
      this.batches = this.utils.deepCopyByValue(this.batches);
    if (this.contracts && this.contracts.length > 0) {
      this.items = this.utils.deepCopyByValue(this.contracts);
      this.items.sort((a, b) => a.retailer_index - b.retailer_index);
    } else {
      this.items = [];
    }
    this.initItems();
    this.getJobTypes();
  }

  private async getJobTypes(): Promise<void> {
    this.jobTypes = await this.jobTypesService.getJobTypes();
  }

  public track(item: any, index: number): number {
    return index;
  }

  private initItems(): void {
    this.items.forEach((item: RetailersContracts) => {
      this.contractsForm.get('contracts').push(
        new UntypedFormGroup(
          {
            id: new UntypedFormControl({ value: item.id, disabled: true }),
            signed_at: new UntypedFormControl(item.signed_at, [Validators.required]),
            contract_type: new UntypedFormControl(item.contract_type, [Validators.required]),
            start_at: new UntypedFormControl(item.start_at, [Validators.required]),
            end_at: new UntypedFormControl(item.end_at, [Validators.required]),
            total_models: new UntypedFormControl(item.total_models, [Validators.required]),
            total_price: new UntypedFormControl(item.total_price, [Validators.required]),
            retailer_index: new UntypedFormControl(item.retailer_index, []),
            batches: new UntypedFormArray(this.loadBatches(item))
          })
      );
    });
  }

  private loadBatches(item): AbstractControl[] {
    const batchesControl: AbstractControl[] = [];

    this.batches.sort((a, b) => a.contract_index - b.contract_index);
    this.batches.forEach(b => {
      if (b.contract_id === item.id) {
        batchesControl.push(new UntypedFormGroup(
          {
            id: new UntypedFormControl({ value: b.id, disabled: true }),
            retailer_id: new UntypedFormControl({ value: b.retailer_id, disabled: true }),
            name: new UntypedFormControl(b.name),
            end_at: new UntypedFormControl(b.end_at, [Validators.required]),
            total_models: new UntypedFormControl(b.total_models, [Validators.required]),
            avg_product_price: new UntypedFormControl(b.avg_product_price, [Validators.required]),
            contract_index: new UntypedFormControl(b.contract_index, []),
            gross_margin: new UntypedFormControl(b.gross_margin, [Validators.required]),
            sla_due_date: new UntypedFormControl(b.sla_due_date, [Validators.required]),
            sla_feedback_due_date: new UntypedFormControl(b.sla_feedback_due_date, [Validators.required]),
            specifications: new UntypedFormArray(this.loadSpec(b)),
            isExpired: new UntypedFormControl((new Date(b.end_at).getTime() < (new Date()).getTime()))
          })
        );
      }
    });
    return batchesControl;
  }

  onTextChange(s: FormGroup, notes: string) {
    s.controls['text'].setValue(notes);
  }

  private loadSpec(batch: RetailerBatch): AbstractControl[] {
    const specControl = [];
    batch.retailers_batches_specifications.forEach(s => {
      specControl.push(new UntypedFormGroup(
        {
          id: new UntypedFormControl({ value: s.id, disabled: false }),
          title: new UntypedFormControl(s.title, [Validators.required]),
          attachment: new UntypedFormControl(s.attachment, [Validators.required]),
          description: new UntypedFormControl(s.description, []),
          sort_index: new UntypedFormControl(s.sort_index, []),
          job_type: new UntypedFormControl(s.job_type, []),
          text: new UntypedFormControl(s.text, []),
        })
      );

    });

    return specControl;
  }

  public addBatchSpec(b: any): void {
    if (!b.controls?.id?.value) {
      this.notifyUser('please save the batch before', BroadcasterNotificationType.Info);
      return;
    }

    if (!b.controls.specifications) {
      b.controls.specifications = [];
    }
    b.controls.specifications.controls.push(new UntypedFormGroup({
      title: new UntypedFormControl(null, [Validators.required]),
      attachment: new UntypedFormControl(null, [Validators.required]),
      sort_index: new UntypedFormControl(null, []),
      job_type: new UntypedFormControl(null, [])
    }));
  }

  public deleteSpec(item, h): void {
    if (!confirm(`Are you sure you want delete attachment?`)) {
      return;
    }

    const s = item.controls.specifications.controls[h];
    if (s.controls['id']?.value) {
      const batchQuery = `/` + s.controls['id'].value + '?rid=' + item.controls['retailer_id'].value + '&rbid=' + item.controls['id'].value;
      this.rest.retailerBatchSpec('delete', {}, batchQuery).subscribe(
        () => {

          this.notifyUser('attachment successfully removed', BroadcasterNotificationType.Success);
          item.controls.specifications.controls.splice(h, 1);

        },
        err => this.utils.httpErrorResponseHandler(err, 'failure deleting attachment')
      );
    } else {
      item.controls.specifications.controls.splice(h, 1);
    }
  }

  public addBatch(item): void {
    if (!item.controls.batches) {
      item.controls.batches = [];
    }

    item.controls.batches.controls.push(new UntypedFormGroup({
      end_at: new UntypedFormControl(null, [Validators.required]),
      total_models: new UntypedFormControl(null, [Validators.required]),
      avg_product_price: new UntypedFormControl(null, [Validators.required]),
      contract_index: new UntypedFormControl(null, []),
      name: new UntypedFormControl(null, []),
      gross_margin: new UntypedFormControl(80, [Validators.required]),
      sla_due_date: new UntypedFormControl(this.slaDueDate, [Validators.required]),
      sla_feedback_due_date: new UntypedFormControl(this.slaFeedbackDueDate, [Validators.required]),
      isExpired: new UntypedFormControl(null),
      specifications: new UntypedFormArray([])
    }));
  }

  public duplicateBatch(item, i: number): void {
    const b = item.controls.batches.controls[i].controls;
    item.controls.batches.controls.push(new UntypedFormGroup({
        end_at: new UntypedFormControl(b['end_at'].value, [Validators.required]),
        total_models: new UntypedFormControl(b['total_models'].value, [Validators.required]),
        name: new UntypedFormControl(b['name'].value),
        avg_product_price: new UntypedFormControl(b['avg_product_price'].value, [Validators.required]),
        gross_margin: new UntypedFormControl(b['gross_margin'].value, [Validators.required]),
        sla_due_date: new UntypedFormControl(b['sla_due_date'].value, [Validators.required]),
        isExpired: new UntypedFormControl(null),
        sla_feedback_due_date: new UntypedFormControl(b['sla_feedback_due_date'].value, [Validators.required]),
      })
    );
  }

  public addContract(): void {
    this.contractsForm.get('contracts').push(
      new UntypedFormGroup(
        {
          signed_at: new UntypedFormControl(null, [Validators.required]),
          contract_type: new UntypedFormControl(null, [Validators.required]),
          start_at: new UntypedFormControl(null, [Validators.required]),
          end_at: new UntypedFormControl(null, [Validators.required]),
          total_models: new UntypedFormControl(null, [Validators.required]),
          name: new UntypedFormControl(null),
          total_price: new UntypedFormControl(null, [Validators.required]),
          retailer_index: new UntypedFormControl(null, []),
          batches: new UntypedFormArray([])
        })
    );
  }

  public deleteBatch(item, j: number): void {
    if (!confirm(`Are you sure you want delete batch?`)) {
      return;
    }

    const b = item.controls.batches.controls[j];
    if (b.controls['id']?.value) {
      const batchQuery = `/${b.controls['id']?.value}` + '?rid=' + this.retailerId;
      this.rest.retailerBatch('delete', {}, batchQuery).subscribe(
        () => {
          this.notifyUser('batch successfully removed', BroadcasterNotificationType.Success);
          item.controls.batches.controls.splice(j, 1);

        },
        err => this.utils.httpErrorResponseHandler(err, 'failure deleting batch')
      );
    } else {
      item.controls.batches.controls.splice(j, 1);
    }
  }


  public deleteContract(item, i: number): void {
    if (item.controls.batches.controls.length > 0) {
      this.notifyUser('The contract cannot be deleted as long as there are products assigned to its batches', BroadcasterNotificationType.Error);
      return;
    } else {
      const batchQuery = `/${item.controls['id']?.value}` + '?rid=' + this.retailerId;
      this.rest.retailerContract('delete', {}, batchQuery).subscribe(
        () => {
          this.notifyUser('contract successfully removed', BroadcasterNotificationType.Success);
          this.contractsForm.controls.contracts.controls.splice(i, 1);
        },
        err => this.utils.httpErrorResponseHandler(err, 'failure deleting batch')
      );
    }
  }

  public save(item): void {
    const payload = {} as RetailersContractsUI;
    if (this.contractsForm.valid) {

      payload.signed_at = new Date(item.controls['signed_at'].value).getTime();
      payload.start_at = new Date(item.controls['start_at'].value).getTime();
      payload.end_at = new Date(item.controls['end_at'].value).getTime();
      payload.total_models = item.controls['total_models'].value;
      payload.total_price = item.controls['total_price'].value;
      payload.contract_type = item.controls['contract_type'].value;
      payload.retailer_index = item.controls['retailer_index']?.value;

      if (payload.end_at < payload.start_at || payload.end_at < payload.signed_at) {
        this.notifyUser('Make sure the contract end date is after the sign date and start date', BroadcasterNotificationType.Error);
        return;
      }

      let payloadBatch = {} as RetailersBatchesUI;
      for (let i = 0; i < item.controls.batches.controls.length; i++) {
        let b = item.controls.batches.controls[i];
        if (b.valid) {
          if (item.controls['id']?.value) {
            payloadBatch.contract_id = item.controls['id'].value;
          }
          payloadBatch.end_at = new Date(b.controls['end_at'].value).getTime();
          payloadBatch.gross_margin = b.controls['gross_margin'].value;
          payloadBatch.sla_due_date = b.controls['sla_due_date'].value;
          payloadBatch.sla_feedback_due_date = b.controls['sla_feedback_due_date'].value;
          payloadBatch.total_models = b.controls['total_models'].value;
          payloadBatch.avg_product_price = b.controls['avg_product_price'].value;
          payloadBatch.contract_index = b.controls['contract_index']?.value;
          payloadBatch.name = b.controls['name']?.value;

          if (b.controls['id']?.value) {
            payloadBatch.id = b.controls['id'].value;
          }
          payloadBatch.specifications = [];

          if (payloadBatch.end_at < payload.start_at || payloadBatch.end_at < payload.signed_at) {
            this.notifyUser('Make sure the batch end date is after the contract sign date and start date', BroadcasterNotificationType.Error);
            return;
          }

          item.controls.batches.controls.forEach((b2, j) => {
            let b2endate = new Date(b2.controls['end_at'].value).getTime();
            if (payloadBatch.end_at === b2endate && j !== i) {
              this.notifyUser('Note, you have at least one more batch with the same end date, make sure you’re entering the correct dates', BroadcasterNotificationType.Error);
            }
          });

          if (b.controls?.specifications?.controls) {
            for (let i = 0; i < b.controls.specifications.controls.length; i++) {
              const s = b.controls.specifications.controls[i];
              payloadBatch.specifications.push(
                {
                  title: s.controls.title.value,
                  attachment: s.controls.attachment.value,
                  retailer_batch_id: b.controls.id.value
                }
              );
            }
          }

        } else {
          this.notifyUser('Error occurs when filling out the batch form', BroadcasterNotificationType.Error);
          return;
        }
      }


      const query = (item.controls['id']?.value ? `/${item.controls['id'].value}` : '') + '?rid=' + this.retailerId;

      this.rest.retailerContract(item.controls['id']?.value ? 'put' : 'post', payload, query).subscribe(
        res => {
          item.controls['id'] = new UntypedFormControl({ value: res['id'], disabled: true });

          if (item.controls.batches.controls.length === 0) {
            this.notifyUser('retailer contract successfully saved', BroadcasterNotificationType.Success);
            return;
          }

          item.controls.batches.controls.forEach(b => {

            if (b.valid) {
              payloadBatch.contract_id = item.controls['id'].value;

              payloadBatch.end_at = new Date(b.controls['end_at'].value).getTime();
              payloadBatch.gross_margin = b.controls['gross_margin'].value;
              payloadBatch.sla_due_date = b.controls['sla_due_date'].value;
              payloadBatch.sla_feedback_due_date = b.controls['sla_feedback_due_date'].value;
              payloadBatch.total_models = b.controls['total_models'].value;
              payloadBatch.avg_product_price = b.controls['avg_product_price'].value;
              payloadBatch.contract_index = b.controls['contract_index']?.value;
              payloadBatch.name = b.controls['name']?.value;

              if (b.controls['id']?.value) {
                payloadBatch.id = b.controls['id'].value;
              } else {
                if (payloadBatch.id) {
                  delete payloadBatch.id;
                }
              }

              const batchQuery = (b.controls['id']?.value ? `/${b.controls['id'].value}` : '') + '?rid=' + this.retailerId;

              if (payloadBatch.end_at > payload.end_at) {
                this.notifyUser('Note, the batch end date is planned after the contract’s end date', BroadcasterNotificationType.Error);
              }

              this.rest.retailerBatch(b.controls['id']?.value ? 'put' : 'post', payloadBatch, batchQuery).subscribe(
                (resp) => {
                  b.controls['id'] = new UntypedFormControl({ value: resp['id'], disabled: true });
                  this.notifyUser('retailer batch successfully saved', BroadcasterNotificationType.Success);

                  const specQuery = '?rid=' + this.retailerId + '&rbid=' + b.controls.id.value;

                  let payload;
                  let method;
                  if (b.controls.specifications.value.length === 0) {
                    payload = [b.controls.specifications.controls[0].value];
                    method = 'post';
                  } else {
                    payload = b.controls.specifications.value;
                    method = 'put';
                  }
                  this.rest.retailerBatchSpec(method, payload, specQuery).subscribe(
                    (resp) => {
                    },
                    err => this.utils.httpErrorResponseHandler(err, 'failure saving specifications')
                  );
                },
                err => this.utils.httpErrorResponseHandler(err, 'failure saving batches')
              );
            }
          });
        },
        err => this.utils.httpErrorResponseHandler(err, 'failure saving contract')
      );
    } else {
      this.notifyUser('Error occurs when filling out the contract form', BroadcasterNotificationType.Error);
    }
  }

  public async onFilesChange(fileList: Array<FileObject>, spec): Promise<void> {
    if (fileList.length > 0) {
      this.uploading = true;
      this.globals.increaseNumOfRequestsInProgress();

      let link = await this.resumableUpload.file(fileList[0].file);
      spec.controls.attachment.setValue(link);

      this.uploading = false;
      this.globals.decreaseNumOfRequestsInProgress();
    }
  }

  private notifyUser(text: string, bntype: BroadcasterNotificationType): void {
    let data: BroadcasterNotification = {
      text: text,
      type: bntype,
      action: 'OK'
    };
    this.broadcaster.broadcast('notifyUser', data);
  }
}
