import { Injectable, ElementRef } from '@angular/core';
import { Subject } from '../../../node_modules/rxjs';
import { BroadcasterService } from '../../../node_modules/ng-broadcaster';
import { RestService } from '../communication/rest.service';

@Injectable({
  providedIn: 'root'
})
export class CombineImagesService {
  public onImagePortion: Subject<string>;
  constructor(
    private broadcaster: BroadcasterService,
    private rest: RestService
  ) {
    this.onImagePortion = new Subject<string>();
  }

  private onImagesLoaded(img1, img2, suffix) {
    let img1Dim = {
      width: img1.width,
      height: img1.height
    };
    let img2Dim = {
      width: img2.width,
      height: img2.height
    };
    if (img1.height < img2.height) {
      let ratio = img2.height / img1.height;
      img2Dim.width /= ratio;
      img2Dim.height /= ratio;
    }
    else {
      let ratio = img1.height / img2.height;
      img1Dim.width /= ratio;
      img1Dim.height /= ratio;
    }

    let canvas = document.createElement('canvas');
    canvas.id = 'combine-images-canvas-' + suffix;
    canvas.width = img1Dim.width + img2Dim.width;
    canvas.height = Math.max(img1Dim.height, img2Dim.height);
    document.body.appendChild(canvas);
    var context = canvas.getContext('2d');

    if (img1Dim.height < img2Dim.height)
      context.drawImage(img1, 0, (img2Dim.height / 2) - (img1Dim.height / 2), img1Dim.width, img1Dim.height);
    else
      context.drawImage(img1, 0, 0, img1Dim.width, img1Dim.height);
    if (img1Dim.height > img2Dim.height)
      context.drawImage(img2, img1Dim.width, (img1Dim.height / 2) - (img2Dim.height / 2), img2Dim.width, img2Dim.height);
    else
      context.drawImage(img2, img1Dim.width, 0, img2Dim.width, img2Dim.height);

    this.broadcaster.broadcast('onCombineImage' + suffix, { message: canvas.toDataURL('image/png'), canvas: canvas });
    canvas.parentElement.removeChild(canvas);
  }

  public getCombineImage(imgPath1, imgPath2, suffix) {
    let img1 = new Image(), img2 = new Image(), img1HasLoaded = false, img2HasLoaded = false;
    img1.setAttribute('crossOrigin', 'anonymous');
    img1.crossOrigin = 'anonymous';
    img2.setAttribute('crossOrigin', 'anonymous');
    img2.crossOrigin = 'anonymous';

    let onImageLoaded = () => {
      if (img1HasLoaded && img2HasLoaded)
        this.onImagesLoaded(img1, img2, suffix);
    }

    img1.onload = (e) => {
      img1HasLoaded = true;
      onImageLoaded();
    };
    img2.onload = () => {
      img2HasLoaded = true;
      onImageLoaded();
    };

    img1.src = imgPath1;
    img2.src = imgPath2;
  }

  public getImagePortion(imgObj: ElementRef, newWidth: number, newHeight: number, startX: number, startY: number, ratio = 1) {
    /* the parameters: - the image element - the new width - the new height - the x point we start taking pixels - the y point we start taking pixels - the ratio */
    //set up canvas for thumbnail
    var tnCanvas = document.createElement('canvas');
    var tnCanvasContext = tnCanvas.getContext('2d');
    tnCanvas.width = newWidth; tnCanvas.height = newHeight;

    /* use the sourceCanvas to duplicate the entire image. This step was crucial for iOS4 and under devices. Follow the link at the end of this post to see what happens when you don’t do this */
    var bufferCanvas = document.createElement('canvas');
    var bufferContext = bufferCanvas.getContext('2d');
    bufferCanvas.width = imgObj.nativeElement.width;
    bufferCanvas.height = imgObj.nativeElement.height;
    bufferContext.drawImage(imgObj.nativeElement, 0, 0);
    try {
      /* now we use the drawImage method to take the pixels from our bufferCanvas and draw them into our thumbnail canvas */
      tnCanvasContext.drawImage(bufferCanvas, startX, startY, newWidth * ratio, newHeight * ratio, 0, 0, newWidth, newHeight);
      this.onImagePortion.next(tnCanvas.toDataURL());
    }
    catch (e) {
      if (e.code == 18)
        // Tainted canvas
        // fallback with base64
        this.convertImageFallback(imgObj.nativeElement.src, newWidth, newHeight, startX, startY, ratio);
    }
  }

  private convertImageFallback(image, newWidth: number, newHeight: number, startX: number, startY: number, ratio = 1) {
    var obj = {
      image_url: image,
      compress: false
    };
    this.rest.imageBase64Convert('POST', obj).subscribe(data => {
      let img = new ElementRef(document.createElement('img'));
      img.nativeElement.onload = () => {
        this.getImagePortion(img, newWidth, newHeight, startX, startY, ratio)
        // this.imageBase64 = 'data:image/png;base64,' + data;
        // this.convertImageReady();
      }
      img.nativeElement.src = 'data:image/png;base64,' + data;
      
    }, () => {

    });
  }
}
