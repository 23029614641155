import { Component, Inject, OnDestroy } from "@angular/core";
import { User } from "../user";
import { RestService } from "../../communication/rest.service";
import { BroadcasterNotification, BroadcasterNotificationType } from "../../communication/broadcaster-notifications";
import { BroadcasterService } from "ng-broadcaster";
import { Subscription } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'app-invite-dialog',
    templateUrl: 'invite-dialog.html',
    styles: [`
        .wrap {
            padding: 50px;
        }
        .close {
            top: -15px;
            right: -15px;
            font-size: 20px;
        }
    `]
})
export class InviteDialog implements OnDestroy {
    private subs: Array<Subscription>;
    constructor(
        public dialogRef: MatDialogRef<InviteDialog>,
        private broadcaster: BroadcasterService
    ) {
        this.subs = []; if (this.subs.length)
            this.subs.forEach(s => s.unsubscribe());
        this.subs.push(this.broadcaster.on('onUsersChange').subscribe(this.close.bind(this)));
    }

    close(): void {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        if (this.subs.length)
            this.subs.forEach(s => s.unsubscribe());
    }
}