import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors, AbstractControl } from '../../../node_modules/@angular/forms';
import { FeedbackType } from '../shared/enums';
import { AuthService } from '../auth/auth.service';
import { Product, ProductResource } from './product';
import { BroadcasterService } from 'ng-broadcaster';
import { BroadcasterNotification, BroadcasterNotificationType } from '../communication/broadcaster-notifications';
import { GraphqlService } from '../communication/graphql.service';
import { feedback_sub_types, feedback_types } from './feedback';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  public currentfeedback: FormGroup;
  public types: Array<feedback_types>;
  public filteredSubTypes: Array<feedback_sub_types>;
  public product: Product;
  public resource: ProductResource;
  public feedbackTypes: Array<feedback_types>;
  private inProgress: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private broadcaster: BroadcasterService,
    private gql: GraphqlService
  ) {

  }

  async init(product: Product, resource: ProductResource, feedback_type?: number) {
    await this.getFeedbackTypes()
    if (product)
      this.product = product;
    if (resource)
      this.resource = resource;
    let fbt = this.types[0].id;
    if (feedback_type)
      fbt = feedback_type;
    this.currentfeedback = this.formBuilder.group({
      feedback_type: [fbt, Validators.required],
      feedback_sub_type: null,
      notes: null,
      opened_by: this.auth.user.id,
      resource_id: this.resource?.id,
      screenshot: null,
      title: null
    });
   // this.currentfeedback.get('feedback_sub_type').setValidators(this.feedbackTypeValidator(this.currentfeedback));
    this.initSubType(fbt)
  }

  // feedbackTypeValidator(form: FormGroup): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     const feedbackType = form.get('feedback_type')?.value;
  //     const needRequire = this.types.some(t => t.id === feedbackType && t.feedback_sub_types.length);
  //     const noValue = needRequire ? !(control.value) : false
  //     return noValue ? { required: control.value } : null;
  //   };
  // }

  isHasImgInNotes() {
    let notes = this.currentfeedback.value.notes;
    if (notes && notes.indexOf('<img ') > -1) {
      return true;
    }
    return false;
  }

  isScreenshotValid(): boolean {

    let obj: BroadcasterNotification = {
      text: "",
      type: BroadcasterNotificationType.Info,
      action: 'OK'
    }
    this.broadcaster.broadcast('notifyUser', obj);

    if (!this.currentfeedback || !this.currentfeedback.value || !this.currentfeedback.value.feedback_type) return false;
    if (this.currentfeedback.value.feedback_type !== FeedbackType.others) {
      if (!this.currentfeedback.value.screenshot && !this.isHasImgInNotes()) {
        obj.text = "You must add an image that reflects the issue in order to submit the feedback.";
        this.broadcaster.broadcast('notifyUser', obj);
        return false;
      }
    }
    return true;
  }

  async initSubType(typeId: number) {
    this.filteredSubTypes = null;
    this.currentfeedback.controls.feedback_sub_type.setValue(null);
    let types = this.types.filter(t => t.id == typeId);
    if (types?.length && types[0].feedback_sub_types?.length) {
      this.filteredSubTypes = types[0].feedback_sub_types;
    }
  }

  async getFeedbackTypes(): Promise<Array<feedback_types>> {
    return new Promise((resolve, reject) => {
      if (!this.types && !this.inProgress) {
        this.inProgress = true;
        this.gql.feedbackTypes().subscribe({
          next: (res) => {
            this.inProgress = false;
            this.types = res.data.feedbackTypes;
            resolve(this.types);
          },
          error: (err) => reject(err)
        })
      } else if (this.types && !this.inProgress) {
        resolve(this.types);
      }
    });
  }

  getFeedbackNamebyId(id: number) {
    for (let i = 0; i < this.types.length; i++) {
      const t = this.types[i];
      if (t.id == id)
        return t.description;
      for (let j = 0; j < t.feedback_sub_types.length; j++) {
        const st = t.feedback_sub_types[j];
        if (st.id == id)
          return st.description;
      }
    }
  }

}
