<div class="wrap">
  <form [formGroup]="inviteForm" (ngSubmit)="invite()" novalidate>
    <div fxLayout="column" fxLayoutAlign="space-between center">
      <div fxFill>
        <mat-form-field fxFill>
          <input matInput type="text" placeholder="First Name" formControlName="firstname" required>
          <mat-hint *ngIf="inviteForm.hasError('required',['firstname']) && inviteForm.dirty" align="start">first name is
            required
          </mat-hint>
        </mat-form-field>
      </div>
      <div fxFill>
        <mat-form-field fxFill>
          <input matInput type="email" placeholder="Email" formControlName="email" pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
            required>
          <mat-hint *ngIf="inviteForm.hasError('required',['email']) && inviteForm.dirty" align="start">e-mail is required</mat-hint>
          <mat-hint *ngIf="inviteForm.hasError('pattern',['email']) && inviteForm.dirty" align="start">e-mail not valid</mat-hint>
        </mat-form-field>
      </div>
      <div fxFill *ngIf="roles.isSuperUserOrObserverLoggedIn()">
        <app-retailers-autocomplete [placeholder]="'select a retailer'" class="disp-block" [clear-on-select]="false" (retailer-change)="onRetailerChange($event)"></app-retailers-autocomplete>
      </div>
      <div fxFill *ngIf="retailer">
        <mat-form-field fxFill>
          <input matInput type="password" placeholder="Password" formControlName="password" pattern="^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$">
          <mat-hint *ngIf="inviteForm.hasError('pattern',['password']) && inviteForm.dirty" align="start">must contain special
            characters, numbers, small letters, capital letters and at least 8 characters long</mat-hint>
        </mat-form-field>
      </div>
      <!-- <div fxFill>
        <div class="creator">
          <span class="text2">Allow to create new Asset</span>
          <app-toggle-checkbox class="float-op-dir" ngDefaultControl [on-val]="true" [off-val]="false" (on-change)="enabledChange($event)"></app-toggle-checkbox>
        </div>
      </div> -->
      <div fxFill>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4" class="actions">
          <div fxFlex="48" class="text-center">
            <button type="button" (click)="reset()" mat-raised-button class="second_button submit min-thin-button-width outline">
              reset
            </button>
          </div>
          <div fxFlex="48" class="text-center">
            <button mat-raised-button color="accent" class="prime_button submit min-thin-button-width">
              invite
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
