<div class="wrap vertical-align box-shadow pos-rel">
  <div class="bottom-box"></div>
  <button mat-icon-button color="accent" class="close pos-abs" (click)="close()">
    <i class="icon-close"></i>
  </button>
  <div class="scrollbar">
    <div class="screenshot-wrap" *ngIf="this.feedback.screenshot">
      <img [src]="this.feedback.screenshot" class="center-me" alt="screenshot">
    </div>
    <div class="disp-flex flex-column space-between flex-start">
      <div class="disp-flex flex-column space-between flex-start">
        <p class="text4">
          {{feedback.created_at | moment : 'format' : 'llll'}} <b class="text2"
            *ngIf="feedback.fixed">&nbsp;(solved)</b>
        </p>
        <div class="text2">
          <b class="capitalize">{{feedback.users[0].firstname}} {{feedback.users[0].lastname}}</b> feedbacked model in
          regards
          to a
          <b *ngIf="feedback.feedback_types[0]?.id">{{feedback.feedback_types[0]?.description}} issue</b>
          <p>
            <app-text-editor [disable]="true" class="customized" fxFill [init-value]="feedback.notes"></app-text-editor>
          </p>
        </div>
      </div>
      <div class="disp-flex space-between align-baseline">
        <div> <h3 class="h2">feedback type</h3></div>
        <div>
          <mat-form-field>
            <mat-select (selectionChange)="feedbackService.initSubType($event?.value)"
              [(ngModel)]="feedback.feedback_type">
              <ng-container *ngFor="let t of feedbackService.types">
                <mat-option [value]="t.id">
                  {{ t.description }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="isShowSubType && feedbackService.filteredSubTypes"> <h3 class="h2">feedback sub-type</h3></div>
        <div *ngIf="isShowSubType && feedbackService.filteredSubTypes?.length">
          <mat-form-field>
            <mat-select placeholder="choose sub type" [(ngModel)]="feedback.feedback_sub_type">
              <ng-container *ngFor="let st of feedbackService.filteredSubTypes">
                <mat-option [value]="st.id">
                  {{ st.description }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <button mat-raised-button (click)="saveType(feedback.feedback_type, feedback.feedback_sub_type)"
            color="accent" class="save-button thinner">
            save 
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="feedback.products_resources_feedback_comments?.length" class="text2">
      <div *ngFor="let c of feedback.products_resources_feedback_comments" class="fade-in">
        <mat-divider></mat-divider>
        <p>
          on
          <span class="text4">{{c.created_at | moment : 'format' : 'llll'}}</span>
          <b class="capitalize"> {{c.users[0].firstname}} {{c.users[0].lastname}}</b> commented:
        </p>
        <app-text-editor [disable]="true" class="customized" fxFill [init-value]="c.comment"></app-text-editor>
      </div>
    </div>
    <div class="comment-creation text2">
      <app-text-editor [set-value]="textValue" class="customized" fxFill (textChange)="onTextChange($event)">
      </app-text-editor>
    </div>
  </div>
  <span class="feedback-toggle first" *ngIf="showSolved()">
    <span class="va-super">solved&nbsp;</span>
    <app-toggle-checkbox (on-change)="onFixChange($event)" ngDefaultControl [on-val]="true" [off-val]="false"
      [(ngModel)]="feedback.fixed"></app-toggle-checkbox>
  </span>
  <span class="feedback-toggle" *ngIf="isSU">
    <span class="va-super">pause due date&nbsp;</span>
    <app-toggle-checkbox ngDefaultControl [on-val]="true" [off-val]="false" [(ngModel)]="isPauseDueDate">
    </app-toggle-checkbox>
  </span>
  <button mat-raised-button (click)="addComment()" color="accent" class="thinner float-op-dir">
    submit
  </button>
</div>