<form [formGroup]="form" novalidate (ngSubmit)="save()">
  <div fxLayout="column" fxLayoutAlign="space-between center" class="marg-bp">
    <div fxFill>
      <mat-form-field fxFill>
        <mat-select formControlName="business_plan_id" placeholder="business plan" required>
          <mat-option *ngFor="let bp of allBusinessPlans" [value]="bp.key">{{ bp.value }}</mat-option>
        </mat-select>
        <mat-hint *ngIf="form.hasError('required',['business_plan_id']) && form.dirty" align="start">business plan is
          required
        </mat-hint>
      </mat-form-field>
    </div>
    <div fxFill *ngIf="PayPer3DAsset == form.controls.business_plan_id.value">
      <mat-form-field fxFill>
        <mat-select formControlName="agreement_budget_type" placeholder="agreement budget type " required>
          <mat-option *ngFor="let abt of allAgreementBudgetTypes" [value]="abt.key">{{ abt.value }}</mat-option>
        </mat-select>
        <mat-hint *ngIf="form.hasError('required',['agreement_budget_type']) && form.dirty" align="start">agreement
          budget type is
          required
        </mat-hint>
      </mat-form-field>
    </div>
    <div fxFill *ngIf="PayPerAPICall == form.controls.business_plan_id.value">
      <mat-form-field fxFill>
        <mat-select formControlName="payment_model" placeholder="payment model" required>
          <mat-option *ngFor="let pm of allPaymentModel" [value]="pm.key">{{ pm.value }}</mat-option>
        </mat-select>
        <mat-hint *ngIf="form.hasError('required',['payment_model']) && form.dirty" align="start">payment model is
          required
        </mat-hint>
      </mat-form-field>
    </div>
    <div fxFill>
      <mat-form-field fxFill>
        <mat-label>start at</mat-label>
        <input matInput [matDatepicker]="startPicker" #startPickerInput>
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxFill>
      <mat-form-field fxFill>
        <mat-label>end at</mat-label>
        <input matInput [matDatepicker]="endPicker" #endPickerInput>
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxFill *ngIf="PayPerAPICall == form.controls.business_plan_id.value">
      <mat-form-field fxFill>
        <input matInput type="number" placeholder="price per API call" formControlName="price_per_api_call" required>
        <mat-hint *ngIf="form.hasError('required',['price_per_api_call']) && form.dirty" align="start">price per API
          call
          is required</mat-hint>
      </mat-form-field>
    </div>
    <div fxFill>
      <mat-form-field fxFill>
        <input matInput type="number" placeholder="API balance limit ($)" formControlName="agreement_limit" required>
        <mat-hint *ngIf="form.hasError('required',['agreement_limit']) && form.dirty" align="start">agreement limit
          is required</mat-hint>
      </mat-form-field>
    </div>
    <div fxFill *ngIf="!(Unlimited == form.controls.payment_model.value && PayPerAPICall == form.controls.business_plan_id.value)">
      <mat-form-field fxFill>
        <input matInput type="number" placeholder="add balance credit" formControlName="monthly_credit" required>
        <mat-hint *ngIf="form.hasError('required',['monthly_credit']) && form.dirty" align="start">monthly credit
          is required</mat-hint>
      </mat-form-field>
    </div>
    <div fxFill *ngIf="!(Unlimited == form.controls.payment_model.value && PayPerAPICall == form.controls.business_plan_id.value)">
      <mat-form-field fxFill>
        <mat-select formControlName="credit_type" placeholder="credit type" required>
          <mat-option *ngFor="let ct of allCreditType" [value]="ct.key">{{ ct.value }}</mat-option>
        </mat-select>
        <mat-hint *ngIf="form.hasError('required',['credit_type']) && form.dirty" align="start">credit type is
          required
        </mat-hint>
      </mat-form-field>
    </div>
    <div fxFill *ngIf="PayPerAPICall == form.controls.business_plan_id.value">
      <mat-form-field fxFill>
        <input matInput type="number" placeholder="minimum 3D asset per month" formControlName="minimum_monthly_assets"
          required>
        <mat-hint *ngIf="form.hasError('required',['minimum_monthly_assets']) && form.dirty" align="start">minimum
          monthly assets
          is required</mat-hint>
      </mat-form-field>
    </div>
    <div fxFill>
      <mat-form-field fxFill>
        <input matInput type="number" placeholder="maximum assets download" formControlName="maximum_assets_download"
          required>
        <mat-hint *ngIf="form.hasError('required',['maximum_assets_download']) && form.dirty" align="start">maximum
          assets download
          is required</mat-hint>
      </mat-form-field>
    </div>
    <div fxFill *ngIf="PayPer3DAsset == form.controls.business_plan_id.value">
      <mat-form-field fxFill>
        <input matInput type="number" placeholder="agreement budget" formControlName="agreement_budget" required>
        <mat-hint *ngIf="form.hasError('required',['agreement_budget']) && form.dirty" align="start">agreement budget
          is required</mat-hint>
      </mat-form-field>
    </div>
    <div fxFill *ngIf="PayPer3DAsset == form.controls.business_plan_id.value">
      <mat-form-field fxFill>
        <input matInput type="number" placeholder="API usage fixed price" formControlName="fixed_payment" required>
        <mat-hint *ngIf="form.hasError('required',['fixed_payment']) && form.dirty" align="start">fixed payment
          is required</mat-hint>
      </mat-form-field>
    </div>
    <div fxFill>
      <mat-slide-toggle color="primary" formControlName="is_poc">is poc</mat-slide-toggle>
    </div>
  </div>
  <div fxFill>
    <button mat-raised-button mat-raised-button color="accent" type="submit" class="thinner marg-top">
      save business model
    </button>
  </div>
</form>