import { Component, AfterViewInit, ViewChild, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ProductRequestService } from 'src/app/product/product-request.service';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'app-viewer-iframe',
  templateUrl: './viewer-iframe.component.html',
  styleUrls: ['./viewer-iframe.component.scss']
})
export class ViewerIframeComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input('viewer-url') viewerUrl: string;
  @ViewChild('iframe') iframeModel: ElementRef;
  public fSMode: boolean;
  private isActive: boolean;
  constructor(
    public prService: ProductRequestService,
    private utils: UtilsService,
  ) {
    this.isActive = true;
  }

  ngOnInit() {
    this.viewerUrl = this.utils.setUrlParam(this.viewerUrl, 'theme-color', '#546994');
  }

  ngAfterViewInit() {
    this.checkIframeLoaded()  
    this.viewerUrl = this.utils.setUrlParam(this.viewerUrl, 'theme-color', '#546994');
  }

  setIframeModel() {
    this.prService.iframeModel = this.iframeModel;
  }

  checkIframeLoaded() {
    if (this.iframeModel.nativeElement.contentWindow)
      this.setIframeModel();
    else if (this.isActive)
      setTimeout(this.checkIframeLoaded.bind(this), 250);
  }

  ngOnDestroy() {
    this.isActive = false;
  }
}
