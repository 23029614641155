<mat-tree *ngIf="dataSource" [dataSource]="dataSource" [treeControl]="treeControl" class="contrast content-va">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-checkbox [checked]="node.checked" [indeterminate]="node.indeterminate" (change)="nodeSelectionToggle(node)"
      color="primary">
      {{node.item.category_name_org_lang || node.item.sub_category_name_org_lang}}
    </mat-checkbox>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node; when: hasChildren" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename" color="primary">
      <mat-icon>
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-checkbox [checked]="node.checked" [indeterminate]="node.indeterminate" (change)="nodeSelectionToggle(node)"
      color="primary">
      {{node.item.category_name_org_lang}}
    </mat-checkbox>
  </mat-tree-node>
</mat-tree>