import { Component, OnInit } from '@angular/core';
import { Retailer } from 'src/app/retailer/retailer';
import { GraphqlService } from 'src/app/communication/graphql.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public retailer: Retailer;
  constructor(
    private gql: GraphqlService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
  }

  onRetailerChange(retailer: Retailer) {
    if (retailer) {
      // delete this.retailer;
      this.gql.retailerForProduct({
        id: retailer.id
      }).subscribe(
        res => {
          this.retailer = retailer = this.utils.deepCopyByValue(res.data.retailers);
          // this.prService.request.retailers[0]
          // this.retailer = retailer;
          // if (this.prService.request.retailers[this.prService.retailerIndex] && this.prService.request.retailers[this.prService.retailerIndex].retailers_resources_types.length)
          //   this.prService.requiredFormats = this.resourceTypesService.mapRetailerResourceTypeToUi(this.prService.request.retailers[this.prService.retailerIndex].retailers_resources_types);
        }
      );
    }
  }
}
