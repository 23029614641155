<a href="https://vqa.creators3d.com/index.html?load=%2Fviews%2Fqa%2Fitem%2F2019213%2F175559224030111%2F175559224030111.obj&autorotate=true&decrypt=1&le-hem=0&le-dir=0.13&le-pnt=0&le-amb=0.43&le-spt=0.23&hdr-intensity=1&shadow-type=dir&show-cam-rot=1"
  target="_blank">
  cube
</a>
<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around" class="flex-wrap-wrap"
  *ngFor="let c of config; let i = index">
  <mat-form-field fxFlex="50" fxFlex.gt-sm="20">
    <input type="number" matInput placeholder="x axis angle" [(ngModel)]="c.angle_x" name="angle_x">
  </mat-form-field>
  <mat-form-field fxFlex="50" fxFlex.gt-sm="20">
    <input type="number" matInput placeholder="y axis angle" [(ngModel)]="c.angle_y" name="angle_y">
  </mat-form-field>
  <div fxFlex="50" fxFlex.gt-sm="20">
    <div fxFlex class="text-center file-image">
      <app-file-upload *ngIf="!c.screenshot" class="accent" [round]="true" [text]="'screenshot'" [drop-mode]="true"
        [icon]="'cloud_upload_outline'" [miltiple]="false" (filesChange)="onFilesChange($event, i)"
        [accept]="'image/*'">
      </app-file-upload>
      <div *ngIf="c.screenshot" class="logo-wrap">
        <img [src]="c.screenshot" alt="profile image">
        <button class="delete-image" mat-button (click)="deleteImage(i)">
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div fxFlex="50" fxFlex.gt-sm="20">
    <button mat-raised-button mat-raised-button color="accent" (click)="save(i)" class="thinner marg-top">
      save render angle
    </button>
  </div>
</div>
<button mat-raised-button mat-raised-button color="accent" (click)="addAngle()" class="thinner marg-top">
  add angle
</button>