<div class="wrap" [style.height]="height" [style.width]="width + 70 + 'px'" #wrap>
  <button mat-mini-fab (click)="navLeft()" [disabled]="currentIndex <= 0" class="nav left" [style.top]="((height / 2) - 20) + 'px'"
    color="accent">
    &#60;
  </button>
  <div class="center-me image-wrap" [style.height]="height + 'px'" [style.width]="width + 'px'">
    <div class="scroller" [ngClass]="{'imm': imm}" [style.width]="(width * total) + 'px'" [style.transform]="'translateX(' + width * (-currentIndex) + 'px)'">
      <span class="image" *ngFor="let img of images; let i = index" [style.width]="width + 'px'" [style.height]="height + 'px'"
        [style.background-image]="getBackground(img, i)">
      </span>
    </div>
  </div>
  <button mat-mini-fab (click)="navRight()" [disabled]="currentIndex >= total - 1 || total == 0" class="nav right" [style.top]="((height / 2) - 20) + 'px'"
    color="accent">
    &#62;
  </button>
</div>