export interface CMSNotification {
    notes: string;
    icon: string;
    title: string;
    // message_type: NotificationType;
    created_at: Date;
    updated_at: Date;
    id: number;
    read: boolean;
    reference_id: number;
    artist_user_id: number;
    // notification_type: number;
    notifications_user_id: Array<number>;
    notifications_types: Array<CMSNotificationType>;
}

export interface CMSNotificationListResponse {
    unread_count: number;
    count: number;
    items: Array<CMSNotification>
}

export interface CMSNotificationType {
    id: number;
    created_at: Date;
    updated_at: Date;
    description: string;
}

export enum CMSNotificationsType {
    ROLES_REFRESHED = 1,
    VERSION_UPDATE = 2,
    ANNOUNCEMENT = 3,
}