<div class="wrap contrast" *ngIf="visible">
    <h3>
        Cookie Policy
    </h3>
    <p>
        Please note that on our website we use cookies necessary for the functionality of our website. To learn more
        about cookies and how we use them please read our <a routerLink="/privacy" class="inline-link">privacy
            policy</a>.
    </p>
    <div>
        <button mat-raised-button class="third_button" (click)="approve()">
            OK
        </button>
    </div>
</div>