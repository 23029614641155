import { Injectable } from '@angular/core';
import { ProductCategory, ProductSubCategory, ProductCategoriesDictionaries } from './product-categories';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilsService } from '../shared/utils.service';
import { GraphqlService } from '../communication/graphql.service';

@Injectable({
  providedIn: 'root'
})
export class ProductCategoriesService {
  private cachedCategories: Array<ProductCategory>;
  private cachedParentCategories: Array<ProductCategory>;
  private _categoriesDictionaryDictionary: { [id: number]: ProductCategory };
  private _subCategoriesDictionaryDictionary: { [id: number]: ProductSubCategory };
  private _parentCategoriesToParentDictionary: { [id: number]: ProductCategory };
  private isFetching: boolean;
  private fetch: Observable<any>;
  // private _categoriesPricing: { [id: number]: Array<CategoryPrice> };
  constructor(
    private utils: UtilsService,
    private gql: GraphqlService
  ) {
    this.isFetching = false;
    // this._categoriesPricing = {};
    this.getCategories();
  }

  private setDictionaries(): void {
    this._categoriesDictionaryDictionary = {};
    this._subCategoriesDictionaryDictionary = {};
    this.getCachedCategories().forEach((category) => {
      this._categoriesDictionaryDictionary[category.id] = category;
      if (category.products_sub_categories) {
        category.products_sub_categories.forEach((sub) => {
          this._subCategoriesDictionaryDictionary[sub.id] = sub;
        });
      }
    });
  }

  get categoriesDictionaryDictionary(): { [id: number]: ProductCategory } {
    if (!this.cachedCategories) throw 'categories arene\'t ready yet, please use CategoriesResolve for your route.';
    if (!this._categoriesDictionaryDictionary) {
      this.setDictionaries();
    }
    return this._categoriesDictionaryDictionary;
  }

  get subCategoriesDictionaryDictionary(): { [id: number]: ProductSubCategory } {
    if (!this.cachedCategories) throw 'categories arene\'t ready yet, please use CategoriesResolve for your route.';
    if (!this._subCategoriesDictionaryDictionary) {
      this.setDictionaries();
    }
    return this._subCategoriesDictionaryDictionary;
  }

  get parentCategoriesToParentDictionary(): { [id: number]: ProductCategory } {
    if (!this.cachedCategories) throw 'categories arene\'t ready yet, please use CategoriesResolve for your route.';
    if (!this._parentCategoriesToParentDictionary) {
      if (!this.cachedParentCategories)
        this.setParents();
      this._parentCategoriesToParentDictionary = {};
      this.cachedCategories.forEach(c => {
        this._parentCategoriesToParentDictionary[c.id] = this.getParentByCategoryId(c.id);
      });
    }
    return this._parentCategoriesToParentDictionary;
  }

  getDictionaries(categories: Array<ProductCategory>): ProductCategoriesDictionaries {
    let dictionary = {
      categoriesDictionary: {},
      subCategories: [],
      subCategoriesDictionary: {}
    } as ProductCategoriesDictionaries;
    categories.forEach((category) => {
      dictionary.categoriesDictionary[category.id] = category;
      if (category.products_sub_categories) {
        category.products_sub_categories.forEach((sub) => {
          dictionary.subCategoriesDictionary[sub.id] = sub;
          dictionary.subCategories.push(sub);
        });
      }
    });
    return dictionary;
  }

  getCachedCategories() {
    return this.cachedCategories;
  }

  getCachedParentsCategories() {
    return this.cachedParentCategories;
  }

  private getParentByCategoryId(cid: number): ProductCategory {
    let res = null as ProductCategory;
    if (!this.cachedParentCategories) return res;
    this.cachedParentCategories.forEach(p => {
      if (p.children.find(c => c == cid)) {
        res = p;
        return false;
      }
    })
    return res;
  }

  setCachedCategories(categories: Array<ProductCategory>) {
    this.cachedCategories = this.utils.deepCopyByValue(categories);
    this.setParents();
  }

  // getCategoryPricing(cid: number): Observable<any> {
  //   if (this._categoriesPricing[cid])
  //     return Notification.createNext(this._categoriesPricing[cid]).toObservable();
  //   return this.gql.categoriesPricing(cid).map(res => {
  //     this._categoriesPricing[cid] = res.data.categories_pricing;
  //     return this._categoriesPricing[cid];
  //   });
  // }

  getCategories(): Observable<Array<ProductCategory>> {
    if (this.isFetching) return this.fetch;
    this.isFetching = true;
    // this.fetch = this.gql.productsCategories().map(res => {
    this.fetch = this.gql.productsCategories().pipe(map(res => {
      this.cachedCategories = this.utils.deepCopyByValue(res.data.productsCategories);
      this.setParents();
      this.isFetching = false;
      return this.getCachedCategories();
    }));
    return this.fetch;
  }

  getParentsByCategoriesId(ids: Array<number>, asArray = false): any {//{ [id: string]: Category; } {
    let res = {} as { [id: string]: ProductCategory; };
    if (!ids || !ids.length || !this.cachedCategories) return res;
    ids.forEach(i => {
      res[this.cachedCategories.find(j => j.id == i).parent_description] = this.cachedCategories.find(c => c.id == i);
      // res[i] = this.cachedCategories.find(c => c.id == i);
    });
    Object.keys(res).forEach(p => res[p].children = this.cachedParentCategories.find(i => i.parent_description == res[p].parent_description).children);
    if (asArray)
      return Object.values(res);
    return res;
  }

  private setParents() {
    this.cachedParentCategories = [];
    this.cachedCategories.forEach(
      c => {
        if (!this.cachedParentCategories.find(p => p.parent_description == c.parent_description)) {
          let p = Object.assign({}, c) as ProductCategory;
          p.children = [];
          this.cachedCategories.forEach(ci => {
            if (ci.parent_description == p.parent_description)
              p.children.push(ci.id);
          });
          this.cachedParentCategories.push(p);
        }
      }
    );
  }
}
