<div class="auth-options">
    <div class="auth-options-buttons">
        <div>
            <a
                type="button"
                mat-button
                class="auth-options-button-wrapper auth-options-button-google"
                [href]="getGoogleLoginLink()"
            >
                <img
                    src="assets/images/google.svg"
                    alt="sign in with google"
                    class="va-middle"
                />
                Continue with <span class="capitalize">google</span>
            </a>
        </div>
        <div class="hide-me">
          <a
              type="button"
              mat-button
              class="auth-options-button-wrapper auth-options-button-okta"
              [href]="getGoogleLoginLink()"
          >
              <img
                  src="assets/images/okta.svg"
                  alt="sign in with okta"
                  class="va-middle invert-filter"
              />
              Continue with <span class="capitalize">okta</span>
          </a>
      </div>
    </div>
</div>
