import { Injectable } from '@angular/core';
import { Retailer } from '../retailer/retailer';
import { ValidState } from '../shared/utils';

@Injectable({
  providedIn: 'root'
})
export class BudgetService {

  constructor() { }

  canPay(retailer: Retailer): ValidState {
    let res = {} as ValidState;
    if (!retailer) {
      res.state = false;
      res.message = 'no retailer was provided';
      return res;
    }
    if (!retailer.zip) {
      res.state = false;
      res.message = 'zip code is mandatory for payments';
      return res;
    }
    if (!retailer.address) {
      res.state = false;
      res.message = 'address is mandatory for payments';
      return res;
    }
    if (!retailer.city) {
      res.state = false;
      res.message = 'city is mandatory for payments';
      return res;
    }
    if (!retailer.country_code) {
      res.state = false;
      res.message = 'country code is mandatory for payments';
      return res;
    }
    if (!retailer.name) {
      res.state = false;
      res.message = 'company name is mandatory for payments';
      return res;
    }
    if (!retailer.tax_id) {
      res.state = false;
      res.message = 'tax ID name is mandatory for payments';
      return res;
    }
    res.state = true;
    return res;
  }
}
