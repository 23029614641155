<div class="roles-wrap scrollbar" *ngIf="config">
  <div class="wrap float-dir">
    <div class="w-100 float-dir">
      <h3 class="h1 uppercase">
        roles
      </h3>
      <ul class="normalize">
        <li *ngFor="let r of config.roles" class="float-dir">
          <button mat-mini-fab *ngIf="!r.id" (click)="deleteRole(r.name)">
            <mat-icon class="mat-24">delete</mat-icon>
          </button>
          <span *ngIf="r.id" class="gray">
            ({{r.id}})
          </span> {{r.name}}
        </li>
      </ul>
      <form #roleForm="ngForm" (ngSubmit)="addRole(newRoleName)" class="float-left">
        <mat-form-field>
          <input matInput placeholder="role name" type="text" [(ngModel)]="newRoleName" name="newRoleName" required #name="ngModel">
          <mat-hint *ngIf="roleForm.hasError('required','newRoleName') && roleForm.dirty" [ngStyle]="{'color': 'red'}" align="start">role name is required</mat-hint>
        </mat-form-field>
        <button mat-raised-button type="submit" mat-raised-button color="primary" [disabled]="!roleForm.form.valid">
          add role
        </button>
      </form>
    </div>
    <div class="w-100 float-dir">
      <h3 class="h1 uppercase">permissions</h3>
      <ul class="normalize">
        <li *ngFor="let p of config.permissions" class="float-dir">
          <span class="gray">({{p.id}})</span> {{p.description}}
        </li>
      </ul>
    </div>
    <div class="w-100 float-dir">
      <h3 class="h1 uppercase">roles permissions</h3>
      <ul class="normalize">
        <li *ngFor="let rp of config.roles_permissions" class="float-dir">
          <button mat-icon-button color="warn" (click)="removeRolePermission(rp)">
            <mat-icon>delete</mat-icon>
          </button>
          {{rp.role_name}} can {{rp.permission_description}}
        </li>
      </ul>
    </div>
    <div class="w-100 float-dir">
      <form #rolePermissionForm="ngForm" (ngSubmit)="addRolePermission(newRoleName, newPermissionDescription)" class="float-left">
        <ul class="normalize">
          <li class="float-dir">
            <mat-form-field>
              <mat-select placeholder="Roles" name="newRoleName" [(ngModel)]="newRoleName" #name="ngModel" required>
                <mat-option *ngFor="let r of config.roles" [value]="r.name">{{ r.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li class="float-dir">
            can
          </li>
          <li class="float-dir">
            <mat-form-field>
              <mat-select placeholder="Actions" name="newPermissionDescription" [(ngModel)]="newPermissionDescription" #name="ngModel"
                required>
                <mat-option *ngFor="let p of config.permissions" [value]="p.description">{{ p.description }}</mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li class="float-dir">
            <button mat-raised-button type="submit" mat-raised-button color="primary" [disabled]="!rolePermissionForm.form.valid">
              add role permission
            </button>
          </li>
          <li class="float-dir"> {{addRolePermissionMsg}}</li>
        </ul>
      </form>
    </div>
    <div class="w-100 float-dir">
      <button mat-raised-button mat-raised-button color="primary" (click)="save()">
        save all
      </button>
    </div>
  </div>
</div>