<div class="wrap" [ngClass]="{'has-error': error}">
  <div [ngClass]="{'approved' : isShowcase, 'orders' : !isShowcase, 'has-error': error}">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center">
      <div fxFlex="{{isShowcase?80:100}}" [ngClass]="{'show-case':isShowcase}">
        <!-- <app-product-showcase (on-change)="onProductShowcaseChange($event)" *ngIf="item.products_resources?.length" [resource]="item.products_resources[resourceIndex]"></app-product-showcase> -->
        <div fxFlex="107px" class="file-upload-wrap">
          <app-file-upload *ngIf="prService.canEditItems" class="accent" [round]="false" [drop-mode]="true" [icon]="'cloud_upload_outline'" [miltiple]="true" (filesChange)="onFilesChange($event)"
            [accept]="'image/*'" [width]="107" [height]="107"></app-file-upload>
        </div>
        <app-upload-images-slider fxFlex="85" [images]="images" [width]="107" [height]="107" [enabled-pin]="true" (on-delete)="deleteImage($event)"
        (on-sort-change)="onSortChange($event)" [counter]="counter"></app-upload-images-slider>
        <div fxFlex="245px" *ngIf="error" class="error dir-dir">
          <div class="warn">
            <mat-icon color="warn">error_outline</mat-icon>
            {{error.key}}
          </div>
          <div class="text3">
            {{error.value}}
          </div>
        </div>
      </div>
      <div fxFlex fxFlexAlign="stretch" *ngIf="isShowcase">
        <div class="first-showcase" [ngClass]="{'gray': item.enabled != 1}">
          <i aria-label="enabled on desktop devices" class="icon-screen icon-button-small"></i>
          <!-- <mat-icon aria-label="enabled on PC">computer</mat-icon> -->
          <span class="text2">Live On Desktop</span>
          <app-toggle-checkbox ngDefaultControl [on-val]="1" [off-val]="0" [(ngModel)]="item.enabled" (on-change)="save()"></app-toggle-checkbox>
          <!-- <mat-slide-toggle>Live On Desktop</mat-slide-toggle> -->
        </div>
        <div [ngClass]="{'gray': item.mobile_enabled != 1}" class="space-top">
          <i aria-label="enabled on mobile devices" class="icon-smartphone icon-button-small"></i>
          <!-- <mat-icon aria-label="enabled on mobile devices">smartphone</mat-icon> -->
          <span class="text2">Live On Mobile</span>
          <app-toggle-checkbox ngDefaultControl [on-val]="1" [off-val]="0" [(ngModel)]="item.mobile_enabled" (on-change)="save()"></app-toggle-checkbox>
        </div>
        <div class="space-top" *ngIf="item.products_resources && item.products_resources[0]">
          <a [href]="item.products_resources[0].resource_default" download="3d_model" target="_blabk" class="link1 no-ul">
            <i class="icon-download"></i>
          </a>
          <span (click)="productService.downloadResource(item.products_resources[0])" download="3d_model" class="link1 pointer">Download
            3D Model</span>
        </div>
      </div>
    </div>
  </div>
  <!-- <div fxLayout="row" fxLayoutAlign="space-around center" class="bottom pos-abs w-100" *ngIf="isShowcase && item.products_resources?.length">
    <div fxFlex="20"></div>
    <div fxFlex="80" class="text2 text-center">
      The sizes of the images on your website will change when you click "Save"
    </div>
    <div fxFlex="20" class="text-center">
      <button mat-raised-button color="accent" class="invert thinner" (click)="save()">
        save
      </button>
    </div>
  </div> -->
</div>