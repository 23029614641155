import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { MaterialModule } from 'src/app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { FlexLayoutModule } from "@angular/flex-layout"
import { BroadcasterService } from 'ng-broadcaster';
import { GlobalsService } from './globals.service';
import { RolesHelperService } from 'src/app/auth/roles-helper.service';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { KeyValuePurePipe } from './key-value-pure.pipe';
import { MapperService } from 'src/app/shared/mapper.service';
import { SimpleSliderComponent } from './simple-slider/simple-slider.component';
import { ClipboardDirective } from './clipboard.directive';
import { BodyService } from 'src/app/shared/body.service';
import { RoundCheckboxComponent } from './round-checkbox/round-checkbox.component';
import { GraphqlService } from 'src/app/communication/graphql.service';
import { PreventKeysDirective } from './prevent-keys.directive';
import { SimpleCarouselSliderComponent } from './simple-carousel-slider/simple-carousel-slider.component';
import { ModelImageCompareComponent } from './model-image-compare/model-image-compare.component';
import { ImageHoverOverlayComponent } from './image-hover-overlay/image-hover-overlay.component';
import { CanvasPaintComponent } from './canvas-paint/canvas-paint.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { ToggleCheckboxComponent } from './toggle-checkbox/toggle-checkbox.component';
import { MomentPipe } from './moment.pipe';
import { UploadImagesSliderComponent } from './upload-images-slider/upload-images-slider.component';
import { RetailersAutocompleteComponent } from 'src/app/retailer/retailers-autocomplete/retailers-autocomplete.component';
import { RetailerProfileComponent } from '../retailer/retailer-profile/retailer-profile.component';
import { UsersSummaryComponent } from '../auth/users-summary/users-summary.component';
import { UserCardComponent } from '../auth/user-card/user-card.component';
// import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { SwService } from '../sw-helper/sw.service';
import { ResourceTypesService } from './resource-types.service';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { SourceFileTypesService } from './source-file-types.service';
import { RetailerApiTokenComponent } from '../retailer/retailer-api-token/retailer-api-token.component';
import { PayComponent } from '../dashboard/pay/pay.component';
import { AddFundsComponent } from '../auth/add-funds/add-funds.component';
import { SafeWindowPipe } from './safe-window.pipe';
import { SpaceUpperPipe } from './space-upper.pipe';
import { ViewerIframeComponent } from './viewer-iframe/viewer-iframe.component';
import { AssetAdjustmentsComponent } from '../asset-adjustments/asset-adjustments/asset-adjustments.component';
import { AssetAdjustmentsFilterPipe } from '../asset-adjustments/asset-adjustments-filter.pipe';
import { CoordinatesComponent } from '../asset-adjustments/coordinates/coordinates.component';
import { NoIntensityPipe } from '../asset-adjustments/no-intensity.pipe';
import { TextureMapComponent } from '../asset-adjustments/texture-map/texture-map.component';
import { CategoriesResolve } from '../product-categories/categories.resolve';
import { ImagesMenuComponent } from './images-menu/images-menu.component';
import { CookiesComplianceComponent } from './cookies-compliance/cookies-compliance.component';
import { RendererComponent } from './renderer/renderer.component';
import { UsersManagementComponent } from '../auth/users-management/users-management.component';
import { UsersAutocompleteComponent } from './users-autocomplete/users-autocomplete.component';
import { RetailerBatchesComponent } from '../retailer/retailer-batches/retailer-batches.component';
import { AssetCommunicationComponent } from './asset-communication/asset-communication.component';
import {environment} from '../../environments/environment';
import { RetailerProductIpComponent } from './retailer-product-ip/retailer-product-ip.component';
import { ResourceDropdownComponent } from './resource-dropdown/resource-dropdown.component';
import { ShowIfTruncatedDirective } from './show-if-truncated.directive';
import { DynamicSnackBarComponent } from './dynamic-snack-bar/dynamic-snack-bar.component';
import { MouseDragScrollDirective } from './mouse-drag-scroll.directive';
import { DropZoneComponent } from './drop-zone/drop-zone.component';
import { SortPipe } from './sort.pipe';
import { ToggleComponent } from './toggle/toggle.component';
import { SrcSuffixPipe } from './src-suffix.pipe';
import { OrSeparatorComponent } from './or-separator/or-separator.component';

@NgModule({
    imports: [
        CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, RouterModule, FlexLayoutModule
    ],
    exports: [MaterialModule, FormsModule, ReactiveFormsModule, RouterModule, FlexLayoutModule,
        FileUploadComponent, UsersManagementComponent, KeyValuePurePipe, SimpleSliderComponent, ClipboardDirective, RoundCheckboxComponent, PreventKeysDirective,
        SimpleCarouselSliderComponent, ModelImageCompareComponent, ImageHoverOverlayComponent, CanvasPaintComponent, TextEditorComponent,
        ToggleCheckboxComponent, MomentPipe, UploadImagesSliderComponent, UsersAutocompleteComponent, RetailersAutocompleteComponent, RetailerProfileComponent,
        UsersSummaryComponent, UserCardComponent, RetailerApiTokenComponent, PayComponent,
        AddFundsComponent, SafeWindowPipe, SpaceUpperPipe, ViewerIframeComponent, AssetAdjustmentsFilterPipe, AssetCommunicationComponent,
        CoordinatesComponent, NoIntensityPipe, TextureMapComponent, AssetAdjustmentsComponent, CookiesComplianceComponent, RendererComponent, RetailerBatchesComponent,
        RetailerProductIpComponent, MouseDragScrollDirective, DropZoneComponent, DynamicSnackBarComponent, ResourceDropdownComponent, SortPipe, ToggleComponent,
        SrcSuffixPipe, OrSeparatorComponent],
    declarations: [FileUploadComponent, UsersManagementComponent, KeyValuePurePipe, SimpleSliderComponent, ClipboardDirective, RoundCheckboxComponent,
        PreventKeysDirective, SimpleCarouselSliderComponent, ModelImageCompareComponent, ImageHoverOverlayComponent, CanvasPaintComponent,
        TextEditorComponent, ToggleCheckboxComponent, MomentPipe, UploadImagesSliderComponent, UsersAutocompleteComponent, RetailersAutocompleteComponent,
        RetailerProfileComponent, UsersSummaryComponent, UserCardComponent, ImageDialogComponent, RetailerApiTokenComponent, PayComponent,
        AddFundsComponent, SafeWindowPipe, SpaceUpperPipe, ViewerIframeComponent, AssetAdjustmentsFilterPipe,
        CoordinatesComponent, NoIntensityPipe, TextureMapComponent, AssetAdjustmentsComponent, ImagesMenuComponent, CookiesComplianceComponent, RendererComponent,
        RetailerBatchesComponent, AssetCommunicationComponent, RetailerProductIpComponent, MouseDragScrollDirective, DropZoneComponent, DynamicSnackBarComponent,
        ResourceDropdownComponent, ShowIfTruncatedDirective, SortPipe, ToggleComponent, SrcSuffixPipe, OrSeparatorComponent],
    providers: [
        AuthService, BroadcasterService, GlobalsService, RolesHelperService, MapperService, BodyService, GraphqlService, SwService,
        DecimalPipe, ResourceTypesService, SourceFileTypesService, CategoriesResolve
    ]
})
export class SharedModule { }
