export enum EndPoints {
  CMS = 'cms',
  GRAPH = 'graph',
  CAROUSEL = 'carousel',
  NOTIF_WEBSOCKETS = 'notificationsWebsocket',
  ANALYTICS = 'analytics',
  UPLOAD = 'upload',
  THREE_JS_VIEWER = 'threejsviewer',
  CREATORS_VIEWER = 'creatorsviewer',
  DOWNLOAD = 'download',
  CREATORS = 'creators'
}
