<div class="wrap text2">
  <div fxLayout="row" fxLayoutAlign="space-around center">
    <div fxFlex>
      <b>{{productService.totalSelected}}</b> Products Selected
    </div>
    <div fxFlex *ngIf="productService.currentPrimeStatusData.primeStatus == 1">
      <div *ngIf="productService.statusDataIndex == 3">
        <button mat-raised-button class="thinner capitalize marg-r" color="accent" (click)="approveAll()">Approve</button>
        <button mat-raised-button class="thinner capitalize" color="accent" (click)="goLiveAll()">approve and go live</button>
      </div>
    </div>
    <!-- <div fxFlex *ngIf="isSU" class="pos-rel ia-toggle">
      <i aria-label="toggle internally approved" class="icon-eye icon-button-small"></i>
      <span class="pos-rel desc">Internally Approved</span>
      <mat-button-toggle-group aria-label="desktop state" class="customized pos-rel" (change)="toggleIA($event)">
        <mat-button-toggle [value]="true">on</mat-button-toggle>
        <mat-button-toggle [value]="false">off</mat-button-toggle>
      </mat-button-toggle-group>
    </div> -->
    <div fxFlex *ngIf="productService.currentPrimeStatusData.primeStatus == 2" class="pos-rel enable-toggle">
      <i aria-label="enabled on desktop devices" class="icon-screen icon-button-small mr"></i>
      <i aria-label="enabled on mobile devices" class="icon-smartphone icon-button-small"></i>
      <!-- <mat-icon aria-label="enabled on mobile devices">smartphone</mat-icon> -->
      <span class="pos-rel desc">Live On All Devices</span>
      <mat-button-toggle-group aria-label="desktop state" class="customized pos-rel" (change)="toggleEnabled($event, '')">
        <mat-button-toggle [value]="1">on</mat-button-toggle>
        <mat-button-toggle [value]="0">off</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <!-- <div fxFlex *ngIf="productService.currentPrimeStatusData.primeStatus == 2" class="pos-rel enable-toggle">
      <i aria-label="enabled on desktop devices" class="icon-screen icon-button-small"></i>
      <span class="pos-rel desc">Live On Desktop</span>
      <mat-button-toggle-group aria-label="desktop state" class="customized pos-rel" (change)="toggleEnabled($event, '')">
        <mat-button-toggle [value]="1">on</mat-button-toggle>
        <mat-button-toggle [value]="0">off</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div fxFlex *ngIf="productService.currentPrimeStatusData.primeStatus == 2" class="pos-rel enable-toggle">
      <i aria-label="enabled on mobile devices" class="icon-smartphone icon-button-small"></i>
      <span class="pos-rel desc">Live On Mobile</span>
      <mat-button-toggle-group aria-label="mobile state" class="customized pos-rel" (change)="toggleEnabled($event, 'mobile_')">
        <mat-button-toggle [value]="1">on</mat-button-toggle>
        <mat-button-toggle [value]="0">off</mat-button-toggle>
      </mat-button-toggle-group>
    </div> -->
    <div fxFlex *ngIf="productService.currentPrimeStatusData.primeStatus == 3" class="text-op-dir">
      <button type="button" mat-icon-button (click)="restore()">
        <mat-icon>restore</mat-icon>
      </button>
    </div>
    <div fxFlex *ngIf="productService.currentPrimeStatusData.primeStatus != 3" class="text-op-dir">
      <button type="button" mat-icon-button (click)="delete()">
        <i class="icon-button icon-delete"></i>
        <!-- <mat-icon>delete</mat-icon> -->
      </button>
    </div>
  </div>
</div>