import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConvertorComponent } from './convertor/convertor.component';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [ConvertorComponent],
  imports: [
    CommonModule, SharedModule
  ]
})
export class ConvertorModule { }