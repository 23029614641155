import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { JobType, RetailerFilterOptions, RetailersPolygonSpecifications } from '../retailer';
import { UtilsService } from 'src/app/shared/utils.service';
import { GraphqlService } from 'src/app/communication/graphql.service';
import { BroadcasterService } from 'ng-broadcaster';
import { RestService } from 'src/app/communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { EnumsService } from 'src/app/shared/enums.service';
import { KeyValuePair } from 'src/app/shared/enums';

@Component({
  selector: 'app-retailers-polygon-spec',
  templateUrl: './retailers-polygon-spec.component.html',
  styleUrls: ['./retailers-polygon-spec.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RetailersPolygonSpecComponent implements OnInit {
  @Input() retailerId: number;
  @Input() polySpecs: Array<RetailersPolygonSpecifications>;
  public polyToAdd: RetailersPolygonSpecifications;
  public polyTypes: Array<KeyValuePair>;
  public polyTypesToAdd: Array<KeyValuePair>;
  public polyShapeTypes: Array<KeyValuePair>;

  constructor(
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private gql: GraphqlService,
    private utils: UtilsService,
    private enums: EnumsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.polyTypes = this.enums.getPolyTypes();
    this.polyTypesToAdd = this.enums.getPolyTypes();
    this.polyShapeTypes = this.enums.getPolyShapeTypes();
  }

  ngOnInit() {
    this.polySpecs = this.utils.deepCopyByValue(this.polySpecs);
  }


  public save(p: RetailersPolygonSpecifications): void {
    const query = '/' + p.id + '?rid=' + this.retailerId;
    if(p.variation_name === '')
    p.variation_name = null;
    this.rest.retailerPolygonSpec('put', p, query).subscribe({
      next: () => {
        const data: BroadcasterNotification = {
          text: 'polygon specifications successfully saved',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', data);
        this.refresh();
      },
      error: err => {
        if (err && err.error && err.error.indexOf('duplicate key value violates unique constraint') > -1) {
          const n: BroadcasterNotification = {
            text: 'Variation name already exists. Please enter a unique name!',
            type: BroadcasterNotificationType.Error,
            action: 'OK'
          };
          this.broadcaster.broadcast('notifyUser', n);
        }
      }
    });
  }

  public deleteSpecs(p: RetailersPolygonSpecifications): void {
    if (!confirm('Are you sure you want to DELETE this polygon specifications forever?')) {
      return;
    }

    const query = '/' + p.id + '?rid=' + this.retailerId;
    this.rest.retailerPolygonSpec('delete', null, query).subscribe({
      next: () => {
        let data: BroadcasterNotification = {
          text: 'category successfully deleted',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', data);
        this.refresh();
      },
      error: err => this.utils.httpErrorResponseHandler(err, 'failure deleting category')
    });
  }

  public saveNewVariation(): void {
    let error: string;
    if (this.polyToAdd.min_poly_count > this.polyToAdd.max_poly_count) {
      error = 'maximum polygons count must be greater than minimum polygons count';
    }
    if (typeof this.polyToAdd.min_poly_count !== 'number') {
      error = 'first insert minimum polygons count';
    }
    if (typeof this.polyToAdd.max_poly_count !== 'number') {
      error = 'first insert maximum polygons count';
    }
    if (!this.polyToAdd.poly_type) {
      error = 'first insert polygons type';
    }
    if (!this.polyToAdd.poly_shape_type) {
      error = 'first insert polygons shape type';
    }
    if (error) {
      const data: BroadcasterNotification = {
        text: error,
        type: BroadcasterNotificationType.Error,
        action: 'OK'
      };
      this.broadcaster.broadcast('notifyUser', data);
      return;
    }

    const query = '?rid=' + this.retailerId;
    this.rest.retailerPolygonSpec('post', this.polyToAdd, query).subscribe({
      next: () => {
        const data: BroadcasterNotification = {
          text: 'polygon specifications successfully added',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', data);
        this.polyToAdd = undefined;
        this.refresh();
      },
      error: err => this.utils.httpErrorResponseHandler(err, 'failure adding polygon specifications')
    });
  }

  public addEmptyVariation(): void {
    this.polyToAdd = {} as RetailersPolygonSpecifications;
    this.polyToAdd.retailer_id = this.retailerId;
  }

  public deleteNewVariation(): void {
    this.polyToAdd = undefined;
  }

  private refresh(): void {
    const options: RetailerFilterOptions = {
      id: this.retailerId
    };
    this.gql.retailerForSettings(options).subscribe({
      next: res => this.polySpecs = this.utils.deepCopyByValue(res.data.retailers.retailers_polygon_specifications),
      error: err => this.utils.httpErrorResponseHandler(err, 'failure refreshing polygon specifications'),
      complete: () => this.changeDetectorRef.markForCheck()
    });
  }

  public get JobType(): typeof JobType {
    return JobType;
  }
}
