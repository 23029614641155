<form [formGroup]="userForm" novalidate>
  <div fxLayout="column" fxLayoutAlign="space-between center">
    <div fxFill>
      <div class="files-wrap file-image">
        <app-file-upload *ngIf="!personalLogo" class="accent" [round]="true" [text]="'PROFILE PIC'" [drop-mode]="true"
          [icon]="'cloud_upload_outline'" [miltiple]="false" (filesChange)="onPPFilesChange($event)"
          [accept]="'image/*'" [disabled]="disabled"></app-file-upload>
        <div *ngIf="personalLogo" class="logo-wrap">
          <!-- <img [src]="personalLogo" alt="profile image"> -->
          <span class="img" [style.backgroundImage]="'url(' + personalLogo + ')'"></span>
          <button class="delete-image" mat-button (click)="deleteImage()">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div fxFill>
      <mat-form-field fxFill>
        <input matInput type="text" placeholder="First Name" formControlName="firstname" required>
        <mat-hint *ngIf="userForm.hasError('required',['firstname']) && userForm.dirty" align="start">first name is
          required</mat-hint>
      </mat-form-field>
    </div>
    <div fxFill>
      <mat-form-field fxFill>
        <input matInput type="text" placeholder="Last Name" formControlName="lastname" required>
        <mat-hint *ngIf="userForm.hasError('required',['lastname']) && userForm.dirty" align="start">last name is
          required</mat-hint>
      </mat-form-field>
    </div>
    <div fxFill>
      <mat-form-field fxFill>
        <input matInput type="email" placeholder="Email" formControlName="email"
          pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" required>
        <mat-hint *ngIf="userForm.hasError('required',['email']) && userForm.dirty" align="start">e-mail is required
        </mat-hint>
        <mat-hint *ngIf="userForm.hasError('pattern',['email']) && userForm.dirty" align="start">e-mail not valid
        </mat-hint>
      </mat-form-field>
    </div>
    <!-- <div fxFill>
      <mat-form-field fxFlexFill>
        <input type="password" matInput placeholder="Password" formControlName="password" required pattern="^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$">
        <mat-hint *ngIf="userForm.hasError('required','password') && userForm.dirty" align="start">password is required</mat-hint>
        <mat-hint *ngIf="userForm.hasError('pattern','password') && userForm.dirty" align="start">must contain special characters, numbers, small letters, capital letters and at least 8 characters long</mat-hint>
      </mat-form-field>
    </div> -->
  </div>
</form>
<div fxLayout="column" fxLayoutAlign="space-between center" *ngIf="isSU">
  <div fxFill>
    <mat-form-field fxFill>
      <mat-select placeholder="User Roles" multiple [(ngModel)]="selectedRoles" name="roleId" required #name="ngModel"
        (selectionChange)="updateRoles()">
        <mat-option *ngFor="let role of uiRoles" [value]="role.id">
          {{ role.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <ng-container *ngIf="canAssociateRetailers">
    <div fxFill>
      <h4 class="title w-100 no-bb">
        retailers:
      </h4>
    </div>
    <div fxFill *ngFor="let ru of user.retailers_users;let i = index" class="retailer">
      <span class="r-name va-top">{{ru.retailers[0].name}}</span>
      <img [src]="ru.retailers[0].logo_url" alt="retailer logo">
      <button mat-icon-button class="float-op-dir invert add-remove" (click)="removeRetailer(i)" color="warn" *ngIf="user.retailers_users.length > 1">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <div fxFill>
      <app-retailers-autocomplete [placeholder]="'associate retailer to user'" class="disp-block"
        [clear-on-select]="true" (retailer-change)="onRetailerChange($event)"></app-retailers-autocomplete>
    </div>
  </ng-container>
</div>


   <div fxFill>
    <h4 class="title w-100 no-bb">
       Password
    </h4>
  </div> 
  <div fxFill>     
    <button mat-raised-button (click)="changePassword()"  class="prime_button min-button-width">
     update password
    </button>  
  </div>
