<div class="wrap" [ngClass]="{'drop': dropMode}">
  <button mat-icon-button [ngClass]="{'round': round}" type="button" color="accent" [disabled]="disabled" [ngStyle]="btnStyle"
  (click)="btnClick()">
    <!-- (click)="fileInput.click()" -->
    <!-- <mat-icon>attach_file</mat-icon> -->
    <i *ngIf="icon" class="material-icons">{{icon}}</i>
    <span class="text">{{text}}</span>
    <input type="file" [disabled]="disabled" [multiple]="miltiple" [accept]="accept" (change)="onFilesChange($event)" #fileInput>
  </button>
  <span *ngIf="msg" class="msg">{{msg}}</span>
</div>