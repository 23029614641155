import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class PaymentStatusResolve  {

  constructor() { }

  resolve(route: ActivatedRouteSnapshot) {
    return route.params['state'] == 'success';
  }

}
