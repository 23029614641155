import { Pipe, PipeTransform } from '@angular/core';
import { AssetAdjustments, AssetAdjustmentSector } from 'asset-adjustments';

@Pipe({
  name: 'assetAdjustmentsFilter'
})
export class AssetAdjustmentsFilterPipe implements PipeTransform {
  static erelevantUrlParam = { 'max-distance': true, 'min-distance': true };
  transform(value: Array<AssetAdjustments>, sector?: AssetAdjustmentSector): Array<AssetAdjustments> {
    if (value && typeof sector !== 'undefined') {
      // return value.filter(p => p.sector == sector);
      return value.filter(p => p.sector == sector && !AssetAdjustmentsFilterPipe.erelevantUrlParam[p.urlParam]);
    }
    return value;
  }

}
