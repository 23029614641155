import { Injectable } from '@angular/core';
import { GraphqlService } from '../communication/graphql.service';
import { BroadcasterService } from 'ng-broadcaster';
import { SourceFileType } from '../product/product';

@Injectable({
  providedIn: 'root'
})
export class SourceFileTypesService {
  private cachedTypes: Array<SourceFileType>;
  // private cachedDefaultTypes: Array<ProductResourceType>;
  constructor(
    private gql: GraphqlService,
    private broadcaster: BroadcasterService
  ) {
    this.gql.sourchFilesTypes().subscribe(
      t => {
        this.cachedTypes = t.data.source_files_types;
        this.broadcaster.broadcast('onSourceFilesTypes');
      }
    );
    // this.gql.defaultResourceTypes().subscribe(
    //   t => {
    //     this.cachedDefaultTypes = t.data.products_defaults_resources_types;
    //     this.broadcaster.broadcast('onDefaultResourceTypes');
    //   }
    // );
  }

  getCachedTypes(): Array<SourceFileType> {
    if (this.cachedTypes) return JSON.parse(JSON.stringify(this.cachedTypes));
    return this.cachedTypes;
  }

  // getCachedDefaultTypes(): Array<ProductResourceType> {
  //   if (this.cachedDefaultTypes) return JSON.parse(JSON.stringify(this.cachedDefaultTypes));
  //   return this.cachedDefaultTypes;
  // }
  getCachedDefaultTypes(): Array<SourceFileType> {
    return [{
      description: 'Any',
      id: 1
    }];
  }

  getTypes() {
    return this.gql.sourchFilesTypes();
  }

  // getDefaultsTypes() {
  //   return this.gql.defaultResourceTypes();
  // }

  // public mapUiToResourceType(value: Array<number>, allFormats: Array<ResourceType>, existing: Array<ProductResourceType>, productId: number): Array<ProductResourceType> {
  //   let res = [] as Array<ProductResourceType>;
  //   if (value && value.length) {
  //     if (!existing) existing = [];
  //     value.forEach(id => {
  //       const exist = existing.find(i => i.resources_types[0] && i.resources_types[0].id == id);
  //       if (exist)
  //         res.push(exist);
  //       else {
  //         let item = {
  //           resources_types: [allFormats.find(af => af.id == id)]
  //         } as ProductResourceType;
  //         item.resource_type_id = item.resources_types[0].id;
  //         item.product_id = productId;
  //         res.push(item);
  //       }
  //     });
  //   }
  //   return res;
  // }

  public mapResourceTypeToUi(value: Array<SourceFileType>): Array<number> {
    return value.map(t => t.id);
  }

  // public mapProductResourceTypeToUi(value: Array<ProductResourceType>): Array<number> {
  //   let res = [];
  //   value.forEach(prt => {
  //     if (prt.resources_types && prt.resources_types[0])
  //       res.push(prt.resources_types[0].id)
  //   });
  //   return res;
  // }
}
