import { Component, OnInit, Input } from '@angular/core';
import { RestService } from 'src/app/communication/rest.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-retailer-api-token',
  templateUrl: './retailer-api-token.component.html',
  styleUrls: ['./retailer-api-token.component.scss']
})
export class RetailerApiTokenComponent implements OnInit {
  @Input('retailer-id') retailerId: number;
  public token: string;
  constructor(
    private rest: RestService,
    public utils: UtilsService
  ) {
    
  }

  ngOnInit() {
  }

  getToken() {
    this.rest.retailerToken('post').subscribe(
      (res: any) => this.token = res.token
    )
  }
}
