<div>
  <ul class="normalize">
    <li *ngFor="let d of domains" class="float-dir">
      <mat-form-field fxFill>
        <input matInput type="text" placeholder="domain" [(ngModel)]="d.retailer_domain">
      </mat-form-field>
      <button mat-icon-button color="warn" (click)="removeDomain(d)">
        <mat-icon>delete</mat-icon>
      </button>
    </li>
    <li class="float-dir">
      <mat-form-field fxFill>
        <input matInput type="text" placeholder="new domain" [(ngModel)]="newDomain">
      </mat-form-field>
      <button mat-icon-button color="accent" (click)="addDomain()">
        <mat-icon>add</mat-icon>
      </button>
    </li>
  </ul>
  <mat-divider class="float-dir w-100"></mat-divider>
  <ul class="normalize">
    <li *ngFor="let p of paths" class="float-dir">
      <mat-form-field fxFill>
        <input matInput type="text" placeholder="path" [(ngModel)]="p.path">
      </mat-form-field>
      <button mat-icon-button color="warn" (click)="removePath(p)">
        <mat-icon>delete</mat-icon>
      </button>
    </li>
    <li class="float-dir">
      <mat-form-field fxFill>
        <input matInput type="text" placeholder="new path" [(ngModel)]="newPath">
      </mat-form-field>
      <button mat-icon-button color="accent" (click)="addPath()">
        <mat-icon>add</mat-icon>
      </button>
    </li>
  </ul>
  <div class="float-dir w-100">
    <button mat-raised-button mat-raised-button color="accent" (click)="save()" class="thinner marg-top">
      save domains & paths
    </button>
  </div>
</div>