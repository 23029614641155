import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RestService } from '../rest.service';
import { BroadcasterService } from 'ng-broadcaster';
import { UtilsService } from '../../shared/utils.service';
import { CMSNotificationsType } from '../notifications';
import { BroadcasterNotification, BroadcasterNotificationType } from '../broadcaster-notifications';
import { GlobalsService } from '../../shared/globals.service';

@Component({
  selector: 'app-broadcast-notifications',
  templateUrl: './broadcast-notifications.component.html',
  styleUrls: ['./broadcast-notifications.component.scss']
})
export class BroadcastNotificationsComponent implements OnInit {
  public broadcastForm: UntypedFormGroup;
  public updateForm: UntypedFormGroup;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService,
    private globals: GlobalsService
  ) {
    // this.broadcastForm = formBuilder.group({
    //   notes: [null, Validators.required],
    //   notification_type: CMSNotificationsType.CUSTOM_BROADCAST
    // });
    this.updateForm = this.formBuilder.group({
      notification_type: CMSNotificationsType.VERSION_UPDATE
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.globals.playgroundClass = null;
      this.globals.setHeaer('Broadcast Notifications', '');
      this.globals.setBreadcrumbs();
    }, 0);
  }

  onTextChange(textModels: string) {
    if (this.utils.getTextContent(textModels))
      this.broadcastForm.controls['notes'].setValue(textModels);
    else
      this.broadcastForm.controls['notes'].setValue(null);
  }

  // send() {
  //   if (!confirm('Are you sure you want to send this notification to all users?')) return;
  //   this.rest.broadcastNotification('post', this.broadcastForm.getRawValue()).subscribe(
  //     () => {
  //       let n: BroadcasterNotification = {
  //         text: 'notification successfully sent',
  //         type: BroadcasterNotificationType.Success,
  //         action: 'OK'
  //       }
  //       this.broadcaster.broadcast('notifyUser', n);
  //     }
  //   );
  // }

  updateVersion() {
    if (!confirm('Are you sure you want to send this notification to all users?')) return;
    this.rest.broadcastNotification('post', this.updateForm.getRawValue()).subscribe(
      () => {
        let n: BroadcasterNotification = {
          text: 'version update notification successfully sent',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', n);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure broadcasting notification')
    );
  }
}
