<div class="wrap">
  <div class="content scrollbar">
    <div fxLayout="row" fxLayoutAlign="space-around">
      <div fxFlex>
        <h2 class="title">
          {{isSelfProfile ? 'Your Profile' : user.firstname + "'s profile"}}
        </h2>
        <div class="details-wrap">
          <app-user-profile [disabled]="!isSelfProfile && !isSU" [user]="user" (on-change)="onProfileChange($event)"
            (on-valid)="onUserValid($event)"></app-user-profile>
        </div>
        <div *ngIf="isSelfProfile || isSU">
          <h2 class="title no-bb">
            Email Notifications Settings
          </h2>
          <div class="bottom-wrap">
            <app-email-notifications [user]="user" (on-change)="onEmailNotificationsChange($event)">
            </app-email-notifications>
          </div>
        </div>

      </div>
      <div fxFlex *ngIf="user.retailers_users[auth.retailerIndex]">
        <h2 class="title">
          Your Company Details
        </h2>
        <div class="details-wrap">
          <app-retailer-profile [retailer]="user.retailers_users[auth.retailerIndex].retailers[0]" (on-change)="onRetailerChange($event)"
            (on-valid)="onRetailerValid($event)" (on-ignore)="onIgnoreRetailerChanges($event)"></app-retailer-profile>
        </div>

        <div class="details-wrap">
          <div class="marg-top-bottom">
            <mat-divider></mat-divider>
          </div>
          <h2 class="title w-100 no-bb">
            Funds
          </h2>
          <div>
            {{user.retailers_users[auth.retailerIndex].retailers[0].name}}'s current budget is
            {{user.retailers_users[auth.retailerIndex].retailers[0].retailers_budget[0].amount}}$
          </div>
          <div>
            You can add more with PayPal here:
          </div>
          <app-add-funds [valid-state]="fundsValidState" [retailer]="user.retailers_users[auth.retailerIndex].retailers[0]">
          </app-add-funds>
        </div>
        <!-- <div class="details-wrap">
          <div class="marg-top-bottom">
            <mat-divider></mat-divider>
          </div>
        </div> -->
        <div class="api-token-wrap">
          <app-retailer-api-token [retailer-id]="user.retailers_users[auth.retailerIndex].retailers[0].id"></app-retailer-api-token>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="actions">
  <button mat-raised-button color="accent" class="prime_button submit min-button-width" (click)="save()">
    save
  </button>
</div>