<div class="flex-wrap-wrap">
    <div *ngIf="retailer" class="retailer-implementation">
      <mat-form-field>
        <mat-label>Implementation Type</mat-label>
        <mat-select [(ngModel)]="retailer.implementation_type" class="uppercase">
          <mat-option *ngFor="let t of implementationTypeOptions" [value]="t.key" class="uppercase">
            {{t.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Preload Model</mat-label>
        <mat-select [(ngModel)]="retailer.preload_model" class="uppercase">
          <mat-option *ngFor="let t of preloadModelOptions" [value]="t.key" class="uppercase">
            {{t.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <br>
      <button mat-raised-button mat-raised-button color="accent" (click)="save()" class="thinner marg-top">
        save
      </button>
      <br>
    </div>
  </div>