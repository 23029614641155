import { PolyType, PolyShapeType } from "../retailer/retailer";

export interface KeyValuePair {
    key: any;
    value: any;
}

export interface KeyValueAnyPair {
    key: any;
    value: any;
}

export interface SystemEnum {
    enum_key: string;
    enum_name: string;
    enum_value: string;
    id: number;
    created_at: Date;
    updated_at: Date;
}

export enum CanvasPaintState {
    NOOP = 0,
    DRAW = 1,
    LINE = 2,
    WRITE = 3,
    VERTICAL_LINE = 4,
    HORIZONTAL_LINE = 5,
}

export interface Coordinates3D {
    x: number;
    y: number;
    z: number;
}

export interface KeyValuePoly {
    key: PolyType;
    value: PolyDesc;
}

export interface PolyDesc {
    name: string;
    shapeType: PolyShapeType;
    shapeName: string;
    icon: string;
    min?: number;
    max?: number;
}

export enum FormatsType {
    VIEWER_JSON = 0,
    OBJ = 1,
    FBX = 2,
    DAE = 4,
    BLEND = 5,
    glTF = 6,
    GLB = 7,
    USDZ = 8,
    PNG = 9,
    JPG = 10,
    MP4 = 11,
    TIFF = 12,
}

export enum Priority {
    LOWEST = 1,
    LOW = 2,
    MEDIUM = 3,
    HIGH = 4,
    HIGHEST = 5,
}

export enum ImageFormatsType {
    PNG = 9,
    JPG = 10,
    TIFF = 12,
}

export enum RenderStatus {
    PENDING = 'waiting for 3D approval',
    IN_PROGRESS = 'in progress',
    FEEDBACK = 'client feedback',
    APPROVED_OFFLINE = 'approved offline',
    APPROVED_ONLINE = 'approved online',
    DELIVERED = 'delivered'
}

export const THREE_LATEST_VERSION = '147';
export const DEFAULT_PREVIEW_DIM = 500;
export const DEFAULT_GIF_DIM = 200;
export const DEFAULT_3D_ICON = '//cdn.hexa3d.io/hotlink-ok/icons/3d-box.svg';
export const DEFAULT_VIDEO_ICON = '//cdn.hexa3d.io/hotlink-ok/icons/video-cam.svg';
export const DEFAULT_IMAGE_ICON = '//cdn.hexa3d.io/gallery.svg'; // change to something better

export enum StudioType {
    None = -1,
    Hexa = 1,
    Syndication = 2,
}
export enum QaMode {
    BOTH = 1,
    FM_ONLY = 2,
    QS_ONLY = 3,
}


export enum FeedbackType {
    shape_doesnt_match = 1,
    colors_materials = 2,
    lighting_shadow = 4,
    camera_positioning = 5,
    others = 3,
}
