<div [ngClass]="{'manipulating': manipulating}" class="wrap" *ngIf="isOpen">
  <div
    *ngIf="!assetAdjustmentsService.animatedState[assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].name][type]">
    <mat-form-field class="lowercase">
      <mat-select placeholder="texture" [(ngModel)]="currentTextureName" (selectionChange)="textureChange()">
        <mat-option *ngFor="let t of assetAdjustmentsService.texturesWithImages | keyValuePure : counter"
          [value]="t.value.name">
          <!-- <img
            [src]="t.value.video ? '//cdn.creators3d.com/hotlink-ok/icons/video_label-24px.svg' : t.value.src"
            alt="texture"> -->
          <img *ngIf="t.value.preview" [src]="t.value.preview" alt="texture" loading="lazy">
          <span class="lowercase"> {{ t.value.type | spaceUpper }} ({{t.value.material?.name}})
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-icon-button color="warn" (click)="clearTexture()">
      <mat-icon>delete</mat-icon>
    </button>
    <br>
    <br>
  </div>
  <ng-container
    *ngIf="intensity[type] && intensity[type].videoEnabled && assetAdjustmentsService.videoTexturesSrcs.length">
    <mat-form-field class="lowercase">
      <mat-select placeholder="video texture" [(ngModel)]="currentVideoTextureSrc"
        (selectionChange)="textureChange(true)">
        <mat-option *ngFor="let s of assetAdjustmentsService.videoTexturesSrcs; let i = index" [value]="s">
          <!-- <video [src]="s" height="40" autoplay muted loop></video> -->
          video {{i + 1}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
  <button mat-button (click)="toggleFlipY()">
    <mat-icon>flip_camera_android</mat-icon>
    Flip Y Axis
  </button>
  <div *ngIf="intensity[type] && intensity[type].upscaleTexture">
    <button mat-button (click)="upscaleTexture()" matTooltip="Use AI Super Resolution in order to increase the texture resolution (require a capable GPU)"
        matTooltipPosition="left">
      <mat-icon>add_photo_alternate</mat-icon>
      Upscale Texture Resolution
    </button>
  </div>
  <div
    *ngIf="assetAdjustmentsService.texturedMaterialsMap[type] && intensity[assetAdjustmentsService.texturedMaterialsMap[type].type]">
    <div *ngIf="intensity[assetAdjustmentsService.texturedMaterialsMap[type].type].maxIntensity">
      <br>
      intensity:
      <mat-slider min="0" [max]="intensity[assetAdjustmentsService.texturedMaterialsMap[type].type].maxIntensity"
        [step]="0.01" [(ngModel)]="assetAdjustmentsService.texturedMaterialsMap[type].intensity" thumbLabel="true"
        (change)="intensityChange(false)"><input matSliderThumb></mat-slider>
    </div>
    <div *ngIf="intensity[assetAdjustmentsService.texturedMaterialsMap[type].type].hasColor">
      color:
      <input type="color" [(ngModel)]="assetAdjustmentsService.texturedMaterialsMap[type].color"
        (change)="intensityChange(false)">
    </div>
    <div
      *ngIf="imageManipulation && intensity[assetAdjustmentsService.texturedMaterialsMap[type].type].imageManipulation">
      <mat-slider min="0" [max]="intensity[type].maxManipulationValue" [step]="0.05" [(ngModel)]="manipulation"
        thumbLabel="true" (change)="intensityChange(true)" class="manipulation"><input matSliderThumb></mat-slider>
      <img class="current-texture" [src]="currentImage" *ngIf="currentImage" alt="texture">
    </div>
    <div *ngIf="intensity[assetAdjustmentsService.texturedMaterialsMap[type].type].sprite">
      <mat-slide-toggle
        [(ngModel)]="assetAdjustmentsService.animatedState[assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].name][type]">
        animated texture</mat-slide-toggle>
    </div>
  </div>
  <div
    *ngIf="intensity[type] && intensity[type].minFilter && assetAdjustmentsService.texturedMaterialsMap[type]?.material && assetAdjustmentsService.texturedMaterialsMap[type]?.material[assetAdjustmentsService.texturedMaterialsMap[type].type]">
    <br>
    <mat-select placeholder="minification filter"
      [(ngModel)]="assetAdjustmentsService.texturedMaterialsMap[type].material[assetAdjustmentsService.texturedMaterialsMap[type].type].minFilter"
      (selectionChange)="minFilterChange()">
      <mat-option *ngFor="let t of minificationFilters" [value]="t.key">
        <span class="lowercase">{{ t.value }}</span>
      </mat-option>
    </mat-select>
  </div>
  <div
    *ngIf="assetAdjustmentsService.animatedState[assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].name][type]">
    <br>
    <mat-select placeholder="animated texture" [(ngModel)]="currentAnimatedTextureName"
      (selectionChange)="animatedTextureChange($event)">
      <mat-option *ngFor="let t of assetAdjustmentsService.texturesWithImages | keyValuePure : counter"
        [value]="t.value.name">
        <img [src]="t.value.src"
          *ngIf="t.value.video ? '//cdn.creators3d.com/hotlink-ok/icons/video_label-24px.svg' : t.value.src"
          alt="texture">
        <span class="lowercase"> {{ t.value.type | spaceUpper }} {{t.value.name}}
          <span *ngIf="t.value.material && t.value.material?.name"> ({{t.value.material?.name}})</span>
        </span>
      </mat-option>
    </mat-select>
  </div>
  <!-- <div
  *ngIf="imageManipulation && assetAdjustmentsService.texturedMaterialsMap[type] && intensity[assetAdjustmentsService.texturedMaterialsMap[type].type] && intensity[assetAdjustmentsService.texturedMaterialsMap[type].type].maxIntensity && intensity[assetAdjustmentsService.texturedMaterialsMap[type].type].rgb">
  <div fxLayout="row" fxLayoutAlign="start stretch" class="text-center">
    <div fxFlex>
      red
      <div>
        <button mat-icon-button (click)="addToNormal(10, 0)">
          <mat-icon>add_circle</mat-icon>
        </button>
        <button mat-icon-button (click)="addToNormal(-10, 0)">
          <mat-icon>remove_circle</mat-icon>
        </button>
      </div>
    </div>
    <div fxFlex>
      green
      <div>
        <button mat-icon-button (click)="addToNormal(10, 1)">
          <mat-icon>add_circle</mat-icon>
        </button>
        <button mat-icon-button (click)="addToNormal(-10, 1)">
          <mat-icon>remove_circle</mat-icon>
        </button>
      </div>
    </div>
    <div fxFlex>
      blue
      <div>
        <button mat-icon-button (click)="addToNormal(10, 2)">
          <mat-icon>add_circle</mat-icon>
        </button>
        <button mat-icon-button (click)="addToNormal(-10, 2)">
          <mat-icon>remove_circle</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <img class="current-texture" [src]="currentImage" *ngIf="currentImage" alt="texture">
</div> -->
</div>