<div class="wrap" #wrap [ngClass]="{'image-to-small': imageToSmall}">
  <div class="inner" #inner>
    <div class="pos-abs spinner-wrap" [hidden]="!createingFix">
      <mat-spinner mode="indeterminate"></mat-spinner>
    </div>
    <img [src]="tsPreview" class="for-to-small" alt="to small" crossorigin="anonymous" [ngStyle]="{'max-height': height}">
    <div class="org" #org [style.background-image]="preview">
      <img crossorigin="anonymous" [src]="imageSet.big" alt="image" class="center-me" [ngStyle]="{'max-height': height}" (load)="imageHasLoaded = true" [style.opacity]="imageHasLoaded ? '1' : '0'">
    </div>
    <div class="flyout" [ngStyle]="{'height': height}" (click)="toggleFix($event)" [style.zIndex]="imageHasLoaded ? '5' : '-5'">
      <img crossorigin="anonymous" [src]="imageSet.big" alt="zoom" #img>
    </div>
  </div>
</div>