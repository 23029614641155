import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { ExternalResourceDetails } from '../product';
import { DEFAULT_3D_ICON, DEFAULT_VIDEO_ICON } from 'src/app/shared/enums';
import { RolesHelperService } from 'src/app/auth/roles-helper.service';

@Component({
  selector: 'app-external-resource-upload-item',
  templateUrl: './external-resource-upload-item.component.html',
  styleUrls: ['./external-resource-upload-item.component.scss']
})
export class ExternalResourceUploadItemComponent implements OnInit, OnChanges {
  @Input() resource: ExternalResourceDetails;
  @Input() counter: number; // just for ngOnChanges to fire whenever we want to refresh something
  @Output() delete = new EventEmitter<void>();
  @Output() preview = new EventEmitter<void>();
  public imgSrc: string;

  constructor(
    public roles: RolesHelperService
  ) { }

  ngOnInit(): void {
    this.setImage();
  }

  ngOnChanges() {
    this.setImage();
  }

  public onDelete(): void {
    this.delete.emit();
  }

  public onPreview(): void {
    this.preview.emit();
  }

  private setImage(): void {
    switch (this.resource.extension.replace('.', '').toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'png': {
        this.imgSrc = this.resource.base64;
        break;
      }
      case 'mp4': {
        this.imgSrc = DEFAULT_VIDEO_ICON;
        break;
      }
      default: {
        this.imgSrc = this.resource.base64 || DEFAULT_3D_ICON;
      }
    }
  }
}
