<div class="wrap">
  <h1>dashboard</h1>
  <div fxLayout="column" fxLayoutAlign="space-between center">
    <div fxFlex>
      <app-retailers-autocomplete [placeholder]="'manage retailer budget'" [clear-on-select]="false" (retailer-change)="onRetailerChange($event)"></app-retailers-autocomplete>
    </div>
    <div fxFlex>
      <!-- <app-pay [amount]="6"></app-pay> -->
    </div>
    <div fxFlex>
      <app-retailer-budget *ngIf="retailer" [retailer]="retailer"></app-retailer-budget>
    </div>



  </div>
</div>