import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BroadcasterService } from 'ng-broadcaster';
import { RestService } from '../communication/rest.service';
import { StorageService } from 'ng-storage-service';
import { User, Credentials, UserRole, ISsoState, UserQueryData } from './user';
import { BroadcasterNotification, BroadcasterNotificationType } from '../communication/broadcaster-notifications';
import { Observable } from 'rxjs'; 
import { EnumsService } from 'src/app/shared/enums.service';  
import { map } from 'rxjs/operators';
import { GraphqlService } from '../communication/graphql.service';
import { UtilsService } from '../shared/utils.service';
import { ApolloQueryResult } from '@apollo/client/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: User;
  public websocketEndpoint: string;
  public notificationsWebsocketEndpoint: string;
  private isFetching: boolean;
  private fetch: Observable<any>;
  private _retailerIndex: number;
  private _ssoState: ISsoState;
  constructor(
    private router: Router,
    private broadcaster: BroadcasterService,
    private rest: RestService,
    private storage: StorageService,
    private enums: EnumsService,
    private gql: GraphqlService,
    private utils: UtilsService
  ) {
    this.isFetching = false;
    this.websocketEndpoint = this.rest.websocketEndpoint;
    this.notificationsWebsocketEndpoint = this.rest.notificationsWebsocketEndpoint;
    this.user = this.storage.get('user') as User;
    // if (this.user)
    //   this.broadcaster.broadcast('onLogin', this.user);
    const retailerIndex = parseInt(this.storage.get('retailerIndex'));
    this._retailerIndex = isNaN(retailerIndex) ? 0 : retailerIndex;
    if (this.user) {
      if (!this.user.retailers_users || !this.user.retailers_users[this._retailerIndex])
        this.setRetailerIndex(0);
      this.refreshUserDate();
    }
  }

  public get retailerIndex(): number {
    return this._retailerIndex;
  };

  get ssoState() {
    return this._ssoState;
  }

  set ssoState(value: ISsoState) {
    this._ssoState = value;
  }

  setRetailerIndex(index: number) {
    this._retailerIndex = index;
    this.storage.set('retailerIndex', this._retailerIndex);
    this.broadcaster.broadcast('onRetailerIndex', this._retailerIndex);
  }

  getRetailerIndexById(retailerId: number): number {
    for (let i = 0; i < this.user.retailers_users.length; i++) {
      if (this.user.retailers_users[i].retailer_id == retailerId) {
        return i;
      }
    }
    return null;
  }

  getRetailerIdByIndex(index: number): number {
    if (this.user.retailers_users[index])
      return this.user.retailers_users[index].retailer_id;
    return null;
  }

  setRetailerIndexById(retailerId: number): boolean {
    if (!this.user)
      return false;
    for (let i = 0; i < this.user.retailers_users.length; i++) {
      if (this.user.retailers_users[i].retailer_id == retailerId) {
        if (this._retailerIndex != i)
          this.setRetailerIndex(i);
        return true;
      }
    }
    return false;
  }

  atachRolesToUser(user: User) {
    // if (!user.roles && user.children && user.children.artistsuserroles) {
    //   user.roles = [];
    //   for (let i = 0; i < user.children.artistsuserroles.length; i++) {
    //     if (user.children.artistsuserroles[i].children && user.children.artistsuserroles[i].children.artistsroles) {
    //       for (let j = 0; j < user.children.artistsuserroles[i].children.artistsroles.length; j++) {
    //         user.roles.push(user.children.artistsuserroles[i].children.artistsroles[j]);
    //       }
    //     }
    //   }
    // }
    if (!user.roles && user.users_roles) {
      user.roles = [];
      for (let i = 0; i < user.users_roles.length; i++) {
        if (user.users_roles[i].roles) {
          for (let j = 0; j < user.users_roles[i].roles.length; j++) {
            user.roles.push(user.users_roles[i].roles[j]);
          }
        }
      }
    }
  }

  login(method:string, credentials: Credentials, query?: string): Promise<User> {
    return new Promise((resolve, reject) => {
    this.rest.auth(method, credentials, query).subscribe(
      (result: User) => {
        resolve(result);
      },
      (err: any) => {
        if (err && (err._body || err.error)) {
          let data: BroadcasterNotification = {
            text: err.error === 'Illegal user/password.' ?'Incorrect email address or password.' : (err._body || err.error),
            type: BroadcasterNotificationType.Error,
            action: 'OK'
          }
          this.broadcaster.broadcast('notifyUser', data);
          reject(err._body || err.error);
        }
        else {
          let data: BroadcasterNotification = {
            text: 'login attempt failure',
            type: BroadcasterNotificationType.Error,
            action: 'OK'
          }
          this.broadcaster.broadcast('notifyUser', data);
        }
        reject();
      }
    );
  });
  }

  onLoginSuccess(user: User, navToDefault = true, broadcastOnLogin = true, remember = true) {
    this.user = user;
    if (remember) {
      this.storage.set('user', this.user);
      this.storage.set('token', this.user.token);
    }
    if (broadcastOnLogin)
      this.broadcaster.broadcast('onLogin', this.user);
    if (navToDefault)
      this.router.navigate(['/products']);
  }

  onLogout() {
    this.storage.remove('user');
    this.storage.remove('token');
    this._retailerIndex = 0;
    this.storage.remove('retailerIndex');
    delete this.user;
    this.broadcaster.broadcast('onLogout', this.user);
  }

  logout() {
    this.rest.auth('delete').subscribe(
      result => this.onLogout(),
      err => this.onLogout()
    );
  }

  isloggedIn() {
    return !!this.user;// this.storage.has('token') && this.storage.has('user');
  }

  userProfile(uid: number): Observable<User> {
    if (this.isFetching) return this.fetch;
    this.isFetching = true;
    this.fetch = this.gql.user(uid).pipe(map(res => {
      if (res.data) {
        let user = this.utils.deepCopyByValue(res.data.users);
        if (!user.users_roles)
          user.users_roles = [{}] as Array<UserRole>;
        this.isFetching = false;
        return user;
      }
      return null;
    }));
    return this.fetch;
  }

  refreshUserDate() {
    if (!this.user) return;
    this.userProfile(this.user.id).subscribe(user => {
      this.atachRolesToUser(user)
      user.token = this.user.token;
      this.user = user;
      this.storage.set('user', this.user);
      if (!this.user.retailers_users[this._retailerIndex])
        this.setRetailerIndex(0);
      this.broadcaster.broadcast('userRefreshed', this.user);
    });
  }

  fetchNotificationTypes() {
    this.rest.notificationTypes().subscribe(
      data => {
        this.enums.setNotificationTypes(data);
        this.broadcaster.broadcast('notificationRefreshed', data);
      }
    )
  }

  public storeToken(token: string): void {
    this.storage.set('token', token);
  }

  public storeUser(user: User): void {
    this.user = user;
    this.storage.set('user', user);
  }

  public fetchUser(userId: number): Observable<ApolloQueryResult<UserQueryData>> {
    return this.gql.user(userId);
  }
}
