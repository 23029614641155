import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Product, MediaTag } from '../product';
import { ProductService } from '../product.service';
import { BodyService } from '../../shared/body.service';
import { Router } from '@angular/router';
import { DEFAULT_IMAGE_ICON } from 'src/app/shared/enums';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCardComponent implements OnInit {
  @Input() item: Product;
  @Input() counter: number;
  @Output('on-change') onChange: EventEmitter<Product>;
  public showMore: boolean;
  public mainImage: string;
  public hasShowcase: boolean;
  public hasModel: boolean;
  public productURL: string;
  constructor(
    public productService: ProductService,
    public body: BodyService,
    private router: Router,
    private auth: AuthService
  ) {
    this.onChange = new EventEmitter<Product>();
  }

  ngOnInit() {
    this.hasModel = this.productService.hasModel(this.item);
    // this.isOrders = this.productService.isOrders(this.item);
    this.hasShowcase = !this.productService.isOrders(this.item) && this.item.products_resources[this.productService.getDefaultResourceIndex(this.item)] && this.item.products_resources[this.productService.getDefaultResourceIndex(this.item)].resource_type == MediaTag.MODEL;
    // if (this.isOrders) {
    //   if (this.item.products_resources && this.item.products_resources[0])
    //     this.mainImage = this.item.products_resources[0].resource_small || this.item.products_resources[0].resource_big;
    // }
    // else {
    //   this.mainImage = this.item.UI.smallImages[0];
    // }
    // if (!this.mainImage) {
    //   this.mainImage = this.item.UI.smallImages[0];
    // }
    this.mainImage = this.item.UI.smallImages[0];
    // Crate would like to see the preview images instead of the reference images
    if (!!this.auth.user.retailers_users.find(r => r.retailer_id === environment.crateRID) || this.item.retailer_id === environment.crateRID || !this.mainImage) {
      let resources = this.item.products_resources.filter(r => r.resource_type === MediaTag.MODEL);
      if (resources.length)
        this.mainImage = resources[0].resource_big || this.mainImage;
      else {
        resources = this.item.products_resources.filter(r => r.resource_type === MediaTag.IMAGE);
        if (resources.length)
          this.mainImage = resources[0].resource_default || this.mainImage;
      }
    }
    this.mainImage = this.mainImage || DEFAULT_IMAGE_ICON;
    if (this.item.products_urls.length)
      this.productURL = this.item.products_urls[0].url;
  }

  toggleEnabled(prefix: string) {
    this.productService.toggleEnabled(this.item, prefix);
    // this.item[prefix + 'enabled'] = !this.item[prefix + 'enabled'];
    this.update();
  }

  toggleQuickView(e) {
    this.productService.toggleQuickView(this.item, e);
  }

  update() {
    this.onChange.next(this.item);
  }

  showRequest(item: Product) {
    this.productService.editItem = item;
  }

  feedback() {
    this.router.navigate(['/product/' + this.item.id, { state: 'feedback' }]);
  }

  // getRetailerSettings(rid: number): RetailersSettings {
  //   // if (this.productService.retailer)
  //     // return this.productService.retailer.retailers_settings;
  //   if (this.productService.cachedRetailers[rid])
  //     return this.productService.cachedRetailers[rid].retailers_settings[0];
  // }
}