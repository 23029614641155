<div fxLayout="column" fxLayoutAlign="space-around" *ngFor="let c of config; let i = index">
  <mat-form-field fxFlex>
    <input matInput placeholder="title" [(ngModel)]="c.title">
  </mat-form-field>
  <app-text-editor [click-img]="true" [show-html]="true" [show-asset]="true" (textChange)="onTextChange($event, c)" placeholder="specifications" [init-value]="c.text"></app-text-editor>
  <div fxFill *ngIf="c.attachment">
      <i class="material-icons">{{utils.getIconByUrl(c.attachment)}}</i>
      <a mat-button [href]="c.attachment" class="ellipsis" target="_blank" download="">{{c.attachment.replace('https://cdn.hexa3d.com/hotlink-ok/',
        '')}}</a>
  </div>
  <div fxFill>
    <app-file-upload class="accent" [round]="true" [text]="'attachment'" [drop-mode]="true"
      [icon]="'cloud_upload_outline'" [miltiple]="false" (filesChange)="onFilesChange($event, c)"></app-file-upload>
  </div>
  <div fxFill>
    <mat-form-field *ngIf="jobTypes">
      <mat-select placeholder="job type" [(ngModel)]="c.job_type">
        <mat-option *ngFor="let t of jobTypes" [value]="t.id">
          {{ t.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxFill>
    <button mat-fab aria-label="delete specification" (click)="remove(i)" matTooltip="remove specification">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
</div>
<div>
  <button mat-fab aria-label="add specifications" (click)="add()" matTooltip="add retailer specification">
    <mat-icon>add</mat-icon>
  </button>
  <button mat-raised-button mat-raised-button color="accent" (click)="save()" class="thinner marg-top" [disabled]="uploading">
    save specifications
  </button>
</div>