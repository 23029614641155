<div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex>
        <mat-form-field class="mat-mdc-form-field-short">
            <input type="number" matInput [placeholder]="light.name" [(ngModel)]="light.value"
                (change)="assetAdjustmentsService.changeValue(light)">
        </mat-form-field>
    </div>
    <mat-slider fxFlex="70" [min]="light.min" [max]="light.max" [step]="light.step" discrete>
        <input [(ngModel)]="light.value"
            (valueChange)="assetAdjustmentsService.changeLightIntensity(light)"
            (input)="assetAdjustmentsService.changeValue(light, $event)" matSliderThumb>
    </mat-slider>
    <div fxFlex *ngIf="light.hasColor" class="gray default">
        <input type="color" [(ngModel)]="light.color" (change)="assetAdjustmentsService.changeColor(light, $event)">
    </div>
    <div fxFlex *ngIf="light.default" class="gray default">
        ({{light.default | number : '1.0-2'}})
    </div>
</div>
<div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="light.hasHSL">
    <mat-accordion class="customized mt-20 max-w-100 low-pad">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Advanced
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex="33">
                    <h3 class="gray sub-title text-center">
                        hue
                    </h3>
                    <mat-slider class="short" [min]="0" [max]="1" [step]="0.01" [(ngModel)]="light.hsl.x"
                        (change)="assetAdjustmentsService.changeHSL(light, null, 'x')"
                        (input)="assetAdjustmentsService.changeHSL(light, $event, 'x')" discrete><input matSliderThumb></mat-slider>
                </div>
                <div fxFlex="33">
                    <h3 class="gray sub-title text-center">
                        saturation
                    </h3>
                    <mat-slider class="short" [min]="0" [max]="1" [step]="0.01" [(ngModel)]="light.hsl.y"
                        (change)="assetAdjustmentsService.changeHSL(light, null, 'y')"
                        (input)="assetAdjustmentsService.changeHSL(light, $event, 'y')" discrete><input matSliderThumb></mat-slider>
                </div>
                <div fxFlex="33">
                    <h3 class="gray sub-title text-center">
                        lightness
                    </h3>
                    <mat-slider class="short" [min]="0" [max]="1" [step]="0.01" [(ngModel)]="light.hsl.z"
                        (change)="assetAdjustmentsService.changeHSL(light, null, 'z')"
                        (input)="assetAdjustmentsService.changeHSL(light, $event, 'z')" discrete><input matSliderThumb></mat-slider>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<div *ngFor="let param of light.children">
    <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="param.type == SLIDER">
        <div fxFlex>
            <mat-form-field class="mat-mdc-form-field-short">
                <input type="number" matInput [placeholder]="param.name" [(ngModel)]="param.value"
                    (change)="assetAdjustmentsService.changeValue(param)">
            </mat-form-field>
        </div>
        <mat-slider fxFlex="70" [min]="param.min" [max]="param.max" [step]="param.step" [(ngModel)]="param.value"
            (change)="assetAdjustmentsService.changeValue(param)"
            (input)="assetAdjustmentsService.changeValue(param, $event)" discrete><input matSliderThumb></mat-slider>
        <div fxFlex *ngIf="param.hasColor" class="gray default">
            <input type="color" [(ngModel)]="param.color" (change)="assetAdjustmentsService.changeColor(param, $event)">
        </div>
        <div fxFlex *ngIf="param.default" class="gray default">
            ({{param.default | number : '1.0-2'}})
        </div>
    </div>
    <div class="select-param" *ngIf="param.type == SELECT">
        <mat-form-field>
            <mat-select [placeholder]="param.name" [multiple]="param.multiple" [(ngModel)]="param.value"
                (selectionChange)="assetAdjustmentsService.changeValue(param)">
                <mat-option *ngFor="let o of param.selectOptions" [value]="o.key">
                    {{ o.value }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- <mat-checkbox *ngFor="let o of param.selectOptions; let i = index" [value]="o.key == param.value[i]" (change)="changeShadowType(param, i, o.key)">
          {{ o.value }} ({{o.key}}) ({{param.value[i]}}) ({{o.key == param.value[i]}})
        </mat-checkbox> -->
    </div>
    <div class="center-me marg-bottom" *ngIf="param.type == TOGGLE">
        <mat-slide-toggle [(ngModel)]="param.value" (change)="assetAdjustmentsService.changeValue(param)">
            {{param.name}}</mat-slide-toggle>
    </div>
    <div class="text-center center-me marg-bottom" *ngIf="param.type == JOYSTICK">
        <div fxLayout="column" fxLayoutAlign="space-around center">
            <div fxFlex>
                <h3 class="gray sub-title">
                    {{param.name}}
                </h3>
            </div>
            <div fxFlex>
                <app-coordinates [min]="param.min" [max]="param.max" [(ngModel)]="param.value"
                    (on-change)="assetAdjustmentsService.changeValue(param)"></app-coordinates>
            </div>
        </div>
    </div>
</div>
<div *ngIf="assetAdjustmentsService.soloLights[light.options.type]">
    <mat-slide-toggle [(ngModel)]="assetAdjustmentsService.soloLights[light.options.type][light.index]" (change)="soloChanged()">
        solo mode</mat-slide-toggle>
</div>