import { Component, OnInit, ViewEncapsulation, Output, Input, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { FileObject } from 'src/app/shared/file';

declare var Tiff: any;

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileUploadComponent implements OnInit {
  static TIFF_CONVERTER = 'https://cdn.hexa3d.io/hotlink-ok/scripts/tiff.min.js';
  @Output() filesChange = new EventEmitter<Array<FileObject>>();
  @Input() miltiple: boolean;
  @Input() accept: string;
  @Input('drop-mode') dropMode: boolean;
  @Input() text: string;
  @Input() round: boolean;
  @Input('additional-data') additionalData: any;
  @Input() disabled: boolean;
  @Input() icon: string;
  @Input() width: number;
  @Input() height: number;
  @Input('ignore-base64') ignoreBase64: boolean;
  @ViewChild('fileInput') fileInput: ElementRef;
  private selectedFiles: any;
  private filesCount: number;
  private files: Array<FileObject>;
  public msg: string;
  public btnStyle: { [key: string]: string; };
  constructor() {
    this.filesChange = new EventEmitter<Array<FileObject>>();
    this.filesCount = 0;
    this.btnStyle = {};
  }

  ngOnInit() {
    if (typeof this.width === 'number')
      this.btnStyle['width'] = this.width + 'px';
    if (typeof this.height === 'number')
      this.btnStyle['height'] = this.height + 'px';
  }

  private async loadScript(url: string) {
    return new Promise((resolve: any, reject: any) => {
      let node = document.createElement('script');
      node.src = url;
      node.type = 'text/javascript';
      node.async = true;
      node.charset = 'utf-8';
      node.onload = () => {
        resolve()
      };
      document.getElementsByTagName('head')[0].appendChild(node);
    });
  }

  private async onFilerender(file, reader?: any, second = false) {
    let suffix = file.name.substring(file.name.lastIndexOf('.') + 1);
    if (this.accept == '.zip' && suffix.toLowerCase() != 'zip') {
      this.msg = 'file extension should be *.ZIP only!';
      return;
    }
    if (!second && this.accept == 'image/*' && (suffix.toLowerCase() == 'tif' || suffix.toLowerCase() == 'tiff')) {
      let selfAny = self as any;
      if (!selfAny.Tiff)
        await this.loadScript(FileUploadComponent.TIFF_CONVERTER);
      let xhr = new XMLHttpRequest();
      xhr.open('GET', reader.result);
      xhr.responseType = 'arraybuffer';
      xhr.onload = async (e) => {
        let buffer = xhr.response;
        let tiff = new Tiff({ buffer: buffer });
        let canvas = tiff.toCanvas();
        canvas.width = tiff.width();
        canvas.height = tiff.height();
        if (canvas) {
          let dataURI = tiff.toDataURL();
          var byteString = atob(dataURI.split(',')[1]);
          var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          var blob = new Blob([ab], { type: mimeString });
          let blobAny = blob as any;
          blobAny.lastModifiedDate = new Date();
          blobAny.name = file.name.replace(`.${suffix}`, '.png');
          let reader2 = new FileReader();
          reader2.addEventListener("load", arg => {
            this.onFilerender(blob, reader2, true);
          }, false);
          reader2.readAsDataURL(blob);
        }
        else
          this.onFilerender(file, reader, true);
      };
      xhr.send();
      return;
    }
    let img = new Image();
    let onFinish = () => {
      this.files.push({
        base64: reader ? reader.result : null,
        name: file.name.substring(0, file.name.lastIndexOf('.')),
        suffix: suffix,
        file: file,
        naturalWidth: img.naturalWidth,
        naturalHeight: img.naturalHeight,
        additionalData: this.additionalData
      });
      // let size = file.size / 1024 / 1024;
      // if (size > 30) {
      //   alert('max file size is 30MB, ' + file.name + ' size is ' + size + 'MB');
      //   this.msg = '';
      //   return;
      // }
      if (this.files.length == this.filesCount) {
        this.msg = '';
        this.filesChange.emit(this.files);
        this.fileInput.nativeElement.value = '';
      }
    };
    if (this.ignoreBase64) {
      onFinish();
    }
    else {
      img.onload = onFinish;
      img.onerror = onFinish;
      img.src = reader.result;
    }
  }

  private renderFile(file) {
    let reader = new FileReader();
    reader.addEventListener("load", arg => {
      this.onFilerender(file, reader);
    }, false);

    reader.readAsDataURL(file);
  }

  onFilesChange(event) {
    this.msg = '';//'processing . . .'
    this.filesCount = event.srcElement.files.length;
    this.files = [];

    for (let file of event.srcElement.files) {
      if (!this.ignoreBase64)
        this.renderFile(file);
      else
        this.onFilerender(file);
    }
  }

  btnClick() {
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      this.fileInput.nativeElement.click();
    }
  }
}
