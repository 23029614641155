<mat-card class="mat-elevation-z"
  [ngClass]="{'quick-view': productService.quickViewItem && productService.quickViewItem.id == item.id}"
  fxLayout="column">
  <mat-card-subtitle fxFlex="36px">
    <app-round-checkbox [isSquare]="true"
      *ngIf="!productService.isAllSelected && productService.currentPrimeStatusData && productService.currentPrimeStatusData.primeStatus != 0"
      ngDefaultControl [(ngModel)]="item.UI.checked" (click)="productService.refreshSelectedCount()"
      [disabled]="productService.isAllSelected" [disabled-mode]="true"></app-round-checkbox>
      <app-round-checkbox [isSquare]="true"
      *ngIf="productService.isAllSelected && productService.currentPrimeStatusData && productService.currentPrimeStatusData.primeStatus != 0"
      ngDefaultControl [ngModel]="true" (click)="productService.refreshSelectedCount()"
      [disabled]="productService.isAllSelected" [disabled-mode]="true"></app-round-checkbox>
    <span *ngIf="item.status_id === 8" matTooltip="rejected: {{item?.products_reject_reasons[0]?.reason}}"
      class="float-dir rejected-icon"><span class="material-icons">highlight_off</span>
    </span>
    <span class="float-op-dir more-options-wrap">
      <span class="more-options">
        <a mat-icon-button class="v-sub" [href]="productURL" target="_blank" *ngIf="productURL"
          matTooltip="External Link to Product Page">
          <i class="icon-link_blank icon-button-small"></i>
        </a>
        <a mat-icon-button class="v-sub" [routerLink]="['/product', item.id]" matTooltip="Manage Product">
          <i class="icon-wheel_setings icon-button-small"></i>
          <!-- <mat-icon aria-label="preview">
            settings
          </mat-icon> -->
        </a>
        <a mat-icon-button class="v-sub" [routerLink]="['./preview', item.id]" (click)="showRequest(item)"
          *ngIf="hasModel" matTooltip="Asset Quick-View">
          <!-- <mat-icon aria-label="preview">
            remove_red_eye_outline
          </mat-icon> -->
          <i class="icon-eye icon-button-small"></i>
        </a>
        <button mat-icon-button class="v-sub" (click)="feedback(item)" *ngIf="productService.hasModel(item)" matTooltip="Add Feedback">
          <i class="icon-edited icon-button-small"></i>
        </button>
      </span>
      <button mat-icon-button class="small more-options-btn" (click)="showMore = !showMore">
        <mat-icon aria-label="more options">
          more_vert
        </mat-icon>
      </button>

    </span>

  </mat-card-subtitle>
  <!-- <mat-card-content></mat-card-content> -->
  <!-- <app-simple-slider mat-card-image [i-width]="248" [i-height]="170" [images]="item.UI.smallImages"></app-simple-slider> -->
  <!-- <lib-ng-simple-slider [i-width]="248" [i-height]="170" [images]="item.UI.smallImages"></lib-ng-simple-slider> -->

  <div (click)="toggleQuickView($event)" class="image-wrap pointer text-center" fxFlex>
    <img alt="product image" mat-card-image *ngIf="mainImage" [src]="mainImage | srcSuffix : '?w=246&h=192' : true" class="vertical-align">
  </div>

  <mat-card-content fxFlex="50px">
    <div fxLayout="row">
      <div fxFlex="70">
        <div class="name ellipsis" [matTooltip]="item.name_org_lang">
          <b appClipboard>{{item.name_org_lang}}</b>
        </div>
        <div class="sn ellipsis" *ngIf="item.products_identifiers[0]">{{item.products_identifiers[0].serial_number}}</div>
      </div>
      <div fxFlex fxLayoutAlign="space-around center">
        <!-- <button mat-icon-button [ngClass]="{'gray': !item.mobile_enabled}" class="small" (click)="toggleEnabled('mobile_')"> -->
        <!-- <mat-icon aria-label="enabled on mobile devices gray" *ngIf="item.mobile_enabled == 1">
          smartphone
        </mat-icon> -->
        <!-- [matTooltip]="item.mobile_traffic" -->
        <i aria-label="enabled on mobile" class="icon-smartphone icon-button-small gray def-dur"
          [ngClass]="{'opacity': item.mobile_enabled != 1}"></i>
        <!-- </button> -->
        <!-- <button mat-icon-button [ngClass]="{'gray': !item.enabled}" class="small" (click)="toggleEnabled('')"> -->
        <!-- <mat-icon aria-label="enabled on PC" *ngIf="item.enabled == 1">
          computer
        </mat-icon> -->
        <!-- [matTooltip]="item.desktop_traffic" -->
        <i aria-label="enabled on desktop" class="icon-screen icon-button-small gray def-dur"
          [ngClass]="{'opacity': item.enabled != 1}"></i>
        <!-- </button> -->
      </div>
    </div>
  </mat-card-content>
</mat-card>
<app-product-quick-view *ngIf="item == productService.quickViewItem" [item]="item"
  [ngClass]="{'orders': !hasShowcase, 'approved': hasShowcase, 'exit': productService.quickViewClass == 'exit', 'enter': productService.quickViewClass == 'enter'}">
</app-product-quick-view>