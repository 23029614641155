<div fxLayout="row" fxLayoutAlign="space-around center">
    <div fxFlex="50" class="pos-rel">
        <mat-form-field>
            <mat-label>your retailer accounts</mat-label>
            <mat-select (selectionChange)="onRetailerChange($event)">
                <mat-option *ngFor="let retailer of retailers" [value]="retailer">
                    <img style="vertical-align:middle" aria-hidden src="{{retailer.logo_url}}" height="25" />
                    <span>{{ retailer.name }}</span>
                    <small class="italic"> ({{retailer.id}})</small>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxFlex="50">
      <button [disabled]="!retailer" mat-raised-button color="accent" class="third_button min-button-width thinner"
        (click)="duplicate()">duplicate</button>
    </div>
</div>