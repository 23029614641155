import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateAuthGuard  {
  constructor(private router: Router, private auth: AuthService) { }
  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.auth.isloggedIn()) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
