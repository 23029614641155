import { Injectable } from '@angular/core';
import { CMSNotificationListResponse, CMSNotification, CMSNotificationsType } from './notifications';
import { Subject, Subscription } from 'rxjs';
import { SwService } from '../sw-helper/sw.service';
import { AuthService } from '../auth/auth.service';
import { BroadcasterService } from 'ng-broadcaster';
import { RolesHelperService } from '../auth/roles-helper.service';
import { NotificationsWebsocketService } from './notifications-websocket.service';
import { BroadcasterNotification, BroadcasterNotificationType } from './broadcaster-notifications';
import { EnumsService } from '../shared/enums.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  static BATCH_SIZE = 100; // TODO increment infinite scrolling
  // static ACTION_TYPES = [CMSNotificationsType.FEEDBACK_RECEIVED, CreatorsNotificationType.JOB_ASSIGNED, CreatorsNotificationType.CUSTOM_BROADCAST];
  public onNotification: Subject<CMSNotification>;
  public onSidebarToggle: Subject<boolean>;
  public notifications: Array<CMSNotification>;
  public unred: number;
  private isSidebarOpen: boolean;
  private onPushNotificationSub: Subscription;
  private onAnnouncementSub: Subscription;
  private onAnnouncementsSub: Subscription;
  private onReadSub: Subscription;
  private unhandledNotifications: Array<CMSNotification>;
  private onNotificationRefreshedSub: Subscription;
  private onStateChangeSub: Subscription;
  private onLogin: Subscription;
  private onLogout: Subscription;
  private isInit: boolean;

  constructor(
    private notificationsWebsocket: NotificationsWebsocketService,
    private swService: SwService,
    private enums: EnumsService,
    private auth: AuthService,
    private broadcaster: BroadcasterService,
    private rolesHelper: RolesHelperService,
    // private body: BodyService
  ) {
    this.onNotification = new Subject<CMSNotification>();
    this.isSidebarOpen = false;
    this.onSidebarToggle = new Subject<boolean>();
    this.isInit = false;
    this.notifications = [];
    this.unhandledNotifications = [];
    this.unred = 0;

    if (this.auth.isloggedIn())
      this.subscribe();
    if (this.notificationsWebsocket.isOpen) {
      this.isInit = true;
      // this.fetch();
    }
    this.onLogin = this.broadcaster.on('onLogin').subscribe(
      () => {
        this.subscribe();
      }
    );
    this.onLogout = this.broadcaster.on('onLogout').subscribe(
      () => {
        this.isInit = false;
        this.unsubscribe();
      }
    );
  }

  public toggleSidebar(state: boolean) {
    this.isSidebarOpen = state;
    // this.body.isBlur = this.isSidebarOpen;
    this.onSidebarToggle.next(this.isSidebarOpen);
  }

  public getSidebarState(): boolean {
    return this.isSidebarOpen;
  }

  public markAsRead(notificationId) {
    this.notificationsWebsocket.read(notificationId);
  }

  private onPush(obj: any) {
    console.log(obj);
    // this.onAnnouncement(obj); // or something like that
  }

  private onRead(id: number) {
    let n = this.notifications.find(n => n.id == id);
    if (n && !n.read) {
      n.read = true;
      this.unred--;
      this.broadcaster.broadcast('deployLinks');
    }
  }

  private onAnnouncements(notifications: CMSNotificationListResponse) {
    this.unred = notifications.unread_count;
    this.pushArray(notifications.items);
  }

  private pushArray(items: Array<CMSNotification>) {
    let n = this.notifications;
    n = n.concat(items);
    this.notifications = n.sort(function (a, b) {
      var c = new Date(a.created_at).getTime();
      var d = new Date(b.created_at).getTime();
      return d - c;
    });
  }

  private onAnnouncement(notification: CMSNotification) {
    let notificationTypes = this.enums.getNotificationTypes();
    if (!notificationTypes) {
      this.unhandledNotifications.push(notification);
      this.auth.fetchNotificationTypes();
    }
    else
      this.handledNotification(notification);
  }

  private handledNotification(notification: CMSNotification) {
    let notificationTypes = this.enums.getNotificationTypes();
    if (!notificationTypes) throw 'notification types ENUM is undefined!';
    // if (!notification.read && !this.notifications.find(n => n.id == notification.id) &&
    //   NotificationsService.ACTION_TYPES.find(t => t == notification.notification_type))
    //   this.unred++;
    switch (notification.notifications_types[0].id) {
      case (CMSNotificationsType.ROLES_REFRESHED): {
        this.rolesHelper.fetchRoles();
        break;
      }
      case CMSNotificationsType.VERSION_UPDATE: {
        let data: BroadcasterNotification = {
          text: 'A newer version of HEXA\'s CMS is available',
          type: BroadcasterNotificationType.Info,
          action: 'REFRESH',
          callback: this.refresh,
          scope: this,
          autoDismiss: false
        };
        this.broadcaster.broadcast('notifyUser', data);
        break;
      }
    }
  }

  public refresh() {
    window.location.reload();
  }

  private onNotificationRefreshed() {
    for (let i = this.unhandledNotifications.length - 1; i >= 0; i--) {
      this.handledNotification(this.unhandledNotifications.pop());
    }
  }

  private subscribe() {
    this.onAnnouncementSub = this.notificationsWebsocket.onAnnouncement.subscribe(this.onAnnouncement.bind(this));
    this.onAnnouncementsSub = this.notificationsWebsocket.onAnnouncements.subscribe(this.onAnnouncements.bind(this));
    this.onReadSub = this.notificationsWebsocket.onRead.subscribe(this.onRead.bind(this));
    this.onPushNotificationSub = this.swService.onPushNotification.subscribe(this.onPush.bind(this));
    this.onNotificationRefreshedSub = this.broadcaster.on('notificationRefreshed').subscribe(this.onNotificationRefreshed.bind(this));


    if (!this.notificationsWebsocket.isOpen) {
      this.onStateChangeSub = this.notificationsWebsocket.onStateChange.subscribe(() => {
        // if (this.notificationsWebsocket.isOpen || !this.isInit) {
        //   this.fetch();
        // }
      });
    }
  }

  // private fetch() {
  //   this.notificationsWebsocket.fetch(NotificationsService.BATCH_SIZE, this.notifications.length, NotificationsService.ACTION_TYPES);
  // }

  private unsubscribe() {
    if (this.onAnnouncementSub)
      this.onAnnouncementSub.unsubscribe();
    if (this.onPushNotificationSub)
      this.onPushNotificationSub.unsubscribe();
    if (this.onNotificationRefreshedSub)
      this.onNotificationRefreshedSub.unsubscribe();
    if (this.onAnnouncementsSub)
      this.onAnnouncementsSub.unsubscribe();
    if (this.onReadSub)
      this.onReadSub.unsubscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
}
