import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
// import { RestService } from '../../communication/rest.service';
import { Observable } from 'rxjs';
// import { ListResponse } from 'src/app/communication/custom-request';
import { GraphqlService } from '../../communication/graphql.service';
import { SingleProductQuery } from '../product';
import { ProductService } from '../product.service';
import { ProductRequestService } from '../product-request.service';

@Injectable()
export class ProductResolve  {

    constructor(
        // private rest: RestService,
        private gql: GraphqlService,
        private productService: ProductService,
        private prService: ProductRequestService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot) {
        if (route.params['state']) {
            this.productService.initialState = route.params['state'] == 'feedback' ? 1 : this.productService.initialState = route.params['state'] == 'adjustments' ? 2 : 3;
            this.prService.adjustmentsMode = route.params['state'] == 'adjustments';
            this.prService.renderMode = route.params['state'] == 'renderer';
        }
        else
            this.productService.initialState = 0;
        let pid = route.params['pid'];
        if (pid.indexOf(';') > -1)
            pid = pid.substring(0, pid.indexOf(';'));
        if (this.productService.editItem && this.productService.editItem.id == pid && this.productService.editItem.retailers) {
            let res = {
                data: {
                    products: this.productService.editItem
                }
            } as SingleProductQuery;
            return res;
        }
        return this.gql.product(pid) as Observable<any>;
    }
}