import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { RetailersResourceFileType } from '../retailer';
import { ResourceTypesService } from '../../shared/resource-types.service';
import { ResourceType } from '../../product/product';
import { RestService } from '../../communication/rest.service';
import { BroadcasterService } from 'ng-broadcaster';
import { UtilsService } from '../../shared/utils.service';
import { BroadcasterNotification, BroadcasterNotificationType } from '../../communication/broadcaster-notifications';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-retailer-resource-types',
  templateUrl: './retailer-resource-types.component.html',
  styleUrls: ['./retailer-resource-types.component.scss']
})
export class RetailerResourceTypesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() config: Array<RetailersResourceFileType>;
  @Input('retailer-id') retailerId: number;
  public requiredFormats: Array<number>;
  public allFormats: Array<ResourceType>;
  private subs: Array<Subscription>;
  constructor(
    private resourceTypesService: ResourceTypesService,
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService
  ) {
    this.subs = [];
    this.allFormats = this.resourceTypesService.getCachedTypes();
    if (!this.allFormats) {
      this.subs.push(
        this.broadcaster.on('onResourceTypes').subscribe(
          () => this.allFormats = this.resourceTypesService.getCachedTypes()
        )
      );
    }
  }

  ngOnInit() {
    this.setDefaults();
  }

  ngOnChanges() {
    this.setDefaults();
  }

  setDefaults() {
    if (!this.config)
      this.config = [];
    else
      this.config = this.utils.deepCopyByValue(this.config);
    this.requiredFormats = this.config.map(i => i.resource_type_id);
  }

  save() {
    this.config = [];
    this.requiredFormats.forEach(rf => {
      this.config.push(
        {
          resource_type_id: rf,
          retailer_id: this.retailerId
        } as RetailersResourceFileType
      );
    });

    this.rest.retailerResourceType('put', this.config, '?rid=' + this.retailerId).subscribe(
      data => {
        this.config = data as Array<RetailersResourceFileType>;
        let res: BroadcasterNotification = {
          text: 'resources file types updated successfully',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', res);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure updating resources file types')
    );
  }

  ngOnDestroy() {
    if (this.subs.length)
      this.subs.forEach(s => s.unsubscribe());
  }
}
