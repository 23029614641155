import { Pipe, PipeTransform } from '@angular/core';
import { User } from './user';

@Pipe({
  name: 'users'
})
export class UsersPipe implements PipeTransform {

  transform(arr: Array<User>, search: string): Array<User> {
    let final = [] as Array<User>;
    if (!search)
      return arr;
    if (!arr || !arr.length)
      return arr;
    search = search.toLowerCase();
    arr.forEach(u => {
      if (u.firstname && u.firstname.toLowerCase().indexOf(search) > -1) {
        final.push(u);
      }
      else if (u.lastname && u.lastname.toLowerCase().indexOf(search) > -1) {
        final.push(u);
      }
      else if (u.email && u.email.toLowerCase().indexOf(search) > -1) {
        final.push(u);
      }
    })
    return final;
  }

}
