<div class="polygon-spec">
  <div *ngFor="let p of polySpecs" class="spec-box">
    <div>
      <mat-form-field class="w-100">
        <input matInput placeholder="Variation name" [(ngModel)]="p.variation_name" name="variation_name">
      </mat-form-field>
    </div>

    <div class="spec-form-row">
      <mat-form-field>
        <mat-label>Poly type</mat-label>
        <mat-select [(ngModel)]="p.poly_type" class="uppercase">
          <mat-option *ngFor="let t of polyTypes" [value]="t.key" class="uppercase">
            {{t.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Shape</mat-label>
        <mat-select [(ngModel)]="p.poly_shape_type" class="uppercase">
          <mat-option *ngFor="let t of polyShapeTypes" [value]="t.key" class="uppercase">
            {{t.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Job type</mat-label>
        <mat-select [(ngModel)]="p.job_type">
          <mat-option [value]="JobType.Geo">
            Geometry
          </mat-option>
          <mat-option [value]="JobType.Text">
            Texture
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="spec-form-row">
      <mat-form-field>
        <input matInput type="number" placeholder="Min polygons" [(ngModel)]="p.min_poly_count" name="min_poly_count">
      </mat-form-field>

      <mat-form-field>
        <input matInput type="number" placeholder="Max polygons" [(ngModel)]="p.max_poly_count" name="max_poly_count">
      </mat-form-field>

      <div class="buttons-container">
        <button mat-raised-button mat-raised-button color="accent" (click)="deleteSpecs(p)" class="thinner marg-top">
          Delete
        </button>
        <button mat-raised-button mat-raised-button color="accent" (click)="save(p)" class="thinner marg-top">
          Save
        </button>
      </div>
    </div>

  </div>

  <div *ngIf="polyToAdd" class="spec-box">
    <div>
      <mat-form-field class="w-100">
        <input matInput placeholder="Insert variation name" [(ngModel)]="polyToAdd.variation_name" name="variation_name">
      </mat-form-field>
    </div>

    <div class="spec-form-row">
      <mat-form-field>
        <mat-label>Poly type</mat-label>
        <mat-select [(ngModel)]="polyToAdd.poly_type">
          <mat-option *ngFor="let t of polyTypesToAdd" [value]="t.key" class="uppercase">
            {{t.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Shape</mat-label>
        <mat-select [(ngModel)]="polyToAdd.poly_shape_type" class="uppercase">
          <mat-option *ngFor="let t of polyShapeTypes" [value]="t.key" class="uppercase">
            {{t.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Job type</mat-label>
        <mat-select [(ngModel)]="polyToAdd.job_type">
          <mat-option [value]="JobType.Geo">
            Geometry
          </mat-option>
          <mat-option [value]="JobType.Text">
            Texture
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="spec-form-row">
      <mat-form-field>
        <input matInput type="number" placeholder="Min polygons" [(ngModel)]="polyToAdd.min_poly_count" name="min_poly_count">
      </mat-form-field>
      <mat-form-field>
        <input matInput type="number" placeholder="Max polygons" [(ngModel)]="polyToAdd.max_poly_count" name="max_poly_count">
      </mat-form-field>
      <div class="buttons-container">
        <button mat-raised-button mat-raised-button color="accent" (click)="deleteNewVariation()" class="thinner marg-top">
          Delete
        </button>
        <button mat-raised-button mat-raised-button color="accent" (click)="saveNewVariation()" class="thinner marg-top">
          Save
        </button>
      </div>
    </div>

  </div>
</div>

<mat-divider></mat-divider>
<div class="add-variation-container">
  <button mat-raised-button mat-raised-button color="accent" (click)="addEmptyVariation()" class="thinner marg-top">
    <mat-icon>add</mat-icon>
    Add variation
  </button>
</div>
