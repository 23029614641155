<div fxLayout="column" fxLayoutAlign="space-around">
  <div fxFill>
    <div *ngFor="let f of config">
      <mat-form-field>
        <input matInput [placeholder]="allFormatsDictionary[f.mapping_id] && allFormatsDictionary[f.mapping_id].mapping_name" [(ngModel)]="f.mapping_value">
      </mat-form-field>
    </div>
  </div>
  <div fxFill>
    <button mat-raised-button mat-raised-button color="accent" (click)="save()" class="thinner marg-top">
      save mapping naming convention
    </button>
  </div>
</div>