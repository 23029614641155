import { Injectable, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { Product, PrimeStatus, PrimeStatusData, ProductViewType, ProductUi, ProductFilterOptions, ProductResource, GifOptions, PreviewOptions, ProductsResourcesSourceFile, MediaTag, ResourceDimension, ProductMassEdit, ProductAudit, ProductAuditUI, ProductAuditActionMethod, AllProductsAudit, ProductAuditUIGroup, AllProducts, SummaryProducts, ProductData, ProductsAttachment, ProductsStatus, ProdutUIOptions } from './product';
import { KeyValuePair, FormatsType, ImageFormatsType } from '../shared/enums';
import { EnumsService } from '../shared/enums.service';
import { MapperService } from '../shared/mapper.service';
import { BodyService } from '../shared/body.service';
import { GraphqlService } from '../communication/graphql.service';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilsService } from '../shared/utils.service';
import { AuthService } from '../auth/auth.service';
import { Params, Router } from '../../../node_modules/@angular/router';
import { GlobalsService } from '../shared/globals.service';
import { BroadcasterService } from '../../../node_modules/ng-broadcaster';
import { RestService } from '../communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from '../communication/broadcaster-notifications';
import { ApiCallOptions } from '../shared/utils';
import { Retailer, Category, SubCategory, RetailerFilterOptions, RetailerBatch } from '../retailer/retailer';
import { MessagesHandlerService, MessageRequest } from 'messages-handler';
import { RolesHelperService } from '../auth/roles-helper.service';
import { MatRadioChange } from '@angular/material/radio';
import { environment } from '../../environments/environment';
import { FeedbackService } from './feedback.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  static minImages = 1;
  static ARRAY_FIELD_NAMES = ['retailer_id', 'batch_id', 'status_id', 'cs_id', 'serial_number', 'url', 'tags', 'retailer_category_id', 'retailer_sub_category_id', 'viewer_resource_type', 'feedback_type', 'feedback_sub_type', 'reject_id'];
  public filter: UntypedFormGroup;
  public ignoreChanges: boolean;
  public items: Array<Product>;
  public count: number;
  public resource_count: number;
  public selectedCount: number;
  public selectedItems: Array<Product>;
  public primeStatusData: Array<PrimeStatusData>;
  public viewType: ProductViewType;
  public GRID: ProductViewType;
  public LIST: ProductViewType;
  public primeStatus: { [id: string]: string };
  public currentPrimeStatusData: PrimeStatusData;
  public quickViewItem: Product;
  public quickViewClass: string;
  public isSearchRequestInProgress: boolean;
  public trySearchDuringProgress: boolean;
  public lastQuery: ProductFilterOptions;
  public scrollOffset: number;
  public scrollLimit: number;
  public onToggleQuickView: Subject<unknown>;
  public onViewTypeChange: Subject<number>;
  public onItemsChange: Subject<unknown>;
  public editItem: Product;
  public initialPrimeStatusDataIndex: number;
  public initialStatusDataIndex: number;
  public statusDataIndex: number;
  public initialSortDir: string;
  public initialSortField: string;
  public initialState: number;
  // public retailer: Retailer;
  public cachedRetailers: { [id: string]: Retailer };
  public onGifReady: Subject<any>;
  public onPreviewReady: Subject<any>;
  public statusList: Array<KeyValuePair>;
  // public rawStatusList: Array<KeyValuePair>;
  // public rawStatusIndex: Array<number>;
  public viewerResourceTypes: Array<number>;
  public feedbackTypes: Array<number>;
  public feedbackSubTypes: Array<number>;
  public rejectReasons: Array<number>;
  public totalSelectedCats: number;
  public IFRAME: MediaTag;
  public IFRAME_RENDR: MediaTag;
  public MODEL: MediaTag;
  public VIDEO: MediaTag;
  public IMAGE: MediaTag;
  public ALL: ResourceDimension;
  public showReportGen: boolean;
  public showBatchDownloader: boolean;
  public OBJ: FormatsType;
  public FBX: FormatsType;
  public DAE: FormatsType;
  public BLEND: FormatsType;
  public glTF: FormatsType;
  public GLB: FormatsType;
  public USDZ: FormatsType;
  public PNG: FormatsType;
  public JPG: FormatsType;
  public MP4: FormatsType;
  public TIFF: FormatsType;
  public VIEWER_JSON: FormatsType;
  public counter: number;
  public allProductsSummary: SummaryProducts;
  public totalByStatus: any;
  public selectedbatches: Array<RetailerBatch> = [];
  public resourceDimensionSelected: ResourceDimension;
  public resourceDimension: typeof ResourceDimension;
  public totalSelected: number;
  public renderStatuses: Array<number>;
  private isAllSelectedInner: boolean;
  private animatedGifOrigins: { [id: string]: boolean };
  private previewOrigins: { [id: string]: boolean };
  private isSU: boolean;
  private _ignoreRetailerChanges: boolean;
  private _onProductsManagement: boolean;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private enums: EnumsService,
    private mapper: MapperService,
    private body: BodyService,
    private gql: GraphqlService,
    private sanitizer: DomSanitizer,
    private utils: UtilsService,
    private auth: AuthService,
    private router: Router,
    private globals: GlobalsService,
    private broadcaster: BroadcasterService,
    private rest: RestService,
    private mhService: MessagesHandlerService,
    private decimalPipe: DecimalPipe,
    private roles: RolesHelperService,
    public feedbackService: FeedbackService,
  ) {
    this.resourceDimension = ResourceDimension;
    this.resourceDimensionSelected = ResourceDimension.THREE;
    this.IFRAME = MediaTag.IFRAME;
    this.MODEL = MediaTag.MODEL;
    this.IMAGE = MediaTag.IMAGE;
    this.VIDEO = MediaTag.VIDEO;
    this.IFRAME_RENDR = MediaTag.IFRAME_RENDR;
    this.OBJ = FormatsType.OBJ;
    this.FBX = FormatsType.FBX;
    this.DAE = FormatsType.DAE;
    this.BLEND = FormatsType.BLEND;
    this.glTF = FormatsType.glTF;
    this.GLB = FormatsType.GLB;
    this.USDZ = FormatsType.USDZ;
    this.PNG = FormatsType.PNG;
    this.JPG = FormatsType.JPG;
    this.MP4 = FormatsType.MP4;
    this.TIFF = FormatsType.TIFF;
    this.VIEWER_JSON = FormatsType.VIEWER_JSON;
    this.cachedRetailers = {};
    this.animatedGifOrigins = {};
    this.previewOrigins = {};
    this.isAllSelectedInner = false;
    this.onToggleQuickView = new Subject<unknown>();
    this.onViewTypeChange = new Subject<number>();
    this.onItemsChange = new Subject<unknown>();
    this.onGifReady = new Subject<any>();
    this.onPreviewReady = new Subject<any>();
    this.statusList = this.enums.getStatus();
    this.GRID = ProductViewType.GRID;
    this.LIST = ProductViewType.LIST;
    this.primeStatus = {};
    this.primeStatus['All'] = 'All';
    this.primeStatus['Orders'] = 'Orders';
    this.primeStatus['Approved'] = 'Approved';
    this.primeStatus['Deleted'] = 'Deleted';
    this.totalSelectedCats = 0;
    this.counter = 0;
    this.isSU = this.roles.isSuperUserOrObserverLoggedIn();
    this.totalByStatus = {};
    this.primeStatusData = [
      {
        primeStatus: PrimeStatus.ALL,
        statusList: [],
        sum: 0
      },
      {
        primeStatus: PrimeStatus.ORDERS,
        statusList: [
          this.statusList.find(s => s.key.find(i => i == ProductsStatus.REQUESTED)),
          this.statusList.find(s => s.key.find(i => i == ProductsStatus.REJECTED)),
          {
            key: [ProductsStatus.IN_PROGRESS],
            value: 'In Progress'
          },
          this.statusList.find(s => s.key.find(i => i == ProductsStatus.DELIVERED)),
          this.statusList.find(s => s.key.find(i => i == ProductsStatus.CLIENT_FEEDBACK))
        ],
        sum: 0
      },
      {
        primeStatus: PrimeStatus.APPROVED,
        statusList: [
          {
            key: [ProductsStatus.ONLINE, ProductsStatus.OFFLINE],
            value: 'All Assets'
          },
          this.statusList.find(s => s.key.find(i => i == ProductsStatus.ONLINE)),
          this.statusList.find(s => s.key.find(i => i == ProductsStatus.OFFLINE))
        ],
        sum: 0
      },
      {
        primeStatus: PrimeStatus.DELETED,
        statusList: [
        ],
        sum: 0
      }
    ];
    this.currentPrimeStatusData = this.primeStatusData[0];
    this.filter = this.formBuilder.group({
      // is_desc: false,
      is_desc: true,
      // sort_by: 'updated_at',
      // order_by: 'created_at',
      order_by: 'updated_at',
      status_id: [[]],
      // rids: [[]],
      serial_number: [[]],
      tags: [[]],
      retailer_category_id: [[]],
      retailer_sub_category_id: [[]],
      viewer_resource_type: [[]],
      reject_id: [[]],
      feedback_type: [[]],
      feedback_sub_type: [[]],
      name: [[]],
      url: [[]],
      is_archived: false,
      retailer_id: [[]],
      batch_id: [[]],
      date_from: null,
      date_to: null,
      date_range: "created_at",
      visible: null,
      ia: null,
      is_poc: null,
      contains_render_request: false,
      render_status_id: [[]]
    });

    // this.buildRawStatusList();

    this.isSearchRequestInProgress = false;
    this.trySearchDuringProgress = false;
    this.ignoreChanges = false;
    this.initPagination();
    this.lastQuery = this.getSearchQuery();

    this.filter.valueChanges.subscribe(this.onFilterValueChanges.bind(this));
    this.setViewType(ProductViewType.GRID);
    this.broadcaster.on('onRetailerIndex').subscribe(this.onRetailerIndex.bind(this));
    this.selectedItems = [];

    this.setSelectedBatches();
  }

  setSelectedBatches() {
    let batches = this?.filter?.controls?.batch_id?.value;
    if (batches.length > 0) {
      batches.forEach(b => {
        this.selectedbatches.push({ batch_id: parseInt(b) });
      });
    }
  }

  on3dRenderFilter(type) {
    if (type === ResourceDimension.TWO)
      this.filter.controls.contains_render_request.setValue(true);
    else
      this.filter.controls.contains_render_request.setValue([]);
    this.statusChange();
  }

  onRetailerIndex(retailerIndex: number) {
    if (!this.ignoreRetailerChanges && this.onProductsManagement) {
      this.filter.controls.retailer_id.setValue([this.auth.getRetailerIdByIndex(retailerIndex)]);
    }
  }

  get onProductsManagement() {
    return this._onProductsManagement;
  }

  set onProductsManagement(valuie: boolean) {
    this._onProductsManagement = valuie;
  }

  get ignoreRetailerChanges() {
    return this._ignoreRetailerChanges;
  }

  get isAllSelected(): boolean {
    return this.isAllSelectedInner;
  };

  set isAllSelected(v: boolean) {
    this.isAllSelectedInner = v;
  };

  // buildRawStatusList() {
  //   this.rawStatusList = []
  //   //   { key: [], value: 'All' }
  //   // ];
  //   this.primeStatusData.forEach(psd => {
  //     psd.statusList.forEach(sl => {
  //       if (sl.key.length) {
  //         this.rawStatusList.push({
  //           key: sl.key,
  //           value: sl.value
  //         });
  //       }
  //     })
  //   });
  //   // this.rawStatusList.push(
  //   //   {
  //   //     key: [-1],
  //   //     value: 'Deleted'
  //   //   }
  //   // );
  // }

  getStatusById(id: number): KeyValuePair {
    return this.statusList.find(s => s.key[0] == id);
  }

  getStatusByStatusId(id: number): Array<number> {
    for (let i = 0; i < this.primeStatusData.length; i++) {
      for (let j = 0; j < this.primeStatusData[i].statusList.length; j++) {
        const res = this.primeStatusData[i].statusList[j].key.find(k => k == id);
        if (res)
          return this.primeStatusData[i].statusList[j].key;
      }
    }
    return [];
    // return this.primeStatusData.find(psd => !!psd.statusList.find(sl => sl.key == id));
  }

  refreshSelectedCats() {
    this.totalSelectedCats = 0;
    let val = null;
    if (this.filter.controls.retailer_category_id) {
      val = this.filter.controls.retailer_category_id.value;
      if (val && val.length)
        this.totalSelectedCats += val.length;
    }
    if (this.filter.controls.retailer_sub_category_id) {
      val = this.filter.controls.retailer_sub_category_id.value;
      if (val && val.length)
        this.totalSelectedCats += val.length;
    }
  }

  onFilterValueChanges() {
    if (!this.ignoreChanges) {
      this.initPagination();
      this.searchByQuery();
    }
    this.refreshSelectedCats();
    // this.onFilterChange.next(this.filter);
  }

  updateSummary(data?: AllProducts) {
    this.allProductsSummary = data.summary;
    this.setSummary();
  }


  refreshSelectedCount() {
    setTimeout(() => {
      if (this.items instanceof Array) {
        this.selectedItems = this.items.filter(i => i.UI.checked);
        this.selectedCount = this.selectedItems.length;
      }
      else
        this.selectedCount = 0;
      this.initTotalSelected();
    });
  }

  select() {
    if (this.isAllSelected) {
      this.unselectAll();
    } else {
      this.selectAll();
    }
    this.initTotalSelected();
  }

  unselectAll() {
    if (this.items instanceof Array)
      this.items.forEach(item => {
        item.UI.checked = false;
      });
    this.selectedCount = 0;
    this.isAllSelected = false;
    this.counter = 0;
  }

  selectAll() {
    if (this.items instanceof Array)
      this.items.forEach(item => {
        item.UI.checked = true;
      });
    this.selectedCount = this.items.length;
    this.isAllSelected = true;
    this.counter++;
  }

  public getTotalProductSummary(): number {
    return this.primeStatusData[PrimeStatus.ALL].sum;
  }

  setSummary() {
    setTimeout(() => {
      this.primeStatusData[PrimeStatus.ALL].sum = this.getTotalByStatus([ProductsStatus.REQUESTED,
      ProductsStatus.IN_PROGRESS,
      ProductsStatus.DELIVERED,
      ProductsStatus.OFFLINE,
      ProductsStatus.ONLINE,
      ProductsStatus.CLIENT_FEEDBACK,
      ProductsStatus.REJECTED]);
      this.primeStatusData[PrimeStatus.ORDERS].sum = this.getTotalByStatus([ProductsStatus.REQUESTED,
      ProductsStatus.IN_PROGRESS,
      ProductsStatus.DELIVERED,
      ProductsStatus.CLIENT_FEEDBACK,
      ProductsStatus.REJECTED]);
      this.primeStatusData[PrimeStatus.APPROVED].sum = this.getTotalByStatus([ProductsStatus.OFFLINE,
      ProductsStatus.ONLINE]);
      this.primeStatusData[PrimeStatus.DELETED].sum = this.getTotalByStatus(['archived']);
      this.settotalByStatus();
    });
  }

  statusChange(index?) {
    if (!isNaN(index))
      this.statusDataIndex = index;
    let statusType = 'status_id'; //3D
    if (this.currentPrimeStatusData.statusList[this.statusDataIndex])
      this.filter.controls[statusType].setValue(this.currentPrimeStatusData.statusList[this.statusDataIndex].key);
    else {
      this.filter.controls['status_id'].setValue([]);
    }
  }

  getDefaultResourceIndex(product: Product): number {
    if (!product) return 0;
    if (!product.products_resources) return 0;
    for (let i = 0; i < product.products_resources.length; i++) {
      if (product.products_resources[i].resource_enabled) {
        return i;
      }
    }
    return 0;
  }

  // rawStatusChange(matSelectChange: MatSelectChange) {
  //   let arr = this.filter.controls['status_id'].value;
  //   if (!arr)
  //     arr = [];
  //   if (matSelectChange.value.length)
  //   matSelectChange.value.forEach(ids => {
  //     if (!arr.find(i => ids.find(inI => inI == ids)))
  //       ids.forEach(i => arr.push(i));
  //   });
  //   arr = [new Set(arr)];
  //   arr = Object.keys(arr).map(i => parseInt(i));
  //   this.filter.controls['status_id'].setValue(arr);
  //   // if (matSelectChange.value[0] == -1) {
  //   //   this.filter.controls['is_archived'].setValue(true);
  //   //   // this.filter.controls['status_id'].setValue([]);
  //   // }
  //   // else {
  //   //   this.filter.controls['is_archived'].setValue(false);
  //   //   // this.filter.controls['status_id'].setValue([matSelectChange.value]);
  //   // }
  // }

  initPagination() {
    this.scrollOffset = 0;
    this.scrollLimit = 24;
  }

  private navigateByQuery(options: ProductFilterOptions): void {
    let navObj = {} as ProductFilterOptions;
    Object.assign(navObj, options);
    delete navObj.limit;
    delete navObj.offset;
    this.router.navigate(['/products', navObj]);
  }

  public setStateFromURL(params: Params) {
    let filter = this.getSearchQuery(this.getFilterFromQueryParams(params));
    this.ignoreChanges = true;
    for (let i in filter) {
      if (i != 'limit' && i != 'offset') {

        if (ProductService.ARRAY_FIELD_NAMES.find(a => a == i) && i != 'serial_number' && i != 'tags' && i != 'url') {
          if (filter[i].map)
            this.filter.controls[i].setValue(filter[i].map(e => parseFloat(e)));
          else
            this.filter.controls[i].setValue([filter[i]]);
        }
        else if (i == 'serial_number' || i == 'tags' || i == 'name') {
          if (filter[i].map)
            this.filter.controls[i].setValue(filter[i].map(e => String(e)));
          else
            this.filter.controls[i].setValue(String(filter[i]).split(','));
        }
        else if (i == 'date_from' || i == 'date_to') {
          this.filter.controls[i].setValue(new Date(filter[i] * 1));
        }
        else if (i == 'visible') {
          if (filter[i])
            this.filter.controls[i].setValue(filter[i] * 1);
          else
            this.filter.controls[i].setValue(null);
        }
        else
          this.filter.controls[i].setValue(filter[i]);
      }
    }
    if (this.filter.controls['order_by'].value) {
      this.initialSortField = this.filter.controls['order_by'].value;
    }
    if (typeof this.filter.controls['is_desc'].value === 'boolean') {
      this.initialSortDir = this.filter.controls['is_desc'].value ? 'desc' : 'asc';
    }
    if (this.filter.controls.viewer_resource_type.value) {
      this.viewerResourceTypes = this.filter.controls.viewer_resource_type.value;
      this.viewerResourceTypes.sort((a: number, b: number) => { return a - b; });

    }
    else {
      delete this.viewerResourceTypes;
    }

    if (this.filter.controls.reject_id.value)
      this.rejectReasons = this.filter.controls.reject_id.value;
    else {
      delete this.rejectReasons;
    }

    this.initialPrimeStatusDataIndex = 0;
    if (this.filter.controls['status_id'].value && this.filter.controls['status_id'].value.length) {
      switch (this.filter.controls['status_id'].value[0]) {
        case ProductsStatus.ONLINE:
        case ProductsStatus.OFFLINE: {
          this.initialPrimeStatusDataIndex = PrimeStatus.APPROVED;
          break;
        }
        case ProductsStatus.REQUESTED:
        case ProductsStatus.IN_PROGRESS:
        case ProductsStatus.DELIVERED:
        case ProductsStatus.CLIENT_FEEDBACK:
        case ProductsStatus.REJECTED: {
          this.initialPrimeStatusDataIndex = PrimeStatus.ORDERS;
          break;
        }
      }
      this.currentPrimeStatusData = this.primeStatusData.find(p => p.primeStatus == this.initialPrimeStatusDataIndex);

      // use bitwise to find the current status
      if (filter.status_id instanceof Array) {
        let querySum = filter.status_id.reduce((a, b) => {
          return (a * 1) + (b * 1);
        }, 0);

        for (let i = 0; i < this.currentPrimeStatusData.statusList.length; i++) {
          let sl = this.currentPrimeStatusData.statusList[i];
          let sum = sl.key.reduce((a, b) => {
            return (a * 1) + (b * 1);
          }, 0);
          if (querySum == sum) {
            this.initialStatusDataIndex = i;
            break;
          }
        }
      }
      this.statusDataIndex = this.initialStatusDataIndex;
      // this.rawStatusIndex = this.filter.controls['status_id'].value;
    }
    else {
      this.currentPrimeStatusData = this.primeStatusData.find(p => p.primeStatus == this.initialPrimeStatusDataIndex);
    }

    if (this.filter.controls['is_archived'].value && this.filter.controls['is_archived'].value == true) {
      this.initialPrimeStatusDataIndex = PrimeStatus.DELETED;
      this.currentPrimeStatusData = this.primeStatusData.find(p => p.primeStatus == this.initialPrimeStatusDataIndex);
      this.filter.controls['status_id'].setValue([]);
    }

    this.lastQuery = this.getSearchQuery();
    this.ignoreChanges = false;

    this.setSelectedBatches();
  }

  public setHeader() {
    this.globals.setHeaer('My Assets', '(' + this.decimalPipe.transform(this.count) + ')');
    this.globals.setBreadcrumbs();
  }

  public getTotalByStatus(status: Array<number | string>) {
    let sum = 0;
    status.forEach(s => {
      const summary = this.allProductsSummary[s.toString()];
      if (isNaN(summary))
        throw new ReferenceError('error, product status type: ' + s + ' does not exists');
      else
        sum += summary;
    });
    return sum;
  }

  searchByQuery(forceRefresh = false): void {
    if (this.isSearchRequestInProgress) {
      this.trySearchDuringProgress = true;
      return;
    }
    let query = this.getSearchQuery();
    if (!forceRefresh && this.compareQueries(this.lastQuery, query, false)) return;
    this.trySearchDuringProgress = false;
    this.isSearchRequestInProgress = true;

    this.navigateByQuery(query);

    this.gql.products(query).subscribe(
      obj => {
        this.isSearchRequestInProgress = false;
        if (obj.errors && obj.errors.length) {
          let dataErr: BroadcasterNotification = {
            text: obj.errors[0].message,
            type: BroadcasterNotificationType.Error,
            action: 'OK'
          }
          this.broadcaster.broadcast('notifyUser', dataErr);
          return;
        }
        let data: AllProducts = obj.data.allProducts;
        this.count = data.count;
        this.resource_count = data.resource_count;
        this.setHeader();
        this.updateSummary(data);

        if (!this.items) this.items = [];
        if (forceRefresh || (typeof this.lastQuery !== 'undefined' && !this.compareQueries(this.lastQuery, query, true))) {
          this.items = this.getProductsFromProductQuery(data.rows) as Array<Product>;
          this.initPagination();
        }
        else {
          this.items = this.items.concat(this.getProductsFromProductQuery(data.rows));
        }
        this.lastQuery = query;
        if (this.trySearchDuringProgress)
          this.searchByQuery();
        this.setProdutsUI();
        this.refreshSelectedCount();
        this.onItemsChange.next(null);
      }
    )
  }

  public getProductsFromProductQuery(rows: Array<Product>): Array<Product> {
    let res = [];
    if (rows.length) {
      rows.forEach((item: Product) => {
        let p = {} as Product;
        Object.assign(p, item);
        res.push(p);
      });
    }
    return res;
  }

  setViewType(t: ProductViewType): void {
    this.viewType = t;
    this.onViewTypeChange.next(this.viewType);
    this.counter++;
  }

  onViewerResourceTypesChange(evt: MatRadioChange) {
    this.viewerResourceTypes = evt.value;
    this.filter.controls.viewer_resource_type.setValue(this.viewerResourceTypes);
  }

  onRenderStatusChange(e) {
    this.renderStatuses = e.value;
    this.filter.controls.contains_render_request.setValue(e.value.length > 0);
    this.filter.controls.render_status_id.setValue(e.value);
  }

  onFeedbackTypesChange(evt) {
    this.feedbackTypes = evt.value;
    this.filter.controls.feedback_type.setValue(this.feedbackTypes);
  }

  onFeedbackSubTypesChange(evt) {
    this.feedbackSubTypes = evt.value;
    this.filter.controls.feedback_sub_type.setValue(this.feedbackSubTypes);
  }

  onRejectChange(evt: MatRadioChange) {
    this.rejectReasons = evt.value;
    this.filter.controls.reject_id.setValue(this.rejectReasons);
  }

  onIAChange(val: boolean) {
    this.filter.controls.ia.setValue(val);
  }

  onPOCChange(val: boolean) {
    this.filter.controls.is_poc.setValue(val);
  }

  settotalByStatus() {
    this.currentPrimeStatusData.statusList.forEach(s => {
      this.totalByStatus[s.key] = this.getTotalByStatus(s.key);
    });
  }

  setMainStatus(status: PrimeStatus) {
    this.currentPrimeStatusData = this.primeStatusData.find(p => p.primeStatus == status);
    this.ignoreChanges = true;
    this.statusDataIndex = 0;
    this.initialStatusDataIndex = 0;
    let statusType = 'status_id'; //3D

    if (status == PrimeStatus.DELETED) {
      this.filter.controls['is_archived'].setValue(true);
      this.ignoreChanges = false;
      this.filter.controls[statusType].setValue([]);
    }
    else {
      if (this.currentPrimeStatusData.statusList[0])
        this.filter.controls[statusType].setValue(this.currentPrimeStatusData.statusList[0].key);
      this.ignoreChanges = false;
      this.filter.controls['is_archived'].setValue(false);
    }
  }

  setProdutsUI() {
    this.items.forEach((item: Product) => {
      if (!item.UI)
        item.UI = this.getProdutUI(item, { improviseImageSet: true });
    })
  }

  getProdutUI(item: Product, options: ProdutUIOptions): ProductUi {
    let UI = {} as ProductUi;
    UI.images = this.mapper.getProductImages(item, options);
    UI.smallImages = UI.images.map(i => i.small || i.big);
    if (item.products_resources && item.products_resources.length) {
      for (let i = 0; i < item.products_resources.length; i++) {
        if (item.products_resources[i].resource_enabled) {
          UI.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.utils.setUrlParam(item.products_resources[i].resource_default, 'theme-color', '#546994'));
          UI.mediaTag = this.getMediaTag(item.products_resources[i].viewer_resource_type);
        }
      }
      if (!UI.safeUrl)
        UI.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.utils.setUrlParam(item.products_resources[0].resource_default, 'theme-color', '#546994'));
    }

    return UI;
  }

  downloadBatch(target_resource_type: Array<FormatsType>, targetImagesFormats?: Array<ImageFormatsType>) {
    let query = `?target_resource_type=${target_resource_type.toString()}`;
    if (targetImagesFormats && targetImagesFormats.length) {
      let imagesFormats = [];
      let all = this.enums.getImagesFormatsTypes();
      targetImagesFormats.forEach(k => imagesFormats.push(all.find(f => f.key == k).value));
      query += `&target_images_formats=${imagesFormats.toString()}`;
    }
    if (!this.roles.isSuperUserOrObserverLoggedIn())
      query += `&rid=${this.auth.user.retailers_users[this.auth.retailerIndex].retailer_id}`;

    let payload = {
      // where: this.getWhere()
      query: this.getWhere()
    } as ProductMassEdit;

    let params = this.gql.getValues(this.gql.normalizeProduct(payload.query));
    let payloadQuery = {
      "query": `{  allProducts( ${params}) { rows { id   name   created_at retailer_id }  summary    count    __typename  }}`
    };
    this.rest.batchDownloadResource('post', payloadQuery, query).subscribe(
      () => {
        this.utils.notifyUser({
          text: 'A file will be sent to your email account shortly',
          type: BroadcasterNotificationType.Success
        });
      },
      err => this.utils.httpErrorResponseHandler(err, 'Failure downloading assets')
    );
  }

  getMediaTag(viewerResourceType: number): MediaTag {
    switch (viewerResourceType) {
      case 11: {
        return MediaTag.VIDEO;
      }
      case 10: {
        return MediaTag.IMAGE;
      }
      case 9: {
        return MediaTag.IMAGE;
      }
      default: {
        return MediaTag.MODEL;
      }
    }
  }

  toggleQuickView(item: Product, event: MouseEvent) {
    let ms = 250;
    if (this.quickViewItem && item && this.quickViewItem != item) {
      this.quickViewClass = 'exit';
      this.onToggleQuickView.next(null);
      setTimeout(() => {
        delete this.quickViewItem;
        this.toggleQuickView(item, event);
      }, ms);
    }
    else {
      if (this.quickViewItem == item) {
        setTimeout(() => {
          delete this.quickViewItem;
        }, ms);
        this.quickViewClass = 'exit';
        this.onToggleQuickView.next(null);
      }
      else {
        this.quickViewItem = item;
        this.quickViewClass = 'enter';
        this.onToggleQuickView.next(null);
        if (event && event.target)
          setTimeout(() => {
            setTimeout(() => {
              this.body.scrollToElement(new ElementRef(event.target), 'center');
            }, 100);
            this.onToggleQuickView.next(null);
            setTimeout(() => {
              this.onToggleQuickView.next(null);
            }, 1000);
          }, ms);
      }
      setTimeout(() => {
        delete this.quickViewClass;
        this.onToggleQuickView.next(null);
      }, ms);
    }
    this.counter++;
  }

  isOrders(item: Product): boolean {
    return item.status_id != ProductsStatus.OFFLINE && item.status_id != ProductsStatus.ONLINE;
  }

  isResourceRender(resource: ProductResource) {
    switch (resource.viewer_resource_type) {
      case FormatsType.MP4:
      case FormatsType.PNG:
      case FormatsType.JPG:
      case FormatsType.TIFF: {
        return true;
      }
    }
    return false;
  }

  hasModel(item: Product, includeDisabled = false): boolean {
    if (!includeDisabled)
      return !!item.products_resources.filter(r => r.resource_enabled || this.isResourceRender(r)).length;
    return item.products_resources && item.products_resources.length > 0;
  }

  public getFilterFromQueryParams(params: Params): ProductFilterOptions {
    let res = {} as ProductFilterOptions;
    for (let i in params) {
      if (ProductService.ARRAY_FIELD_NAMES.find(a => a == i)) {
        res[i] = this.utils.parseToArr(params[i]);
        // if (i == 'tags' || i == 'serial_number')
        //   res[i] = res[i].map(e => e.toString());
      }
      else
        res[i] = params[i];
    }
    return res;
  }

  splitSpecialChars(obj: any): any {
    if (typeof obj === 'string' && obj.indexOf('"') > -1) {
      obj = obj.substring(0, obj.indexOf('"'));
    }
    else if (obj instanceof Array) {
      let arrayToAdd = [];
      for (let i = 0; i < obj.length; i++) {
        if (typeof obj[i] === 'string') {
          let split = obj[i].split(/[\",]+/);
          obj[i] = split[0];
          for (let j = 1; j < split.length; j++) {
            arrayToAdd.push(split[j].trim());
          }
        }
      }
      arrayToAdd.forEach(i => i && obj.push(i));
    }
    return obj;
  }

  public getSearchQuery(options?: ProductFilterOptions): ProductFilterOptions {
    if (!options) {
      options = {
        limit: this.scrollLimit,
        offset: this.scrollOffset
      } as ProductFilterOptions;
      Object.keys(this.filter.controls).forEach(key => {
        const val = this.filter.controls[key].value;
        if (val === null || (typeof val === 'string' && !val)) { }
        else
          options[key] = val;
        if (options[key] == null || (options[key] instanceof Array && !options[key].length))
          delete options[key];
        if (options[key])
          options[key] = this.splitSpecialChars(options[key]);
      });
    }
    // if ((!(options.retailer_id instanceof Array) || !options.retailer_id.length) && this.auth.user.retailers_users[this.auth.retailerIndex])
    //   options.retailer_id = [this.auth.user.retailers_users[this.auth.retailerIndex].retailer_id];
    if (!options.limit)
      options.limit = this.scrollLimit;
    if (!options.offset)
      options.offset = this.scrollOffset;
    if (typeof options.is_desc === 'string' && options.is_desc)
      options.is_desc = options.is_desc == 'true';
    if (typeof options.is_archived === 'string' && options.is_archived)
      options.is_archived = options.is_archived == 'true';
    if (typeof options.contains_render_request === 'string' && options.contains_render_request)
      options.contains_render_request = options.contains_render_request == 'true';
    if (typeof options.render_status_id === 'string' && options.contains_render_request) {
      let renderStatusIdUrlParams: String = options.render_status_id;
      options.render_status_id = renderStatusIdUrlParams.split(',').map((s) => Number(s));
    }
    if (options.contains_render_request && options.render_status_id instanceof Array) {
      this.renderStatuses = options.render_status_id = options.render_status_id.map(s => s * 1);
    }
    if (options.status_id instanceof Array) {
      options.status_id = options.status_id.map(s => s * 1);
    }
    if (typeof options.ia === 'string' && options.ia)
      options.ia = options.ia == 'true';

    if (options.date_from || options.date_to) {
      if (options.date_from)
        options.date_from = options.date_from * 1;
      if (options.date_to)
        options.date_to = options.date_to * 1;
    }
    if (!this.isSU) {
      options.visible = 1;
      if (this.auth.user && this.auth.user.retailers_users && this.auth.user.retailers_users.length > 1) {
        this._ignoreRetailerChanges = true;
        if (!options.retailer_id)
          options.retailer_id = [];
        if (options.retailer_id.length == 0)
          options.retailer_id.push(this.auth.user.retailers_users[this.auth.retailerIndex].retailer_id);
        else if (options.retailer_id.length == 1)
          this.auth.setRetailerIndexById(options.retailer_id[0]);
        else if (options.retailer_id.length > 1) {
          this.auth.setRetailerIndex(this.auth.getRetailerIndexById(options.retailer_id[0]));
          options.retailer_id = [this.auth.retailerIndex]
        }
        this._ignoreRetailerChanges = false;
      }
    }
    this.resourceDimensionSelected = options.contains_render_request ? this.resourceDimension.TWO : this.resourceDimension.THREE;
    // if (options.tags)
    //   options.tags = options.tags.map(e => e.toString());
    // if (options.serial_number)
    //   options.serial_number = options.serial_number.map(e => e.toString());

    return options;
  }

  public toggleEnabled(item: Product, prefix: string, state?: boolean) {
    if (typeof state === 'boolean')
      item[prefix + 'enabled'] = state ? 1 : 0;
    else
      item[prefix + 'enabled'] = item[prefix + 'enabled'] == 1 ? 0 : 1;
    this.counter++;
  }

  preRequest(item: Product): Product {
    item = this.utils.deepCopyByValue(item);
    // remove empty products_polygon_specifications
    for (var i = item.products_polygon_specifications.length - 1; i >= 0; i--) {
        if (!item.products_polygon_specifications[i].poly_type)
            item.products_polygon_specifications.splice(i, 1);
    }
    return item;
  }

  public save(item: Product, options = new ApiCallOptions()) {
    if (item.products_identifiers) {
      item.products_identifiers.forEach(identity => {
        if (identity.variation_id === '')
          identity.variation_id = null;
      });
    }
    let query = '', newProd = false;
    if (item.id)
      query = '/' + item.id;
    else {
      newProd = true;
      if (this.auth.user.retailers_users && this.auth.user.retailers_users.length > 1)
        query = `?rid=${this.auth.user.retailers_users[this.auth.retailerIndex].retailer_id}`;
      // else if (item.retailer_id)
      //   query = `?rid=${item.retailer_id}`;
    }
    if (item.products_polygon_specifications) { // remove empty products_polygon_specifications
      item = this.utils.deepCopyByValue(item);
      for (var i = 0; i < item.products_polygon_specifications.length; i++) {
        if (!item.products_polygon_specifications[i].poly_type)
          item.products_polygon_specifications.splice(i, 1);
        if (item.products_polygon_specifications[i]?.variation_name === '')
            item.products_polygon_specifications[i].variation_name = null;
      }
    }

    //set products_renders_requests = null if it empty
    for (let i = 0; i < item.products_renders_requests.length; i++) {
      if (!item.products_renders_requests[i].products_renders_requests_types.length)
        //   item.products_renders_requests[i] = null;
        item.products_renders_requests.splice(i, 1);
    }
    if (!item.products_renders_requests.length)
      item.products_renders_requests = null;

    item = this.preRequest(item);
    this.rest.product(item.id ? 'put' : 'post', item, query).subscribe({
        next: (item: Product) => {
          let data: BroadcasterNotification = {
            text: options.successMessage || new ApiCallOptions().successMessage,
            type: BroadcasterNotificationType.Success,
            action: 'OK'
          }
          this.broadcaster.broadcast('notifyUser', data);
          if (typeof options.callback === 'function') {
            options.callback(item, true);
          }
          if (newProd) // refresh retailer budget
            this.auth.refreshUserDate();
        },
        error: err => {
          const msg = this.utils.getErrorMessage(err);

          if (msg.indexOf('Product serial number already exists in product: ') > -1) {
            msg.replace('[ErrorCode ILLEGAL_USER_REQUEST] ', '');
            const productID = msg.split(': ')[1];
            const overrideNotificationTemplate = `<div>A product with at least one of those details already exists, check it
                                                    <a href='https://cms.${environment.endPoints.cmsDomain}/product/${productID}'
                                                    target='_blank'>here</a>
                                                </div>`;
            const data: BroadcasterNotification = {
              text: err._body ? err._body : options.errorMessage || err.error ? err.error : new ApiCallOptions().errorMessage,
              type: BroadcasterNotificationType.Error,
              action: 'OK',
              htmlTemplate: overrideNotificationTemplate
            };
            this.utils.notifyUser(data);
          } else {
            this.utils.httpErrorResponseHandler(err);
          }

          if (typeof options.callback === 'function')
            options.callback(err, false);
        }
      }
    );
  }

  downloadResource(resource: ProductResource, type?: Array<number>, imagesType?: Array<string>) {
    // if (type == -1)
    //   type = null;
    if (resource.resource_default) {
      if (!type && resource.resource_type === MediaTag.MODEL && (resource.viewer_resource_type === FormatsType.glTF || resource.viewer_resource_type === FormatsType.GLB))
        type = [FormatsType.GLB];
      this.rest.downloadResource(resource.id, type, imagesType);
    }
  }

  downloadResources(resources: ProductResource[], type?: number[], imagesType?: string[]) {
    const ids = resources.map(resource => resource.id);
    this.rest.downloadResources(ids, type, imagesType);
  }

  downloadSource(source: ProductsResourcesSourceFile) {
    if (source.id)
      this.rest.downloadSource(source.id);
  }

  setRetailerToCache(retailer: Retailer) {
    if (this.cachedRetailers[retailer.id])
      Object.assign(this.cachedRetailers[retailer.id], retailer);
    else
      this.cachedRetailers[retailer.id] = this.utils.deepCopyByValue(retailer);
  }

  clearRetailersCache() {
    this.cachedRetailers = {};
  }

  getRetailersCategories(): Array<Category> {
    let res = [] as Array<Category>;
    for (let i in this.cachedRetailers) {
      res = res.concat(this.cachedRetailers[i].retailers_categories)
    }
    return res;
  }

  getRetailersSubCategories(): Array<SubCategory> {
    let res = [] as Array<SubCategory>;
    for (let i in this.cachedRetailers) {
      res = res.concat(this.cachedRetailers[i].retailers_sub_categories)
    }
    return res;
  }

  async getRetailerById(rid: number): Promise<Retailer> {
    return new Promise((resolve, reject) => {
      const options = {
        id: rid
      } as RetailerFilterOptions;
      this.gql.retailerForProduct(options).subscribe(
        obj => resolve(obj.data.retailers)
      );
    });
  }

  async getRetailerByProductId(pid: number): Promise<Retailer> {
    return new Promise((resolve, reject) => {
      this.gql.product(pid).subscribe(
        async obj => resolve(await this.getRetailerById(obj.data.products.retailers[0].id))
      );
    });
  }

  async getProduct(pid: number): Promise<Product> {
    return new Promise((resolve, reject) => {
      this.gql.product(pid).subscribe(
        async obj => resolve(obj.data.products),
        err => { reject(err) }
      );
    });
  }

  getRetailerByRetailerId(rid: number): Subject<Retailer> {
    let subject = new Subject<Retailer>();
    if (!this.cachedRetailers[rid]) {
      const options = {
        id: rid
      } as RetailerFilterOptions;
      this.gql.retailerForProduct(options).subscribe(
        obj => {
          this.setRetailerToCache(obj.data.retailers);// this.cachedRetailers[rid] = this.utils.deepCopyByValue(obj.data.allRetailers.rows[0]);
          subject.next(this.cachedRetailers[rid]);
        }
      )
    }
    else {
      setTimeout(() => {
        subject.next(this.cachedRetailers[rid]);
      })
    }
    return subject;
  }

  getRetailerIdByResourceId(productId: number): number {
    let res = null;
    if (this.items) {
      this.items.forEach(p => {
        if (p.products_resources.find(pr => pr.product_id == productId)) {
          res = p.retailer_id;
          return false;
        }
      });
    }
    return res;
  }

  public createGif(resource: string, options: GifOptions) {
    if (!options)
      throw 'options parameter is mandatory!';
    if (!resource)
      throw 'resource parameter is mandatory!';

    let origin = this.utils.extractDomainFromURL(resource);
    if (resource.indexOf('//') == 0)
      origin = '//' + origin;
    else if (resource.indexOf('https') == 0)
      origin = 'https://' + origin;
    else if (resource.indexOf('http') == 0)
      origin = 'http://' + origin;
    else
      console.warn('URL for gif is:' + resource + ' and has no protocol!');

    const queryString = this.utils.fromObjectToQuerystring(options) + '&ggreferrer=' + encodeURIComponent(window.location.protocol + '//' + window.location.host) + '&gifgen=1';

    // if (!this.onAnimatedGifDoneInit)
    this.initOnAnimatedGifDone(origin);
    var iframe = document.createElement('iframe');
    // if (resource.indexOf('&theme-color=#') > -1)
    //   resource = resource.substring(0, resource.indexOf('&theme-color=#'));
    if (resource.indexOf('?') > -1)
      iframe.src = resource + '&' + queryString;
    else
      iframe.src = resource + '?' + queryString;
    iframe.id = 'create_animated_gif';
    iframe.style.zIndex = '1';
    iframe.style.opacity = '0.05';
    iframe.style.position = 'fixed';
    let height = options.ggheight ? options.ggheight : 200;
    iframe.style.width = options.ggwidth ? options.ggwidth + 'px' : '200px';
    iframe.style.height = options.ggheight ? options.ggheight + 'px' : '200px';
    iframe.style.bottom = -1 * (height - 2) + 'px';
    document.getElementsByTagName('body')[0].appendChild(iframe);
  }

  private onGifGen(msg) {
    if (msg && msg.image) {
      this.onGifReady.next(msg);
      var create_animated_gif = document.getElementById('create_animated_gif');
      create_animated_gif.parentNode.removeChild(create_animated_gif);
    }
  }

  initOnAnimatedGifDone(origin: string) {
    if (this.animatedGifOrigins[origin]) return;
    this.animatedGifOrigins[origin] = true;
    let obj = {
      actions: ['gifgen'],
      invoke: 'onGifGen',
      scope: this,
      options: {
        apply: true,
        // invokeOnce: true
      }
    } as MessageRequest;
    this.mhService.onPostMessageEvent(obj, origin);
  }

  public createPreview(resource: string, options: PreviewOptions) {
    if (!options)
      throw 'options parameter is mandatory!';
    if (!resource)
      throw 'resource parameter is mandatory!';

    let origin = this.utils.extractDomainFromURL(resource);
    if (resource.indexOf('//') == 0)
      origin = '//' + origin;
    else if (resource.indexOf('https') == 0)
      origin = 'https://' + origin;
    else if (resource.indexOf('http') == 0)
      origin = 'http://' + origin;
    else
      console.warn('URL for preview is:' + resource + ' and has no protocol!');

    const queryString = this.utils.fromObjectToQuerystring(options) + '&igreferrer=' + encodeURIComponent(window.location.protocol + '//' + window.location.host);

    this.initOnPreviewDone(origin);

    var iframe = document.createElement('iframe');
    // if (resource.indexOf('&theme-color=#') > -1)
    //   resource = resource.substring(0, resource.indexOf('&theme-color=#'));
    if (resource.indexOf('?') > -1)
      iframe.src = resource + '&' + queryString;
    else
      iframe.src = resource + '?' + queryString;
    iframe.id = 'create_images_by_tour';
    iframe.style.zIndex = '1';
    iframe.style.opacity = '0.05';
    iframe.style.position = 'fixed';
    let height = options.height ? options.height : 1000;
    iframe.style.width = options.width ? options.width + 'px' : '1000px';
    iframe.style.height = options.height ? options.height + 'px' : '1000px';
    iframe.style.bottom = -1 * (height - 2) + 'px';
    document.getElementsByTagName('body')[0].appendChild(iframe);
  }

  private onPreview(msg) {
    if (msg && msg.data && msg.data.images) {
      this.onPreviewReady.next(msg.data);
      var create_animated_gif = document.getElementById('create_images_by_tour');
      create_animated_gif.parentNode.removeChild(create_animated_gif);
    }
  }

  public updateLocalItem(src: Product) {
    if (this.items) {
      let item = this.items.find(i => i.id == src.id);
      if (item)
        Object.assign(item, src);
    }
  }

  initOnPreviewDone(origin: string) {
    if (this.previewOrigins[origin]) return;
    this.previewOrigins[origin] = true;
    let obj = {
      actions: ['create_images_by_tour'],
      invoke: 'onPreview',
      scope: this,
      options: {
        apply: true,
        // invokeOnce: true
      }
    } as MessageRequest;
    this.mhService.onPostMessageEvent(obj, origin);
  }

  private compareQueries(a: ProductFilterOptions, b: ProductFilterOptions, ignoreLO: boolean): boolean {
    a = this.utils.deepCopyByValue(a);
    b = this.utils.deepCopyByValue(b);
    if (ignoreLO) {
      delete a.limit;
      delete b.limit;
      delete a.offset;
      delete b.offset;
    }
    this.utils.removeApostropheFromArrays(a);
    this.utils.removeApostropheFromArrays(b);
    return this.utils.equals(a, b);
  }

  generateReport(columns: Array<string>) {
    let rid: number;
    const hasRetailer = this.filter.controls.retailer_id.value && this.filter.controls.retailer_id.value.length;
    if (this.isSU && hasRetailer)
      rid = this.filter.controls.retailer_id.value[0];
    else {
      rid = this.auth.user.retailers_users[this.auth.retailerIndex].retailer_id;
    }
    let query = hasRetailer || this.isSU ? '' : `?rid=${rid}`;
    let where = this.filter.getRawValue();
    for (let i in where) {
      if (typeof where[i] === 'undefined' || where[i] === null)
        delete where[i];
    }

    if (this.selectedCount > 0) {
      where.id = this.selectedItems.map((p) => p.id);
    }

    if (columns.find(i => i == 'api_calls')) {
      if (where.date_from && where.date_from.getTime)
        where.api_calls_from = where.date_from.getTime();
      if (where.date_to && where.date_to.getTime)
        where.api_calls_to = where.date_to.getTime();
    }

    if (where.date_from && typeof (where.date_from) !== "number") {
      where.date_from = where.date_from.getTime();
    }

    if (where.date_to && typeof (where.date_to) !== "number") {
      where.date_to = where.date_to.getTime();
    }

    let params = this.gql.getValues(this.gql.normalizeProduct(where));
    let payloadQuery = `{  allProducts( ${params}) { rows { id   name   created_at retailer_id }  summary    count  }}`;
    let payload = { query: payloadQuery, columns, where };
    this.rest.productReport('post', payload, query).subscribe(
      () => {
        let data: BroadcasterNotification = {
          text: `The report will be sent to ${this.auth.user.email}.`,
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
      },
      err => this.utils.httpErrorResponseHandler(err, 'Failure generating report')
    )
  }

  initTotalSelected(): void {
    this.totalSelected = this.isAllSelected ? this.count : this.selectedCount;
  }

  getWhere() {
    if (this.filter.controls.date_from.value || this.filter.controls.date_to.value) {
      if (!this.filter.controls.date_range.value)
        this.filter.controls.date_range.setValue('created_at');
      if (this.filter.controls.date_from.value)
        this.filter.controls.date_from.setValue(this.filter.controls.date_from.value * 1);
      if (this.filter.controls.date_to.value)
        this.filter.controls.date_to.setValue(this.filter.controls.date_to.value * 1);
    }
    const clean = (obj: any) => {
      for (let i in obj) {
        if (obj[i] === null || obj[i] === undefined) {
          delete obj[i];
        }
      }
      return this.gql.normalizeProduct(obj);
    };
    return this.isAllSelected ? clean(this.filter.getRawValue()) : { id: this.items.filter(i => i.UI.checked).map(i => i.id) };
  }

  saveProductMassEdit(payload: ProductMassEdit) {
    this.rest.product('put', payload).subscribe(
      () => {
        let data: BroadcasterNotification = {
          text: 'products updated successfully',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
        this.initPagination();
        this.searchByQuery(true);
        this.unselectAll();
        const onItemsChange = this.onItemsChange.subscribe(
          () => {
            onItemsChange.unsubscribe();
          }
        );
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure updating products')
    );
  }

  fixActionByType(method: ProductAuditActionMethod, text: string): string {
    switch (method) {
      case ProductAuditActionMethod.CREATE: {
        return text.replace('Edited', 'Created');
      }
      case ProductAuditActionMethod.UPDATE: {
        return text;
      }
      case ProductAuditActionMethod.DELETE: {
        return text.replace('Edited', 'Deleted');
      }
      default: {
        return text;
      }
    }
  }

  mapAudit(retailer: Retailer, productsAudit: Array<ProductAudit>, productsData: Array<ProductData>, productsAttachments?: Array<ProductsAttachment>) {
    let unitsTypes = this.enums.getDimensionsUnits();
    let types = this.enums.getProductActionsTypes();
    if (productsAudit && productsAudit.length) {
      let list: Array<ProductAuditUIGroup> = [];
      let last = null as ProductAudit;

      productsAudit.forEach(item => {

        const currentType = types.find(t => t.key == item.reference_table);
        if (!currentType) return;
        let audit = {} as ProductAuditUI;
        audit.acronym = item.users[0].firstname[0];
        audit.avatarUrl = item.users[0].avatar_url;
        audit.fullName = item.users[0].firstname;
        if (item.users[0].lastname) {
          audit.acronym += ' ' + item.users[0].lastname[0];
          audit.fullName += ' ' + item.users[0].lastname;
        }
        audit.created_at = item.created_at;
        if (currentType.key)
          audit.action = this.fixActionByType(item.action_type, currentType.value);
        else {
          let valuesForUI = true, newValue = item.new_value, oldValue = item.old_value;
          audit.action = this.fixActionByType(item.action_type, currentType.value[item.field_name]);

          switch (item.field_name) {
            case 'retailer_sub_category_id': {
              if (retailer) {
                if (newValue) {
                  const rsc = retailer.retailers_sub_categories.find(i => i.id == item.new_value);
                  if (rsc)
                    newValue = rsc.sub_category_name_org_lang;
                  else
                    console.warn('ProductService.mapAudit', `couldn't find retailer sub category with ID = ${item.new_value} for new value`);
                }
                if (oldValue) {
                  const rsc = retailer.retailers_sub_categories.find(i => i.id == item.old_value);
                  if (rsc)
                    oldValue = rsc.sub_category_name_org_lang;
                  else
                    console.warn('ProductService.mapAudit', `couldn't find retailer sub category with ID = ${item.old_value} for old value`);
                }
              }
              else {
                valuesForUI = false;
              }
              break;
            }
            case 'retailer_category_id': {
              if (retailer) {
                if (newValue) {
                  const newCat = retailer.retailers_categories.find(i => i.id == item.new_value);
                  if (newCat)
                    newValue = newCat.category_name_org_lang;
                }
                if (oldValue) {
                  const oldCat = retailer.retailers_categories.find(i => i.id == item.old_value);
                  if (oldCat)
                    oldValue = oldCat.category_name_org_lang;
                }
              }
              else {
                valuesForUI = false;
              }
              break;
            }
            case 'units': {
              if (newValue) {
                newValue = unitsTypes.find(u => u.key == item.new_value).value;
              }
              if (oldValue) {
                oldValue = unitsTypes.find(u => u.key == item.old_value).value;
              }
            }
            case 'products_mapping_naming_convention': {
              newValue = '';
              oldValue = '';
              // valuesForUI = false;
              break;
            }
            case 'products_polygon_specifications': {
              newValue = '';
              oldValue = '';
              // valuesForUI = false;
              break;
            }
           
            case 'units': {
              if (newValue) {
                newValue = unitsTypes.find(u => u.key == item.new_value).value;
              }
              if (oldValue) {
                oldValue = unitsTypes.find(u => u.key == item.old_value).value;
              }
            }
          }

          if (!audit.action) {
            const undefinedFields = ['category_id', 'sub_category_id', 'c_name', 'charged_price', 'created_at', 'ir'];
            if (!undefinedFields.find(uf => uf == item.field_name))
              console.warn(item.field_name + ' is not defined for audit!');
            return;
          }
          if (!valuesForUI) {
            audit.action = 'Changed ' + audit.action;
          }
          else if (newValue && oldValue)
            audit.action = 'Changed ' + audit.action + ' from ' + oldValue + ' to ' + newValue;
          else
            audit.action = 'Changed ' + audit.action + (newValue ? ' to ' + newValue : '');
        }

        if (item.reference_table == 'products_resources_feedback') {
          if(item.action_type == ProductAuditActionMethod.CREATE || !item.field_name){
          audit.onClickArgs = ['feedback', item.reference_id];
          if (item.field_name == 'fixed')
            audit.action = `${item.new_value == 'true' ? 'Solved' : 'Unsolved'}  a Feedback`;
          } else {            
              if (!isNaN(item.new_value)) {
                item.new_value =  this.feedbackService.getFeedbackNamebyId(1*item.new_value);
              }
              if (!isNaN(item.old_value)) {
                item.old_value =  this.feedbackService.getFeedbackNamebyId(1*item.old_value);
              } 
              audit.onClickArgs = ['feedback', item.reference_id];
              audit.action = `Changed ${item.field_name.replace('_',' ').replace('_',' ')} from ${item.old_value} to ${item.new_value}`;           
          }
        }

        if (item.reference_table == 'products_data') {
          let pdata = productsData;
          if (!productsData) {
            pdata = item.products[0].products_data;
          }

          for (let i = 0; i < pdata.length; i++) {
            if (pdata[i].id === item.reference_id) {
              audit.url = pdata[i].url;
              audit.onClickArgs = ['data', null];
            }
          }
        }

        if (productsAttachments && item.reference_table === 'products_attachments') {
          let attachedItem = productsAttachments.find(a => a.id === item.reference_id);
          if (attachedItem) {
            audit.url = attachedItem.attachment;
            audit.iconName = 'open_in_new';
            audit.onClickArgs = ['attachment', attachedItem.attachment];
          }
        }

        if (item.products) {
          audit.products = item.products;
          if (item.products instanceof Array && item.products[0])
            audit.product_id = item.products[0].id;
        }

        if (last && this.utils.sameDay(last.created_at, item.created_at)) {
          list[list.length - 1].items.push(audit);
        }
        else {
          list.push({
            created_at: item.created_at,
            id: item.id,
            items: [audit]
          });
        }
        last = item;
      });
      return list;
    }
  }



}
