import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MappingNamingConvention, RetailerMappingNamingConvention } from 'src/app/product/product';
import { Subscription } from 'rxjs';
import { MappingNamingConventionService } from 'src/app/shared/mapping-naming-convention.service';
import { RestService } from 'src/app/communication/rest.service';
import { BroadcasterService } from 'ng-broadcaster';
import { UtilsService } from 'src/app/shared/utils.service';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';

@Component({
  selector: 'app-retailer-mapping-naming-conventions',
  templateUrl: './retailer-mapping-naming-conventions.component.html',
  styleUrls: ['./retailer-mapping-naming-conventions.component.scss']
})
export class RetailerMappingNamingConventionsComponent implements OnInit, OnChanges {
  @Input() config: Array<RetailerMappingNamingConvention>;
  @Input('retailer-id') retailerId: number;
  // public requiredFormats: Array<number>;
  public allFormats: Array<MappingNamingConvention>;
  public allFormatsDictionary: { [id: number]: MappingNamingConvention; };
  private subs: Array<Subscription>;
  constructor(
    private mappingNamingConventionService: MappingNamingConventionService,
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService
  ) {
    this.subs = [];
    this.allFormats = this.mappingNamingConventionService.getCachedTypes();
    this.setDictionary();
    if (!this.allFormats) {
      this.subs.push(
        this.broadcaster.on('onMappingNamingConventions').subscribe(
          () => {
            this.allFormats = this.mappingNamingConventionService.getCachedTypes();
            this.setDefaults();
            this.setDictionary();
          }
        )
      );
    }
  }

  ngOnInit() {
    this.setDefaults();
  }

  ngOnChanges() {
    this.setDefaults();
  }

  setDefaults() {
    if (!this.config)
      this.config = [];
    else
      this.config = this.utils.deepCopyByValue(this.config);
    if (this.allFormats instanceof Array) {
      this.allFormats.forEach(f => {
        let currentMap = this.config.find(i => i.mapping_id == f.id);
        if (!currentMap)
          this.config.push(
            {
              mapping_id: f.id,
              retailer_id: this.retailerId,
              mapping_value: ''
            } as RetailerMappingNamingConvention
          );
        // this.allFormatsDictionary[f.id] = f;
      });
    }
    // this.requiredFormats = this.config.map(i => i.id);
  }

  setDictionary() {
    this.allFormatsDictionary = {};
    if (this.allFormats instanceof Array) {
      this.allFormats.forEach(f => this.allFormatsDictionary[f.id] = f);
    }
  }

  save() {
    let config = [] as Array<RetailerMappingNamingConvention>;
    this.config.forEach(f => {
      if (f.mapping_value)
        config.push(f);
    });

    this.rest.retailerMapping('put', config, '?rid=' + this.retailerId).subscribe(
      data => {
        this.config = data as Array<RetailerMappingNamingConvention>;
        this.setDefaults();
        let res: BroadcasterNotification = {
          text: 'mapping naming conventions updated successfully',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', res);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure updating mapping naming conventions')
    );
  }

  ngOnDestroy() {
    if (this.subs.length)
      this.subs.forEach(s => s.unsubscribe());
  }
}
