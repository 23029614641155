import { Component, OnInit, Input } from '@angular/core';
import { Retailer, RetailersBudget } from 'src/app/retailer/retailer';
import { RestService } from 'src/app/communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-retailer-budget',
  templateUrl: './retailer-budget.component.html',
  styleUrls: ['./retailer-budget.component.scss']
})
export class RetailerBudgetComponent implements OnInit {
  @Input() retailer: Retailer;
  constructor(
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
  }

  addBudget() {
    const query = '/' + this.retailer.retailers_budget[0].id;
    this.rest.retailerBudget('put', this.retailer.retailers_budget[0], query).subscribe(
      (data: RetailersBudget) => {
        this.retailer.retailers_budget[0] = data;
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure updating budget')
    );
  }
}
