<mat-form-field fxFill [ngClass]="{'contrast': isContrastStyle}" class="no-padd">
  <mat-label>{{batchesDropdownPlaceholder}}</mat-label>
  <mat-select [multiple]="multiple" [compareWith]="compareObjects"
    [(ngModel)]="selectedbatches" [required]="required" (selectionChange)="setSelected()">
    <mat-select-trigger>
      {{selectedbatches.length> 0 ? selectedbatches[0]?.displayName : ''}}
      <span *ngIf="selectedbatches?.length > 1">
        (+{{selectedbatches.length - 1}} {{selectedbatches.length === 2 ? 'other' : 'others'}})
      </span>
    </mat-select-trigger>
    <mat-option *ngFor="let b of retailerBatches" [value]="b" [disabled]="disabled" [matTooltip]="b.displayName" matTooltipPosition="above" showIfTruncated>
      {{b.displayName}}
    </mat-option>
  </mat-select>
</mat-form-field>
