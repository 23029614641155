<div class="canvas-paint wrap" [ngClass]="{'text': state === WRITE}">

    <!-- <button mat-raised-button color="primary" class="center-me" (click)="active = !active">{{active ? 'done' : 'edit image'}}</button> -->

    <div fxLayout="row" fxLayoutAlign="center center" class="text-center">
        <div fxFlex="70px">
            <button type="button" mat-icon-button (click)="undo()" [disabled]="events.length == 0">
                <mat-icon>undo</mat-icon>
            </button>
        </div>
        <div fxFlex="70px">
            <button type="button" mat-icon-button (click)="redo()" [disabled]="eventsHistory.length == 0">
                <mat-icon>redo</mat-icon>
            </button>
        </div>
        <div fxFlex="70px">
            <button type="button" mat-icon-button (click)="clearAll()">
                <i class="icon-button-small icon-eraser"></i>
                <!-- <mat-icon>clear_all</mat-icon> -->
            </button>
        </div>
        <div fxFlex="70px">
            <button type="button" mat-icon-button (click)="text()" [ngClass]="{'primary': state == 3}">
                <i class="icon-button-small icon-text"></i>
                <!-- <mat-icon>clear_all</mat-icon> -->
            </button>
        </div>
        <div fxFlex="70px">
            <button type="button" mat-icon-button (click)="toggleLine()" [ngClass]="{'primary': state == 2}">
                <mat-icon>arrow_right_alt</mat-icon>
            </button>
        </div>
        <div fxFlex="70px">
            <button mat-icon-button [matMenuTriggerFor]="menu" type="button">
                <mat-icon>line_weight</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="lineWidth = 1" [ngClass]="{'primary': lineWidth == 1}">
                    <span>thinnest</span>
                </button>
                <button mat-menu-item (click)="lineWidth = 3" [ngClass]="{'primary': lineWidth == 3}">
                    <span>thin</span>
                </button>
                <button mat-menu-item (click)="lineWidth = 5" [ngClass]="{'primary': lineWidth == 5}">
                    <span>normal</span>
                </button>
                <button mat-menu-item (click)="lineWidth = 7" [ngClass]="{'primary': lineWidth == 7}">
                    <span>thick</span>
                </button>
                <button mat-menu-item (click)="lineWidth = 9" [ngClass]="{'primary': lineWidth == 9}">
                    <span>thickest</span>
                </button>
            </mat-menu>
        </div>
        <div fxFlex="70px">
            <span class="color-wrap">
                <input type="color" [(ngModel)]="color">
            </span>
        </div>
        <div fxFlex="70px">
            <button type="button" mat-icon-button (click)="addVerticalLine()" [ngClass]="{'primary-color': state == 4}">
                <mat-icon>swap_vert</mat-icon>
            </button>
        </div>
        <div fxFlex="70px">
            <button type="button" mat-icon-button (click)="addHorizontalLine()"
                [ngClass]="{'primary-color': state == 5}">
                <mat-icon>swap_horiz</mat-icon>
            </button>
        </div>
    </div>

    <textarea placeholder="write something & click to place your text" #textinput [(ngModel)]="textToAdd"
        name="text_on_image" class="text-input"></textarea>

    <canvas #canvas></canvas>
</div>