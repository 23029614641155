<div fxLayout="row" fxLayoutAlign="space-around center">
  <div fxFlex="30" class="pos-rel">
    <span *ngIf="retailer" class="retailer-name pos-abs">
      {{retailer.name}}
    </span>
    <app-retailers-autocomplete [placeholder]="'search for retailers'" class="disp-block" [clear-on-select]="true"
      (retailer-change)="onRetailerChange($event)"></app-retailers-autocomplete>
  </div>
  <div fxFlex="30">
    <mat-form-field fxFill>
      <input matInput type="number" placeholder="Product ID" [(ngModel)]="pid">
    </mat-form-field>
  </div>
  <div fxFlex="30">
    <button [disabled]="(!retailer || !productId) && !pid" mat-raised-button color="accent" class="third_button min-button-width thinner"
      (click)="duplicate()">duplicate</button>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="start center" *ngIf="prService.request.source_pid">
   <a class="link-image" target="_blank" [routerLink]="['/product', prService.request.source_pid]"><span class="material-icons">
    copy_all
    </span>This product request is a clone of {{prService.request.source_pid}}</a>
</div>