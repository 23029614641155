<div>
  <mat-form-field fxFill>
    <mat-label>IP</mat-label>
    <mat-select [(ngModel)]="ip" name="ip" (selectionChange)="selectionChange.emit($event.value)">
      <mat-option *ngFor="let ip of ipOptions" [value]="ip.value">
        {{ ip.key }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
