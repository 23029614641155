
export interface BroadcasterNotification {
    text?: string;
    htmlTemplate?: string;
    action?: string;
    type: BroadcasterNotificationType;
    callback?: Function;
    scope?: any;
    duration?: number;
    autoDismiss?: boolean;
}

export enum  BroadcasterNotificationType {
    Error,
    Success,
    Info,
}
