<div class="wrap box-shadow content-va" *ngFor="let item of items" [ngClass]="{'has-specs': item.retailers_sub_categories_specifications.length}">
  <div class="items">
    <mat-form-field>
      <mat-select placeholder="Retailer Category" [(ngModel)]="item.category_id">
        <mat-option *ngFor="let rc of retailerCategories" [value]="rc.id">
          {{rc.category_name_org_lang}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Retailer Sub Category Name" [(ngModel)]="item.sub_category_name_org_lang">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Estimated Traffic" [(ngModel)]="item.estimated_traffic" type="number">
    </mat-form-field>
    <mat-form-field>
      <mat-select (selectionChange)="onProductCategoryChange(item)" placeholder="Product Category"
        [(ngModel)]="item.productCategoryId">
        <mat-option *ngFor="let pc of pCategories" [value]="pc.id">
          {{pc.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Product Sub Category" [(ngModel)]="item.product_sub_category_id"
        [disabled]="!item.productsSubCategories">
        <mat-option *ngFor="let psc of item.productsSubCategories" [value]="psc.id">
          {{psc.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngFor="let s of item.retailers_sub_categories_specifications; let i = index" class="specs">
    <mat-form-field class="disp-block">
      <input matInput placeholder="attachment title" [(ngModel)]="s.title">
    </mat-form-field>
    <mat-form-field class="disp-block">
      <input matInput placeholder="sort index" [(ngModel)]="s.sort_index" type="number">
    </mat-form-field>
    <mat-form-field *ngIf="jobTypes">
      <mat-select placeholder="job type" [(ngModel)]="s.job_type">
        <mat-option *ngFor="let t of jobTypes" [value]="t.id">
          {{ t.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <a class="disp-block" target="_blank" [href]="s.attachment" *ngIf="s.attachment">
      attachment
    </a>
    <app-file-upload [icon]="'attach_file'" [drop-mode]="true" [text]="'add attachment'" [miltiple]="false"
      (filesChange)="onFilesChange($event, s)" class="dashed disp-block marg-top w-100">
    </app-file-upload>
    <app-text-editor [click-img]="true" [show-html]="true" [show-asset]="true" (textChange)="onTextChange(s, $event)" placeholder="specifications" [init-value]="s.text"></app-text-editor>
    <button mat-fab aria-label="delete specifications" (click)="deleteSpec(item, i)" color="warn" matTooltip="delete attachment">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <button mat-fab aria-label="add specifications" (click)="addSpec(item)" matTooltip="add an attachment">
    <mat-icon>add</mat-icon>
  </button>
  <button mat-button aria-label="save" (click)="save(item)" [disabled]="uploading">
    save
  </button>
</div>
<button mat-fab aria-label="Add a new sub-category" (click)="add()">
  <mat-icon>add</mat-icon>
</button>
