import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UserQueryData } from '../user';
import { GraphqlService } from 'src/app/communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client/core';

@Injectable()
export class UserResolve  {

    constructor(
        // private rest: RestService
        private gql: GraphqlService
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (!route.params['uid']) return null;
        return this.gql.user(route.params['uid'] * 1);
        // let query = '?uid=' + route.params['uid'] as string;
        // return this.rest.userProfile('get', null, query) as Observable<User>;
    }
}