import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app.routing';
import { AuthModule } from 'src/app/auth/auth.module';
import { HeaderModule } from './header/header.module';
import { CommunicationModule } from 'src/app/communication/communication.module';
import { ProductModule } from './product/product.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ScraperModule } from 'src/app/scraper/scraper.module';
import { RetailerModule } from './retailer/retailer.module';
import { NotificationsService } from './communication/notifications.service';
import { DashboardModule } from './dashboard/dashboard.module';
import { AssetAdjustmentsModule } from './asset-adjustments/asset-adjustments.module';
import { ConvertorModule } from './convertor/convertor.module';
import { AuthInterceptor } from './http-interceptors/auth-interceptor';
import { StorageService } from 'ng-storage-service';
import { Meta } from '@angular/platform-browser';
import { HttpLink } from 'apollo-angular/http';
import { EndpointsService } from './communication/endpoints.service';
import { EndPoints } from './communication/endpoints';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache, createHttpLink, ApolloLink, DefaultOptions } from '@apollo/client/core';
@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
        BrowserAnimationsModule,
        SharedModule,
        AppRoutingModule,
        AuthModule,
        HeaderModule,
        CommunicationModule,
        ProductModule, // provides HttpClient for HttpLink
        ApolloModule,
        ScraperModule,
        RetailerModule,
        DashboardModule,
        AssetAdjustmentsModule,
        ConvertorModule], providers: [NotificationsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
            deps: [StorageService]
        },
        {
            provide: APOLLO_OPTIONS,
            useFactory: (httpLink: HttpLink) => {
                let endpoints = new EndpointsService();
                let endpoint = endpoints.getEndpointDomain(EndPoints.GRAPH);
                let storage = new StorageService();
                const errorLink = onError(({ graphQLErrors, networkError }) => {
                    if (graphQLErrors)
                        graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
                    if (networkError) {
                        if (networkError.toString().indexOf('code 401') > -1) {
                            storage.remove('user');
                            storage.remove('token');
                            window.location.pathname = 'login';
                        }
                    }
                });
                const auth = new ApolloLink((operation: any, forward: any) => {
                    const token = storage.get('token');
                    operation.setContext({
                        headers: {
                            Authorization: token ?? ''
                        }
                    });
                    return forward(operation);
                });
                const hLink = createHttpLink({
                    uri: endpoint
                });
                const defaultOptions = {
                    watchQuery: {
                        fetchPolicy: 'network-only',
                        errorPolicy: 'ignore',
                    },
                    query: {
                        fetchPolicy: 'network-only',
                        errorPolicy: 'all',
                    },
                } as DefaultOptions;
                return {
                    cache: new InMemoryCache(),
                    link: ApolloLink.from([errorLink, auth, hLink]),
                    defaultOptions: defaultOptions
                };
            },
            deps: [HttpLink],
        }, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {
  constructor(private meta: Meta) { 
    this.meta.addTag({ httpEquiv: 'Content-Security-Policy', content: environment.csp });
  }
}
