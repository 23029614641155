import { Injectable } from '@angular/core';
import { BroadcasterService } from 'ng-broadcaster';
import { BroadcasterNotification, BroadcasterNotificationType } from '../communication/broadcaster-notifications';
import { FormattedDim, ScriptsFetcher, ScriptsFetcherType } from './utils';
import { ProductResource, MediaTag } from '../product/product';
import { DecimalPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { FormatsType } from './enums';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RenderingEngine } from 'asset-adjustments';
import { RestService } from '../communication/rest.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private _scriptsFetcher: { [id: number]: ScriptsFetcher };
  constructor(
    private broadcaster?: BroadcasterService,
    private decimalPipe?: DecimalPipe,
    private router?: Router,
    private rest?: RestService
  ) {
    this._scriptsFetcher = {};
  }

  public copyClipboard(str: string, notify = true): boolean {
    let container = document.createElement('input');
    container.className = 'copy-clipboard';
    container.style.position = 'fixed';
    container.style.top = '0';
    container.style.left = '0';
    container.style.opacity = '0.05';
    container.value = str;
    document.body.appendChild(container);

    container.select();

    try {
      var successful = document.execCommand('copy');
      if (notify) {
        let data: BroadcasterNotification = {
          text: 'copied to clipboard',
          type: BroadcasterNotificationType.Info,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
      }
      else {
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was: "' + msg + '"');
      }
    } catch (err) {
      console.log('unable to copy clipboard');
    }
    document.body.removeChild(container);
    return successful;
  }

  public notifyUser(b: BroadcasterNotification) {
    this.broadcaster.broadcast('notifyUser', b);
  }

  public parseToArr(val, returnDefault = false): Array<any> {
    let res = [];
    if (typeof val === 'string' && val) {
      try {
        res = JSON.parse(val);
        if (!returnDefault && res.length == 0)
          res = null;
      } catch (e) {
        res = val.split(',');
      }
    }
    if (!(res instanceof Array)) {
      res = [res];
    }
    return res;
  }

  public getValueAsString(val: any, seperator = ','): string {
    switch (typeof val) {
      case 'undefined': {
        return '';
      }
      case 'object': {
        if (val instanceof Array)
          return '[' + val.toString().replace(/,/g, seperator) + ']';
        else {
          if (val === null) return '';
          return '[' + val.toString() + ']';
        }
      }
      default: {
        return String(val);
      }
    }
  }

  public equals(x: any, y: any) {
    if (x === y) return true;
    // if both x and y are null or undefined and exactly the same

    if (!(x instanceof Object) || !(y instanceof Object)) return false;
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (var p in x) {
      if (!x.hasOwnProperty(p)) continue;
      // other properties were tested using x.constructor === y.constructor

      if (!y.hasOwnProperty(p)) return false;
      // allows to compare x[ p ] and y[ p ] when set to undefined

      if (x[p] === y[p]) continue;
      // if they have the same strict value or identity then they are equal

      if (typeof (x[p]) !== "object") return false;
      // Numbers, Strings, Functions, Booleans must be strictly equal

      if (!this.equals(x[p], y[p])) return false;
      // Objects and Arrays must be tested recursively
    }

    for (p in y) {
      if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
      // allows x[ p ] to be set to undefined
    }
    return true;
  }

  public deepCopyByValue(src: any): any {
    try {
      return JSON.parse(JSON.stringify(src));
    } catch (e) {
      return null;
    }
  }

  public fixImages(html: string): string {
    try {
      let div = document.createElement('div');
      div.innerHTML = html;
      return div.innerHTML.replace(/"\\&quot;/g, "'").replace(/\\&quot;"/g, "'");
    }
    catch (e) {
      return html;
    }
  }

  public stripScripts(s) {
    var div = document.createElement('div');
    div.innerHTML = s;
    var scripts = div.getElementsByTagName('script');
    var i = scripts.length;
    while (i--) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }
    return div.innerHTML;
  }

  public getFormattedDim(value: string): FormattedDim {
    if (!value) return null;

    value = String(value);

    var returnBysuffix = (val, suffix): FormattedDim => {
      return {
        size: parseFloat(val.substring(0, val.indexOf(suffix))),
        suffix: suffix
      }
    }

    if (value.indexOf('%') > -1)
      return returnBysuffix(value, '%');
    if (value.indexOf('px') > -1)
      return returnBysuffix(value, 'px');
    if (value.indexOf('em') > -1)
      return returnBysuffix(value, 'em');
    if (value.indexOf('rem') > -1)
      return returnBysuffix(value, 'rem');
    if (value.indexOf('pt') > -1)
      return returnBysuffix(value, 'pt');
    if (value == 'auto')
      return returnBysuffix(value, '');
  }

  public sameDay(d1: Date, d2: Date) {
    if (typeof d1 === 'string')
      d1 = new Date(d1);
    if (typeof d2 === 'string')
      d2 = new Date(d2);
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  public getFileForDownload(path: string, filename?: string) {
    try {
      if (!filename) {
        const arr = path.split('/');
        filename = arr[arr.length - 1];
      }
      let first = '?';
      if (path.indexOf('?') > -1)
        first = '&';
      return path + `${first}disposition=attachment;filename=${filename}`
    } catch (e) { console.warn(e); }
    return path;
  }

  multipleDownloads(urls: Array<string>, name?: string) {
    let counter = 0;
    urls.forEach(url => {
      var link = document.createElement("a");
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', name ? counter ? name + `_${counter}` : name : '');
      link.href = url;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.remove();
      // let iframe = document.createElement('iframe');
      // iframe.style.visibility = 'collapse';
      // document.body.append(iframe);
      // setTimeout(() => iframe.remove(), 2000);

      // iframe.contentDocument.write(
      //   `<form action="${url.replace(/\"/g, '"')}" method="GET"></form>`
      // );
      // iframe.contentDocument.forms[0].submit();
    });
  }

  public fromObjectToQuerystring(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  public extractDomainFromURL(url: string) {
    var a = document.createElement('a'); a.href = url; return a.hostname;
  }

  public getFileExtension(filename) {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : filename;
  }

  public getParamFromURL(urlString, param): string {
    const url = new URL(urlString);
    return url.searchParams.get(param);
  }

  async loadScript(url: string, successCB?: Function) {
    return new Promise((resolve: Function, reject: Function) => {
      let script = document.createElement('script');
      script.src = url;
      script.type = 'text/javascript';
      script.addEventListener('load', () => {
        resolve();
        if (successCB)
          successCB();
      }, false);
      document.getElementsByTagName('body')[0].appendChild(script);
    });
  }

  public closest(elem: Element, selector: string) {
    if (selector.indexOf('.') == 0) {
      if (elem.classList.contains(selector.substring(1)))
        return elem;
    }
    if (selector.indexOf('#') == 0) {
      if (elem.getAttribute('id') == selector.substring(1))
        return elem;
    }
    if (elem.tagName && elem.tagName.toLowerCase() == selector.toLowerCase())
      return elem;
    if (elem.parentElement)
      return this.closest(elem.parentElement, selector);
    return null;
  }

  public getTextContent(html: string) {
    var div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent;
  }

  public generateUUID() {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
      d += performance.now(); //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  public setUrlParam(url: string, name: string, value: string): string {
    if (!url) return url;
    if (url.indexOf('?') == -1) return `${url}?${name}=${value}`;
    let paramsString = url.substring(url.indexOf('?') + 1);
    let searchParams = new URLSearchParams(paramsString);
    if (value)
      searchParams.set(name, value);
    else
      searchParams.delete(name);
    return url.substring(0, url.indexOf('?') + 1) + searchParams.toString();
  }

  public getUrlParam(url: string, name: string): string {
    if (!url || url.indexOf('?') == -1) return null;
    let paramsString = url.substring(url.indexOf('?') + 1);
    let searchParams = new URLSearchParams(paramsString);
    return searchParams.get(name);
  }

  public getIconByUrl(url: string) {
    var icon = 'insert_drive_file';
    if (url) {
      ['.png', '.jpg', '.jpeg', '.jfif', '.exif', '.tiff', '.gif', '.bpm', '.ppm', '.pgm', '.pbm', '.pnm', '.svg'].forEach((ext) => {
        if (url.toLowerCase().indexOf(ext) > -1) {
          icon = 'add_a_photo';
          return false;
        }
      });
    }
    return icon;
  }

  public removeApostropheFromArrays(a: any) {
    Object.keys(a).forEach(i => {
      if (a[i] instanceof Array && typeof a[i][0] === 'string')
        for (let j = 0; j < a[i].length; j++) {
          a[i][j] = a[i][j].replace(/"/g, '');
        }
    });
  }

  public getObjectLength(obj: any): number {
    if (!obj) return 0;
    return Object.keys(obj).length;
  }

  public arraysEqual(arr1: Array<any>, arr2: Array<any>) {
    if (arr1.length !== arr2.length)
      return false;
    for (var i = arr1.length; i--;) {
      if (arr1[i] !== arr2[i])
        return false;
    }
    return true;
  }

  public getMediaTagByResource(resource: ProductResource): MediaTag {
    let mediaTag = MediaTag.MODEL;
    if (resource) {
      if (resource.viewer_resource_type == FormatsType.PNG
        || resource.viewer_resource_type == FormatsType.JPG
        || resource.viewer_resource_type == FormatsType.TIFF)
        mediaTag = MediaTag.IMAGE;
      else if (resource.viewer_resource_type == 11) {
        if (resource.resource_big || resource.resource_small)
          mediaTag = MediaTag.IFRAME_RENDR;
        else
          mediaTag = MediaTag.VIDEO;
      }
    }
    return mediaTag;
  }

  getVATByCountryCode(cc: string): number {
    if (cc && cc.toLowerCase() == 'il')
      return 1.17;
    return 1
  }

  isEnter(charCode: number): boolean {
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1)
      return charCode === 0;
    return charCode == 13;
  }

  floatinPrecision(num: number, precision: number): number {
    return parseFloat(this.decimalPipe.transform(num, `1.0-${precision}`).replace(/,/g, '').replace(/[^\d.-]/g, ''));
  }

  getErrorMessage(err: HttpErrorResponse, fallbackMsg = '') {
    let msg = err && err.error ? err.error : fallbackMsg;
    if (msg && msg.error && msg.error.message)
      msg = msg.error.message;
    if (msg && msg.indexOf && msg.indexOf('Reason: ') == 0) {
      msg = msg.substring(8);
    }
    return msg;
  }

  httpErrorResponseHandler(err: HttpErrorResponse, fallbackMsg?: string, duration?: number) {
    let data: BroadcasterNotification = {
      text: this.getErrorMessage(err, fallbackMsg),
      type: BroadcasterNotificationType.Error,
      action: 'OK',
      duration: duration
    }
    this.broadcaster.broadcast('notifyUser', data);
  }

  getTotalImagesSize(files: { [id: string]: string; }, imagesSuffix = ['.png', '.jpg', '.jpeg'], ignoreFor = []): number {
    let size = 0;

    const isInIgnore = (name: string) => {
      for (let i = 0; i < ignoreFor.length; i++) {
        if (name.indexOf(ignoreFor[i]) > -1)
          return true;
      }
      return false;
    }

    for (let i in files) {
      imagesSuffix.forEach(s => {
        if (i.toLowerCase().indexOf(s) > -1 && !isInIgnore(i)) {
          let fileSize = this.getFilsSizeInMiB(files[i]);
          if (!isNaN(fileSize * 1))
            size += fileSize;
        }
      });
    }
    return size;
  }

  getFilsSizeInMiB(fileDesc: string) {
    let fileArr = fileDesc.split(' ');
    let fileSize = parseFloat(fileArr[0]);
    if (!isNaN(fileSize * 1)) {
      if (fileArr[1] == 'KiB')
        fileSize /= 1024;
      else if (fileArr[1] == 'B')
        fileSize /= (1024 * 1024);
      fileSize;
    }
    return fileSize;
  }

  convertBytesToMb(bytes: number): number {
    return bytes / Math.pow(1024, 2);
  }

  public getFileNameExtension(fileName: string): string {
    let res = fileName.substring(fileName.lastIndexOf('.') + 1);
    if (res.indexOf('&') > -1)
      res = res.substring(0, res.indexOf('&'));
    return res;
  }

  public getFileName(fileName: string): string {
    return fileName.substring(0, fileName.lastIndexOf('.'));
  }

  public base64toFile(dataurl: string, filename: string): File {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  public base64ToArrayBuffer(base64: string): any {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public getSafeDate(date: any): Date {
    if (date instanceof Date) return date;
    if (typeof date === 'string') {
      return new Date(date);
    }
    return null;
  }

  sortByDate(arr: Array<any>, field: string, isAsc: boolean = true): Array<any> {
    if (!arr) return arr;
    arr.sort((a: any, b: any) => {
      if (isAsc)
        return this.getSafeDate(a[field]).getTime() - this.getSafeDate(b[field]).getTime();
      else
        return - this.getSafeDate(a[field]).getTime() + this.getSafeDate(b[field]).getTime();
    });
    return arr;
  }

  getResourceJsonParams(params: any, targetResourceType: FormatsType) {
    if (params) {
      params.scene = params.scene || {};
      if (params && params.materialManipulations) {
        params.scene.materialManipulations = params.materialManipulations;
        delete params.materialManipulations;
      }
      params.scene.materialManipulations = this.getCleanMaterialManipulations(params.scene.materialManipulations, targetResourceType);
    }
    return params;
  }

  getCleanMaterialManipulations(materialManipulations: any, targetResourceType: FormatsType): any {
    // in case we want to save a glTF file we don't need those manipulations, the format supports those natively
    if ((targetResourceType == FormatsType.GLB || targetResourceType == FormatsType.glTF) && materialManipulations) {
      for (let i in materialManipulations) {
        for (let j in materialManipulations[i]) {
          // if (j !== 'color' && j !== 'alphaTest' && j !== 'transparent' && j !== 'castShadow' && j !== 'receiveShadow')
          if (j !== 'castShadow' && j !== 'receiveShadow' && j !== 'reflectivity' && j !== 'type' && j !== 'clearCoat' && j !== 'clearCoatRoughness')
            delete materialManipulations[i][j];
          // delete params.materialManipulations[i].color;
          // delete params.materialManipulations[i].alphaTest;
          // delete params.materialManipulations[i].transparent;
        }

      }
    }
    return materialManipulations;
  }

  cancelEvent(e?: Event): boolean {
    if (!e)
      e = window.event;
    try {
      e.preventDefault();
    } catch (e) { }
    try {
      e.stopPropagation();
    } catch (e) { }
    try {
      e.stopImmediatePropagation();
    } catch (e) { }
    return false;
  }

  isProd(): boolean {
    return window.location.hostname == 'cms.hexa3d.io';
  }

  isQA(): boolean {
    return window.location.hostname == 'cms.h3dstaging.com';
  }

  isDev(): boolean {
    return window.location.hostname == 'cms.h3ddevelopment.com';
  }

  isLocal(): boolean {
    return window.location.hostname == 'localhost' || window.location.hostname == '127.0.0.1';
  }

  round(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  public forceRedirectTo(url: string) {
    this.router.navigateByUrl('/blank', { skipLocationChange: true }).then(() =>
      this.router.navigateByUrl(url));
  }

  async fetchScript(src: string, type: ScriptsFetcherType) {
    return new Promise(async (resolve, reject) => {
      if (!this._scriptsFetcher[type]) {
        this._scriptsFetcher[type] = {
          loaded: false,
          promise: [resolve],
          type
        };
        await this.loadScript(src);
        this._scriptsFetcher[type].loaded = true;
        this._scriptsFetcher[type].promise.forEach(f => f());
        return;
      }
      if (this._scriptsFetcher[type].loaded) {
        resolve(null);
        return;
      }
      this._scriptsFetcher[type].promise.push(resolve);
    });
  }

  async fetchCss(src: string, type: ScriptsFetcherType) {
    return new Promise(async (resolve, reject) => {
      if (!this._scriptsFetcher[type]) {
        this._scriptsFetcher[type] = {
          loaded: false,
          promise: [resolve],
          type
        };
        await this.loadStylesheet(src);
        this._scriptsFetcher[type].loaded = true;
        this._scriptsFetcher[type].promise.forEach(f => f());
        return;
      }
      if (this._scriptsFetcher[type].loaded) {
        resolve(null);
        return;
      }
      this._scriptsFetcher[type].promise.push(resolve);
    });
  }

  async loadStylesheet(url: string) {
    return new Promise((resolve: Function, reject: Function) => {
      var head = document.getElementsByTagName('head')[0];
      var link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = url;
      link.media = 'all';
      head.appendChild(link);
      var img = new Image();
      img.onerror = () => {
        resolve(null);
      };
      img.onload = () => {
        resolve(null);
      };
      img.src = url;
    });
  }

  async observableToPromise(o: Observable<any>): Promise<any> {
    return new Promise((resolve: Function, reject: Function) => {
      o.subscribe(res => resolve(res), err => reject(err));
    });
  }

  isValidHttpUrl(text: string): boolean {
    let url: URL;
    try {
      url = new URL(text);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  isMobile = {
    Android: () => {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: () => {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: () => {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: () => {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: () => {
      return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: () => {
      return (this.isMobile.Android() || this.isMobile.BlackBerry() || this.isMobile.iOS() || this.isMobile.Opera() || this.isMobile.Windows());
    }
  }
  deleteHtmlTags(val) {
    if (val)
      return val.replace(/<\/?[^>]+(>|$)/g, "");
    return null;
  }

  async setTimeout(ms?: number) {
    return new Promise((resolve: any, reject: any) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }

  toUtcEpoch(et, isStartOfDay) {
    let date = new Date(et);
    if (isStartOfDay)
      date.setHours(0, 0, 0, 0);
    else
      date.setHours(23, 59, 59, 999);
    let utcDate = Date.UTC(date.getFullYear(), date.getMonth(),
      date.getDate(), date.getHours(),
      date.getMinutes(), date.getSeconds());
    return utcDate;

  }

  getRenderingEngine(url: string): RenderingEngine {
    return parseInt(this.getUrlParam(url, 'engine') || '1') as RenderingEngine;
  }

  public dataURLtoFile(dataurl: string, filename: string) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  async convertToJpeg(base64: string, encoderOptions = 0.92): Promise<string> {
    return new Promise((resolve: Function, reject: Function) => {
      if (base64.indexOf('data:image/jpeg;base64') === 0 || base64.indexOf('data:image/jpg;base64') === 0)
        resolve(base64);
      else {
        const length = base64.length;
        let c = document.createElement('canvas');
        const ctx = c.getContext('2d');
        const img = new Image();
        img.onload = () => {
          c.width = img.width;
          c.height = img.height;
          ctx.fillStyle = '#fff';  /// set white fill style
          ctx.fillRect(0, 0, c.width, c.height);
          ctx.drawImage(img, 0, 0);
          document.body.appendChild(c);
          base64 = c.toDataURL('image/jpeg', encoderOptions);
          resolve(base64);
          c.parentElement.removeChild(c);
        };
        img.src = base64;
        c.style.position = 'absolute';
        c.style.top = '0';
        c.style.left = '0';
        c.style.opacity = '0.01';
        c.style.zIndex = '99999999';
      }
    });
  }

  tryParse(s: string) {
    let res = null;
    try {
      res = JSON.parse(s);
    } catch (e) { }
    return res;
  }

  async uploadFile(file: File, compress: boolean): Promise<string> {
    return new Promise((resolve: Function, reject: Function) => {
      const fd = new FormData();
      fd.append('file', file);
      if (compress)
        fd.append('compress', 'true');
      this.rest.afterCdn('post', fd).subscribe(
        data => resolve(data.url),
        err => reject(err)
      );
    });
  }

  async getFileSizeByURL(url: string): Promise<number> {
    const response = await fetch(url);
    return parseInt(response.headers.get('Content-Length')) / 1024 / 1024;
  }

  async isEmptyImage(url: string): Promise<boolean> {
    return await this.getFileSizeByURL(url) < 0.01;
  }
}
