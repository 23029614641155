import { Injectable } from '@angular/core';
import * as EventBus from '@vertx/eventbus-bridge-client.js';
import { Subscription, Subject } from 'rxjs';
import { CMSNotification, CMSNotificationListResponse, CMSNotificationsType } from './notifications';
import { StorageService } from 'ng-storage-service';
import { AuthService } from '../auth/auth.service';
import { BroadcasterService } from 'ng-broadcaster';
import { SwService } from '../sw-helper/sw.service';
import { RolesManagerService } from 'ng-role-based-access-control';
import { RolesHelperService } from '../auth/roles-helper.service';
import { User } from '../auth/user';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsWebsocketService {
  private eventBus: any;
  private onLogin: Subscription;
  private onLogout: Subscription;
  private connetionLossCount: number;
  private connetionLossNotify: boolean;
  private onRolesFetched: Subscription;
  public onStateChange: Subject<boolean>;
  public onAnnouncement: Subject<CMSNotification>;
  public onAnnouncements: Subject<CMSNotificationListResponse>;
  public onRead: Subject<number>;
  public isOpen: boolean;
  constructor(
    private storage: StorageService,
    private auth: AuthService,
    private broadcaster: BroadcasterService,
    // private textToSpeech: TextToSpeechService,
    private swService: SwService,
    private roles: RolesManagerService,
    private rolesHelper: RolesHelperService
  ) {
    this.isOpen = false;
    this.onAnnouncement = new Subject<CMSNotification>();
    this.onAnnouncements = new Subject<CMSNotificationListResponse>();
    this.onRead = new Subject<number>();
    this.connetionLossCount = 0;
    this.connetionLossNotify = false;
    // if (this.auth.isloggedIn())
    //   this.connect();
    this.onLogin = this.broadcaster.on<User>('onLogin').subscribe(
      user => this.connect()
    );
    this.onLogout = this.broadcaster.on<User>('onLogout').subscribe(
      user => this.disconnect()
    );
    this.onStateChange = new Subject<boolean>();
    this.onRolesFetched = this.rolesHelper.onRolesFetched.subscribe(
      data => this.auth.refreshUserDate()
    );
  }

  // public fetch(limit: number, offset: number, types = [CMSNotificationsType.FEEDBACK_RECEIVED, CreatorsNotificationType.JOB_ASSIGNED, CreatorsNotificationType.CUSTOM_BROADCAST]) {
  //   if (!this.auth.isloggedIn()) return;
  //   let obj = {
  //     limit: limit,
  //     offset: offset,
  //     types: types
  //   }
  //   this.eventBus.send('com.hexa.notifications.server.fetch', obj, {}, (err, response) => {
  //     if (err || response.body.code == 500)
  //       console.warn(response.body.error);
  //     else
  //       this.onAnnouncements.next(response.body);
  //   });
  // }

  public read(notificationId: number) {
    let obj = {
      id: notificationId
    }
    this.eventBus.send('com.hexa.notifications.server.read', obj, {}, (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onRead.next(response.body instanceof Array ? response.body[0] : response.body);
    });
  }

  private connect() {
    let options = {
      vertxbus_reconnect_attempts_max: Infinity,
      vertxbus_reconnect_delay_min: 1000,
      vertxbus_reconnect_delay_max: 5000,
      vertxbus_reconnect_exponent: 2,
      vertxbus_randomization_factor: 0.5
    };
    this.eventBus = new EventBus(this.auth.notificationsWebsocketEndpoint + '/cmseventbus?token=' + this.storage.get('token'), options);
    this.eventBus.onopen = this.onopen.bind(this);
    this.eventBus.onclose = this.onclose.bind(this);
    // this.eventBus.onreconnect = () => {

    // }
    this.eventBus.enableReconnect(true);
  }

  private onopen() {
    this.setState(true);
    this.register();

  }

  private onclose() {
    this.setState(false);
  }

  private setState(state: boolean) {
    this.isOpen = state;
    console.log('Notifications WebSocket is ' + (this.isOpen ? 'open' : 'close'));
    if (this.isOpen) {
      this.rolesHelper.fetchRoles();
      this.connetionLossCount = 0;
      if (environment.production)
        this.swService.checkForUpdate(); // after machine sleep or offline check for updates
      // if (this.connetionLossNotify) {

      // }
      this.connetionLossNotify = false;
    }
    else {
      if (++this.connetionLossCount > 10 && !this.connetionLossNotify) {
        this.connetionLossNotify = true;
      }
    }
    this.onStateChange.next(this.isOpen);
  }

  private register() {
    this.eventBus.registerHandler("com.hexa.notifications.client." + this.auth.user.id, (err, response) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onAnnouncement.next(response.body);
    });
  }

  private disconnect() {
    if (this.eventBus.state != this.eventBus.CLOSING && this.eventBus.state != this.eventBus.CLOSED)
      this.eventBus.close();
  }
}
