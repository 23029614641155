import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { ProductsManagerComponent } from 'src/app/product/products-manager/products-manager.component';
import { CanActivateAuthGuard } from 'src/app/auth/can-activate-auth-guard.service';
import { ProductsResolve } from 'src/app/product/products-manager/products-manager.resolve';
import { RegisterComponent } from 'src/app/auth/register/register.component';
import { RecoverPasswordComponent } from 'src/app/auth/recover-password/recover-password.component';
import { RecoverPasswordResolve } from 'src/app/auth/recover-password/recover-password.resolve';
import { UserSettingsComponent } from 'src/app/auth/user-settings/user-settings.component';
import { UserResolve } from './auth/user-settings/user.resolve';
import { RetailerResolve } from './retailer/retailer.resolve';
import { ProductRequestComponent } from './product/product-request/product-request.component';
import { ProductResolve } from './product/product-request/product-request.resolve';
import { ProductPreviewComponent } from './product/product-preview/product-preview.component';
import { RetailerProductResolve } from './retailer/retailer-product.resolve';
import { FeedbackEditComponent } from './product/feedback-edit/feedback-edit.component';
import { FeedbackEditResolve } from './product/feedback-edit/feedback-edit.resolve';
import { RolesManagementComponent } from './auth/roles-management/roles-management.component';
import { MulitipleScraperComponent } from './scraper/mulitiple-scraper/mulitiple-scraper.component';
import { CanActivateSuGuardService } from './auth/can-activate-su-guard.service';
import { UsersManagementComponent } from './auth/users-management/users-management.component';
import { RetailersManagementComponent } from './retailer/retailers-management/retailers-management.component';
import { GifgenResolve } from './product/product-request/gifgen.resolve';
import { BroadcastNotificationsComponent } from './communication/broadcast-notifications/broadcast-notifications.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { PrivacyViewComponent } from './auth/privacy-view/privacy-view.component';
import { TermsViewComponent } from './auth/terms-view/terms-view.component';
import { SelfProfileComponent } from './auth/self-profile/self-profile.component';
import { PaymentStatusComponent } from './auth/payment-status/payment-status.component';
import { PaymentStatusResolve } from './auth/payment-status/payment-status.resolve';
import { ScraperBatchesComponent } from './scraper/scraper-batches/scraper-batches.component';
import { CategoriesResolve } from './product-categories/categories.resolve';
import { ConvertorComponent } from './convertor/convertor/convertor.component';
import { BlankComponent } from './shared/blank/blank.component';
import { ProductsAuditComponent } from './product/products-audit/products-audit.component';
import { RetailerSettingsResolve } from './retailer/retailer-settings.resolve';
import { SocialComponent } from './auth/social/social.component';
import { SocialResolve } from './auth/social/social.resolve';
import { ProductFixerComponent } from './shared/product-fixer/product-fixer.component';

const routes: Routes = [
    { path: '', redirectTo: 'products', pathMatch: 'full' },
    { path: 'register', component: RegisterComponent },
    { path: 'login', component: LoginComponent },
    { path: 'signup/:email/:name/:token', component: LoginComponent },
    { path: 'reset/password/:email/:token', component: LoginComponent },
    { path: 'reset/password/:email/:token/:enforce', component: LoginComponent },
    {
        path: 'auth/sso',
        resolve: {
            profile: SocialResolve
        },
        component: SocialComponent
    },
    { path: 'update/qr/:email/:token', component: LoginComponent },

    { path: 'blank', component: BlankComponent },
    {
        path: 'retailer/user/password/:email',
        // path: 'retailer/user/password',
        component: RecoverPasswordComponent,
        resolve: {
            token: RecoverPasswordResolve
        }
    },
    {
        path: 'products-audit',
        component: ProductsAuditComponent,
        resolve: {
        },
        canActivate: [CanActivateAuthGuard],
        children: [
        ]
    },
    {
        path: 'products-fix',
        component: ProductFixerComponent,
        canActivate: [CanActivateAuthGuard],
    },
    {
        path: 'products',
        component: ProductsManagerComponent,
        resolve: {
            products: ProductsResolve,
            retailer: RetailerResolve
        },
        canActivate: [CanActivateAuthGuard],
        children: [
            // {
            //     path: 'request/:pid',
            //     component: ProductRequestComponent,
            //     canActivate: [CanActivateAuthGuard],
            //     data: { stateName: 'request' },
            //     resolve: {
            //         product: ProductResolve
            //     }
            // },
            {
                path: 'preview/:pid',
                component: ProductPreviewComponent,
                canActivate: [CanActivateAuthGuard],
                data: { stateName: 'request' },
                resolve: {
                    product: ProductResolve
                }
            }
        ]
    },
    {
        path: 'product/:pid',
        component: ProductRequestComponent,
        canActivate: [CanActivateAuthGuard],
        // data: { stateName: 'product' },
        resolve: {
            product: ProductResolve,
            gifgen: GifgenResolve,
            // resourceTypes: ResourceTypesResolve
            // retailer: RetailerProductResolve
        },
        children: [
            {
                path: 'feedback/:fid',
                component: FeedbackEditComponent,
                canActivate: [CanActivateAuthGuard],
                data: { stateName: 'feedback' },
                resolve: {
                    feedback: FeedbackEditResolve
                }
            }
        ]
    },
    {
        path: 'product',
        component: ProductRequestComponent,
        canActivate: [CanActivateAuthGuard],
        // data: { stateName: 'product' },
        resolve: {
            retailer: RetailerProductResolve
        }
    },
    {
        path: 'settings/:uid',
        component: UserSettingsComponent,
        resolve: {
            user: UserResolve
        },
        canActivate: [CanActivateAuthGuard]
    },
    {
        path: 'roles-management',
        component: RolesManagementComponent,
        canActivate: [CanActivateSuGuardService]
    },
    {
        path: 'scraper',
        component: MulitipleScraperComponent,
        canActivate: [CanActivateSuGuardService]
    },
    {
        path: 'scraper-batch',
        component: ScraperBatchesComponent,
        canActivate: [CanActivateSuGuardService]
    },
    {
        path: 'users-management',
        component: UsersManagementComponent,
        canActivate: [CanActivateSuGuardService]
    },
    {
        path: 'retailers-management',
        component: RetailersManagementComponent,
        canActivate: [CanActivateSuGuardService],
        resolve: {
            categories: CategoriesResolve
        }
    },
    {
        path: 'retailers-management/:retailer_id',
        component: RetailersManagementComponent,
        canActivate: [CanActivateAuthGuard],
        resolve: {
            retailer: RetailerSettingsResolve,
            categories: CategoriesResolve
        },
    },
    {
        path: 'broadcast',
        component: BroadcastNotificationsComponent,
        canActivate: [CanActivateAuthGuard, CanActivateSuGuardService]
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [CanActivateAuthGuard]
    },
    {
        path: 'privacy',
        component: PrivacyViewComponent
    },
    {
        path: 'terms',
        component: TermsViewComponent
    },
    {
        path: 'self-profile',
        component: SelfProfileComponent
    },
    {
        path: 'payment/:state',
        component: PaymentStatusComponent,
        resolve: {
            state: PaymentStatusResolve
        }
    },
    {
        path: 'convertor',
        component: ConvertorComponent
    },
    { path: '**', redirectTo: 'products' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule { }
