import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { UserQueryData } from '../user';
import { Observable } from 'rxjs';

@Injectable()
export class SocialResolve {
    constructor(private auth: AuthService) {}

    resolve(route: ActivatedRouteSnapshot) {
        this.auth.ssoState = {
            mfa_route_type: route.queryParams['mfa_route_type'],
            qr_image: route.queryParams['qr_image'],
            sign_on_type: route.queryParams['sign_on_type'],
            sso_provider: route.queryParams['sso_provider'],
            token: route.queryParams['token'],
            user_id: parseInt(route.queryParams['user_id']),
            sso_token: route.queryParams['sso_token'],
        };
        if (this.auth.ssoState.token)
            this.auth.storeToken(this.auth.ssoState.token);
        if (!this.auth.ssoState.user_id) return null;
        if (this.auth.ssoState.token)
            return this.auth.fetchUser(
                this.auth.ssoState.user_id
            ) as Observable<ApolloQueryResult<UserQueryData>>;
        return null;
    }
}
