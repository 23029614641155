import { Injectable } from '@angular/core';
import { MappingNamingConvention } from '../product/product';
import { GraphqlService } from '../communication/graphql.service';
import { BroadcasterService } from 'ng-broadcaster';

@Injectable({
  providedIn: 'root'
})
export class MappingNamingConventionService {
  private cachedTypes: Array<MappingNamingConvention>;
  constructor(
    private gql: GraphqlService,
    private broadcaster: BroadcasterService
  ) {
    this.gql.mappingNamingConventions().subscribe(
      t => {
        this.cachedTypes = t.data.mapping_naming_convention;
        this.broadcaster.broadcast('onMappingNamingConventions');
      }
    );
  }

  getCachedTypes(): Array<MappingNamingConvention> {
    if (this.cachedTypes) return JSON.parse(JSON.stringify(this.cachedTypes));
    return this.cachedTypes;
  }

  // getCachedDefaultTypes(): Array<MappingNamingConvention> {
  //   return [{
  //     mapping_name: 'Any',
  //     id: 1
  //   }];
  // }

  getTypes() {
    return this.gql.sourchFilesTypes();
  }

  public mapResourceTypeToUi(value: Array<MappingNamingConvention>): Array<number> {
    return value.map(t => t.id);
  }
}
