<mat-progress-bar class="main-progress-bar" *ngIf="globals.numOfRequestsInProgress > 0" mode="indeterminate">
</mat-progress-bar>
<div [ngClass]="{'no-auth': !isLogin}" class="center-me main">
  <app-header *ngIf="isHeaderVisible"></app-header>
  <div class="playground" [ngClass]="globals.playgroundClass">
    <div class="inner box-shadow">
      <div class="bottom-box"></div>
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-cookies-compliance></app-cookies-compliance>
</div>