<div class="wrap pos-rel">
  <!-- <button mat-icon-button color="accent" class="close pos-abs" mat-dialog-close>
    <i class="icon-close"></i>
  </button> -->
  <h2 mat-dialog-title>{{imageUrl.title}}</h2>
  <mat-dialog-content class="scrollbar">
    <iframe data-hj-allow-iframe="" *ngIf="imageUrl.isIframe" [src]="imageUrl.url | safeWindow" frameborder="0"></iframe>
    <img *ngIf="!imageUrl.isIframe" class="center-me max-w-100" [src]="imageUrl.url" alt="previw">
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button class="second_button" mat-dialog-close>Close</button>
    <button mat-button (click)="copyImage()" class="marg-left">copy link</button>
  </mat-dialog-actions>
</div>