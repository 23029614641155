<div class="wrap padd-sides scrollbar">
  <div class="space-top">
    <div class="grid query">
      <app-retailers-autocomplete [placeholder]="'retailer'" class="disp-block" [clear-on-select]="false"
        (retailer-change)="onRetailerChange($event)"></app-retailers-autocomplete>
      <mat-form-field>
        <input matInput placeholder="user id" ([ngModel])="query.user_id">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="status id" ([ngModel])="query.user_id">
      </mat-form-field>
    </div>
    <button [disabled]="!isValid()" mat-raised-button color="accent" class="min-button-width" (click)="getBatches()">get
      batch items</button>
  </div>
  <div class="space-top">
    <div class="grid items" *ngFor="let item of items">
      <span class="item"><b>{{item.batch_id}}</b> ({{item.status_id}})</span>
      <span class="item">
        <div *ngFor="let identifier of item.products_scraper_batch_requests_identifiers">
          <span>
            <b>{{identifier.identifier}}</b>
          </span>
          <span class="warn">
            {{identifier.error_message}}
          </span>
          <span>
            <span *ngFor="let created of identifier.products_scraper_batch_requests_identifiers_created">
              <span *ngFor="let product of created.products">
                <a [routerLink]="'/product/' + product.id">
                  {{product.name}}
                </a>
              </span>
            </span>
          </span>
        </div>
      </span>
    </div>
  </div>
</div>