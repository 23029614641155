import { Injectable } from '@angular/core';
import { Product, ImageSet, MediaTag, ProdutUIOptions } from '../product/product';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class MapperService {

  constructor(
    private utils: UtilsService
  ) { }
  
  getImageSetFrom360(url: string): ImageSet {
    let path = this.utils.getUrlParam(url, 'path');
    return {
      big: path + '0.jpg',
      small: path + 'small/0.jpg'
    };
  }

  getProductImages(product: Product, options: ProdutUIOptions): Array<ImageSet> {
    let res = [] as Array<ImageSet>;
    if (product.products_data instanceof Array && product.products_data.length) {
      product.products_data.forEach(pd => {
        res.push({
          big: pd.url,
          small: pd.small_image_url,
          sort_index: pd.sort_index,
          products_resources_alignments: pd.products_resources_alignments
        });
      });
    }
    else if (product.products_resources instanceof Array && product.products_resources.length && options.improviseImageSet) {
      product.products_resources.forEach(pd => {
        if (pd.resource_type === MediaTag.VIDEO && pd.resource_big) {
          res.push(this.getImageSetFrom360(pd.resource_big));
        }
        else
          res.push({
            big: pd.resource_small,
            small: pd.resource_big
          });
      });
    }
    res.sort(this.sortImageSet);
    return res;
  }

  private sortImageSet(a: ImageSet, b: ImageSet): number {
    return b.products_resources_alignments?.length - a.products_resources_alignments?.length;
  }

  getImagesFromProduct(p: Product): Array<ImageSet> {
    let res = [];
    if (p && p.products_data && p.products_data.length) {
      p.products_data.forEach(pd => res.push({ small: pd.small_image_url, big: pd.url, sort_index: pd.sort_index }));
    }
    return res;
  }
}
