import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class RecoverPasswordResolve  {

  constructor() { }

  resolve(route: ActivatedRouteSnapshot) {
    return route.params['token'];
    // return route.queryParams['token'];
  }

}
