import { Component, OnInit, Input, AfterViewChecked, ElementRef, ViewChild } from '@angular/core';
import { UtilsService } from '../utils.service';
import { ProductResource } from 'src/app/product/product';
import { WatermarkService } from '../watermark.service';
import { RestService } from 'src/app/communication/rest.service';
import { RolesHelperService } from 'src/app/auth/roles-helper.service';
import { GlobalsService } from '../globals.service';
import { AssetAdjustmentsHelperService } from '../asset-adjustments-helper.service';

@Component({
  selector: 'app-renderer',
  templateUrl: './renderer.component.html',
  styleUrls: ['./renderer.component.scss']
})
export class RendererComponent implements OnInit, AfterViewChecked {
  // @Input('viewer-url') viewerUrl: string;
  @Input('resource') resource: ProductResource;
  @Input('price') price: number;
  @Input('retailer-id') rid: number;
  @Input('product-id') pid: number;
  @ViewChild('frameWrap') frameWrap: ElementRef;
  public iframeModel: ElementRef;
  // public iframeStyle: any;
  public width: number = 2048;
  public height: number = 2048;
  public createdAt: string;
  private lastStyle: string;
  constructor(
    private assetAdjustmentsHelperService: AssetAdjustmentsHelperService,
    private utils: UtilsService,
    private watermark: WatermarkService,
    private rest: RestService,
    private roles: RolesHelperService,
    private globals: GlobalsService
  ) {
    this.setIframeStyle();
    this.createdAt = new Date().toDateString();
  }

  ngOnInit(): void {

  }

  ngAfterViewChecked() {
    this.iframeModel = new ElementRef(this.frameWrap.nativeElement.querySelector('iframe'));
    setTimeout(() => {
      this.setIframeStyle();
    }, 1000);
  }

  setIframeStyle() {
    // const top = document.querySelector('.app-renderer ._frame-wrap').getBoundingClientRect().top;
    // let sw = document.querySelector('app-renderer ._frame-wrap');

    if (this.frameWrap) {
      let style = '';
      // let transform = '';
      let rect = this.frameWrap.nativeElement.getBoundingClientRect();
      const parentHeight = this.frameWrap.nativeElement.parentElement.getBoundingClientRect().height - 80;
      let scale = Math.min(rect.width / this.width, parentHeight / this.height);
      style += `width: ${this.width}px;height: ${this.height}px;`;
      // style += `transform: scale(${scale}) translateX(-${scale * this.width * 4 - 160}px) translateY(-${scale * this.height * 4 - 160}px);`;
      style += `transform: scale(${scale});transform-origin: top left;`;
      if (this.lastStyle != style) {
        this.lastStyle = style;
        this.frameWrap.nativeElement.children[0].style.cssText = this.lastStyle;
      }
    }


  }

  async render(pay: boolean) {
    if (this.frameWrap) {
      let addWatermark = !pay;
      this.globals.increaseNumOfRequestsInProgress();
      const screenshot = await this.assetAdjustmentsHelperService.getScreenshot();
      const icon = 'assets/icons/icon-512x512.png';
      const opacity = 0.2;
      const render = async () => {
        if (addWatermark) {
          let img = await this.watermark.center(screenshot, icon, opacity);
          this.utils.multipleDownloads([img.src], 'render.png');
        }
        else
          this.utils.multipleDownloads([screenshot], 'render.png');
        this.globals.decreaseNumOfRequestsInProgress();
      };
      if (pay && this.roles.isSuperUserOrObserverLoggedIn()) {
        if (!confirm('Would you like to charge the retailer for this render?'))
          pay = false;
      }
      if (!pay) {
        // let img = await this.watermark.center(screenshot, icon, opacity);
        // this.utils.multipleDownloads([img.src], 'render.png');
        render();
      }
      else {
        let query = `?rid=${this.rid}`;
        this.rest.renderBilling('post', {
          product_id: this.pid
        }, query).subscribe(res => {
          render();
        }, err => {
          this.utils.httpErrorResponseHandler(err, 'failure charging for render');
          this.globals.decreaseNumOfRequestsInProgress();
        });
      }
    }
  }
}
