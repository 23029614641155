import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { GlobalsService } from 'src/app/shared/globals.service';
import { PlatgroundClass } from 'src/app/shared/globals';
import { Subscription } from 'rxjs';
import { UploadResource } from 'src/app/product/product';
import { EndpointsService} from 'src/app/communication/endpoints.service';
import { AssetAdjustmentsService, GltfExportOptions } from 'asset-adjustments';
import { AssetAdjustmentsHelperService } from 'src/app/shared/asset-adjustments-helper.service';
import { UtilsService } from 'src/app/shared/utils.service';
import {EndPoints} from '../../communication/endpoints';

@Component({
  selector: 'app-convertor',
  templateUrl: './convertor.component.html',
  styleUrls: ['./convertor.component.scss']
})
export class ConvertorComponent implements OnInit, OnDestroy, AfterViewInit {
  public viewerURL: string;
  public createdAt: Date;
  public resource: UploadResource;
  public ready: boolean;
  private subs: Array<Subscription>;
  private firstLoad: boolean;
  @ViewChild('iframe') iframeModel: ElementRef;
  constructor(
    private globals: GlobalsService,
    private endpoints: EndpointsService,
    public assetAdjustmentsService: AssetAdjustmentsService,
    public assetAdjustmentsHelperService: AssetAdjustmentsHelperService,
    private utils: UtilsService
  ) {
    this.subs = [];
    this.createdAt = new Date();
    this.resource = {
      viewer_type: '3JS'
    } as UploadResource;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.globals.playgroundClass = PlatgroundClass[PlatgroundClass.FULL].toLowerCase();
    }, 0);
    this.viewerURL = this.endpoints.getEndpointDomain(EndPoints.THREE_JS_VIEWER) + '/index.html?embed-loader=1&tv=112&auto-adjust=1';
    this.subs.push(this.assetAdjustmentsService.broadcaster.subscribe(this.onAdjustmentMessage.bind(this)));
    // this.subs.push(this.assetAdjustmentsService.pixel.subscribe(this.onAdjustmentPixel.bind(this)));
    // this.viewerURL = '//localhost:9000/index.html?embed-loader=1&tv=112';
  }

  ngAfterViewInit() {
    setTimeout(() => this.ready = true);
  }

  async onAdjustmentMessage(message: any) {
    switch (message.key) {
      case 'notifyUser': {
        // this.broadcaster.broadcast('notifyUser', message.data);
        break;
      }
      case 'onResourceExport': {
        this.assetAdjustmentsHelperService.catchEmbed = true;
        let res = await this.assetAdjustmentsHelperService.onResourceExport(message.data);
        let msgObj = {
          'action': 'setResource',
          'data': {
            'resource': res
          }
        };
        window.parent.postMessage(JSON.stringify(msgObj), document.referrer);
        break;
      }
      case 'viewerFullyLoaded': {
        this.assetAdjustmentsHelperService.isViewerFullyLoaded = true;
        if (!this.firstLoad) {
          this.firstLoad = true;
          setTimeout(() => {
            this.assetAdjustmentsHelperService.isViewerFullyLoaded = false;
          });
        }
        // else {
        //   console.log('export');
        //   let options = {
        //     downloadFile: false,
        //     useOptipng: false
        //   } as GltfExportOptions;
        //   this.assetAdjustmentsService.export(options);
        // }
        break;
      }
      case 'onLightsSummary': {
        let options = {
          downloadFile: false,
          useOptipng: false
        } as GltfExportOptions;
        this.assetAdjustmentsService.export(options);
        break;
      }
    }
  }

  refresh() {
    this.viewerURL = this.utils.setUrlParam(this.viewerURL, 'dummyVar', String((new Date()).getTime()));
    this.assetAdjustmentsHelperService.isViewerFullyLoaded = false;
    delete this.assetAdjustmentsHelperService.embedLink;
  }

  ngOnDestroy() {
    delete this.assetAdjustmentsHelperService.embedLink;
  }
}
