<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around" class="flex-wrap-wrap"
    *ngFor="let c of config; let i = index" fxLayoutGap="4">
    <button fxFlex="10" fxFlex.gt-sm="10" mat-icon-button mat-raised-button (click)="remove(i)" class="thinner marg-top">
        <mat-icon>delete</mat-icon>
    </button>    
    <mat-form-field fxFlex="40" fxFlex.gt-sm="15">
        <input type="text" matInput placeholder="mesh name" [(ngModel)]="c.mesh_name" name="mesh_name_{{i}}">
    </mat-form-field>
    <mat-form-field fxFlex="50" fxFlex.gt-sm="71">
        <textarea class="scrollbar" matInput placeholder="mesh description" [(ngModel)]="c.mesh_description"
            name="mesh_description_{{i}}"></textarea>
    </mat-form-field>
</div>
<button mat-icon-button mat-raised-button (click)="add()" class="thinner marg-top">
    <mat-icon>add</mat-icon>
</button>
<button mat-raised-button mat-raised-button color="accent" (click)="save()" class="thinner marg-top">
    save
</button>