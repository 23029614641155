import { Injectable, ElementRef } from '@angular/core';
import { BroadcasterService } from 'ng-broadcaster'
import { fromEvent  } from 'rxjs';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {DynamicSnackBarComponent} from './dynamic-snack-bar/dynamic-snack-bar.component';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class BodyService {
  public bodyElement: ElementRef;
  public isShift: boolean;
  public isCtrl: boolean;
  public isBlur: boolean;
  // public canHover: boolean;
  // private onFirstHoverSub: Subscription;
  private isOnline: boolean;
  private _isScraperAvailable: boolean;
  private _scraperResolve: Function;
  constructor(
    private broadcaster: BroadcasterService,
    private snackBar: MatSnackBar,
    private utils: UtilsService,
  ) {
    this.isShift = false;
    this.isCtrl = false;
    this.isBlur = false;
    // this.canHover = false;

    this.bodyElement = new ElementRef(document.querySelector('body'));

    fromEvent(window, 'message')
      .subscribe((e: any) => {
        if (e.data?.hexaScraper) {
          switch (e.data.action) {
            case 'identifyBack': {
              this._isScraperAvailable = e.data.identify;
              break;
            }
            case 'setProducts': {
              this._scraperResolve(e.data.products);
              break;
            }
          }
        }
      });

    window.postMessage({ hexaScraper: true, action: 'identify' });

    fromEvent(this.bodyElement.nativeElement, 'keyup')
      .subscribe((e: any) => {
        switch (e.keyCode) {
          case 27: {
            this.onEsc();
            break;
          }
          case 16: {
            this.onShift(false);
            break;
          }
          case 17: {
            this.onCtrl(false);
            break;
          }
        }
      });

    fromEvent(this.bodyElement.nativeElement, 'keydown')
      .subscribe((e: any) => {
        switch (e.keyCode) {
          case 16: {
            this.onShift(true);
            break;
          }
          case 17: {
            this.onCtrl(true);
            break;
          }
          case 89: {
            if (this.isCtrl)
              this.onCtrlY();
            break;
          }
          case 90: {
            if (this.isCtrl) {
              if (this.isShift)
                this.onCtrlY();
              else
                this.onCtrlZ();
            }
            break;
          }
        }
      });

    fromEvent(this.bodyElement.nativeElement, 'click').subscribe(
      (evt: MouseEvent) => this.broadcaster.broadcast('onclick', evt.target)
    );

    fromEvent(window, 'popstate')
      .subscribe((e: any) => {
        this.broadcaster.broadcast('popstate', e);
      });

    fromEvent(window, 'blur')
      .subscribe((e: any) => {
        this.onShift(false);
        this.onCtrl(false);
      });

    // this.onFirstHoverSub = fromEvent(window, 'mouseover').subscribe(this.onFirstHover.bind(this));

    this.broadcaster.on('notifyUser').subscribe(
      (data: BroadcasterNotification) => {

        if (!data.text) return;
        data.action = data.action || 'OK';
        let c = {} as MatSnackBarConfig;
        if (data.autoDismiss || typeof data.autoDismiss === 'undefined')
          c.duration = typeof data.duration === 'number' ? data.duration : 5000;
        switch (data.type) {
          case BroadcasterNotificationType.Error: {
            c.panelClass = 'error';
            break;
          }
          case BroadcasterNotificationType.Success: {
            c.panelClass = 'success';
            break;
          }
          case BroadcasterNotificationType.Info: {
            c.panelClass = 'info';
            break;
          }
        }

        let ref;
        if(data.htmlTemplate) {
          ref = this.snackBar.openFromComponent(DynamicSnackBarComponent, {data});
        } else {
          ref = this.snackBar.open(data.text, data.action, c);
        }

        if (typeof data.callback === 'function') {
          ref.onAction().subscribe(() => {
            if (data.scope)
              data.callback.apply(data.scope);
            else
              data.callback();
          });
        }
      }
    );

    fromEvent(window, 'online').subscribe(
      this.updateOnlineStatus.bind(this)
    )
    fromEvent(window, 'offline').subscribe(
      this.updateOnlineStatus.bind(this)
    )
    this.updateOnlineStatus();
  }

  get isScraperAvailable() {
    return this._isScraperAvailable;
  }

  scrapeWithChromeExtension(url: string, config: any) {
    url = this.utils.setUrlParam(url, 'hexa-scraper', '1')
    const getProductData = async () => {
      return new Promise((resolve: Function, reject: Function) => {
        this._scraperResolve = resolve;
        window.postMessage({ url: url, config, hexaScraper: true, action: 'getProductData' });
      });
    };
    return new Promise(async (resolve: Function, reject: Function) => {
      // We will add the parameter that will indicate the Chrome Extension to scrape the site:
      const w = window.open(url);
      // We will let the tab start and listen first:
      await this.utils.setTimeout(1000);
      resolve(await getProductData());
      // We will close the retailer's product page after scraping is done:
      w.close();
    });
  }

  private updateOnlineStatus() {
    this.isOnline = navigator.onLine;
    if (this.isOnline)
      this.bodyElement.nativeElement.classList.remove('offline');
    else
      this.bodyElement.nativeElement.classList.add('offline');
  }

  public scrollToElement(element: ElementRef, block = 'start', inline = 'nearest') {
    if (element.nativeElement.scrollIntoView) {
      element.nativeElement.scrollIntoView({ behavior: 'smooth', block: block });
    }
    else {
      this.scrollTo(window.scrollY, element.nativeElement.offsetTop, document.body.scrollHeight / 50);
    }
  }

  public scrollTo(currentPos: number = window.scrollY, finalPos: number, increment = document.body.scrollHeight / 50, counter?: number, asec?: boolean) {
    try {
      window.scroll({
        top: finalPos,
        // left: 0,
        behavior: 'smooth'
      });
      return;
    } catch (e) { }
    counter = counter ? (counter + 1) : 1;
    asec = typeof asec === 'boolean' ? asec : currentPos > finalPos;
    if (asec)
      currentPos -= increment;
    else
      currentPos += increment;
    window.scrollTo(0, currentPos);
    if ((asec ? currentPos > finalPos : currentPos < finalPos) && counter < 50)
      setTimeout(() => {
        this.scrollTo(currentPos, finalPos, increment, counter, asec);
      }, 10);
  }

  private onEsc() {
    this.broadcaster.broadcast('esc');
  }

  private onShift(state: boolean) {
    this.isShift = state;
    this.broadcaster.broadcast('shift', this.isShift);
  }

  private onCtrl(state: boolean) {
    this.isCtrl = state;
    this.broadcaster.broadcast('ctrl', this.isShift);
  }

  private onCtrlZ() {
    this.broadcaster.broadcast('ctrlZ', this.isShift);
  }

  private onCtrlY() {
    this.broadcaster.broadcast('ctrlY', this.isShift);
  }

  // private onFirstHover() {
  //   this.canHover = true;
  //   this.onFirstHoverSub.unsubscribe();
  // }
}
