import { Injectable } from '@angular/core';
import { BroadcasterService } from 'ng-broadcaster';
import { NavigationLink } from './globals';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  private h1: string;
  private h2: string;
  private breadcrumbs: Array<NavigationLink>;
  private currentRouteName: string;
  public playgroundClass: string;
  public numOfRequestsInProgress: number = 0;
  constructor(
    private broadcaster: BroadcasterService
  ) {
    this.reset();
    this.broadcaster.on('setCurrentRouteName').subscribe((name: string) => {
      this.currentRouteName = name;
    });
  }

  public increaseNumOfRequestsInProgress() {
    setTimeout(() => { this.numOfRequestsInProgress++; });
  }

  public decreaseNumOfRequestsInProgress() {
    setTimeout(() => {
      this.numOfRequestsInProgress--;
      if (this.numOfRequestsInProgress < 0)
        this.numOfRequestsInProgress = 0;
    });
  }

  public resetNumOfRequestsInProgress() {
    setTimeout(() => {
      this.numOfRequestsInProgress = 0;
    });
  }

  setHeaer(h1: string, h2: string) {//, subTitle?: string) {
    this.h1 = h1 || String.fromCharCode(160);
    this.h2 = h2;
    // this.subTitle = subTitle;
  }

  setBreadcrumbs(breadcrumbs?: Array<NavigationLink>) {
    if (breadcrumbs)
      this.breadcrumbs = breadcrumbs;
    else
      this.breadcrumbs = [];
  }

  getH1() {
    return this.h1;
  }

  getH2() {
    return this.h2;
  }

  getBreadcrumbs() {
    return this.breadcrumbs;
  }

  // getSubTitle() {
  //   return this.subTitle;
  // }

  // getTotalBudget() {
  //   return this.totalBudget;
  // }

  // getTotalProducts() {
  //   return this.totalProducts;
  // }

  getCurrentRouteName() {
    return this.currentRouteName;
  }

  reset() {
    this.h1 = String.fromCharCode(160);
    this.h2 = '';
    this.breadcrumbs = [];
  }

  // refreshRetailerUsers(user: User) {
  //   // if (user.retailers_users instanceof Array && user.retailers_users.length > 0) {
  //   //   let query = '?rid=' + user.retailers_users[this.auth.retailerIndex].retailer_id;
  //   //   this.rest.userProfile('get', null, query).subscribe((users: Array<User>) => {
  //   //     this.retailerUsers = users;
  //   //   });
  //   // }
  //   // if (user.children.retailerusers instanceof Array && user.children.retailerusers.length > 0) {
  //   //   let query = '?rid=' + user.children.retailerusers[0].retailer_id;
  //   //   this.rest.userProfile('get', null, query).subscribe((users: Array<User>) => {
  //   //     this.retailerUsers = users;
  //   //   });
  //   // }
  // }
}
