import {Component, ChangeDetectorRef, OnInit, ErrorHandler, APP_INITIALIZER} from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { BroadcasterService } from 'ng-broadcaster';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { GlobalsService } from './shared/globals.service';
import { BodyService } from 'src/app/shared/body.service';
import { MatIconRegistry } from '@angular/material/icon';
import { KeyValuePair } from './shared/enums';
import { RolesHelperService } from './auth/roles-helper.service';
// import * as Sentry from '@sentry/angular';
import {SentryService} from './shared/sentry.service';
import {environment} from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // providers: [
  //   {
  //     provide: ErrorHandler,
  //     useValue: Sentry.createErrorHandler({
  //       showDialog: false,
  //     }),
  //   },
  //   {
  //     provide: Sentry.TraceService,
  //     deps: [Router],
  //   },
  //   {
  //     provide: APP_INITIALIZER,
  //     useFactory: () => () => {},
  //     deps: [Sentry.TraceService],
  //     multi: true,
  //   },
  // ],
})
export class AppComponent implements OnInit {
  public isLogin: boolean;
  public isHeaderVisible: boolean;
  public progressBarActive: boolean = false;
  constructor(
    private auth: AuthService,
    // private rest: RestService,
    private cdr: ChangeDetectorRef,
    private broadcaster: BroadcasterService,
    private router: Router,
    private route: ActivatedRoute,
    public globals: GlobalsService,
    public body: BodyService,
    private matIconRegistry: MatIconRegistry,
    private rolesHelper: RolesHelperService,
  ) {
    // this.addIcons();
    this.refreshIsLogin();
    this.rolesHelper.fetchRoles();
    this.broadcaster.on('onLogin').subscribe(this.refreshIsLogin.bind(this));
    this.broadcaster.on('onLogout').subscribe(this.onLogout.bind(this));
    this.broadcaster.on('requestsInProgress').subscribe(this.onIntercept.bind(this));
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.refreshIsLogin();

        var snapshot = this.route.snapshot;
        var activated = this.route.firstChild;
        if(activated != null) {
          while (activated != null) {
            snapshot = activated.snapshot;
            activated = activated.firstChild;
          }
        }
        this.broadcaster.broadcast('setCurrentRouteName', snapshot.data['stateName']);
        this.broadcaster.broadcast('onNavigationSnapshotChange', snapshot);
      }
    });
  }

  ngOnInit(): void {
    if (this.auth.isloggedIn())
      this.broadcaster.broadcast('onLogin', this.auth.user);

    // init the Sentry browser SDK (for error monitoring)
    if(environment.sentry.isActivated) {
      SentryService.init();
    }
  }

  onIntercept(value: boolean) {
    this.progressBarActive = value;
    try {
      this.cdr.detectChanges();
    } catch (e) { }
  }

  addIcons() {
    let iconxToAdd = [
    ] as Array<KeyValuePair>;
    iconxToAdd.forEach(i =>
      this.matIconRegistry.addSvgIcon(
        i.key,
        i.value
      ));
  }

  onLogout() {
    this.refreshIsLogin();
  }

  refreshIsLogin() {
    let allowPaths = ['/retailer/user/password', '/register', '/terms', '/privacy', '/convertor', '/login', '/signup', '/reset', '/update', '/auth/sso'];
    this.isLogin = this.auth.isloggedIn();
    this.isHeaderVisible = this.isLogin;
    if (!this.isLogin && !allowPaths.find(p => window.location.pathname.indexOf(p) > -1))
      this.router.navigate(['/login']);
    if (window.location.pathname == '/register')
      this.isHeaderVisible = true;
  }
}
