import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, AbstractControl, UntypedFormControl } from '@angular/forms';
import { GlobalsService } from 'src/app/shared/globals.service';
import { PlatgroundClass } from 'src/app/shared/globals';
import { FileObject } from '../../shared/file';
import { RestService } from '../../communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from '../../communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { TermsComponent } from '../terms/terms.component';
import { User, RetailerUser } from '../user';
import { AuthService } from '../auth.service';
import { PrivacyComponent } from '../privacy/privacy.component';
import { MatDialog } from '@angular/material/dialog';
import { UtilsService } from 'src/app/shared/utils.service';
import { ResumableUploadService } from 'src/app/shared/resumable-upload.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  private numOfFilesUploading: number;
  private registerOnUploadDone: boolean;
  public regForm: UntypedFormGroup;
  public regCompanyForm: UntypedFormGroup;
  public state: RegisterComponentState;
  public PERSONAL = RegisterComponentState.Personal;
  public COMPANY = RegisterComponentState.Company;
  public personalLogo: string;
  public companyLogo: string;
  // public industriesTypes: Array<KeyValuePair>;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private globals: GlobalsService,
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private dialog: MatDialog,
    private utils: UtilsService,
    private auth: AuthService,
    private resumableUpload: ResumableUploadService
  ) {
    this.state = RegisterComponentState.Personal;
    this.numOfFilesUploading = 0;
    this.regForm = this.formBuilder.group({
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      email: [null, Validators.email],
      // phone_number: null,
      // lead: null,
      password: [null, Validators.required],
      rememberMe: true,
      confirm_password: [null, this.confirmPassword]
      // ,
      // approved: [false, Validators.requiredTrue]
    });
    this.regCompanyForm = this.formBuilder.group({
      name: [null, Validators.required],
      website: [null, Validators.required],
      address: null,
      industry: null,
      recaptchaReactive: new UntypedFormControl(null, Validators.required)
    });
    // this.industriesTypes = this.enums.getIndustriesTypes();
  }

  ngOnInit() {
    setTimeout(() => {
      this.globals.setHeaer('Sign Up', null);
      this.globals.setBreadcrumbs();
      this.globals.playgroundClass = PlatgroundClass[PlatgroundClass.MEDIUM].toLowerCase();
    }, 0);
    // this.state = RegisterComponentState.Company;
  }

  captchaResolved(captchaResponse: string) {
    this.regCompanyForm.controls.recaptchaReactive.setValue(captchaResponse);
  }

  isPasswordsMatch(): boolean {
    this.regForm.controls['password'].value == this.regForm.controls['confirm_password'].value;
    return this.regForm.controls['password'].value == this.regForm.controls['confirm_password'].value;
  }

  confirmPassword(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return { 'passwordIdentical': { value: this.regForm.controls['password'].value == this.regForm.controls['confirmPassword'].value } } || null;
    };
  }

  next() {
    if (!this.regForm.valid) return;
    this.state = RegisterComponentState.Company;
  }

  register() {
    if (this.numOfFilesUploading > 0) {
      this.registerOnUploadDone = true;
      return;
    }
    if (!this.regCompanyForm.valid) return;
    let user = this.regForm.getRawValue();// as User;
    user.avatar_url = this.personalLogo;
    user.retailers_users = [
      {
        retailers: [
          this.regCompanyForm.getRawValue()
        ]
      }
    ] as Array<RetailerUser>;
    user.children = {
      userroles: [{ role_id: 2 }]
    };
    // user.retailers_users[this.auth.retailerIndex].retailers[0].logo_url = this.companyLogo;
    user.retailer = this.regCompanyForm.getRawValue();
    user.retailer.logo_url = this.companyLogo;
    // user.users_roles = [{
    //   role_id: 2
    // }] as Array<UserRole>;
    this.rest.userProfile('post', user).subscribe(
      (res: User) => {
        let data: BroadcasterNotification = {
          text: 'user created sucesffully',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
        this.auth.atachRolesToUser(res);
        this.auth.onLoginSuccess(res, true, true, true);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure creating user')
    );
  }

  deleteImage(type: RegisterComponentState) {
    switch (type) {
      case RegisterComponentState.Company: {
        delete this.companyLogo;
        break;
      }
      case RegisterComponentState.Personal: {
        delete this.personalLogo;
        break;
      }
    }
  }

  async onPPFilesChange(fileList: Array<FileObject>) {
    let file = fileList[0];
    if (!file) return;
    this.personalLogo = file.base64;
    this.numOfFilesUploading++;
    // let fileUploadRequest = {
    //   file: file.base64,
    //   filename: file.name + '.' + file.suffix,
    //   compress: true
    // } as FileUploadRequest;
    try {
      this.personalLogo = await this.resumableUpload.file(fileList[0].file);
    } catch (e) {
      this.utils.httpErrorResponseHandler(e, 'failure uploading image');
    }
    this.numOfFilesUploading--;
    this.onUploadDone();
    // this.rest.cdn('post', fileUploadRequest).subscribe(
    //   data => {
    //     this.numOfFilesUploading--;
    //     this.personalLogo = data['url'];
    //   },
    //   err => {
    //     this.numOfFilesUploading--;
    //     this.utils.httpErrorResponseHandler(err, 'failure uploading image');
    //   },
    //   this.onUploadDone.bind(this)
    // );
  }

  async onCFilesChange(fileList: Array<FileObject>) {
    let file = fileList[0];
    if (!file) return;
    this.companyLogo = file.base64;
    this.numOfFilesUploading++;
    try {
      this.companyLogo = await this.resumableUpload.file(fileList[0].file);
    }
    catch (e) {
      this.utils.httpErrorResponseHandler(e, 'failure uploading image');
    }
    this.numOfFilesUploading--;
    this.onUploadDone();
    // let fileUploadRequest = {
    //   file: file.base64,
    //   filename: file.name + '.' + file.suffix,
    //   compress: true
    // } as FileUploadRequest;
    // this.rest.cdn('post', fileUploadRequest).subscribe(
    //   data => {
    //     this.numOfFilesUploading--;
    //     this.companyLogo = data['url'];
    //   },
    //   err => {
    //     this.numOfFilesUploading--;
    //     this.utils.httpErrorResponseHandler(err, 'failure uploading image');
    //   },
    //   this.onUploadDone.bind(this)
    // );
  }

  onUploadDone() {
    if (this.registerOnUploadDone && this.numOfFilesUploading == 0) {
      this.register();
    }
  }

  back() {
    this.state = RegisterComponentState.Personal;
  }

  showTerms() {
    this.dialog.open(TermsComponent);
  }

  showPrivacy() {
    this.dialog.open(PrivacyComponent);
  }
}

export enum RegisterComponentState {
  Personal = 1,
  Company = 2
}
