<div [ngClass]="{'loading': assetAdjustmentsService.loading && !embed}" class="pos-rel" *ngIf="!hide">
  <mat-spinner *ngIf="assetAdjustmentsService.loading && !embed"></mat-spinner>
  <div class="inner-wrap no-padd-children">
    <mat-accordion class="customized-title" [ngClass]="{'main-accordion': tutorialEnabled}"
      #mainAccordion="matAccordion">
      <mat-expansion-panel (opened)="tabIndexChange(SCENE)" [expanded]="true" #scenePanel="matExpansionPanel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            SCENE
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content-va">
          <div *ngIf="gltfValidation && gltfValidation.issues.numErrors">
            <button mat-button color="warn" (click)="showGltfErrors()">
              glTF contains {{gltfValidation.issues.numErrors}} errors
            </button>
          </div>
          <div *ngFor="let param of assetAdjustmentsService.parameters | assetAdjustmentsFilter : SCENE">
            <div *ngIf="param.type === SLIDER" class="disp-flex space-between flex-align-center param-row" [class]="param.urlParam">
              <div>
                <mat-form-field class="mat-mdc-form-field-short">
                  <mat-label>{{param.name}}</mat-label>
                  <input type="number" matInput
                    [(ngModel)]="param.value" (change)="assetAdjustmentsService.changeValue(param)"
                    [disabled]="param.disabled">
                </mat-form-field>
              </div>
              <mat-slider role="slider" aria-label="viewer" fxFlex="70" [min]="param.min" [max]="param.max"
                [step]="param.step" discrete
                [disabled]="param.disabled"><input [(ngModel)]="param.value" (valueChange)="assetAdjustmentsService.changeValue(param)"
                (input)="assetAdjustmentsService.changeValue(param, {value: $event})" matSliderThumb></mat-slider>
              <div alt="default param" fxFlex *ngIf="param.default" class="gray default">
                ({{param.default | number : '1.0-2'}})
              </div>
            </div>
            <div class="select-param {{param.urlParam}}" [ngClass]="param.cssClass" *ngIf="param.type == SELECT">
              <mat-form-field>
                <mat-select [placeholder]="param.name" [(ngModel)]="param.value"
                  (selectionChange)="assetAdjustmentsService.changeValue(param)">
                  <mat-option *ngFor="let o of param.selectOptions; let i = index" [value]="o.key">
                    {{ o.value }}
                    <img class="option-img material" [src]="param.optionsImages[o.key]"
                      *ngIf="param.optionsImages && param.optionsImages[o.key]" alt="preview">
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="image-menu" [ngClass]="param.cssClass" *ngIf="param.type == IMAGE_MENU">
              <app-images-menu [options-images]="param.optionsImages" [value]="param.value" [placeholder]="param.name"
                (on-change)="onSelectMenuClick(param, $event)"></app-images-menu>
            </div>
            <div class="toggle-param w-100" *ngIf="param.type == TOGGLE" [ngClass]="{'has-color': param.hasColor}">
              <hexa-toggle [(ngModel)]="param.value" (toggle)="assetAdjustmentsService.changeValue(param)">
                {{param.name}}</hexa-toggle>
              <span *ngIf="param.hasColor" class="color">
                <label>
                  <input aria-label="color" type="color" [(ngModel)]="param.color"
                    (change)="assetAdjustmentsService.changeColor(param, $event)">
                </label>
              </span>
            </div>
            <div *ngIf="param.type === VECTOR3 && param.id === 'hdr-rotation'" [class]="param.urlParam">
              <div class="gray capitalize">{{param.name}}</div>
              <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px" class="dimensions-xyz">
                <mat-form-field fxFlex="30" class="mat-form-field-short">
                  <mat-label>x</mat-label>
                  <input type="number" matInput [(ngModel)]="param.value.x" [step]="param.step"
                    (change)="assetAdjustmentsService.changeValue(param)" [min]="param.min" [max]="param.max">
                </mat-form-field>
                <mat-form-field fxFlex="30" class="mat-form-field-short">
                  <mat-label>y</mat-label>
                  <input type="number" matInput [(ngModel)]="param.value.y" [step]="param.step"
                    (change)="assetAdjustmentsService.changeValue(param)" [min]="param.min" [max]="param.max">
                </mat-form-field>
                <mat-form-field fxFlex="30" class="mat-form-field-short">
                  <mat-label>z</mat-label>
                  <input type="number" matInput [(ngModel)]="param.value.z" [step]="param.step"
                    (change)="assetAdjustmentsService.changeValue(param)" [min]="param.min" [max]="param.max">
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="toggle-param w-100">
            <hexa-toggle [(ngModel)]="isDistanceFree"
              (toggle)="assetAdjustmentsService.toggleNoDistanceLimit(isDistanceFree)">no distance limit
            </hexa-toggle>
          </div>
          <div class="toggle-param w-100">
            <hexa-toggle [(ngModel)]="assetAdjustmentsHelperService.noDamping"
              (toggle)="assetAdjustmentsService.toggleNoControlsDamping(assetAdjustmentsHelperService.noDamping)">
              slow camera
            </hexa-toggle>
          </div>

          <div *ngFor="let param of assetAdjustmentsService.parameters | assetAdjustmentsFilter : SCENE">
            <ng-container *ngIf="param.type == VECTOR3 && param.id !== 'hdr-rotation'">
              <div *ngIf="param.hasToggle" fxLayout="row" class="toggle-param"
                [ngClass]="{'has-color': param.hasColor}">
                <hexa-toggle [(ngModel)]="param.toggleState" (toggle)="assetAdjustmentsService.changeValue(param)">
                  {{param.name}}</hexa-toggle>
                <span *ngIf="param.hasColor" class="color">
                  <label>
                    <input aria-label="color" type="color" [(ngModel)]="param.color"
                      (change)="assetAdjustmentsService.changeColor(param, $event)">
                  </label>
                </span>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px" class="dimensions-xyz">
                <mat-form-field fxFlex="30" class="mat-mdc-form-field-short">
                  <mat-label>x</mat-label>
                  <input type="text" matInput [(ngModel)]="param.value.x"
                    (change)="assetAdjustmentsService.changeValue(param)">
                </mat-form-field>
                <mat-form-field fxFlex="30" class="mat-mdc-form-field-short">
                  <mat-label>y</mat-label>
                  <input type="text" matInput [(ngModel)]="param.value.y"
                    (change)="assetAdjustmentsService.changeValue(param)">
                </mat-form-field>
                <mat-form-field fxFlex="30" class="mat-mdc-form-field-short">
                  <mat-label>z</mat-label>
                  <input type="text" matInput [(ngModel)]="param.value.z"
                    (change)="assetAdjustmentsService.changeValue(param)">
                </mat-form-field>
              </div>
            </ng-container>
          </div>

          <div class="float-dir w-100" *ngIf="resource?.viewer_type == '3JS'">
            <mat-accordion class="customized">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    position
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                  <h2 class="gray sub-title text-center">
                    x axis position
                  </h2>
                  <div class="center-inline-flex">
                    <mat-button-toggle-group aria-label="x pivot" (change)="pivotChange('x', $event)"
                      [(ngModel)]="pivotChangeValueX">
                      <mat-button-toggle [value]="BEFORE">before</mat-button-toggle>
                      <mat-button-toggle [value]="CENTER">center</mat-button-toggle>
                      <mat-button-toggle [value]="AFTER">after</mat-button-toggle>
                    </mat-button-toggle-group>
                  </div>
                  <div class="center-inline-flex">
                    <mat-form-field class="mat-mdc-form-field-short">
                      <input matInput placeholder="x" (keypress)="validateNumber($event)"
                        [(ngModel)]="inputChangeValueX" (ngModelChange)="setPosition('x',$event)">
                    </mat-form-field>
                  </div>
                </div>
                <div>
                  <h2 class="gray sub-title text-center">
                    y axis position
                  </h2>
                  <div class="center-inline-flex">
                    <mat-button-toggle-group aria-label="y pivot" (change)="pivotChange('y', $event)"
                      [(ngModel)]="pivotChangeValueY">
                      <mat-button-toggle [value]="BEFORE">before</mat-button-toggle>
                      <mat-button-toggle [value]="CENTER">center</mat-button-toggle>
                      <mat-button-toggle [value]="AFTER">after</mat-button-toggle>
                    </mat-button-toggle-group>
                  </div>
                  <div class="center-inline-flex">
                    <mat-form-field class="mat-mdc-form-field-short">
                      <input matInput placeholder="y" (keypress)="validateNumber($event)"
                        [(ngModel)]="inputChangeValueY" (ngModelChange)="setPosition('y',$event)">
                    </mat-form-field>
                  </div>
                </div>
                <div>
                  <h2 class="gray sub-title text-center">
                    z axis position
                  </h2>
                  <div class="center-inline-flex">
                    <mat-button-toggle-group aria-label="z pivot" (change)="pivotChange('z', $event)"
                      [(ngModel)]="pivotChangeValueZ">
                      <mat-button-toggle [value]="BEFORE">before</mat-button-toggle>
                      <mat-button-toggle [value]="CENTER">center</mat-button-toggle>
                      <mat-button-toggle [value]="AFTER">after</mat-button-toggle>
                    </mat-button-toggle-group>
                  </div>
                  <div class="center-inline-flex">
                    <mat-form-field class="mat-mdc-form-field-short">
                      <input matInput placeholder="z" (keypress)="validateNumber($event)"
                        [(ngModel)]="inputChangeValueZ" (ngModelChange)="setPosition('z',$event)">
                    </mat-form-field>
                  </div>
                </div>
                <div class="progress-wrap space-top">
                  <div *ngIf="exportProgress == 100" class="blink text-center text2 pos-rel export-progress">
                    <div class="in">
                      processing . . .
                    </div>
                  </div>
                  <app-progress-bar *ngIf="exportProgress" [percentage]="exportProgress"></app-progress-bar>
                </div>
                <button aria-label="save position" mat-button color="primary"
                  (click)="assetAdjustmentsService.export()">
                  save position
                </button>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    rotation
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div fxFlex="115px" class="text-center">
                    <app-coordinates [min]="assetAdjustmentsService.modelRotation.min"
                      [max]="assetAdjustmentsService.modelRotation.max"
                      [(ngModel)]="assetAdjustmentsService.modelRotation.value" (on-change)="rotationChange()">
                    </app-coordinates>
                  </div>
                  <div fxFlex>
                    <div fxLayout="column" fxLayoutAlign="space-between center">
                      <div fxFlex>
                        <mat-form-field class="mat-mdc-form-field-short">
                          <mat-label>x</mat-label>
                          <input type="number" matInput
                            [min]="assetAdjustmentsService.modelRotation.min"
                            [max]="assetAdjustmentsService.modelRotation.max"
                            [step]="assetAdjustmentsService.modelRotation.step"
                            [(ngModel)]="assetAdjustmentsService.modelRotation.degree.x" (change)="rotationChange()">
                        </mat-form-field>
                      </div>
                      <div fxFlex>
                        <mat-form-field class="mat-mdc-form-field-short">
                          <mat-label>y</mat-label>
                          <input type="number" matInput
                            [min]="assetAdjustmentsService.modelRotation.min"
                            [max]="assetAdjustmentsService.modelRotation.max"
                            [step]="assetAdjustmentsService.modelRotation.step"
                            [(ngModel)]="assetAdjustmentsService.modelRotation.degree.y" (change)="rotationChange()">
                        </mat-form-field>
                      </div>
                      <div fxFlex>
                        <mat-form-field class="mat-mdc-form-field-short">
                          <mat-label>z</mat-label>
                          <input type="number" matInput
                            [min]="assetAdjustmentsService.modelRotation.min"
                            [max]="assetAdjustmentsService.modelRotation.max"
                            [step]="assetAdjustmentsService.modelRotation.step"
                            [(ngModel)]="assetAdjustmentsService.modelRotation.degree.z" (change)="rotationChange()">
                        </mat-form-field>
                      </div>
                      <div fxFlex>
                        <button aria-label="reset rotation" mat-button color="primary" (click)="resetRotation()">
                          reset rotation
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="progress-wrap space-top">
                  <div *ngIf="exportProgress == 100" class="blink text-center text2 pos-rel export-progress">
                    <div class="in">
                      processing . . .
                    </div>
                  </div>
                  <!-- <app-progress-bar *ngIf="exportProgress" [percentage]="exportProgress"></app-progress-bar> -->
                </div>
                <button mat-button color="primary" (click)="assetAdjustmentsService.export()">
                  save rotation
                </button>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    camera angle
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                  <div class="center-inline-flex">
                    <mat-button-toggle-group aria-label="x pivot" (change)="cameraPositionChange($event)">
                      <mat-button-toggle [value]="TOP">top</mat-button-toggle>
                      <mat-button-toggle [value]="BEFORE">left</mat-button-toggle>
                      <mat-button-toggle [value]="CENTER">center</mat-button-toggle>
                      <mat-button-toggle [value]="AFTER">right</mat-button-toggle>
                      <mat-button-toggle [value]="BOTTOM">bottom</mat-button-toggle>
                    </mat-button-toggle-group>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    download HDRI images
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                  <ul class="normalize hdri-download content-va">
                    <li *ngFor="let i of hdriImages | keyValuePure">
                      <a target="_blank" download="{{i.key}}.jpg" rel="noopener"
                        [href]="i.value.key + '?disposition=attachment'">
                        <img width="150px" height="114px" [src]="i.value.value" [alt]="i.key" loading="lazy">
                      </a>
                    </li>
                  </ul>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel *ngIf="isSU">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    framework
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span fxFlex class="text-center">
                      <mat-form-field>
                        <mat-label>rendering engine</mat-label>
                        <mat-select [(ngModel)]="assetAdjustmentsService.engine">
                          <mat-option [value]="RenderingEngine.THREE">
                            three.js
                          </mat-option>
                          <mat-option [value]="RenderingEngine.BABYLON">
                            babylon.js
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </span>
                    <span fxFlex class="text-center" *ngIf="RenderingEngine.THREE == assetAdjustmentsService.engine">
                      <mat-form-field>
                        <mat-label>three.js version</mat-label>
                        <mat-select [(ngModel)]="assetAdjustmentsService.threeVersion">
                          <mat-option [value]="s.key" *ngFor="let s of allThreeJsVersions">
                            {{s.value}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </span>
                    <span fxFlex class="text-center" *ngIf="RenderingEngine.BABYLON == assetAdjustmentsService.engine">
                      <mat-form-field>
                        <mat-label>babylon.js version</mat-label>
                        <mat-select [(ngModel)]="assetAdjustmentsService.babylonVersion">
                          <mat-option [value]="s" *ngFor="let s of allBabylonJsVersions">
                            {{s}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </span>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span fxFlex class="text-center">
                      <mat-form-field>
                        <mat-label>rendering technology</mat-label>
                        <mat-select [(ngModel)]="assetAdjustmentsService.tech">
                          <mat-option [value]="RenderTechnology.WebGL">
                            WebGL
                          </mat-option>
                          <mat-option [value]="RenderTechnology.WebGPU">
                            WebGPU
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </span>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
        <div *ngIf="resource?.viewer_type == '3JS'" class="float-dir">
          <div class="auto-adjust-btn">
            <button aria-label="auto-adjust scene" mat-raised-button color="primary"
              (click)="assetAdjustmentsService.adjustScene()">
              auto-adjust scene
            </button>
          </div>
          <!-- <button mat-button color="primary" (click)="assetAdjustmentsService.emulateTarget()" *ngIf="isSU">
            emulate target
          </button> -->
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="tabIndexChange(LIGHTING)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            LIGHTING
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content-va">
          <div>
            <div fxLayout="row" fxLayoutGap="10px grid" class="flex-wrap">
              <span *ngFor="let l of lights; let i = index" fxFlex>
                <button aria-label="content-va" class="short" color="primary" mat-raised-button (click)="changeLight(i)"
                  [ngClass]="{'mixed': i === currentLight}">
                  <ng-container *ngIf="l.nickname">{{ l.nickname }}</ng-container>
                  <ng-container *ngIf="!l.nickname">{{ l.name }} {{l.index + 1}}</ng-container>
                </button>
              </span>
            </div>
          </div>
          <div class="marg-top">
            <button aria-label="add Light" mat-button [matMenuTriggerFor]="lightsMenu">
              <mat-icon>add</mat-icon> Add Light
            </button>
            <button matTooltip="duplicate current light" *ngIf="currentLight !== undefined && currentLight !== null"
              aria-label="duplicate light" (click)="duplicateLight()" mat-button>
              <mat-icon>content_copy</mat-icon> Duplicate light
            </button>
            <mat-menu #lightsMenu="matMenu">
              <button aria-label="add" *ngFor="let l of allLightTypes" mat-button (click)="addLight(l.key)">
                <mat-icon>add</mat-icon>
                {{l.value}}
              </button>
            </mat-menu>
            <button aria-label="delete_forever" color="warn" mat-button (click)="removeAllLights()"
              matTooltip="delete all lights" class="float-op-dir">
              <mat-icon color="warn">delete_forever</mat-icon>
            </button>
          </div>
          <div *ngIf="lights[currentLight]" class="current-light bg-gray6 marg-top">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxFill>
                <mat-form-field class="w-100 lowercase">
                  <input type="text" matInput
                    [placeholder]="lights[currentLight].name + ' ' + (lights[currentLight].index + 1) + ' name'"
                    [(ngModel)]="lights[currentLight].nickname" (change)="changeLightNickname(lights[currentLight])">
                </mat-form-field>
              </div>
            </div>
            <button aria-label="delete" color="warn" mat-button (click)="deleteLight()"
              matTooltip="delete current light">
              <mat-icon color="warn">delete</mat-icon>
            </button>
            <hexa-toggle
              [(ngModel)]="assetAdjustmentsService.soloLights[lights[currentLight].options.type][lights[currentLight].index]"
              (toggle)="soloChanged()" *ngIf="assetAdjustmentsService.soloLights[lights[currentLight].options.type]">
              isolate light</hexa-toggle>
            <app-light-manager class="disp-block" [light]="lights[currentLight]"></app-light-manager>
          </div>
          <h2 class="gray sub-title">shadow plane:</h2>
          <div *ngFor="let param of assetAdjustmentsService.parameters | assetAdjustmentsFilter : SHADOW">
            <div *ngIf="param.type == SLIDER && param.name == 'opacity'">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex *ngIf="param.hasToggle" class="slider-checkbox">
                  <mat-checkbox color="primary" [(ngModel)]="param.toggleState"
                    (change)="assetAdjustmentsService.changeValue(param)">
                  </mat-checkbox>
                </div>
                <mat-slider role="slider" aria-label="shadow plane" [fxFlex]="param.hasToggle ? 70 : 50"
                  [min]="param.min" [max]="param.max" [step]="param.step" color="primary" discrete>
                  <input matSliderThumb [(ngModel)]="param.value"
                    (valueChange)="assetAdjustmentsService.changeValue(param)"
                    (input)="assetAdjustmentsService.changeValue(param, {value: $event})">
                </mat-slider>
                <div fxFlex *ngIf="param.hasColor" class="gray default">
                  <label>&nbsp;
                    <input aria-label="color" type="color" [(ngModel)]="param.color"
                      (change)="assetAdjustmentsService.changeColor(param, $event)">
                  </label>
                </div>
                <div fxFlex *ngIf="param.default" class="gray default">
                  ({{param.default | number : '1.0-2'}})
                </div>
              </div>
            </div>
            <div *ngIf="param.type == SLIDER && param.name != 'opacity'" class="w-100 float-dir">
              <mat-slider role="slider" aria-label="viewer" [min]="param.min" [max]="param.max" [step]="param.step"
                color="primary" discrete>
                <input [(ngModel)]="param.value" (change)="assetAdjustmentsService.changeValue(param)"
                  (valueChange)="assetAdjustmentsService.changeValue(param, {value: $event})" matSliderThumb>
              </mat-slider>
              <span *ngIf="param.default" class="gray default">
                {{param.name}} ({{param.default | number : '1.0-2'}})
              </span>
            </div>
            <div *ngIf="param.type === TOGGLE">
              <hexa-toggle [(ngModel)]="param.value" (toggle)="assetAdjustmentsService.changeValue(param)" class="outline-toggle">
                {{param.name}}</hexa-toggle>
              <ng-container *ngIf="param.value && param.children?.length">
                <div fxLayout="column">
                  <div fxFlex *ngFor="let child of param.children">
                    <div fxFlex *ngIf="child.type == SLIDER">
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxFlex>
                          <mat-form-field class="mat-mdc-form-field-short">
                            <mat-label>{{child.name}}</mat-label>
                            <input type="number" matInput [(ngModel)]="child.value"
                              (change)="assetAdjustmentsService.changeValue(child)">
                          </mat-form-field>
                        </div>
                        <mat-slider role="slider" aria-label="viewer" fxFlex="70" [min]="child.min" [max]="child.max"
                          [step]="child.step" discrete>
                          <input [(ngModel)]="child.value"
                            (valueChange)="assetAdjustmentsService.changeValue(child)"
                            (input)="assetAdjustmentsService.changeValue(child, {value: $event})" matSliderThumb>
                        </mat-slider>
                        <div fxFlex *ngIf="child.default" class="gray default">
                          ({{child.default | number : '1.0-2'}})
                        </div>
                      </div>
                    </div>
                    <div class="select-param" *ngIf="child.type == SELECT">
                      <mat-form-field>
                        <mat-select role="mat-select" [placeholder]="child.name" [multiple]="child.multiple"
                          [(ngModel)]="child.value" (selectionChange)="assetAdjustmentsService.changeValue(child)">
                          <mat-option role="option" *ngFor="let o of child.selectOptions" [value]="o.key">
                            {{ o.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="tabIndexChange(ANNOTATIONS)" [disabled]="resource?.viewer_type != '3JS'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            DISPLAY EFFECTS
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content-va">
          <div *ngFor="let param of assetAdjustmentsService.parameters | assetAdjustmentsFilter : ANNOTATIONS">
            <div fxFlex *ngIf="param.type == TOGGLE">
              <hexa-toggle [(ngModel)]="param.value" (toggle)="assetAdjustmentsService.changeValue(param)" class="outline-toggle">
                {{param.name}}</hexa-toggle>
            </div>
          </div>
          <ng-container *ngIf="isSU">
            <mat-card *ngFor="let annotation of assetAdjustmentsService.annotations;let i = index"
              class="space-top bg-gray3 mat-card box-shadow">
              <mat-card-header>
                <mat-card-subtitle>Annotation {{i + 1}}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <div>
                  <mat-form-field fxFill>
                    <input type="text" (keyup)="updateAnnotations()" matInput placeholder="text"
                      [(ngModel)]="annotation.title">
                  </mat-form-field>
                </div>
                <ng-container *ngIf="annotation.title">

                  <div fxLayout="row" fxLayoutAlign="space-between center" class="h48">
                    <span fxFlex>Dynamic/Fixed text</span>
                    <span fxFlex>
                      <hexa-toggle (toggle)="updateAnnotations()" [(ngModel)]="annotation.textAlwaysAppears">
                      </hexa-toggle>
                    </span>
                  </div>

                  <div *ngIf="!annotation.textAlwaysAppears" fxLayout="row" fxLayoutAlign="space-between center"
                    class="h48">
                    <span fxFlex>Display text duration</span>
                    <span fxFlex>
                      <mat-slider [min]="3" [max]="20" [step]="1" discrete>
                        <input [(ngModel)]="annotation.textDuration" (valueChange)="updateAnnotations()" matSliderThumb>
                      </mat-slider>
                    </span>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="space-between center" class="h48">
                    <span fxFlex>Text color</span>
                    <span fxFlex>
                      <input type="color" [(ngModel)]="annotation.color" (change)="updateAnnotations()">
                    </span>
                  </div>
                </ng-container>
                <div fxLayout="row" fxLayoutAlign="space-between center" class="h48">
                  <span fxFlex>Edit position</span>
                  <span fxFlex>
                    <hexa-toggle (toggle)="updateAnnotations()" [(ngModel)]="annotation.editMode">
                    </hexa-toggle>
                  </span>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center" class="h48">
                  <span fxFlex>Upload image</span>
                  <span fxFlex>
                    <app-file-upload [icon]="'attach_file'" [drop-mode]="true" [text]="'upload image'"
                      [miltiple]="false" (filesChange)="onAddAnnotationImg($event, annotation)" [accept]="'image/*'"
                      class="bg dashed disp-block">
                    </app-file-upload>
                  </span>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center" class="h48">
                  <span fxFlex>Image size</span>
                  <span fxFlex>
                    <mat-slider [min]="1" [max]="3" [step]="0.3" discrete>
                      <input (change)="updateAnnotations()" [(ngModel)]="annotation.imageScale" matSliderThumb>
                    </mat-slider>
                  </span>
                </div>

                <div *ngIf="assetAdjustmentsService.hasMeshAnimations">
                  <div *ngFor="let a of annotation.meshAnimations | keyValuePure" fxLayout="row"
                    fxLayoutAlign="space-between center">
                    <div fxFlex>{{a.key}}</div>
                    <hexa-toggle fxFlex [(ngModel)]="a.value.active" (toggle)="updateAnnotations()">
                      active
                    </hexa-toggle>
                    <hexa-toggle fxFlex [(ngModel)]="a.value.once" (toggle)="updateAnnotations()">
                      run once
                    </hexa-toggle>
                  </div>
                </div>
              </mat-card-content>
              <mat-card-actions>
                <button mat-raised-button color="primary" (click)="setCurrentAnnotationPos(annotation)"
                  matTooltip="set target position">
                  anchor target
                </button>
                <button aria-label="delete annotation" color="warn" mat-button (click)="removeAnnotations(annotation)"
                  matTooltip="delete annotation">
                  delete
                </button>
              </mat-card-actions>
            </mat-card>
            <div class="space-top">
              <button mat-raised-button color="primary" (click)="addAnnotation()" class="mixed">
                add annotation
              </button>
            </div>
          </ng-container>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="tabIndexChange(HIERARCHY_TREE)" [disabled]="resource?.viewer_type != '3JS'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            HIERARCHY
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <ng-container *ngIf="assetAdjustmentsService.sceneHierarchy?.children?.length">
            <app-scene-hierarchy [hierarchy]="assetAdjustmentsService.sceneHierarchy"
              (on-node-select)="onNodeSelect($event)"></app-scene-hierarchy>
          </ng-container>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="tabIndexChange(POSTPROCESSING)" [disabled]="resource.viewer_type != '3JS'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            POSTPROCESSING
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="marg-top" id="postprocessing">
            <div *ngFor="let param of assetAdjustmentsService.parameters | assetAdjustmentsFilter : POSTPROCESSING"
                [ngClass]="param.cssClass">
                <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="param.type == SLIDER">
                  <div fxFlex>
                    <mat-form-field class="mat-mdc-form-field-short">
                      <input type="number" matInput [(ngModel)]="param.value"
                        (change)="assetAdjustmentsService.changeValue(param)" [disabled]="param.disabled">
                    </mat-form-field>
                  </div>
                  <mat-slider fxFlex="70" [min]="param.min" [max]="param.max" [step]="param.step"discrete
                    [disabled]="param.disabled">
                    <mat-label>{{param.name}}</mat-label>
                    <input [(ngModel)]="param.value"
                      (valueChange)="assetAdjustmentsService.changeValue(param)"
                      (input)="assetAdjustmentsService.changeValue(param, {value: $event})" matSliderThumb>
                  </mat-slider>
                  <div fxFlex *ngIf="param.default" class="gray default">
                    ({{param.default | number : '1.0-2'}})
                  </div>
                </div>
            </div>
            <button aria-label="reset postprocessing" mat-button color="primary" (click)="assetAdjustmentsService.resetSVGPostprocessing()">
                reset
            </button>
            <p>
                Bloom effect can be defined on the mesh level under the <a class="primary-color bold pointer" (click)="navToMeshes()">MESHES</a> section
            </p>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel #materialsPanel="matExpansionPanel" (opened)="tabIndexChange(MATERIALS)"
        [disabled]="resource?.viewer_type != '3JS'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            MATERIALS
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="pos-rel content-va" id="textures">
          <div *ngFor="let param of assetAdjustmentsService.parameters | assetAdjustmentsFilter : MATERIALS">
            <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="param.type == SLIDER">
              <div fxFlex>
                <mat-form-field class="mat-mdc-form-field-short">
                  <mat-label>{{param.name}}</mat-label>
                  <input type="number" matInput [(ngModel)]="param.value"
                    (change)="assetAdjustmentsService.changeValue(param)">
                </mat-form-field>
              </div>
              <label>
                <input class="range" type="range" fxFlex="70" [min]="param.min" [max]="param.max" [step]="param.step"
                  [(ngModel)]="param.value" (change)="assetAdjustmentsService.changeValue(param)">
              </label>
              <div fxFlex *ngIf="param.default" class="gray default">
                ({{param.default | number : '1.0-2'}})
              </div>
            </div>
            <div class="select-param" *ngIf="param.type === SELECT">
              <mat-form-field>
                <mat-select role="mat-select" [placeholder]="param.name" [(ngModel)]="param.value"
                  (change)="assetAdjustmentsService.changeValue(param)">
                  <mat-option role="option" *ngFor="let o of param.selectOptions" [value]="o.key">
                    {{ o.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="center-me" *ngIf="param.type === TOGGLE">
              <hexa-toggle [(ngModel)]="param.value" (toggle)="assetAdjustmentsService.changeValue(param)">
                {{param.name}}</hexa-toggle>
            </div>
          </div>
          <div>
            <div *ngIf="assetAdjustmentsService.materials">
              <mat-form-field>
                <mat-select role="mat-select" placeholder="materials"
                  [(ngModel)]="assetAdjustmentsService.currentMaterialIndex" (selectionChange)="onMaterialChange()">
                  <mat-option role="option" *ngFor="let material of assetAdjustmentsService.materials;let i = index"
                    [value]="i">
                    {{ material.name }} ({{ material.type }})
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="padd-bottom-60">
                <hexa-toggle class="outline-toggle" [(ngModel)]="imageManipulation"
                  (toggle)="imageManipulationChange()">allow image manipulation
                </hexa-toggle>
                <hexa-toggle class="outline-toggle"
                  [(ngModel)]="assetAdjustmentsService.showOutline" (toggle)="toggleOutline()">show
                  outline
                </hexa-toggle>
              </div>
              <div
                *ngIf="assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex] && assetAdjustmentsService.materialManipulations[assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].name]">
                <div>
                  <mat-form-field>
                    <mat-label>material name</mat-label>
                    <input type="text" matInput [(ngModel)]="newMaterialName"
                      (change)="assetAdjustmentsService.setMaterialName(assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex], newMaterialName)">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-select role="mat-select" placeholder="change material type"
                      [(ngModel)]="assetAdjustmentsService.materialManipulations[assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].name].type"
                      (selectionChange)="onMaterialTypeChange()">
                      <mat-option role="option" *ngFor="let mt of assetAdjustmentsService.materialTypes"
                        [value]="mt.key">
                        {{ mt.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <ng-contailer *ngIf="hasReflectivity()">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span fxFlex class="text-center">reflectivity</span>
                    <span fxFlex class="text-center">
                      <mat-slider role="slider" aria-label="viewer" [min]="0" [max]="2" [step]="0.1" discrete
                        class="float-op-dir">
                        <input (valueChange)="onMaterialPropChange(false)" matSliderThumb
                          [(ngModel)]="assetAdjustmentsService.materialManipulations[assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].name].reflectivity">
                      </mat-slider>
                    </span>
                  </div>
                  <mat-divider class="marg-top-half marg-bottom-half"></mat-divider>
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span fxFlex class="text-center">clear coat</span>
                    <span fxFlex class="text-center">
                      <mat-slider role="slider" aria-label="clear coat" [min]="0" [max]="1" [step]="0.05"
                        discrete class="float-op-dir">
                        <input [(ngModel)]="assetAdjustmentsService.materialManipulations[assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].name].clearCoat"
                          (valueChange)="onMaterialPropChange(false)" matSliderThumb>
                      </mat-slider>
                    </span>
                  </div>
                  <mat-divider class="marg-top-half marg-bottom-half"></mat-divider>
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span fxFlex class="text-center">clear coat roughness</span>
                    <span fxFlex class="text-center">
                      <mat-slider role="slider" aria-label="clear coat roughness" [min]="0" [max]="1" [step]="0.05"
                        discrete class="float-op-dir">
                        <input matSliderThumb (valueChange)="onMaterialPropChange(false)"
                          [(ngModel)]="assetAdjustmentsService.materialManipulations[assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].name].clearCoatRoughness">
                      </mat-slider>
                    </span>
                  </div>
                  <mat-divider class="marg-top-half marg-bottom-half"></mat-divider>
                </ng-contailer>
                <div class="marg-top" fxLayout="row" fxLayoutAlign="space-between center">
                  <span fxFlex class="text-center">base color</span>
                  <span fxFlex class="text-center">
                    <label>
                      <input aria-label="color" type="color" (change)="onMaterialPropChange(true)"
                        [(ngModel)]="assetAdjustmentsService.materialManipulations[assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].name].color">
                    </label>
                  </span>
                </div>
                <mat-divider class="marg-top"></mat-divider>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <span fxFlex class="text-center marg-top">transparent</span>
                  <span fxFlex class="text-center">
                    <hexa-toggle (toggle)="onMaterialPropChange(true)"
                      [(ngModel)]="assetAdjustmentsService.materialManipulations[assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].name].transparent">
                    </hexa-toggle>
                  </span>
                </div>
                <div
                  *ngIf="assetAdjustmentsService.materialManipulations[assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].name].transparent">
                  <mat-divider class="marg-top"></mat-divider>
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span fxFlex class="text-center marg-top">opacity</span>
                    <span fxFlex class="text-center">
                      <mat-slider role="slider" aria-label="opacity" [min]="0" [max]="1" [step]="0.01" discrete>
                        <input matSliderThumb (valueChange)="onMaterialPropChange(true)"
                          [(ngModel)]="assetAdjustmentsService.materialManipulations[assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].name].opacity">
                      </mat-slider>
                    </span>
                  </div>
                  <mat-divider class="marg-top"></mat-divider>
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span fxFlex class="text-center marg-top">alpha test</span>
                    <span fxFlex class="text-center">
                      <mat-slider role="slider" aria-label="alpha test" [min]="0" [max]="1" [step]="0.01" discrete>
                          <input (change)="onMaterialPropChange(true)" matSliderThumb
                            [(ngModel)]="assetAdjustmentsService.materialManipulations[assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].name].alphaTest">
                        </mat-slider>
                    </span>
                  </div>
                </div>
                <mat-divider class="marg-top"></mat-divider>
                <div class="marg-top">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-form-field>
                      <mat-select role="mat-select" placeholder="render sides"
                        [(ngModel)]="assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].side"
                        (selectionChange)="assetAdjustmentsService.setMaterialProps(assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex], 'side', assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].side, true)">
                        <mat-option role="option" [value]="s.key" *ngFor="let s of renderSides">
                          {{s.value}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <ng-container
              *ngIf="assetAdjustmentsService.materials && assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex]">
              <form class="marg-top float-dir w-100">
                <mat-accordion *ngFor="let map of assetAdjustmentsService.materialMaps">
                  <mat-expansion-panel
                    [ngClass]="{'bg-gray3': doesMaterialHasMap(assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex], map)}"
                    [expanded]="assetAdjustmentsService.materialAccordion[assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].name + '_' + map]"
                    (opened)="toggleMaterialExpandedState(assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].name, map, true)"
                    (closed)="toggleMaterialExpandedState(assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].name, map, false)">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="lowercase {{map}}">
                        {{getMapAlias(map) | spaceUpper}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                      <app-texture-map [intensity]="mapsDictionary" [type]="map"
                        [mapping-changed]="assetAdjustmentsService.mappingChanged"
                        [image-manipulation]="imageManipulation" [changes]="assetAdjustmentsService.counter"
                        [is-open]="assetAdjustmentsService.materialAccordion[assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].name + '_' + map]">
                      </app-texture-map>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
                <app-file-upload [icon]="'attach_file'" [drop-mode]="true" [text]="'add texture files'"
                  [miltiple]="true" (filesChange)="onFilesChange($event)" [accept]="'image/*,video/*'"
                  class="dashed disp-block marg-top">
                </app-file-upload>
                <app-progress-bar *ngFor="let p of fileUploadsProgress" [percentage]="p"></app-progress-bar>
              </form>
              <mat-accordion class="marg-top float-dir w-100 customized">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="lowercase">
                      textures matrix
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div fxLayout="row" fxLayoutAlign="space-between center" class="flex-wrap">
                    <div fxFlex="100">
                      <mat-form-field fxFill>
                        <mat-label>rotation</mat-label>
                        <input type="number" matInput
                          [(ngModel)]="assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].rotation"
                          (change)="assetAdjustmentsService.setTexturesPropsByMaterial(assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex], 'rotation', assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].rotation, true)">
                      </mat-form-field>
                    </div>
                    <ng-container
                      *ngIf="assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].offset">
                      <mat-form-field fxFlex="50">
                        <mat-label>offset x</mat-label>
                        <input type="number" matInput step="0.05"
                          [(ngModel)]="assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].offset.x"
                          (change)="assetAdjustmentsService.setTexturesPropsByMaterial(assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex], 'offset', assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].offset, true)">
                      </mat-form-field>
                      <mat-form-field fxFlex="50">
                        <mat-label>offset y</mat-label>
                        <input type="number" matInput placeholder="offset y" step="0.05"
                          [(ngModel)]="assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].offset.y"
                          (change)="assetAdjustmentsService.setTexturesPropsByMaterial(assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex], 'offset', assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].offset, true)">
                      </mat-form-field>
                    </ng-container>
                    <ng-container
                      *ngIf="assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].repeat">
                      <mat-form-field fxFlex="50">
                        <mat-label>repeat x</mat-label>
                        <input type="number" matInput step="0.1"
                          [(ngModel)]="assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].repeat.x"
                          (change)="assetAdjustmentsService.setTexturesPropsByMaterial(assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex], 'repeat', assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].repeat, true)">
                      </mat-form-field>
                      <mat-form-field fxFlex="50">
                        <mat-label>repeat y</mat-label>
                        <input type="number" matInput step="0.1"
                          [(ngModel)]="assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].repeat.y"
                          (change)="assetAdjustmentsService.setTexturesPropsByMaterial(assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex], 'repeat', assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].repeat, true)">
                      </mat-form-field>
                    </ng-container>
                    <ng-container
                      *ngIf="assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].center">
                      <mat-form-field fxFlex="50">
                        <mat-label>center x</mat-label>
                        <input type="number" matInput placeholder="center x" step="0.1"
                          [(ngModel)]="assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].center.x"
                          (change)="assetAdjustmentsService.setTexturesPropsByMaterial(assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex], 'center', assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].center, true)">
                      </mat-form-field>
                      <mat-form-field fxFlex="50">
                        <mat-label>center y</mat-label>
                        <input type="number" matInput step="0.1"
                          [(ngModel)]="assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].center.y"
                          (change)="assetAdjustmentsService.setTexturesPropsByMaterial(assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex], 'center', assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].center, true)">
                      </mat-form-field>
                    </ng-container>
                    <mat-form-field fxFlex="50">
                      <mat-select role="mat-select" placeholder="textures wrapS type"
                        [(ngModel)]="assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].wrapS"
                        (selectionChange)="assetAdjustmentsService.setTexturesPropsByMaterial(assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex], 'wrapS', assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].wrapS, true)">
                        <mat-option role="option" [value]="t.key" *ngFor="let t of textureWrapTypes">
                          {{t.value}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="50">
                      <mat-select role="mat-select" placeholder="textures wrapT type"
                        [(ngModel)]="assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].wrapT"
                        (selectionChange)="assetAdjustmentsService.setTexturesPropsByMaterial(assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex], 'wrapT', assetAdjustmentsService.materials[assetAdjustmentsService.currentMaterialIndex].wrapT, true)">
                        <mat-option role="option" [value]="t.key" *ngFor="let t of textureWrapTypes">
                          {{t.value}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </ng-container>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="tabIndexChange(MESHES)" (closed)="assetAdjustmentsService.setRaycasterState(false)"
        [disabled]="resource?.viewer_type != '3JS'" #meshsPanel="matExpansionPanel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            MESHES
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content-va" id="meshs">
          <mat-menu #appMenu="matMenu">
            <button aria-label="all meshes cast shadow" mat-menu-item
              (click)="assetAdjustmentsService.setMeshesCastShadow(true)">
              all meshes cast shadow
            </button>
            <button aria-label="all meshes don't cast shadow" mat-menu-item
              (click)="assetAdjustmentsService.setMeshesCastShadow(false)">
              all meshes don't cast shadow
            </button>
            <button aria-label="all meshes receive shadow" mat-menu-item
              (click)="assetAdjustmentsService.setMeshesReceiveShadow(true)">
              all meshes receive shadow
            </button>
            <button aria-label="all meshes don't receive shadow" mat-menu-item
              (click)="assetAdjustmentsService.setMeshesReceiveShadow(false)">
              all meshes don't receive shadow
            </button>
          </mat-menu>
          <button aria-label="more vert" mat-icon-button [matMenuTriggerFor]="appMenu" class="more-mesh-options">
            <mat-icon>more_vert</mat-icon>
          </button>
          <div class="space-top">
            <ng-container *ngIf="!assetAdjustmentsService.selectedMesh">
              <div *ngFor="let param of assetAdjustmentsService.parameters | assetAdjustmentsFilter : MESHES">
                <div *ngIf="param.type == TOGGLE" [ngClass]="{'has-color': param.hasColor}">
                  <hexa-toggle [(ngModel)]="param.value" (toggle)="assetAdjustmentsService.changeValue(param)">
                    {{param.name}}
                  </hexa-toggle>
                </div>
              </div>
              <br>
            </ng-container>
            <div *ngIf="assetAdjustmentsService.selectedMesh" class="meshes-data">
              <div class="pos-rel mesh-item">
                <button aria-label="highlight off" mat-icon-button class="pos-abs toggle" matTooltip="clear"
                  (click)="toggleMeshByName(assetAdjustmentsService.selectedMesh.name)">
                  <mat-icon>highlight_off</mat-icon>
                </button>
                <button aria-label="delete forever" mat-icon-button
                  (click)="deleteMesh(assetAdjustmentsService.selectedMesh)" color="warn" class="pos-abs delete"
                  matTooltip="delete mesh">
                  <mat-icon>delete_forever</mat-icon>
                </button>
                <mat-form-field>
                  <mat-label>mesh name</mat-label>
                  <input type="text" [ngStyle]='{"color": assetAdjustmentsService.selectedMesh.color}'
                    [(ngModel)]="assetAdjustmentsService.selectedMesh.name" matInput (focus)="saveMeshLastName()"
                    (change)="setMeshName()">
                </mat-form-field>
                <app-autocomplete *ngIf="materializeMeshes && materializeMeshes.length > 0" [items]="materializeMeshes"
                  [main-field]="'key'" [placeholder]="'select texture'" (item-change)="setTextureToMesh($event)"
                  [identifier]="'value'">
                </app-autocomplete>
                <div *ngIf="meshNames?.length">
                  <mat-form-field>
                    <mat-select role="mat-select" placeholder="possible mesh names"
                      (selectionChange)="selectMeshName($event)">
                      <mat-option role="option" [value]="mn.mesh_name" *ngFor="let mn of meshNames">
                        {{mn.mesh_name}} <ng-container *ngIf="mn.mesh_description">({{mn.mesh_description}})
                        </ng-container>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <ul>
                  <li>
                    positions/translation: (
                    <mat-form-field class="max-w-50px">
                      <mat-label>x</mat-label>
                      <input size="1" type="number" [(ngModel)]="assetAdjustmentsService.selectedMesh.position.x"
                        matInput
                        (change)="assetAdjustmentsService.setMeshProps(assetAdjustmentsService.selectedMesh, 'position', assetAdjustmentsService.selectedMesh.position, true)">
                    </mat-form-field>,
                    <mat-form-field class="max-w-50px">
                      <mat-label>y</mat-label>
                      <input size="1" type="number" [(ngModel)]="assetAdjustmentsService.selectedMesh.position.y"
                        matInput
                        (change)="assetAdjustmentsService.setMeshProps(assetAdjustmentsService.selectedMesh, 'position', assetAdjustmentsService.selectedMesh.position, true)">
                    </mat-form-field>,
                    <mat-form-field class="max-w-50px">
                      <mat-label>z</mat-label>
                      <input size="1" type="number" [(ngModel)]="assetAdjustmentsService.selectedMesh.position.z"
                        matInput
                        (change)="assetAdjustmentsService.setMeshProps(assetAdjustmentsService.selectedMesh, 'position', assetAdjustmentsService.selectedMesh.position, true)">
                    </mat-form-field>)
                  </li>
                  <li>
                    rotation: (
                    <mat-form-field class="max-w-50px">
                      <mat-label>x</mat-label>
                      <input size="1" type="number"
                        [(ngModel)]="assetAdjustmentsService.selectedMesh.rotationDegree.x" matInput
                        (change)="assetAdjustmentsService.setMeshProps(assetAdjustmentsService.selectedMesh, 'rotation', assetAdjustmentsService.selectedMesh.rotation, true)">
                    </mat-form-field>,
                    <mat-form-field class="max-w-50px">
                      <mat-label>y</mat-label>
                      <input size="1" type="number"
                        [(ngModel)]="assetAdjustmentsService.selectedMesh.rotationDegree.y" matInput
                        (change)="assetAdjustmentsService.setMeshProps(assetAdjustmentsService.selectedMesh, 'rotation', assetAdjustmentsService.selectedMesh.rotation, true)">
                    </mat-form-field>,
                    <mat-form-field class="max-w-50px">
                      <mat-label>z</mat-label>
                      <input size="1" type="number"
                        [(ngModel)]="assetAdjustmentsService.selectedMesh.rotationDegree.z" matInput
                        (change)="assetAdjustmentsService.setMeshProps(assetAdjustmentsService.selectedMesh, 'rotation', assetAdjustmentsService.selectedMesh.rotation, true)">
                    </mat-form-field>)
                  </li>
                  <li>
                    scale: (
                    <mat-form-field class="max-w-50px">
                      <mat-label>x</mat-label>
                      <input size="1" type="number" [(ngModel)]="assetAdjustmentsService.selectedMesh.scale.x"
                        matInput
                        (change)="assetAdjustmentsService.setMeshProps(assetAdjustmentsService.selectedMesh, 'scale', assetAdjustmentsService.selectedMesh.scale, true)">
                    </mat-form-field>,
                    <mat-form-field class="max-w-50px">
                      <mat-label>y</mat-label>
                      <input size="1" type="number" [(ngModel)]="assetAdjustmentsService.selectedMesh.scale.y"
                        matInput
                        (change)="assetAdjustmentsService.setMeshProps(assetAdjustmentsService.selectedMesh, 'scale', assetAdjustmentsService.selectedMesh.scale, true)">
                    </mat-form-field>,
                    <mat-form-field class="max-w-50px">
                      <mat-label>z</mat-label>
                      <input size="1" type="number" [(ngModel)]="assetAdjustmentsService.selectedMesh.scale.z"
                        matInput
                        (change)="assetAdjustmentsService.setMeshProps(assetAdjustmentsService.selectedMesh, 'scale', assetAdjustmentsService.selectedMesh.scale, true)">
                    </mat-form-field>)
                  </li>
                  <li>
                    material: {{assetAdjustmentsService.selectedMesh.materialName}}
                  </li>
                  <li>
                    <hexa-toggle [(ngModel)]="assetAdjustmentsService.selectedMesh.ignoreBB"
                      (toggle)="assetAdjustmentsService.setMeshProps(assetAdjustmentsService.selectedMesh, 'ignoreBB', assetAdjustmentsService.selectedMesh.ignoreBB)">
                      exclude from bounding box
                    </hexa-toggle>
                  </li>
                  <li>
                    <hexa-toggle [(ngModel)]="assetAdjustmentsService.selectedMesh.castShadow"
                      (toggle)="assetAdjustmentsService.setMeshProps(assetAdjustmentsService.selectedMesh, 'castShadow', assetAdjustmentsService.selectedMesh.castShadow)">
                      cast shadow
                    </hexa-toggle>
                  </li>
                  <li>
                    <hexa-toggle [(ngModel)]="assetAdjustmentsService.selectedMesh.receiveShadow"
                      (toggle)="assetAdjustmentsService.setMeshProps(assetAdjustmentsService.selectedMesh, 'receiveShadow', assetAdjustmentsService.selectedMesh.receiveShadow)">
                      receive shadow
                    </hexa-toggle>
                  </li>
                  <li>
                    <hexa-toggle [(ngModel)]="assetAdjustmentsService.selectedMesh.bloom"
                      (toggle)="assetAdjustmentsService.setMeshProps(assetAdjustmentsService.selectedMesh, 'bloom', assetAdjustmentsService.selectedMesh.bloom)">
                      bloom effect
                    </hexa-toggle>
                  </li>
                </ul>
              </div>
            </div>
            <div *ngIf="assetAdjustmentsService.selectedMesh && assetAdjustmentsService.materials">
              <mat-form-field fxFill>
                <mat-select role="mat-select" placeholder="material"
                  [(ngModel)]="assetAdjustmentsService.selectedMesh.materialName"
                  (selectionChange)="assetAdjustmentsService.changeMeshMaterial(assetAdjustmentsService.selectedMesh.materialName, assetAdjustmentsService.selectedMesh)">
                  <mat-option role="option" [value]="null">
                    new material
                  </mat-option>
                  <mat-option [value]="m.name" *ngFor="let m of assetAdjustmentsService.materials">
                    {{m.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="10px grid" class="flex-wrap" *ngIf="assetAdjustmentsService.meshesData">
              <span
                *ngFor="let data of assetAdjustmentsService.meshesData | keyValuePure : assetAdjustmentsService.counter"
                fxFlex>
                <button aria-label="meshes data name" class="short" color="primary" mat-raised-button
                  [ngClass]="{'mixed': assetAdjustmentsService.selectedMesh?.name == data.value.name}"
                  (click)="toggleMeshByName(data.value.name)">
                  {{data.value.name}}
                </button>
              </span>
            </div>
            <div *ngIf="assetAdjustmentsService.numOfBloomMeshes">
              <h3 class="gray capitalize">
                Bloom Effect
              </h3>
              <div>
                <div class="space-top">
                  <div class="gray sub-title">
                    threshold
                  </div>
                  <mat-slider role="slider" aria-label="viewer" fxFill [min]="0" [max]="1" [step]="0.05"
                    discrete>
                    <input [(ngModel)]="assetAdjustmentsService.meshBloomOptions.luminanceThreshold" 
                      (valueChange)="assetAdjustmentsService.onMeshBloomOptions()"
                      (input)="assetAdjustmentsService.onMeshBloomOptions({value: $event.target.value}, 'luminanceThreshold')" matSliderThumb>
                  </mat-slider>
                </div>
                <div class="space-top">
                  <div class="gray sub-title">
                    smoothing
                  </div>
                  <mat-slider role="slider" aria-label="smoothing" role="slider" aria-label="viewer" fxFill [min]="0"
                    [max]="1" [step]="0.05" discrete>
                    <input (valueChange)="assetAdjustmentsService.onMeshBloomOptions()"
                      [(ngModel)]="assetAdjustmentsService.meshBloomOptions.luminanceSmoothing"
                      (input)="assetAdjustmentsService.onMeshBloomOptions($event, 'luminanceSmoothing')" matSliderThumb>
                  </mat-slider>
                </div>
                <div class="space-top">
                  <div class="gray sub-title">
                    intensity
                  </div>
                  <mat-slider role="slider" aria-label="intensity" fxFill [min]="0" [max]="10" [step]="0.05" discrete>
                    <input [(ngModel)]="assetAdjustmentsService.meshBloomOptions.intensity" 
                      (valueChange)="assetAdjustmentsService.onMeshBloomOptions()"
                      (input)="assetAdjustmentsService.onMeshBloomOptions({value: $event}, 'intensity')" matSliderThumb>
                  </mat-slider>
                </div>
                <mat-form-field class="space-top">
                  <mat-label>dimensions</mat-label>
                  <input type="number" matInput
                    [(ngModel)]="assetAdjustmentsService.meshBloomOptions.height"
                    (change)="assetAdjustmentsService.onMeshBloomOptions()"
                    (input)="assetAdjustmentsService.onMeshBloomOptions($event, 'height')">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="tabIndexChange(MESH_ANIMATIONS)" [disabled]="resource?.viewer_type != '3JS'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            ANIMATIONS
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <ng-container *ngIf="!assetAdjustmentsService.hasMeshAnimations">
            Model contains no animations
          </ng-container>
          <ng-container *ngIf="assetAdjustmentsService.hasMeshAnimations">
            <div *ngFor="let a of assetAdjustmentsService.meshAnimations | keyValuePure">
              <hexa-toggle [(ngModel)]="a.value.active" (toggle)="assetAdjustmentsService.updateMeshAnimations()">
                {{a.key}}
              </hexa-toggle>
            </div>
            <p>Toggle all animations</p>
            <mat-button-toggle-group name="toggle-stats" aria-label="Toggle Stats">
              <mat-button-toggle (click)="toggleAllAnimations(true)" [value]="true">On</mat-button-toggle>
              <mat-button-toggle (click)="toggleAllAnimations(false)" [value]="false">Off</mat-button-toggle>
            </mat-button-toggle-group>
          </ng-container>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<div *ngIf="!embed && !hide" class="actions">
  <div class="progress-wrap"
    *ngIf="assetAdjustmentsService.compressedFiles && assetAdjustmentsService.compressedFiles.started">
    <div class="blink text-center text2 pos-rel export-progress">
      <div class="in">
        compressed {{assetAdjustmentsService.compressedFiles.ended}} /
        {{assetAdjustmentsService.compressedFiles.started}}
        PNG images
      </div>
    </div>
    <app-progress-bar
      [percentage]="assetAdjustmentsService.compressedFiles.ended / assetAdjustmentsService.compressedFiles.started * 100">
    </app-progress-bar>
  </div>
  <div class="progress-wrap" *ngIf="exportProgress">
    <div *ngIf="exportProgress == 100" class="blink text-center text2 pos-rel export-progress">
      <div class="in">
        processing . . .
      </div>
    </div>
    <app-progress-bar [percentage]="exportProgress"></app-progress-bar>
  </div>
  <button aria-label="save scene" class="prime_button short" color="primary" mat-raised-button (click)="saveAll()">
    save scene
  </button>
  <button aria-label="restore all" class="big invert short" color="accent" mat-raised-button
    (click)="assetAdjustmentsService.restoreAll()">
    restore all
  </button>
</div>
<div *ngIf="embed" class="actions">
  <button aria-label="download" class="big mixed short" color="primary" mat-raised-button (click)="exportImagesType()">
    download as {{assetAdjustmentsService.binary ? 'GLB' : 'glTF'}}
  </button>
  <div class="space-top">
    <div class="progress-wrap" *ngIf="assetAdjustmentsHelperService.exportProgress">
      <div *ngIf="assetAdjustmentsHelperService.exportProgress == 100"
        class="blink text-center text2 pos-rel export-progress">
        <div class="in">
          processing . . .
        </div>
      </div>
      <app-progress-bar [percentage]="assetAdjustmentsHelperService.exportProgress"></app-progress-bar>
    </div>
    <div *ngIf="assetAdjustmentsHelperService.isViewerFullyLoaded" [ngClass]="{'share-wrap': shareTutorial}">
      <div class="_share">
        <button aria-label="share" class="big mixed short" color="primary" mat-raised-button
          (click)="assetAdjustmentsHelperService.shareEmbed()" *ngIf="!assetAdjustmentsHelperService.embedLink">
          share (upload)
        </button>
      </div>
      <div *ngIf="assetAdjustmentsHelperService.embedLink">
        <app-embed-code [link]="assetAdjustmentsHelperService.embedLink" [user]="auth.user"></app-embed-code>
        <div>
          <a class="primary-color bold" [routerLink]="['/uploads']">all uploads</a>
        </div>
      </div>
      <div *ngIf="assetAdjustmentsHelperService.shareState && !auth.user">
        You must <a class="primary-color bold" routerLink="/login">login</a> or <a class="primary-color bold"
          routerLink="/onboarding">sign-up</a> upload a model
      </div>
    </div>
  </div>
  <div class="space-top">
    <button aria-label="download as USDZ" color="primary" class="mixed" mat-button
      (click)="assetAdjustmentsService.exportUSDZ()">
      download as USDZ
    </button>
  </div>
  <div class="space-top">
    <button aria-label="download as OBJ" color="primary" mat-button (click)="assetAdjustmentsService.exportOBJ()">
      download as OBJ
    </button>
  </div>
</div>
