import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ProductCategoriesService } from './product-categories.service';
import { ProductCategory } from './product-categories';

@Injectable()
export class CategoriesResolve  {

    constructor(
        private cService: ProductCategoriesService
        ) { }

    resolve() {
        const cached = this.cService.getCachedCategories();
        if (cached && cached.length) {
            return cached;
        }
        return this.cService.getCategories() as Observable<Array<ProductCategory>>;
    }
}