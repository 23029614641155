<!-- <app-header></app-header> -->
<div class="pos-rel login-wrap center-me">
  <div class="pos-abs">
    <a routerLink="/login" class="link pointer">I have an account</a>
  </div>
</div>
<h2 class="title">
  Account Details
</h2>
<div class="wrap">
  <div fxLayout="row" fxLayoutAlign="space-around center" class="w-100">
    <div fxFlex class="inner scrollbar">
      <form [formGroup]="regForm" (ngSubmit)="next()" novalidate [hidden]="state != PERSONAL">
        <div fxLayout="column" fxLayoutAlign="space-between center">
          <div fxFill>
            <mat-form-field fxFill>
              <mat-label>First Name</mat-label>
              <input matInput type="text" formControlName="firstname" required>
              <mat-hint *ngIf="regForm.hasError('required',['firstname']) && regForm.dirty" align="start">first name is required</mat-hint>
            </mat-form-field>
          </div>
          <div fxFill>
            <mat-form-field fxFill>
              <mat-label>Last Name</mat-label>
              <input matInput type="text" formControlName="lastname" required>
              <mat-hint *ngIf="regForm.hasError('required',['lastname']) && regForm.dirty" align="start">last name is required</mat-hint>
            </mat-form-field>
          </div>
          <div fxFill>
            <mat-form-field fxFill>
              <mat-label>Email</mat-label>
              <input matInput type="email" formControlName="email" pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                required>
              <mat-hint *ngIf="regForm.hasError('required',['email']) && regForm.dirty" align="start">e-mail is required</mat-hint>
              <mat-hint *ngIf="regForm.hasError('pattern',['email']) && regForm.dirty" align="start">e-mail not valid</mat-hint>
            </mat-form-field>
          </div>
          <div fxFill>
            <mat-form-field fxFlexFill>
              <mat-label>Password</mat-label>
              <input type="password" matInput formControlName="password" required pattern="^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$">
              <mat-hint *ngIf="regForm.hasError('required',['password']) && regForm.dirty" align="start">password is required</mat-hint>
              <mat-hint *ngIf="regForm.hasError('pattern',['password']) && regForm.dirty" align="start">must contain special
                characters, numbers, small letters, capital letters and at least 8 characters long</mat-hint>
            </mat-form-field>
          </div>
          <div fxFill>
            <mat-form-field fxFlexFill [ngClass]="{'form-field-invalid mat-form-field-invalid ng-invalid': !isPasswordsMatch() && regForm.dirty}">
              <mat-label>Confirm Password</mat-label>
              <input type="password" matInput formControlName="confirm_password" required>
              <!-- <mat-hint *ngIf="regForm.hasError('required','confirm_password') && regForm.dirty" align="start">password confirmation is required</mat-hint> -->
              <mat-hint *ngIf="!isPasswordsMatch() && regForm.dirty" align="start">password don't match</mat-hint>
              <!-- <mat-hint *ngIf="regForm.hasError('passwordIdentical','confirm_password') && regForm.dirty" align="start">password confirmation is required 2</mat-hint> -->

            </mat-form-field>
          </div>
          <!-- <div fxFill>
          <div class="g-recaptcha" data-sitekey="6LcpeGAUAAAAABQS2Rdf2By3Y9FdrmfwWh0abuIK"></div>
        </div> -->
          <div fxFill>
            <button mat-raised-button color="accent" class="submit center-me min-button-width">
              get started
            </button>
          </div>
          <div fxFill>
            <div class="terms-text text-center">
              By clicking Get started you confirm that you have read the
              <span class="link2 pointer" (click)="showTerms()">terms and conditions</span>
              and <span class="link2 pointer" (click)="showPrivacy()">privacy policy</span>,
              <br> That you understand them and that you agree to be bound to them.
            </div>
          </div>
        </div>
      </form>
      <div [hidden]="state != COMPANY">
        <div fxLayout="column" fxLayoutAlign="space-between center">
          <div fxFill>
            <div class="files-wrap">
              <div fxLayout="row" fxLayoutAlign="center center">
                <div fxFlex class="text-center file-image">
                  <app-file-upload *ngIf="!personalLogo" class="accent" [round]="true" [text]="'PROFILE PIC'" [drop-mode]="true" [icon]="'cloud_upload_outline'"
                    [miltiple]="false" (filesChange)="onPPFilesChange($event)" [accept]="'image/*'"></app-file-upload>
                  <div *ngIf="personalLogo" class="logo-wrap">
                    <img [src]="personalLogo" alt="profile image">
                    <button class="delete-image" mat-button (click)="deleteImage(PERSONAL)">
                      <mat-icon>delete_forever</mat-icon>
                    </button>
                  </div>
                </div>
                <div fxFlex class="text-center file-image">
                  <app-file-upload *ngIf="!companyLogo" class="secondary" [round]="true" [text]="'COMPANY LOGO'" [drop-mode]="true" [icon]="'cloud_upload_outline'"
                    [miltiple]="false" (filesChange)="onCFilesChange($event)" [accept]="'image/*'"></app-file-upload>
                  <div *ngIf="companyLogo" class="logo-wrap">
                    <img [src]="companyLogo" alt="company logo">
                    <button class="delete-image" mat-button (click)="deleteImage(COMPANY)">
                      <mat-icon>delete_forever</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form [formGroup]="regCompanyForm" (ngSubmit)="register()" novalidate class="space-form">
          <div fxLayout="column" fxLayoutAlign="space-between center">
            <div fxFill>
              <mat-form-field fxFill>
                <mat-label>Company Name</mat-label>
                <input matInput type="text" formControlName="name" required>
                <mat-hint *ngIf="regCompanyForm.hasError('required',['name']) && regCompanyForm.dirty" align="start">company
                  name is required</mat-hint>
              </mat-form-field>
            </div>
            <div fxFill>
              <mat-form-field fxFill>
                <mat-label>Company Website</mat-label>
                <input matInput type="text" formControlName="website" required pattern="[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}">
                <mat-hint *ngIf="regCompanyForm.hasError('required',['website']) && regCompanyForm.dirty" align="start">website
                  is required</mat-hint>
                <mat-hint *ngIf="regCompanyForm.hasError('pattern',['website']) && regCompanyForm.dirty" align="start">valid
                  website looks like "my-domain.com" or "my-domain.co.uk"</mat-hint>
              </mat-form-field>
            </div>
            <div fxFill>
              <mat-form-field fxFill>
                <mat-label>Address</mat-label>
                <input matInput type="text" formControlName="address">
              </mat-form-field>
            </div>
            <!-- <div fxFill>
              <mat-form-field fxFill>
                <mat-select placeholder="Industry" formControlName="industry">
                  <mat-option *ngFor="let industry of industriesTypes" [value]="industry.key">
                    {{industry.value}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->
            <div fxFill>
              <!-- <div class="g-recaptcha" data-sitekey="6LcpeGAUAAAAABQS2Rdf2By3Y9FdrmfwWh0abuIK"></div> -->
              <!-- <re-captcha class="center-me" formControlName="recaptchaReactive" siteKey="6LcagWEUAAAAAFO3ov2QwQgSR1EoMqLlvRv1IdPj"></re-captcha> -->
              <!-- <re-captcha class="center-me" siteKey="6LcagWEUAAAAAFO3ov2QwQgSR1EoMqLlvRv1IdPj" (resolved)="captchaResolved($event)"></re-captcha> -->
            </div>
            <div fxFill class="pos-rel">
              <span class="link back pos-abs pointer gray" (click)="back()">back</span>
              <!-- <button data-sitekey="6LcagWEUAAAAAFO3ov2QwQgSR1EoMqLlvRv1IdPj" data-callback="YourOnSubmitFn_TODO" mat-raised-button color="accent"
                class="submit center-me min-button-width g-recaptcha" [disabled]="!regForm.valid">
                submit
              </button> -->
              <button mat-raised-button color="accent"
                class="submit center-me min-button-width g-recaptcha" [disabled]="!regForm.valid">
                submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>