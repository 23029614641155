import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RetailersQueryData, RetailersFilterOptions } from './retailer';
import { AuthService } from '../auth/auth.service';
import { GraphqlService } from '../communication/graphql.service';
import { RolesHelperService } from '../auth/roles-helper.service';
import { UtilsService } from '../shared/utils.service';
import { ApolloQueryResult } from '@apollo/client/core';

@Injectable()
export class RetailerResolve  {

    constructor(
        private gql: GraphqlService,
        private auth: AuthService,
        private roles: RolesHelperService,
        private utils: UtilsService
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        let rid = null;
        if (this.auth && this.auth.user && this.auth.user.retailers_users &&
            this.auth.user.retailers_users[this.auth.retailerIndex] && !this.roles.isSuperUserOrObserverLoggedIn()) {
            rid = [this.auth.user.retailers_users[this.auth.retailerIndex].retailer_id];
        }
        else if (route.params['retailer_id'])
            rid = this.utils.parseToArr(route.params['retailer_id']);
        if (rid == null)
            return null;
        rid = rid.map(i => i * 1);
        const options = {
            id: rid
        } as RetailersFilterOptions;
        return this.gql.retailerForProducts(options) as Observable<ApolloQueryResult<RetailersQueryData>>;
    }
}
