import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IP } from './retailer-product-ip.interface';
import { KeyValuePair } from '../enums';

@Component({
  selector: 'app-retailer-product-ip',
  templateUrl: './retailer-product-ip.component.html',
  styleUrls: ['./retailer-product-ip.component.scss']
})
export class RetailerProductIpComponent {
  @Output() selectionChange = new EventEmitter<IP>();
  @Input() ip: IP = IP['Client’s IP'];
  public ipOptions: KeyValuePair[] = [];

  constructor() {
    for (const ipKey in IP) {
      if (isNaN(Number(ipKey))) {
        this.ipOptions.push({
          key: ipKey,
          value: IP[ipKey]
        })
      }
    }
  }
}
