<div class="wrap h-100">
  <h3 class="h2 text-center">Product History</h3>
  <mat-divider></mat-divider>
  <div class="inner float-dir scrollbar">

    <div *ngFor="let group of list" class="pos-rel float-dir w-100 row">
      <div class="date-wrap">
        <mat-divider></mat-divider>
        <p class="text2">
          {{group.created_at | moment : 'format' : 'dddd, MMMM Do YYYY' }}
        </p>
      </div>

      <div class="activity float-dir w-100" *ngFor="let item of group.items">
        <div class="pp-wrap float-dir uppercase" [ngClass]="{'image': item.avatarUrl}">
          <span class="acronym">{{item.acronym}}</span>
          <span class="avatar" *ngIf="item.avatarUrl" [style.backgroundImage]="'url(' + item.avatarUrl + ')'"></span>
        </div>
        <div class="details float-dir scrollbar">
          <div>
            <b class="text2 capitalize">{{item.fullName}}</b>
            <span>
              {{item.created_at | moment : 'format' : 'h:mm a' }}
            </span>
          </div>
          <div class="text2" (click)="onClick(item)" [ngClass]="{'pointer underline bold': item.onClickArgs}">
            <i *ngIf="item.onClickArgs && !item.iconName" class="icon-link_blank product-history-icon"></i>
            <span *ngIf="item.onClickArgs && item.iconName" class="material-icons">{{item.iconName}}</span>
            <span>{{item.action}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>