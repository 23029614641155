import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

// declare var watermark: any;

@Injectable({
  providedIn: 'root'
})


export class WatermarkService {
  private onLoad: Array<Function>;
  constructor(
    private utils: UtilsService
  ) {
    this.onLoad = [];
  }

  private invokeOnLoad() {
    this.onLoad.forEach(f => f());
    this.onLoad = [];
  }

  private get _watermark() {
    let selfAny = self as any;
    return selfAny.watermark;
  }

  private async fetchScript() {
    return new Promise((resolve: any, reject: any) => {
      this.onLoad.push(resolve);
      if (this._watermark) {
        this.invokeOnLoad();
        return;
      }
      this.utils.loadScript('https://cdn.hexa3d.io/hotlink-ok/scripts/watermark.min.js', () => {
        this.invokeOnLoad();
      });
    });
  }

  async center(imageSrc: string, iconSrc: string, opacity: number): Promise<HTMLImageElement> {
    await this.fetchScript();
    return await this._watermark([imageSrc, iconSrc]).image(this._watermark.image.center(opacity));
  }
}
