<div class="wrap">
    <div>checked products: {{ checkedProducts }}</div>
    <div>current batch: {{ batch }}</div>
    <div>fixed resources: {{ fixedResources }}</div>
    <div>
        <div class="images">
            <img [src]="img2" alt="old preview" /> ➡️
            <img [src]="img1" alt="new preview" />
        </div>
    </div>
</div>
