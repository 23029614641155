import { Component, OnInit, Input } from '@angular/core';
import { Retailer } from '../../retailer/retailer';
import { RestService } from '../../communication/rest.service';
import { Product, SingleProductQueryData, ProductResource, Dimensions } from '../product';
import { BroadcasterNotification, BroadcasterNotificationType } from '../../communication/broadcaster-notifications';
import { BroadcasterService } from 'node_modules/ng-broadcaster';
import { Router } from 'node_modules/@angular/router';
import { ProductRequestService } from '../product-request.service';
import { ProductService } from '../product.service';
import { GraphqlService } from '../../communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { DimensionsUnits } from 'src/app/shared/utils';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-duplicate-product',
  templateUrl: './duplicate-product.component.html',
  styleUrls: ['./duplicate-product.component.scss']
})
export class DuplicateProductComponent implements OnInit {
  @Input('product-id') productId: number;
  @Input('resources') resources: Array<ProductResource>;
  public retailer: Retailer;
  public pid: number;// the product ID to copy resources to
  constructor(
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private router: Router,
    public prService: ProductRequestService,
    private productService: ProductService,
    private gql: GraphqlService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
  }

  onRetailerChange(retailer: Retailer) {
    if (retailer)
      this.retailer = retailer;
  }

  async duplicate() {
    let retailer: Retailer;
    if (this.pid)
      // check specs for current retailer
      retailer = await this.productService.getRetailerByProductId(this.pid);
    else
      // check specs for other retailer
      retailer = await this.productService.getRetailerById(this.retailer.id);
    let maxGlbSize = retailer.maximum_glb_size ? (retailer.maximum_glb_size / 1024 / 1024) : 5;
    if (this.prService.finalGlbSize && this.prService.finalGlbSize > maxGlbSize &&
      !confirm(`Model file size is ${this.prService.finalGlbSize} MB while the maximum model size for this retailer is ${maxGlbSize} MB\n\nAre you sure you want to duplicate this product?`))
      return;
    if (this.prService.enabledResources[this.prService.currentResourceIndex]) {
      for (let i = 0; i < retailer.retailers_polygon_specifications.length; i++) {
        if (this.prService.enabledResources[this.prService.currentResourceIndex].assets_details[0]) {
          if (this.prService.enabledResources[this.prService.currentResourceIndex].assets_details[0].poly_count > retailer.retailers_polygon_specifications[i].max_poly_count &&
            !confirm(`${retailer.name} has a limitation of ${retailer.retailers_polygon_specifications[i].max_poly_count} maximum polygon count while this resource has ${this.prService.enabledResources[this.prService.currentResourceIndex].assets_details[0].poly_count} polygons\n\nAre you sure you want to duplicate this product?`))
            return;
        }
      }
      if (this.prService.request.width && this.prService.request.height && this.prService.request.length && this.prService.request.units) {
        let resourceDimensions = this.prService.enabledResources[this.prService.currentResourceIndex].assets_details[0] as Dimensions;
        // TODO add those next 2 lines after the next asset-adjustments package update (above 10.2.503)
        // if (!resourceDimensions.width)
        //   resourceDimensions = await this.prService.getDimensions();
        if (resourceDimensions?.width) {
          let maxTarget = Math.max(this.prService.request.width, Math.max(this.prService.request.height, this.prService.request.length));
          let maxActual = Math.max(resourceDimensions.width, Math.max(resourceDimensions.height, resourceDimensions.length));
          if (this.prService.request.units == DimensionsUnits.INCH)
            maxTarget *= 2.54;
          maxActual *= 100;
          if (Math.max(Math.abs(maxActual / maxTarget), Math.abs(maxTarget / maxActual)) > 1.05) {
            if (!confirm('Model required dimensions does not match actual dimensions\n\nAre you sure you want to duplicate this product?'))
              return;
          }
        }
      }
    }
    if (this.pid) {
      this.duplicateResources();
      return;
    }
    if (!this.retailer) return;
    if (maxGlbSize == 0) {
      if (!confirm(`Model file size is unknown while the maximum model size for this retailer is ${maxGlbSize} MB\n\nAre you sure you want to duplicate this product?`)) return;
    }
    const query = '?rid=' + this.retailer.id;
    this.rest.productDuplicate('post', { id: this.productId }, query).subscribe(
      (p: Product) => {
        this.gql.product(p.id).subscribe(
          (res: ApolloQueryResult<SingleProductQueryData>) => {
            this.prService.mapFromProduct(this.productService.getProductsFromProductQuery([res.data.products])[0])
            this.productService.editItem = this.prService.request;
            this.router.routeReuseStrategy.shouldReuseRoute = function () {
              return false;
          }
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['/product', p.id]);
          },
          err => this.utils.httpErrorResponseHandler(err, 'failure fetching duplicating product')
        );
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure duplicating product')
    );
  }

  duplicateResources() {
    const enabledResourcesIds = this.resources.filter(r => r.resource_enabled).map(r => r.id);
    if (!enabledResourcesIds.length) {
      let data: BroadcasterNotification = {
        text: "This product doesn't have any enabled resources to duplicate",
        type: BroadcasterNotificationType.Error,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
      return;
    }
    this.rest.resourcesDuplicate('post', { id: this.pid, resources: enabledResourcesIds }).subscribe(
      () => {
        let data: BroadcasterNotification = {
          text: 'resources successfully duplicated',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
        this.utils.forceRedirectTo('/product/' + this.pid);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure duplicating resources')
    );
  }
}
