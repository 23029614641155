import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Product, ProductData, ImageSet, ProductResource, MediaTag } from '../product';
import { ProductService } from '../product.service';
import { FileObject } from '../../shared/file';
// import { RestService } from '../../communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from '../../communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { KeyValuePair } from '../../shared/enums';
import { ApiCallOptions } from '../../shared/utils';
import { UtilsService } from '../../shared/utils.service';
import { MapperService } from '../../shared/mapper.service';
import { RolesHelperService } from '../../auth/roles-helper.service';
import { ResumableUploadService } from 'src/app/shared/resumable-upload.service';
import { GlobalsService } from 'src/app/shared/globals.service';
import { ProductRequestService } from '../product-request.service';

@Component({
  selector: 'app-product-quick-view',
  templateUrl: './product-quick-view.component.html',
  styleUrls: ['./product-quick-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductQuickViewComponent implements OnInit {
  @Input('item') itemInput: Product;
  // @Input('retailer-settings') retailerSettings: Array<RetailersSettings>;
  public isShowcase: boolean;
  public gifWidth: number;
  public gifHeight: number;
  public previewWidth: number;
  public previewHeight: number;
  public uploadingCount: number;
  public item: Product;
  public error: KeyValuePair
  public images: Array<ImageSet>;
  public isSU: boolean;
  public resourceIndex: number;
  public counter: number;
  private uploadedImages: Array<string>;
  private imagesToLoad: Array<FileObject>;
  constructor(
    private productService: ProductService,
    // private rest: RestService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService,
    private mapper: MapperService,
    private roles: RolesHelperService,
    private resumableUpload: ResumableUploadService,
    private cdr: ChangeDetectorRef,
    private globals: GlobalsService,
    public prService: ProductRequestService
  ) {
    this.uploadedImages = [];
    this.uploadingCount = 0;
    this.isSU = this.roles.isSuperUserOrObserverLoggedIn();
  }

  ngOnInit() {
    this.item = this.utils.deepCopyByValue(this.itemInput);
    this.images = this.mapper.getImagesFromProduct(this.item);
    this.resourceIndex = this.productService.getDefaultResourceIndex(this.item);
    this.setCurrentState();
    this.setPixels();
    this.setErrors();
    this.prService.setCanEditItems(this.item.status_id);
    setTimeout(() => {
      this.cdr.detectChanges();
    });
  }

  setErrors() {
    if (!this.isShowcase) {
      if (this.item.products_data.length < ProductService.minImages) {
        this.error = {
          key: 'Upload at least one more image!',
          value: 'Please add missing information in order to complete this asset'
        };
      }
    }
  }

  setCurrentState() {
    this.isShowcase = !this.productService.isOrders(this.item) && this.item.products_resources[this.resourceIndex].resource_type == MediaTag.MODEL;
  }

  setPixels() {
    this.gifWidth = 200;
    this.gifHeight = 200;
    this.previewWidth = 500;
    this.previewHeight = 500;
  }

  deleteImage(index: number) {
    if (!confirm('Are you sure you want to DELETE this image forever?')) return;
    this.images.splice(index, 1);
    this.item.products_data.splice(index, 1);
    this.save();
  }

  onSortChange(images: Array<ImageSet>) {
    for (let i = 0; i < this.item.products_data.length; i++) {
      this.item.products_data[i].sort_index = images[i].sort_index;
    }
    this.save();
  }

  async upload(file: FileObject) {
    this.globals.increaseNumOfRequestsInProgress();
    try {
      this.uploadedImages.push(await this.resumableUpload.file(file.file));
    }
    catch (e) {
      this.utils.httpErrorResponseHandler(e, 'failure uploading image');
      if (this.uploadingCount - 1 == 0)
        this.onUploadDone();
      else
        this.uploadingCount--;
    }
    this.onUploadDone();
    // let fileUploadRequest = {
    //   file: file.base64,
    //   filename: file.name + '.' + file.suffix,
    //   compress: true
    // } as FileUploadRequest;
    // this.rest.cdn('post', fileUploadRequest).subscribe(
    //   data => {
    //     // this.item.products_data.push({
    //     //   product_id: this.item.id,
    //     //   url: data['url']
    //     // } as ProductData);
    //     this.uploadedImages.push(data['url']);
    //   },
    //   err => {
    //     this.utils.httpErrorResponseHandler(err, 'failure uploading image');
    //     if (this.uploadingCount - 1 == 0)
    //       this.onUploadDone();
    //     else
    //       this.uploadingCount--;
    //   },
    //   this.onUploadDone.bind(this)
    // );
  }

  onFilesChange(fileList: Array<FileObject>) {
    this.imagesToLoad = fileList;
    if (this.imagesToLoad && this.imagesToLoad.length) {
      this.imagesToLoad.forEach(file => {
        if ((file.naturalHeight >= 300 || !file.naturalHeight) && (file.naturalWidth >= 300 || !file.naturalWidth)) {
          this.uploadingCount++;
          this.upload(file);
        }
        else {
          let data: BroadcasterNotification = {
            text: `${file.name} is too small or type not supported - must be a JPEG or a PNG above 300X300 pixels`,
            type: BroadcasterNotificationType.Error,
            action: 'OK',
            duration: 10000
          }
          this.broadcaster.broadcast('notifyUser', data);
        }
      });
    }
  }

  onUploadDone() {
    if (--this.uploadingCount == 0 && this.uploadedImages.length) {
      this.uploadedImages.forEach(img => {
        this.item.products_data.push({
          product_id: this.item.id,
          url: img
        } as ProductData);
        this.images.push({ big: img, small: img });
      });
      this.counter++;
      this.cdr.detectChanges();
      this.uploadedImages = [];
      this.save();
      this.globals.decreaseNumOfRequestsInProgress();
    }
  }

  save() {
    const saved = (item: Product, success: boolean) => {
      if (success) {
        this.item = item;
        this.images = this.mapper.getImagesFromProduct(this.item);
        this.setErrors();
        this.productService.updateLocalItem(this.item);
      }
    };
    let options = new ApiCallOptions('product saved', 'failure saving product');
    options.callback = saved.bind(this);
    this.productService.save(this.item, options);
  }

  onProductShowcaseChange(resource: ProductResource) {
    this.item.products_resources[0].resource_small = resource.resource_small;
    this.item.products_resources[0].resource_big = resource.resource_big;
  }
}
