import { Role } from 'ng-role-based-access-control';
import { Retailer } from '../retailer/retailer';
import { ListRowsResponse } from '../communication/custom-request';

export interface User {
    id?: number;
    created_at?: Date;
    updated_at?: Date;
    email: string;
    roles?: Array<Role>;
    token?: string;
    // children?: UserChildren,
    avatar_url: string;
    firstname: string;
    lastname: string;
    phone_number: string;
    subscribed: boolean;
    subscribed_notifications: boolean;
    subscribed_daily_analytics: boolean;
    subscribed_weekly_analytics: boolean;
    retailers_users: Array<RetailerUser>;
    users_mails_subscriptions: Array<UsersMailsSubscription>;
    users_mails_subscriptions_intervals: Array<UsersMailsSubscriptionsInterval>;
    users_roles?: Array<UserRole>;
    uiRoles?: Array<Role>;
    users_push_details?: Array<UserPushDetails>;
    qr?: string;
}

export interface UserPushDetails {
    artist_user_id: number;
    created_at?: Date;
    updated_at?: Date;
    id?: number;
    endpoint: string;
    public_key: string;
    auth: string;
}

// export interface UIRole extends Role {
//     checked: boolean;
// }

// export interface UserChildren {
//     artistsuserroles?: Array<UserRole>;
//     uiRoles?: Array<Role>;
//     retailerusers?: Array<RetailerUser>;
// }

export interface RetailerUser {
    created_at: Date;
    id: number;
    retailer_id: number;
    updated_at: Date;
    user_id: number;
    retailers: Array<Retailer>;
}

export interface UserRole {
    id?: number;
    role_id?: number;
    user_id?: number;
    created_at?: Date;
    updated_at?: Date;
    // children?: UserRoleChildren;
    roles: Array<Role>;
}

// export interface UserRoleChildren {
//     artistsroles: Array<Role>;
//     // id: number;
//     // name: string;
//     // created_at: Data;
//     // updated_at: Data;
// }

export interface Credentials {
    email: string,
    password: string
}

export interface UsersFilterOptions {
    limit:number;
    offset: number;
    firstname: string;
    lastname: string;
    email: string;
    retailer_id: Array<number>;
    role_id: Array<number>;
    
}

export interface UsersQueryData {
    allUsers: ListRowsResponse;
}

export enum MailCategory {
    ASSET_CREATION_UPDATES = 1,
    ANALYTICS = 2,
    BILLING = 3,
    MARKETING = 4,
    ESSENTIAL = 5,
    FEEDBACK_UPDATES = 6
}

export enum EmailInterval {
    PUSH = 1,
    DAILY = 2,
    WEEKLY = 3,
    MONTHLY = 4,
}

export interface UsersMailsSubscriptionsInterval {
    created_at: Date;
    id: number;
    interval_id: EmailInterval;
    mail_category_id: MailCategory;
    subscribed: boolean;
    updated_at: Date;
    user_id: number;
}

export interface UsersMailsSubscription {
    created_at: Date;
    id: number;
    mail_category_id: MailCategory;
    subscribed: boolean;
    updated_at: Date;
    user_id: number;
}

export interface UserQueryData {
    users: User;
}

export interface ISsoState {
    token: string;
    user_id: number;
    sso_provider: string;
    sign_on_type: SignOnType;
    mfa_route_type: string;
    qr_image: string;
    sso_token: string;
}

export enum SignOnType {
    SIGN_IN = 'Sign-In',
    SIGN_UP = 'Sign-Up'
}