<mat-card>
    <mat-card-header>
      <div *ngIf="retailer.logo_url" mat-card-avatar class="header-image"
        [style.backgroundImage]="'url(' + retailer.logo_url + ')'"></div>
      <div *ngIf="!retailer.logo_url" mat-card-avatar>
        <mat-icon class="account-circle">business</mat-icon>
      </div>
      <mat-card-title class="ellipsis max-w-c">{{retailer.name}} ({{retailer.id}})</mat-card-title>
      <mat-card-subtitle class="ellipsis max-w-c">{{retailer.website}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <br>
      <mat-divider></mat-divider>
      <br>
      <button mat-button class="outline thinnest" (click)="select()">select</button>
    </mat-card-content>
  </mat-card>