<div [formGroup]="contractsForm">
  <div formArrayName="contracts">
    <div class="wrap box-shadow" *ngFor="let item of contractsForm.get('contracts').controls;let i=index;trackBy:track"
         [formGroupName]="i">
      <mat-form-field>
        <input matInput required [matDatepicker]="signed_at" [max]="today" formControlName="signed_at"
               placeholder="Sign date">
        <mat-hint *ngIf="item.hasError('required',['signed_at']) && contractsForm.dirty">Missing field
        </mat-hint>
        <mat-datepicker-toggle matSuffix [for]="signed_at"></mat-datepicker-toggle>
        <mat-datepicker #signed_at></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="Contract type" formControlName="contract_type">
          <mat-option *ngFor="let c of contractTypesArr" [value]="c">
            {{c}}
          </mat-option>
        </mat-select>
        <mat-hint *ngIf="item.get('contract_type').value == null && contractsForm.dirty">Missing
          field
        </mat-hint>
      </mat-form-field>
      <mat-form-field>
        <input matInput [matDatepicker]="startdate" formControlName="start_at" placeholder="Start date">
        <mat-hint *ngIf="item.hasError('required',['start_at']) && contractsForm.dirty">Missing field
        </mat-hint>
        <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
        <mat-datepicker #startdate></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <input matInput [matDatepicker]="enddate" formControlName="end_at" placeholder="End date">
        <mat-hint *ngIf="item.hasError('required',['end_at']) && contractsForm.dirty">Missing field
        </mat-hint>
        <mat-datepicker-toggle matSuffix [for]="enddate"></mat-datepicker-toggle>
        <mat-datepicker #enddate></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Total numbers of models" formControlName="total_models" pattern="[0-9]*"
               type="number">
        <mat-hint *ngIf="item.hasError('required',['total_models']) && contractsForm.dirty">Missing field</mat-hint>
        <mat-hint *ngIf="item.hasError('pattern',['total_models']) && contractsForm.dirty">Integer only</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Sum ($)" formControlName="total_price" type="number">
        <mat-hint *ngIf="item.hasError('required',['total_price']) && contractsForm.dirty">Missing field</mat-hint>
      </mat-form-field>

      <ng-container formArrayName="batches">
        <fieldset *ngFor="let b of item.get('batches').controls; let j = index;trackBy:track" [formGroupName]="j"
             class="specs flex-wrap-wrap" fxLayout="row">
          <legend>Batch {{j + 1}}</legend>
          <mat-form-field fxFlex>
              <input matInput placeholder="Batch Name" formControlName="name">
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput [matDatepicker]="benddate" placeholder="End date" formControlName="end_at">
            <mat-hint *ngIf="b.hasError('required',['end_at']) && contractsForm.dirty">Missing field</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="benddate"></mat-datepicker-toggle>
            <mat-datepicker #benddate></mat-datepicker>
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="Total numbers of models" formControlName="total_models" type="number"
                   pattern="[0-9]*">
            <mat-hint *ngIf="b.hasError('required',['total_models']) && contractsForm.dirty">Missing field</mat-hint>
            <mat-hint *ngIf="b.hasError('pattern',['total_models']) && contractsForm.dirty">Integer only</mat-hint>
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="Avarage Product Price ($)" formControlName="avg_product_price" type="number">
            <mat-hint *ngIf="b.hasError('required',['avg_product_price']) && contractsForm.dirty">Missing field
            </mat-hint>
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput min="0" max="100" placeholder="Expected gross margin" pattern="^[1-9][0-9]?$|^100$"
                   formControlName="gross_margin" type="number">
            <mat-hint *ngIf="b.hasError('required',['gross_margin']) && contractsForm.dirty">Missing field</mat-hint>
            <mat-hint *ngIf="b.hasError('pattern',['gross_margin']) && contractsForm.dirty"> the range is from 0 to 100
            </mat-hint>
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput min="0" max="100" placeholder="Genaral SLA - assets" pattern="^(?:[1-9]|\d\d\d*)$"
                   formControlName="sla_due_date" type="number" [readonly]="b.controls['isExpired'].value">
            <mat-hint *ngIf="b.hasError('pattern',['sla_due_date']) && contractsForm.dirty"> the field is integer greater than 0
            </mat-hint>
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput min="0" max="100" placeholder="Genaral SLA - feedbacks" [readonly]="b.controls['isExpired'].value" pattern="^(?:[1-9]|\d\d\d*)$"
              formControlName="sla_feedback_due_date" type="number">
            <mat-hint *ngIf="b.hasError('pattern',['sla_feedback_due_date']) && contractsForm.dirty"> the field is integer greater than 0
            </mat-hint>
          </mat-form-field>
          <a class="link pointer" (click)="addBatchSpec(b)" fxFlex>+ add attachment</a>
          <ng-container formArrayName="specifications">
            <fieldset *ngFor="let s of b?.get('specifications')?.controls; let h = index;trackBy:track" [formGroupName]="h"
              class="specs">
              <legend>attachment {{h + 1}}</legend>
              <div fxLayout="row">
                <mat-form-field fxFlex>
                  <input matInput placeholder="attachment title" formControlName="title">
                </mat-form-field>
                <mat-form-field fxFlex>
                  <input matInput placeholder="sort index" formControlName="sort_index" type="number">
                </mat-form-field>
                <mat-form-field *ngIf="jobTypes">
                  <mat-select placeholder="job type" formControlName="job_type">
                    <mat-option *ngFor="let t of jobTypes" [value]="t.id">
                      {{ t.description }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <a fxFlex target="_blank" [href]="s.get('attachment').value" *ngIf="s.get('attachment').value">
                  attachment
                </a>
                <app-file-upload [icon]="'attach_file'" [drop-mode]="true" [text]="'add attachment'" [miltiple]="false"
                  (filesChange)="onFilesChange($event, s)" class="dashed disp-block marg-top w-100">
                </app-file-upload>
                <button mat-fab aria-label="delete specifications" (click)="deleteSpec(b, h)" color="warn" matTooltip="delete attachment">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <app-text-editor [click-img]="true" [show-html]="true" [show-asset]="true" class="customized disp-block" fxFill [init-value]="s.get('text')?.value"
                (textChange)="onTextChange(s, $event)"></app-text-editor>
            </fieldset>
          </ng-container>
          <button mat-fab aria-label="delete specifications" (click)="deleteBatch(item, j)" color="warn"
                  matTooltip="Delete batch">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-fab aria-label="delete specifications" class="duplicate-button" (click)="duplicateBatch(item, j)"
                  matTooltip="Duplicate batch">
            <mat-icon>file_copy</mat-icon>
          </button>
        </fieldset>
      </ng-container>


      <button mat-fab aria-label="add specifications" (click)="addBatch(item)" matTooltip="Add a batch">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-button aria-label="save" (click)="save(item)" [disabled]="uploading">
        save
      </button>
      <button mat-fab aria-label="delete specifications" class="delete-contract" (click)="deleteContract(item, i)"
              color="warn" matTooltip="Delete contract">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <button mat-fab aria-label="add a contract" matTooltip="Add a contract" (click)="addContract()">
    <mat-icon>add</mat-icon>
  </button>
</div>
