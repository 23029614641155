import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { RolesHelperService } from 'src/app/auth/roles-helper.service';
import { BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { GraphqlService } from 'src/app/communication/graphql.service';
import { RetailerFilterOptions } from 'src/app/retailer/retailer';
import { FormatsType, ImageFormatsType, KeyValuePair } from 'src/app/shared/enums';
import { EnumsService } from 'src/app/shared/enums.service';
import { ResourceTypesService } from 'src/app/shared/resource-types.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ResourceType } from '../product';
import { ProductService } from '../product.service';

@Component({
  selector: 'app-batch-downloader',
  templateUrl: './batch-downloader.component.html',
  styleUrls: ['./batch-downloader.component.scss']
})
export class BatchDownloaderComponent implements OnInit {
  public formatToDownloads: Array<FormatsType>;
  public imagesFormatToDownloads: Array<ImageFormatsType>;
  public allImagesFormat: Array<KeyValuePair>;
  public maximumAssetsDownload: number;
  public usersDownloadsCount: number;
  public retailerId: number;
  public resourceFormats: Array<ResourceType>;
  constructor(
    public productService: ProductService,
    private enums: EnumsService,
    private roles: RolesHelperService,
    private auth: AuthService,
    private gql: GraphqlService,
    public resourceTypesService: ResourceTypesService,
    private utils: UtilsService
  ) {
    this.allImagesFormat = this.enums.getImagesFormatsTypes();
  }

  ngOnInit(): void {
    this.resourceFormats = this.resourceTypesService.getCachedTypes();
    if (!this.roles.isSuperUserLoggedIn()) {
      this.retailerId = this.auth.user.retailers_users[this.auth.retailerIndex].retailer_id;
      const options = {
        id: this.retailerId
      } as RetailerFilterOptions;
      this.gql.retailerForProduct(options).subscribe(
        obj => {
          const retailer = obj.data.retailers;
          this.maximumAssetsDownload = retailer.retailers_agreements[0].maximum_assets_download;
          this.usersDownloadsCount = retailer.users_downloads_count;
        }
      );
    }
  }

  downloadResources() {
    if (!this.formatToDownloads || !this.formatToDownloads.length) {
      this.utils.notifyUser({
        text: 'first select desired formats',
        type: BroadcasterNotificationType.Error
      });
      return;
    }
    this.productService.downloadBatch(this.formatToDownloads, this.imagesFormatToDownloads);
  }
}
