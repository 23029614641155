import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { RestService } from '../../communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from '../../communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { User, UserRole } from '../user';
import { RolesHelperService } from '../roles-helper.service';
import { Retailer } from '../../retailer/retailer';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss']
})
export class InviteUserComponent implements OnInit {
  public inviteForm: UntypedFormGroup;
  public retailer: Retailer;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private rest: RestService,
    private broadcaster: BroadcasterService,
    public roles: RolesHelperService,
    private utils: UtilsService
  ) {
    this.inviteForm = this.formBuilder.group({
      firstname: [null, Validators.required],
      email: [null, Validators.email],
      assetCreator: [true],
      password: [null]
    });
  }

  ngOnInit() {
  }

  enabledChange(val) {
    this.inviteForm.controls.assetCreator.setValue(val);
  }

  invite() {
    if (!this.inviteForm.valid) return;
    let user = this.inviteForm.getRawValue() as User;
    let role = [{
      role_id: this.inviteForm.controls.assetCreator.value ? 2 : 3
    }] as Array<UserRole>;
    user.users_roles = role;
    if (!this.retailer && this.roles.isSuperUserOrObserverLoggedIn()) {
      alert('First select a retailer');
      return;
    }
    const query = this.retailer ? '?rid=' + this.retailer.id : '';
    // if (this.retailer)
    this.rest.userProfile('post', user, query).subscribe(
      res => {
        let data: BroadcasterNotification = {
          text: 'user invited successfully',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', data);
        this.broadcaster.broadcast('onUsersChange');
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure inviting user')
    );
  }

  onRetailerChange(retailer: Retailer) {
    if (retailer)
      this.retailer = retailer;
  }

  reset() {
    this.inviteForm.reset();
    // delete this.retailer;
  }
}
