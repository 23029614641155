<div class="wrap" [ngClass]="{'show-bottom':showBottom}">
  <div *ngIf="asset">
    <iframe class="preview" *ngIf="mediaTag == productService.MODEL || mediaTag == productService.IFRAME_RENDR"
      fxLayout="column" [src]="asset" frameborder="0"></iframe>
    <video class="preview" *ngIf="mediaTag == productService.VIDEO" controls="true" autoplay muted loop playsinline fxLayout="column"
      [src]="asset" type="video/mp4">
      <source [src]="asset" type="video/mp4">
    </video>
    <img class="preview" *ngIf="mediaTag == productService.IMAGE" [src]="asset" fxLayout="column" />
    <div class="select pos-abs" *ngIf="resources.length">
      <button mat-icon-button *ngFor="let r of resources; let i = index" (click)="setAsset(i)"
        [ngClass]="{'active': currentResourceIndex == i, 'img-icon-button': r.icon == '//cdn.hexa3d.io/hotlink-ok/icons/360.png'}">
        <img [src]="r.icon" alt="Resource Type">
      </button>
    </div>
  </div>
  <button mat-icon-button color="accent" class="close pos-abs" (click)="close()">
    <i class="icon-close"></i>
  </button>
  <div class="bottom text-center">
    <button mat-raised-button class="second_button thinner" (click)="feedback()">
      feedback
    </button>
    <button mat-raised-button class="prime_button thinner" (click)="approve(false)">
      approve
    </button>
    <button mat-raised-button class="prime_button thinner no-padd" (click)="approve(true)">
      approve and go live
    </button>
    <!-- <button mat-raised-button class="thinner capitalize invert" (click)="approve(true, true)">
      approve and render
    </button> -->
  </div>
</div>