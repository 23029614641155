<div class="wrap">
    <h3 class="h2">Download Multiple Assets</h3>
    <div class="text3">
        The selected field will be included in the report along with your current filter as defined on the <i
            class="primary icon-settings"></i> REFINE RESULTS section.
    </div>


    <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="4"
        class="space-top text-center flex-wrap-wrap">
        <div fxFlex.gt-sm="29" fxFlex="100">
            <p class="h2">Select file format</p>
        </div>
        <div fxFlex.gt-sm="29" fxFlex="100">
            <mat-form-field>
                <mat-select placeholder="File Format" [(ngModel)]="formatToDownloads" [multiple]="true">
                    <ng-container *ngFor="let f of resourceFormats">
                        <mat-option [value]="f.id">
                          {{f.resource_name}}
                        </mat-option>
                      </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex.gt-sm="29" fxFlex="100">
            <button mat-raised-button color="accent" (click)="downloadResources()">
                download
            </button>
        </div>
        <div fxFlex.gt-sm="48" fxFlex="100" *ngIf="retailerId">
            <p class="gray3 download-desc text-dir">
                <mat-icon class="va-bottom">info</mat-icon>Your monthly download capacity is <b>{{maximumAssetsDownload || 0}}</b>
                according to your service-level agreement (SLA). In case you want to
                extend your download capacity, please contact your account manager at Hexa.
                <br>
                So far you have downloaded <b>{{usersDownloadsCount || 0}}</b> models in the last month.
            </p>
        </div>
        <div fxFlex.gt-sm="48" fxFlex="100">
            <div>
                <mat-accordion class="customized">
                    <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                ADVANCED
                            </mat-panel-title>
                            <mat-panel-description>
                                select images file types
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div>You can select images file types for</div>
                        <div>FBX, OBJ and Collada (dae)</div>
                        <mat-form-field>
                            <mat-select placeholder="Images Format" [(ngModel)]="imagesFormatToDownloads"
                                [multiple]="true">
                                <mat-option [value]="f.key" *ngFor="let f of allImagesFormat">
                                    {{f.value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>



</div>