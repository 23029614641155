export enum JobsTypes {
    GEOMETRY = 1,
    TEXTURE = 2,
    FIX = 3,
    RENDR = 4,
}

export interface JobTypes {
    id: JobsTypes;
    description: string;
    // type_id: number;
}

export interface JobTypesQueryData {
    jobs_types: Array<JobTypes>;
}

export interface Resource {
    artists_jobs_items: Array<ArtistJobItem>;
}
export interface ArtistJobItem {
    job_id: number;
    is_dummy: boolean;
    artists_jobs: Array<Job>;
}
export interface Job {
    is_dummy?: boolean;
    artists_jobs_types: Array<ArtistsJobsType>;
}
export interface ArtistsJobsType {
    type_id: JobsTypes;
}

export interface JobItem {
   id: number;
   is_dummy: boolean;
   job_type: Array<ArtistsJobsType>;
}