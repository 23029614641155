<div class="wrap pos-rel">
    <button mat-icon-button color="accent" class="close pos-abs" mat-dialog-close>
        <i class="icon-close va-sub"></i>
    </button>
    <h2 class="h2 text-center" mat-dialog-title>Collaborators</h2>
    <mat-form-field class="search">
        <input matInput name="uSearch" placeholder="Search Collaborators" type="text" [(ngModel)]="filter">
        <span matPrefix>
            <mat-icon class="va-middle">search</mat-icon>&nbsp;</span>
    </mat-form-field>
    <mat-divider class="full-width"></mat-divider>
    <mat-dialog-content class="scrollbar">
        <div *ngIf="users" class="list scrollbar">
            <div *ngFor="let user of users | users : filter;let i = index" class="item float-dir w-100">
                <div class="content float-dir w-100">
                    <div class="icon float-dir">
                        <a mat-mini-fab class="uppercase thin" color="primary" routerLink="/settings/{{user.id}}" (click)="close()">
                            {{getAcronyms(user)}}
                        </a>
                    </div>
                    <div class="name float-dir">
                        <b class="text2">{{user.firstname}}&nbsp;{{user.lastname}}</b>
                        <span *ngIf="user.users_roles?.length">
                            <span *ngFor="let r of user.users_roles" class="text3">&nbsp;{{r.roles[0].name}}</span>
                        </span>
                        <div class="text3 ellipsis">{{user.email}}</div>
                    </div>
                    <div class="actions float-dir">
                        <span class="link1 pointer" (click)="remove(user)">remove</span>
                    </div>
                </div>
                <mat-divider *ngIf="i < users.length - 1" class="float-dir w-100"></mat-divider>
            </div>
        </div>
        <mat-spinner *ngIf="!users" class="center-me"></mat-spinner>
    </mat-dialog-content>
</div>