import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Retailer } from '../retailer';

@Component({
  selector: 'app-retailer-card',
  templateUrl: './retailer-card.component.html',
  styleUrls: ['./retailer-card.component.scss']
})
export class RetailerCardComponent implements OnInit {
  @Input() retailer: Retailer;
  @Output('on-select') onSelect: EventEmitter<Retailer>;
  constructor() {
    this.onSelect = new EventEmitter<Retailer>();
  }

  ngOnInit() {
  }

  select() {
    this.onSelect.emit(this.retailer);
  }
}
