<app-refine-results></app-refine-results>
<div class="router-outlet-wrap" [ngClass]="{'on': productService.editItem}">
  <router-outlet></router-outlet>
</div>
<div class="wrap">
  <div class="b-b" fxHide.lt-md="true">
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <mat-tab-group fxFlex class="no-min-w no-underline customized big-text pos-rel top-2 small-text"
        (selectedIndexChange)="productService.setMainStatus($event)"
        [selectedIndex]="productService.initialPrimeStatusDataIndex" [disableRipple]="true">
        <mat-tab label="{{productService.primeStatus.All}} ({{productService.primeStatusData[0].sum | number}})">
        </mat-tab>
        <mat-tab label="{{productService.primeStatus.Orders}} ({{productService.primeStatusData[1].sum | number}})">
        </mat-tab>
        <mat-tab label="{{productService.primeStatus.Approved}} ({{productService.primeStatusData[2].sum | number}})">
        </mat-tab>
        <mat-tab label="{{productService.primeStatus.Deleted}} ({{productService.primeStatusData[3].sum | number}})">
        </mat-tab>
      </mat-tab-group>
      <div fxFlex>
        <a mat-raised-button class="prime_button float-op-dir thin" routerLink="/product">
          order new asset
        </a>
      </div>
    </div>
  </div>
  <div class="pos-rel report-gen">
    <i *ngIf="productService.showReportGen" (click)="productService.showReportGen = false"
      class="pointer pos-abs remove warn icon-close"></i>
    <app-products-report-generator class="pos-abs w-100 fade-in box-shadow floating-component"
      *ngIf="productService.showReportGen"></app-products-report-generator>
  </div>
  <div class="pos-rel report-gen">
    <i *ngIf="productService.showBatchDownloader" (click)="productService.showBatchDownloader = false"
      class="pointer pos-abs remove warn icon-close"></i>
    <app-batch-downloader class="pos-abs w-100 fade-in box-shadow floating-component"
      *ngIf="productService.showBatchDownloader"></app-batch-downloader>
  </div>
  <div class="padd-sides marg-bottom" [ngClass]="{'marg-second-row-top': !productService.currentPrimeStatusData.statusList.length}">
    <div fxLayout="row" fxLayoutAlign="start center">
      <span fxFlex="100%" fxFlex="1" fxFlex.gt-sm="72">
        <mat-tab-group class="no-min-w no-underline customized small-text uppercase no-marg-first double-gap"
          (selectedIndexChange)="productService.statusChange($event)"
          [(selectedIndex)]="productService.initialStatusDataIndex" [disableRipple]="true" fxHide.lt-md="true">
          <mat-tab *ngFor="let ps of productService.currentPrimeStatusData.statusList"
            label="{{ps.value}} {{productService?.totalByStatus[ps.key]?'(' + productService?.totalByStatus[ps.key] + ')':''}}">
          </mat-tab>
        </mat-tab-group>
      </span>
    </div>
    <div class="report-buttons" fxLayout="row" fxLayoutAlign="space-around center">
      <div fxFlex="70">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="text-center">
            <button mat-button (click)="toggleReportGen()" class="invert">
              Export to CSV
            </button>
          </div>
          <div class="text-center" *ngIf="!roles.isObserverLoggedIn()">
            <button mat-button (click)="toggleBatchDownloader()" class="invert">
              Download Assets
            </button>
          </div>
        </div>
      </div>
      <span fxFlex="73" fxFlex.gt-sm="" class="pos-rel">
        <mat-select class="pos-abs uppercase small-text date-filter-type" [(ngModel)]="dateFilterSelectedValue"
          (selectionChange)="dateSelectChange()">
          <mat-option *ngFor="let d of dateFilter" [value]="d.value" class="uppercase small-text">
            {{d.key}}
          </mat-option>
        </mat-select>
        <mat-form-field class="min-datepicker marg-right" [ngClass]="{'active-date': !!pickerFromInput.value}">
          <input matInput #pickerFromInput [matDatepicker]="pickerFrom" placeholder="From" name="dateFrom"
            (keyup)="dateKeyUp($event)" (dateChange)="dateChange()">
          <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="min-datepicker" [ngClass]="{'active-date': !!pickerToInput.value}">
          <input matInput #pickerToInput [matDatepicker]="pickerTo" placeholder="To" name="dateTo"
            (dateChange)="dateChange()">
          <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>

      </span>
      <span class="view-types text-op-dir" fxFlex="25" fxFlex.gt-sm="8">
        <div>
          <button mat-icon-button (click)="setViewType(productService.GRID)"
            [ngClass]="{'current': productService.viewType == productService.GRID}">
            <i class="icon-grid icon-button" aria-label="grid view"></i>
            <!-- view_module -->
          </button>
          <button mat-icon-button (click)="setViewType(productService.LIST)"
            [ngClass]="{'current': productService.viewType == productService.LIST}">
            <!-- <mat-icon aria-label="list view">
              list
            </mat-icon> -->
            <i class="icon-list icon-button" aria-label="list view"></i>
          </button>
        </div>
      </span>
    </div>
  </div>

  <div>
    <div class="grid pos-rel" *ngIf="productService.viewType == productService.GRID">
      <table matSort (matSortChange)="sortData($event)" class="center-me"
        [matSortActive]="productService.initialSortField" [matSortDirection]="productService.initialSortDir"
        [matSortDisableClear]="true">
        <tr>
          <th mat-sort-header="serial_number" [id]="1">S.NO</th>
          <th mat-sort-header="name">name</th>
          <th mat-sort-header="retailer_category_id">category</th>
          <th mat-sort-header="retailer_sub_category_id">sub category</th>
          <th mat-sort-header="updated_at">created</th>
        </tr>
      </table>

      <app-round-checkbox [isSquare]="true"
        *ngIf="productService.items?.length && productService.currentPrimeStatusData && productService.currentPrimeStatusData.primeStatus != 0"
        class="pos-abs" ngDefaultControl (ngModelChange)="onSelectModeChange()"
        [(ngModel)]="productService.isAllSelected" [text]="'Select All'"></app-round-checkbox>

        <div class="flex-wrap-wrap scrollbar padd-sides product-card-wrap pos-rel" fxLayout="row" fxLayoutAlign="center start" #scrollCards id="products-cards-list">
          @for (item of productService.items; track item) {
            <div fxFlex="100%" fxFlex.gt-sm="25" class="card-instance">
              <app-product-card [item]="item"
                [counter]="productService.counter"></app-product-card>
            </div>
          }
        </div>
    </div>
    <div class="list" *ngIf="productService.viewType == productService.LIST">
      <app-round-checkbox [isSquare]="true" class="select-all-list" [text]="'Select All'" ngDefaultControl
        [(ngModel)]="productService.isAllSelected"
        *ngIf="productService.viewType == productService.LIST && productService.currentPrimeStatusData && productService.currentPrimeStatusData.primeStatus != 0">
      </app-round-checkbox>

      <div matSort class="bold" (matSortChange)="sortData($event)" fxLayout="row" fxLayoutAlign="space-around center"
        [matSortDisableClear]="true">
        <span fxFlex="100%" fxFlex.gt-sm="4"></span>
        <span fxFlex="100%" fxFlex.gt-sm="12.5" mat-sort-header="serial_number">S.NO</span>
        <span fxFlex="100%" fxFlex.gt-sm="24.5" mat-sort-header="name">name</span>
        <span fxFlex="100%" fxFlex.gt-sm="8" mat-sort-header="status_id">status</span>
        <span fxFlex="100%" fxFlex.gt-sm="15.5" mat-sort-header="retailer_category_id">category</span>
        <span fxFlex="100%" fxFlex.gt-sm="15.5" mat-sort-header="retailer_sub_category_id">sub category</span>
        <span fxFlex="100%" fxFlex.gt-sm="8" mat-sort-header="updated_at">created</span>
        <span fxFlex="100%" fxFlex.gt-sm=""></span>
      </div>
      <div class="scrollbar padd-sides list-items" #scrollLines id="products-lines-list">
      @for (item of productService.items; track item) {
        <div class="product-line-wrap">
          <app-product-line class="disp-block pos-rel" [item]="item">
          </app-product-line>
        </div>
      }
      </div>
    </div>
  </div>
  <div
    *ngIf="(productService.isAllSelected || productService.selectedCount) && productService.currentPrimeStatusData.primeStatus != 0"
    class="selected pos-abs w-100">
    <app-selected-products-actions></app-selected-products-actions>
  </div>
</div>
