import { Injectable } from '@angular/core';
import { ResourceType, ProductResourceType } from '../product/product';
import { GraphqlService } from '../communication/graphql.service';
import { BroadcasterService } from 'ng-broadcaster';
import { RetailersResourceFileType } from '../retailer/retailer';

@Injectable({
  providedIn: 'root'
})
export class ResourceTypesService {
  private cachedTypes: Array<ResourceType>;
  private cachedDictionary: { [id: number]: string };
  private cachedDefaultTypes: Array<ProductResourceType>;
  constructor(
    private gql: GraphqlService,
    private broadcaster: BroadcasterService
  ) {
    this.gql.resourceTypes().subscribe(
      t => {
        this.cachedTypes = t.data.resources_types;
        this.broadcaster.broadcast('onResourceTypes');
      }
    );
    this.gql.defaultResourceTypes().subscribe(
      t => {
        this.cachedDefaultTypes = t.data.products_defaults_resources_types;
        this.broadcaster.broadcast('onDefaultResourceTypes');
      }
    );
  }

  getCachedTypes(): Array<ResourceType> {
    if (this.cachedTypes) return JSON.parse(JSON.stringify(this.cachedTypes));
    return this.cachedTypes;
  }

  getDictionaryTypes(): { [id: number]: string } {
    if (this.cachedDictionary) return this.cachedDictionary;
    if (!this.cachedTypes) return {};
    this.cachedDictionary = {};
    this.cachedTypes.forEach(rt => {
      this.cachedDictionary[rt.id] = rt.resource_name;
    });
    return this.cachedDictionary;
  }

  getCachedDefaultTypes(): Array<ProductResourceType> {
    if (this.cachedDefaultTypes) return JSON.parse(JSON.stringify(this.cachedDefaultTypes));
    return this.cachedDefaultTypes;
  }

  getTypes() {
    return this.gql.resourceTypes();
  }

  getDefaultsTypes() {
    return this.gql.defaultResourceTypes();
  }

  public mapUiToResourceType(value: Array<number>, allFormats: Array<ResourceType>, existing: Array<ProductResourceType>, productId: number): Array<ProductResourceType> {
    let res = [] as Array<ProductResourceType>;
    if (value && value.length) {
      if (!existing) existing = [];
      value.forEach(id => {
        const exist = existing.find(i => i.resources_types[0] && i.resources_types[0].id == id);
        if (exist)
          res.push(exist);
        else {
          let item = {
            resources_types: [allFormats.find(af => af.id == id)]
          } as ProductResourceType;
          item.resource_type_id = item.resources_types[0].id;
          item.product_id = productId;
          res.push(item);
        }
      });
    }
    return res;
  }

  public mapResourceTypeToUi(value: Array<ResourceType>): Array<number> {
    return value.map(t => t.id);
  }

  public mapProductResourceTypeToUi(value: Array<ProductResourceType>): Array<number> {
    let res = [];
    value.forEach(prt => {
      if (prt.resources_types && prt.resources_types[0])
        res.push(prt.resources_types[0].id)
    });
    return res;
  }

  public mapRetailerResourceTypeToUi(value: Array<RetailersResourceFileType>): Array<number> {
    let res = [];
    value.forEach(prt => {
      res.push(prt.resource_type_id);
    });
    return res;
  }
}
