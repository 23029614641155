<ng-container *ngIf="feedbackService.currentfeedback">
<form [formGroup]="feedbackService.currentfeedback" (ngSubmit)="create()" *ngIf="active"
    [ngClass]="{'tutorial': tutorialActive}">
    <div fxLayout="column" fxLayoutAlign="center start">
        <div fxFill class="screenshot-wrap">
            <div *ngIf="model" [hidden]="!isVisual()">
                <app-model-image-compare [media-tag]="mediaTag" [image]="images[currentImageIndex]" [model]="model"
                    (on-image-reject)="addRejectWithImage($event)" [allow-screenshot]="true" [zoom]="true">
                </app-model-image-compare>
                <div fxLayout="row" fxLayoutAlign="start center" class="sliders">
                    <div fxFlex="45">
                        <app-simple-carousel-slider class="disp-block" [width]="70" [height]="70"
                            [images]="smallImagesUrls" (index-change)="setIndex($event)"></app-simple-carousel-slider>
                    </div>
                    <div fxFlex="45" fxFlexOffset="10">
                        <app-simple-carousel-slider class="disp-block" [width]="70" [height]="70"
                            [current-index]="prService.currentResourceIndex" [images]="resourcesImagesUrls"
                            (index-change)="setResourceIndex($event)"></app-simple-carousel-slider>
                    </div>
                </div>
            </div>
            <div [hidden]="isVisual()" class="no-ss text-center gray">
                no screenshot needed . . .
            </div>
        </div>
        <!-- <mat-divider class="w-100"></mat-divider> -->
        <div class="w-100 disp-flex flex-start align-center flex-gap-24">
           <div> <h3 class="h2">feedback type</h3></div>
            <div>               
                <div *ngIf="feedbackService.types">
                    <mat-form-field class="no-padd hide-subscript">
                        <mat-select placeholder="Select feedback issue" formControlName="feedback_type" (selectionChange)="feedbackService.initSubType($event?.value)">
                            <ng-container *ngFor="let t of feedbackService.types">
                                <mat-option [value]="t.id">
                                    {{ t.description }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="isShowSubType && feedbackService.filteredSubTypes"> <h3 class="h2">feedback sub-type</h3></div>
            <div *ngIf="isShowSubType && feedbackService.filteredSubTypes">
                <mat-form-field class="no-padd hide-subscript">
                  <mat-select placeholder="choose sub type" formControlName="feedback_sub_type">
                    <ng-container *ngFor="let st of feedbackService.filteredSubTypes">
                      <mat-option [value]="st.id">
                        {{ st.description }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
            </div>
        </div>
        <app-text-editor class="customized" fxFill [init-value]="feedbackService.currentfeedback.get('notes').value"
            (textChange)="onTextChange($event)" [click-img]="true" [upload-on-drop]="true" (uploading)="onUploading($event)"></app-text-editor>
        <div fxFill>
            <div class="submit-wrap float-op-dir">
                <button mat-raised-button class="prime_button min-button-width">
                    submit
                </button>
            </div>
        </div>
    </div>
</form>
</ng-container>