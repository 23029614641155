<div class="wrap">
  <button mat-icon-button (click)="navLeft()" [hidden]="translateX == 0" class="nav left" color="accent" type="button">
    <i class="icon-left"></i>
  </button>
  <div class="center-me image-wrap" [style.height]="(height + 10) + 'px'">
    <div class="scroller" [ngClass]="{'imm': imm}" [style.width]="((width + 6) * total) + 'px'" #scroller [style.transform]="'translateX(' + translateX + 'px)'">
      <button type="button" class="image pos-rel" *ngFor="let img of images;let i = index" (click)="setIndex(i)" [style.width]="width + 'px'" [style.height]="height + 'px'"
        [style.background-image]="getBackground(img)" [style.background-color]="'transparent'" [ngClass]="{'current': i == currentIndex}">
      </button>
    </div>
  </div>
  <button mat-icon-button (click)="navRight()" [hidden]="isRightNavDisabled" class="nav right" color="accent" type="button">
    <i class="icon-right"></i>
  </button>
</div>