<iframe class="preview" #iframe [src]="viewerURL | safeWindow" frameborder="0"></iframe>
<!-- <div *ngIf="assetAdjustmentsHelperService.exportProgress == 100"
    class="blink text-center text2 pos-rel export-progress">
    <div class="in">
        processing . . .
    </div>
</div> -->
<!-- <mat-progress-bar color="primary" mode="determinate" [value]="assetAdjustmentsHelperService.exportProgress">
</mat-progress-bar> -->
<div *ngIf="ready" class="disp-none">
    <app-asset-adjustments [created-at]="createdAt" [iframe-model]="iframeModel" [resource]="resource" [embed]="true"
        [tutorial-enabled]="true" [hide]="true"></app-asset-adjustments>
</div>