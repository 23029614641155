import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Product } from '../product';
import { BodyService } from '../../shared/body.service';
import { ProductService } from '../product.service';
// import { Category, SubCategory } from '../../retailer/retailer';

@Component({
  selector: 'app-product-line',
  templateUrl: './product-line.component.html',
  styleUrls: ['./product-line.component.scss']
})
export class ProductLineComponent implements OnInit {
  @Input() item: Product;
  @Output('on-change') onChange: EventEmitter<Product>;
  public showMore: boolean;
  public mainImage: string;
  public isOrders: boolean;
  public hasModel: boolean;
  // public catDic: { [id: number]: Category; }
  // public subCatDic: { [id: number]: SubCategory; }
  constructor(
    public productService: ProductService,
    public body: BodyService
  ) {
    this.onChange = new EventEmitter<Product>();
    // this.catDic = {};
    // this.subCatDic = {};
  }

  ngOnInit() {
    this.hasModel = this.productService.hasModel(this.item);
    this.isOrders = this.productService.isOrders(this.item);
    if (this.isOrders) {
      if (this.item.products_resources && this.item.products_resources[0])
        this.mainImage = this.item.products_resources[0].resource_small || this.item.products_resources[0].resource_big;
    }
    else {
      this.mainImage = this.item.UI.smallImages[0];
    }
    if (!this.mainImage) {
      this.mainImage = this.item.UI.smallImages[0];
    }
    // this.productService.retailer.retailers_categories.forEach(c => this.catDic[c.id] = c);
    // this.productService.retailer.retailers_sub_categories.forEach(c => this.subCatDic[c.id] = c);
  }

  toggleEnabled(prefix: string) {
    this.productService.toggleEnabled(this.item, prefix);
    this.update();
    this.productService.save(this.item);
  }

  toggleQuickView(e) {
    if (e.target && e.target.classList.contains('no-toggle')) return;
    this.productService.toggleQuickView(this.item, e);
  }

  update() {
    this.onChange.next(this.item);
  }

  showRequest(item: Product) {
    this.productService.editItem = item;
  }
}
