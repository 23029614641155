<div class="wrap">
    <button mat-button [matMenuTriggerFor]="menu" class="pos-rel select-btn center-me item-border" (menuOpened)="onMenuOpened()">
        <span class="text bg-on-button">
            Select {{placeholder}}
        </span>
        <img [src]="optionsImages[value]" alt="current preview" *ngIf="value" class="current-preview" loading="lazy">
        <button mat-button class="remove bg-on-button" (click)="remove($event)" *ngIf="value">
            <mat-icon>delete_forever</mat-icon>
        </button>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let o of optionsImages | keyValuePure" (click)="onSelectMenuClick(o.key)"
            class="text-center menu-img-btn">
            <img [src]="o.value" alt="preview">
        </button>
    </mat-menu>
</div>