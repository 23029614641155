import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { RolesManagerService, RolesConfig, Role } from 'ng-role-based-access-control';
import { StorageService } from 'ng-storage-service';
import { RestService } from 'src/app/communication/rest.service';
import { AuthService } from 'src/app/auth/auth.service';
import { UserRole } from 'src/app/auth/user';
import { BroadcasterService } from 'ng-broadcaster';
import { UtilsService } from '../shared/utils.service';

@Injectable({
  providedIn: 'root'
})
export class RolesHelperService {
  private fetchingRoles: boolean;
  public onRolesFetched: Subject<null>;
  private init: boolean;
  private subs: Array<Subscription>;
  private _isAdminLoggedIn: boolean;
  private _isSULoggedIn: boolean;
  private _isGodLoggedIn: boolean;
  private _userRoles: { [id: string]: boolean };
  private _isObserver: boolean;
  constructor(
    private roles: RolesManagerService,
    private storage: StorageService,
    private rest: RestService,
    private auth: AuthService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService
  ) {
    this.fetchingRoles = false;
    if (!this._userRoles)
      this.deleteRolesCache();

    this.onRolesFetched = new Subject<null>();
    this.subs = [];
    this.subs.push(this.broadcaster.on('onLogout').subscribe(this.deleteLazy.bind(this)));
    this.subs.push(this.broadcaster.on('onLogin').subscribe(this.deleteLazy.bind(this)));
  }

  deleteLazy() {
    delete this._isObserver;
    delete this._isAdminLoggedIn;
    delete this._isSULoggedIn;
    delete this._isGodLoggedIn;
    this.deleteRolesCache();
  }

  deleteRolesCache() {
    this._userRoles = {};
  }

  getConfig(): RolesConfig {
    const res = this.roles.getConfig();
    if (res && Object.keys(res).length)
      return res;
    return this.storage.get('roles');
  }

  // initialize role config from server
  fetchRoles() {
    try {
      let last = this.storage.get('roles'), isUpdatedFormat = true;
      if (last) {
        if (last.roles_permissions instanceof Array && (!last.roles_permissions[0].role_name || !last.roles_permissions[0].permission_description)) {
          isUpdatedFormat = false;
        }
        if (isUpdatedFormat) {
          this.roles.setConfig(last);
          this.init = true;
          this.onRolesFetched.next(null);
        }
      }
    }
    catch (e) { }
    if (!this.fetchingRoles) {
      this.fetchingRoles = true;
      this.rest.rolesConfig('GET').subscribe((data: RolesConfig) => {
        this.fetchingRoles = false;
        this.roles.setConfig(data);
        this.storage.set('roles', data);
        this.init = true;
        this.onRolesFetched.next(null);
      },
        err => {
          this.fetchingRoles = false;
          if (!this.roles.getConfig()) {
            this.init = false;
            // return this.auth.logout();
            return;
          }
          // this.init = true;
          this.onRolesFetched.next(null);
        });
    }
  }

  getAllUIRoles(): Array<Role> {
    if (this.init) {
      let res = [] as Array<Role>;
      this.roles.getConfig().roles.forEach(role => {
        res.push(
          {
            created_at: role.created_at,
            id: role.id,
            name: role.name,
            updated_at: role.updated_at
          }
        );
      });
      return res;
    }
    return null;
  }

  getUIRoles(existing?: Array<UserRole>): Array<Role> {
    if (this.init) {
      let res = [] as Array<Role>;
      existing = existing ? existing : [];
      this.roles.getConfig().roles.forEach(role => {
        if (existing.filter(r => r.role_id == role.id).length > 0)
          res.push(
            {
              created_at: role.created_at,
              id: role.id,
              name: role.name,
              updated_at: role.updated_at
            }
          );
      });
      return res;
    }
    return null;
  }

  save(config): void {
    this.rest.rolesConfig('PUT', config).subscribe((data: RolesConfig) => {
      this.fetchRoles();
    });
  }

  doesUserHasPermission(permissionName: string): boolean {
    if (!this.auth.user) return false;

    if (typeof this._userRoles[permissionName] !== 'boolean')
      this._userRoles[permissionName] = this.roles.doesRolesHasPermission(this.auth.user.roles, permissionName);
    return this._userRoles[permissionName];
  }

  isInit() {
    return this.init;
  }

  isAdminLoggedIn(): boolean {
    if (typeof this._isAdminLoggedIn !== 'boolean')
      this._isAdminLoggedIn = this.auth.user && this.roles.isUserInRole(this.auth.user.roles, 'Admin');
    return this._isAdminLoggedIn;
  }

  isSuperUserOrObserverLoggedIn(): boolean {
    return this.isSuperUserLoggedIn() || this.isObserverLoggedIn();
  }

  isSuperUserLoggedIn(): boolean {
    if (typeof this._isSULoggedIn !== 'boolean')
      this._isSULoggedIn = this.auth.user && this.roles.isUserInRole(this.auth.user.roles, 'Super User');
    return this._isSULoggedIn;
  }

  isObserverLoggedIn(): boolean {
    if (typeof this._isObserver !== 'boolean')
      this._isObserver = this.auth.user && this.roles.isUserInRole(this.auth.user.roles, 'Observer');
    return this._isObserver;
  }

  isGodLoggedIn(): boolean {
    const gods = [85, 125, 1891]
    if (typeof this._isGodLoggedIn !== 'boolean')
      this._isGodLoggedIn = this.auth.user && (!this.utils.isProd() ? true : !!gods.find(g => g == this.auth.user.id));
    return this._isGodLoggedIn;
  }
}
