<div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="4" class="flex-wrap-wrap">
  <div fxFlex.gt-sm="13.5" fxFlex="100">
    <mat-form-field fxFill>
      <input matInput type="number" min="0" placeholder="Desktop Order" name="rOrder"
        [(ngModel)]="resource.resource_order" (blur)="dataChanged()">
    </mat-form-field>
  </div>
  <div fxFlex.gt-sm="13.5" fxFlex="100">
    <mat-form-field fxFill>
      <input matInput type="number" min="0" placeholder="Mobile Order" name="rMOrder"
        [(ngModel)]="resource.resource_mobile_order" (blur)="dataChanged()">
    </mat-form-field>
  </div>
  <div fxFlex.gt-sm="29" fxFlex="100">
    <mat-form-field fxFill>
      <input matInput type="text" placeholder="text" name="rText" [(ngModel)]="resource.resource_text"
        (blur)="dataChanged()">
    </mat-form-field>
  </div>
  <div fxFlex.gt-sm="29" fxFlex="100">
    <mat-form-field fxFill>
      <input matInput type="text" placeholder="info" name="rInfo" [(ngModel)]="resource.resource_info"
        (blur)="dataChanged()">
    </mat-form-field>
  </div>
  <div fxFlex.gt-sm="29" fxFlex="100">
    <mat-form-field fxFill>
      <input matInput type="text" [placeholder]="placeholdersValues[resource.resource_type]['resource_default']"
        name="rDefault" [(ngModel)]="resource.resource_default" (blur)="dataChanged()">
    </mat-form-field>
  </div>
  <div fxFlex.gt-sm="29" fxFlex="100">
    <mat-form-field fxFill>
      <input matInput type="text" [placeholder]="placeholdersValues[resource.resource_type]['resource_big']"
        name="rPreview" [(ngModel)]="resource.resource_big" (blur)="dataChanged()">
    </mat-form-field>
  </div>
  <div fxFlex.gt-sm="29" fxFlex="100">
    <mat-form-field fxFill>
      <input matInput type="text" [placeholder]="placeholdersValues[resource.resource_type]['resource_small']"
        name="rThumbnail" [(ngModel)]="resource.resource_small" (blur)="dataChanged()">
    </mat-form-field>
  </div>
  <div fxFlex.gt-sm="29" fxFlex="100"
    *ngIf="resource.resource_default && resource.resource_type == productService.MODEL">
    <mat-form-field fxFill>
      <input matInput type="text" placeholder="Theme Color" name="rTheme" [(ngModel)]="themeColor"
        (blur)="onThemeChange()">
    </mat-form-field>
  </div>
  <div fxFlex.gt-sm="29" fxFlex="100" *ngIf="isSU" class="toggle">
    <span class="capitalize">
      enabled / delete
    </span>
    <app-toggle-checkbox ngDefaultControl [on-val]="1" [off-val]="0" [(ngModel)]="resource.resource_enabled">
    </app-toggle-checkbox>
  </div>
  <div fxFlex.gt-sm="29" fxFlex="100" *ngIf="resource.resource_big && resource.resource_default" class="toggle">
    <span class="capitalize">
      add preview
    </span>
    <app-toggle-checkbox ngDefaultControl [on-val]="true" [off-val]="false" [(ngModel)]="isPreview"
      (on-change)="onPreviewChange($event)"></app-toggle-checkbox>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="space-between start" class="padd-bottom">
  <div fxFlex.gt-sm="29" fxFlex="100" *ngIf="resource.resource_default" class="btn-wrap">
    <button class="second_button slim outline min-button-width"
      (click)="prService.deleteResource(prService.currentResourceIndex)">
      delete resource
    </button>
  </div>
  <div fxFlex.gt-sm="29" fxFlex="100" *ngIf="prService.request.qr_url" class="text-op-dir btn-wrap">
    <button class="third_button outline min-button-width"
      (click)="viewImageURL(prService.request.qr_url, prService.request.qr_direct_url)">
      Augmented Reality
      <!-- <img [src]="prService.request.qr_url" alt="QR Code"> -->
    </button>
    <!-- <button mat-icon-button aria-label="QR Code" *ngIf="prService.request.qr_url" class="pos-abs qr"
      (click)="viewImageURL(prService.request.qr_url, prService.request.qr_direct_url)" matTooltip="AR experience">
      <img [src]="prService.request.qr_url" alt="QR Code">
    </button> -->
  </div>
</div>