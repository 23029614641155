<div class="wrap">
  <h3 class="h2">Reports Generator</h3>
  <div class="text3">
    The selected field will be included in the report along with your current filter as defined on the <i class="primary icon-settings"></i> REFINE RESULTS section.
  </div>
  <ul class="normalize">
    <li *ngFor="let f of fields">
      <span (click)="f.key = !f.key">
        {{f.value.value}}
      </span>
      <app-round-checkbox ngDefaultControl [(ngModel)]="f.key">
      </app-round-checkbox>
    </li>
  </ul>
  <div class="space-top">
    <button mat-raised-button color="accent" class="thin" (click)="generateReport()">
      generate
    </button>
  </div>
</div>