import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { RetailersSettings, RetailersUISettings } from '../retailer';
import { RestService } from '../../communication/rest.service';
import { UtilsService } from '../../shared/utils.service';
import { BroadcasterNotification, BroadcasterNotificationType } from '../../communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';

@Component({
  selector: 'app-retailer-settings',
  templateUrl: './retailer-settings.component.html',
  styleUrls: ['./retailer-settings.component.scss']
})
export class RetailerSettingsComponent implements OnInit, OnChanges {
  @Input() settings: Array<RetailersSettings>;
  @Input('retailer-id') retailerId: number;
  public innerSettings: RetailersUISettings;
  constructor(
    private rest: RestService,
    private utils: UtilsService,
    private broadcaster: BroadcasterService
  ) { }

  ngOnInit() {
    this.setInnerSettings();
  }

  ngOnChanges() {
    this.setInnerSettings();
  }

  setInnerSettings() {
    if (this.settings && this.settings.length && this.settings[0].settings) {
      try {
        this.innerSettings = JSON.parse(this.settings[0].settings) as RetailersUISettings;
      } catch (e) {
        this.innerSettings = {} as RetailersUISettings;
      }
    }
    else {
      this.innerSettings = {} as RetailersUISettings;
    }
    if (!this.innerSettings.gif)
      this.innerSettings.gif = {};
    if (!this.innerSettings.preload)
      this.innerSettings.preload = {};
  }

  getSettingsAsString(): string {
    return JSON.stringify(this.innerSettings);
  }

  save() {
    let payload = (this.settings && this.settings.length) ? this.utils.deepCopyByValue(this.settings[0]) : {} as RetailersSettings;
    payload.settings = this.getSettingsAsString();
    const query = '?rid=' + this.retailerId;
    this.rest.retailerSettings((this.settings.length && this.settings[0].id) ? 'put' : 'post', payload, query).subscribe(
      (data: Array<RetailersSettings>) => {
        this.settings = data;
        this.setInnerSettings();
        let obj: BroadcasterNotification = {
          text: 'settings successfully saved',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', obj);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure updating settings')
    )
  }
}
