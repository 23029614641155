<div class="wrap scrollbar">
  <button mat-icon-button class="hide-desktop pos-abs toggler" (click)="show = !show">
    <mat-icon>more_vert</mat-icon>
  </button>
  <h3 class="uppercase header">
    <!-- <mat-icon color="primary">adjust</mat-icon> -->
    <i class="icon-settings"></i>
    refine results
  </h3>
  <div [ngClass]="{'hide-options': !show}">
    <div class="content">
      <div class="field" *ngIf="canViewAllRetailers">
        <span class="uppercase top">
          retailers
          <span class="link pointer float-op-dir capitalize" (click)="clearRetailers()">Clear</span>
        </span>
        <div class="retailers-input">
          <app-retailers-autocomplete class="disp-block invert" [clear-on-select]="true"
            (retailer-change)="onRetailerChange($event)"></app-retailers-autocomplete>

        <div class="list" *ngIf="productService.filter.controls.retailer_id.value.length">
          <span class="item float-dir" *ngFor="let rid of productService.filter.controls.retailer_id.value" mat-button>
             <ng-container *ngIf="!isSU">
             {{productService.cachedRetailers[rid] ? productService.cachedRetailers[rid].name : rid}}
            </ng-container>
            <a *ngIf="isSU" class="retailer-tag-link" routerLink="/retailers-management/{{rid}}"> {{productService.cachedRetailers[rid] ? productService.cachedRetailers[rid].name : rid}}</a>
            <span class="pointer" (click)="remove('retailer_id', rid)">x</span>
          </span>
        </div>
      </div>
      </div>
      <div class="field float-dir max-w-100" [ngClass]="{'space-top': i > 0}"
        *ngFor="let field of uiBinds | keyValuePure : counter; let i = index">
        <div class="title-area">
          <span class="uppercase top">{{field.value.title}}</span>
          <span class="link pointer float-op-dir" (click)="setVal(field.key, [])">Clear</span>
        </div>

        <div class="input-area pos-rel float-op-dir max-w-100 w-100-m">
          <input type="text" class="add-items" (keypress)="onKeypress($event, field.key)"
            [(ngModel)]="field.value.value" [prevent-keys]='[222]'>
          <span class="plus" (click)="addToFilter(field.key, field.value.value)">+</span>
        </div>
        <div class="list">
          <span class="item float-dir" *ngFor="let item of productService.filter.controls[field.key].value" mat-button>
            {{item}}
            <span class="pointer" (click)="remove(field.key, item)">x</span>
          </span>
        </div>
      </div>
      <div class="field space-top contrast-wrap" *ngIf="retailerForBatch?.id">
        <span class="uppercase top">
          batches
        </span>
        <app-retailer-batches [required]="false" [setselectedbatches]="productService.selectedbatches"
          [isContrastStyle]="true" (on-select)="setBatches($event)" [retailer]="retailerForBatch">
        </app-retailer-batches>
      </div>
      <div class="field">
        <span class="uppercase top">
          Render status
        </span>
        <mat-form-field fxFill class="contrast no-padd">
         <mat-select placeholder="select render status" [value]="productService.renderStatuses" (selectionChange)="productService.onRenderStatusChange($event)" multiple="true">
          <ng-container *ngFor="let t of renderStatuses">
            <mat-option [value]="t.value">
            {{ t.key }}
            </mat-option>
          </ng-container>
       </mat-select>
      </mat-form-field>
      </div>
      <div class="field" *ngIf="isSU">
        <span class="uppercase top">
          resource type
        </span>
        <mat-form-field fxFill class="contrast no-padd">
          <mat-select placeholder="select type" [value]="productService.viewerResourceTypes" multiple
            (selectionChange)="productService.onViewerResourceTypesChange($event)">
            <ng-container *ngFor="let f of resourceFormats">
              <mat-option [value]="f.id">
                {{f.resource_name}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="field" *ngIf="allRejectReason">
        <span class="uppercase top">
          reject reasons
        </span>
        <mat-form-field fxFill class="contrast no-padd">
          <mat-select placeholder="select type" [value]="productService.rejectReasons" multiple
            (selectionChange)="productService.onRejectChange($event)">
            <mat-option *ngFor="let f of allRejectReason" [value]="f.id">
              {{f.reason}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="field" *ngIf="isSU">
        <span class="uppercase top">
          feedback type
        </span>
        <mat-form-field fxFill class="contrast no-padd">
          <mat-select multiple [value]="productService.feedbackTypes" placeholder="Select feedback issue"
            (selectionChange)="productService.onFeedbackTypesChange($event)">
            <ng-container *ngFor="let t of feedbackService.types">
              <mat-option [value]="t.id">
                {{ t.description }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="field" *ngIf="isSU">
        <span class="uppercase top">
          feedback sub-type  
        </span>
        <mat-form-field fxFill class="contrast no-padd">
          <mat-select multiple [value]="productService.feedbackSubTypes"  placeholder="Select feedback issue" (selectionChange)="productService.onFeedbackSubTypesChange($event)">
            <ng-container *ngFor="let t of feedbackService.types" >
            <mat-optgroup *ngIf="productService?.feedbackTypes?.includes(t.id)"  [label]="t.description">
              <mat-option *ngFor="let st of t.feedback_sub_types" [value]="st.id">
                {{ st.description }}
              </mat-option>
            </mat-optgroup>
          </ng-container>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="field toggle-button float-dir w-100 text-center" *ngIf="isSU">
        <mat-button-toggle-group class="customized invert" aria-label="visible to retailer"
          [value]="productService.filter.controls['visible'].value">
          <mat-button-toggle (click)="setVal('visible', 1)" [value]="1">visible</mat-button-toggle>
          <mat-button-toggle (click)="setVal('visible', 0)" [value]="0">hidden</mat-button-toggle>
          <mat-button-toggle (click)="setVal('visible', null)" [value]="null">all</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="field toggle-button float-dir w-100 text-center" *ngIf="isSU">
        <mat-button-toggle-group class="customized invert" aria-label="internally approved"
          [value]="productService.filter.controls.ia.value">
          <mat-button-toggle (click)="productService.onIAChange(true)" [value]="true">approved</mat-button-toggle>
          <mat-button-toggle (click)="productService.onIAChange(false)" [value]="false">not</mat-button-toggle>
          <mat-button-toggle (click)="productService.onIAChange(null)" [value]="null">all</mat-button-toggle>
        </mat-button-toggle-group>
        <!-- <app-toggle-checkbox ngDefaultControl [on-val]="true" [off-val]="false" (on-change)="productService.onIAChange($event)"
          [(ngModel)]="productService.filter.controls.ia.value"></app-toggle-checkbox> -->
      </div>
      <div class="field toggle-button float-dir w-100 text-center padd-bottom" *ngIf="isSU">
        <mat-button-toggle-group class="customized invert" aria-label="is poc"
          [value]="productService.filter.controls.is_poc.value">
          <mat-button-toggle (click)="productService.onPOCChange(true)" [value]="true">Demo</mat-button-toggle>
          <mat-button-toggle (click)="productService.onPOCChange(false)" [value]="false">not</mat-button-toggle>
          <mat-button-toggle (click)="productService.onPOCChange(null)" [value]="null">all</mat-button-toggle>
        </mat-button-toggle-group>
        <!-- <app-toggle-checkbox ngDefaultControl [on-val]="true" [off-val]="false" (on-change)="productService.onIAChange($event)"
          [(ngModel)]="productService.filter.controls.ia.value"></app-toggle-checkbox> -->
      </div>

      <div *ngIf="categories?.length || subCategories?.length" class="float-dir w-100">
        <div class="field space-top pos-rel categories float-none">
          <span class="uppercase top">
            categories
            <span class="primary" *ngIf="productService.totalSelectedCats">({{productService.totalSelectedCats}})</span>
            <span class="link pointer float-op-dir capitalize" (click)="clearCategories()">clear</span>
          </span>
        </div>

        <app-retailer-categories-tree [restore]="restoreTree" [categories]="categories" [sub-categories]="subCategories"
          (on-change)="onCategoriesChange($event)"
          [selected-categories]="productService.filter.controls['retailer_category_id'].value"
          [selected-sub-categories]="productService.filter.controls['retailer_sub_category_id'].value">
        </app-retailer-categories-tree>
      </div>
      <div class="float-dir w-100 hide-desktop groups-wrap">
        <div>
          <mat-tab-group class="no-min-w no-underline customized big-text pos-rel top-2"
            (selectedIndexChange)="productService.setMainStatus($event)"
            [selectedIndex]="productService.initialPrimeStatusDataIndex" [disableRipple]="true">
            <mat-tab label="{{productService.primeStatus.All}} ({{productService.primeStatusData[0].sum | number}})">
            </mat-tab>
            <mat-tab label="{{productService.primeStatus.Orders}} ({{productService.primeStatusData[1].sum | number}})">
            </mat-tab>
            <mat-tab
              label="{{productService.primeStatus.Approved}} ({{productService.primeStatusData[2].sum | number}})">
            </mat-tab>
            <mat-tab
              label="{{productService.primeStatus.Deleted}} ({{productService.primeStatusData[3].sum | number}})">
            </mat-tab>
          </mat-tab-group>
        </div>
        <div>
          <mat-tab-group class="no-min-w no-underline customized small-text uppercase"
            (selectedIndexChange)="productService.statusChange($event)"
            [selectedIndex]="productService.initialStatusDataIndex" [disableRipple]="true">
            <mat-tab *ngFor="let ps of productService.currentPrimeStatusData.statusList" label="{{ps.value}}">
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div class="field space-top contrast-wrap" *ngIf="retailerForBatch?.id && isSU && productService.totalSelected">
        <span class="uppercase top"> replace to batch </span>
        <app-retailer-batches [multiple]="false" #retBatchRef [required]="false" [isContrastStyle]="true"
          (on-select)="addBatchesToProducts($event)" [retailer]="retailerForBatch">
        </app-retailer-batches>
      </div>
      <div class="text-center" *ngIf="isSU && productService.totalSelected">
        <span class="text3 dim-title">Priority</span>
        <span class="dim-units">
          <mat-form-field fxFill class="contrast no-padd">
            <mat-select placeholder="Priority" (selectionChange)="onPriorityChange($event)">
              <mat-option *ngFor="let p of priorities" [value]="p.key">
                {{p.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <div>
          <div class="pos-rel desc">Dynamic Priority</div>
          <mat-button-toggle-group aria-label="Dynamic Priority" class="customized pos-rel" (change)="toggleDP($event)">
            <mat-button-toggle [value]="2">on</mat-button-toggle>
            <mat-button-toggle [value]="0">off</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <span class="text3 dim-title">Dimensions</span>
        <span class="dim-units">
          <mat-form-field fxFill class="contrast no-padd">
            <mat-select [(ngModel)]="units" placeholder="units">
              <mat-option *ngFor="let u of allDimUnits" [value]="u.key">
                {{ u.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <div fxLayout="column" fxLayoutAlign="center center" class="dim-list">
          <div fxFlex>
            <div class="text-center">
              <input type="number" min="0" [(ngModel)]="length" placeholder="Length">
            </div>
          </div>
          <div fxFlex>
            <div class="text-center">
              <input type="number" min="0" [(ngModel)]="width" placeholder="Width">
            </div>
          </div>
          <div fxFlex>
            <div class="text-center">
              <input type="number" min="0" [(ngModel)]="height" placeholder="Height">
            </div>
          </div>
          <div fxFlex>
            <button mat-button (click)="applyDimensions()">
              apply dimensions
            </button>
          </div>
        </div>
        <div class="pos-rel">
          <div class="pos-rel desc">Internally Approved</div>
          <mat-button-toggle-group aria-label="desktop state" class="customized pos-rel" (change)="toggleIA($event)">
            <mat-button-toggle [value]="true">on</mat-button-toggle>
            <mat-button-toggle [value]="false">off</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="pos-rel">
          <div class="pos-rel desc">Visible</div>
          <mat-button-toggle-group aria-label="desktop state" class="customized pos-rel"
            (change)="toggleVisible($event)">
            <mat-button-toggle [value]="1">on</mat-button-toggle>
            <mat-button-toggle [value]="0">off</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="pos-rel">
          <div class="pos-rel desc">Demo</div>
          <mat-button-toggle-group aria-label="is poc" class="customized pos-rel" (change)="togglePOC($event)">
            <mat-button-toggle [value]="true">on</mat-button-toggle>
            <mat-button-toggle [value]="false">off</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="pos-rel">
          <div class="pos-rel desc">Auto Open Texture</div>
          <mat-button-toggle-group aria-label="open texture" class="customized pos-rel" (change)="toggleAOT($event)">
            <mat-button-toggle [value]="true">on</mat-button-toggle>
            <mat-button-toggle [value]="false">off</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>
  </div>
</div>
