import { Injectable } from '@angular/core';
import { KeyValuePair, SystemEnum, KeyValuePoly, KeyValueAnyPair, Priority, ImageFormatsType, RenderStatus, StudioType } from './enums';
import { StorageService } from 'ng-storage-service';
import { RestService } from '../communication/rest.service';
import { RenderType, ProductRenderType, MediaTag, ProductsStatus, ProductsRendersRequestStatus } from '../product/product';
import { PolyType, PolyShapeType, BusinessPlan, PaymentModel, CreditType, AgreementBudgetType, ImplementationType } from '../retailer/retailer';
import { DimensionsUnits } from './utils';

@Injectable({
  providedIn: 'root'
})
export class EnumsService {
  private notificationTypes: Array<KeyValuePair>;
  private allEnums: Array<SystemEnum>;
  private isUpdating: boolean;
  constructor(
    private storage: StorageService,
    private rest: RestService
  ) {
    this.featchEnums();
  }

  setNotificationTypes(data) {
    this.notificationTypes = data;
    this.storage.set('notificationTypes', data);
  }

  getNotificationTypes() {
    if (!this.notificationTypes)
      this.notificationTypes = this.storage.get('notificationTypes');
    return this.notificationTypes;
  }

  // getIndustriesTypes(): Array<KeyValuePair> {
  //   return [
  //     {
  //       key: 1,
  //       value: 'Fashion'
  //     },
  //     {
  //       key: 2,
  //       value: 'Apparel & Accessories'
  //     },
  //     {
  //       key: 4,
  //       value: 'Consumer Electronics'
  //     },
  //     {
  //       key: 3,
  //       value: 'Furniture & Home Decor'
  //     },
  //     {
  //       key: 5,
  //       value: 'General'
  //     }
  //   ] as Array<KeyValuePair>;
  // }

  getStatus(): Array<KeyValuePair> {
    let res = [] as Array<KeyValuePair>;
    let allStatus = this.getListByName('status_id');
    if (allStatus) {
      allStatus.forEach(item => {
        res.push({
          key: [parseInt(item.enum_key)],
          value: item.enum_value
        });
      });
    }
    return res;
    // return [
    //   {
    //     key: 'Offline',
    //     value: 'Offline'
    //   },
    //   {
    //     key: 'Request Created',
    //     value: 'Requested'
    //   },
    //   {
    //     key: 'Pending Internal Review',
    //     value: 'Pending Internal Review'
    //   },
    //   {
    //     key: 'In Progress',
    //     value: 'In Progress'
    //   },
    //   {
    //     key: 'Live',
    //     value: 'Online'
    //   },
    //   {
    //     key: 'Rejected',
    //     value: 'Rejected'
    //   },
    //   {
    //     key: 'Approved Internally',
    //     value: 'Approved Internally'
    //   },
    //   {
    //     key: 'Delivered',
    //     value: 'Delivered'
    //   },
    //   {
    //     key: 'Feedbacked',
    //     value: 'Feedbacked'
    //   },
    //   {
    //     key: 'Live~Offline',
    //     value: 'All Assets'
    //   },
    //   {
    //     key: '',
    //     value: 'Deleted'
    //   }
    // ] as Array<KeyValuePair>;
  }

  // getFormatsTypes(): Array<KeyValuePair> {
  //   let res: Array<KeyValuePair> = new Array();
  //   res.push({
  //     key: 1,
  //     value: 'OBJ'
  //   });
  //   res.push({
  //     key: 2,
  //     value: 'FBX'
  //   });
  //   res.push({
  //     key: 3,
  //     value: 'FBX_PBR'
  //   });
  //   res.push({
  //     key: 4,
  //     value: 'DAE'
  //   });
  //   res.push({
  //     key: 5,
  //     value: 'BLEND'
  //   });
  //   res.push({
  //     key: 6,
  //     value: 'GLTF'
  //   });
  //   return res;
  // }

  getImagesFormatsTypes(): Array<KeyValuePair> {
    let res: Array<KeyValuePair> = new Array();
    res.push({
      key: ImageFormatsType.JPG,
      value: 'JPG'
    });
    res.push({
      key: ImageFormatsType.PNG,
      value: 'PNG'
    });
    res.push({
      key: ImageFormatsType.TIFF,
      value: 'TIFF'
    });
    return res;
  }

  getProductPositions(): Array<KeyValuePair> {
    let res: Array<KeyValuePair> = new Array();
    res.push({
      key: 1,
      value: 'Standing'
    });
    res.push({
      key: 2,
      value: 'Flat'
    });
    return res;
  }

  getRenderTypes(): Array<ProductRenderType> {
    let res: Array<ProductRenderType> = new Array();
    res.push({
      type_id: RenderType.IMAGES,
      name: 'images'
    });
    res.push({
      type_id: RenderType.VIDEO,
      name: 'video'
    });
    return res;
  }

  getProductActionsTypes(): Array<KeyValuePair> {
    let res: Array<KeyValuePair> = new Array();
    res.push({
      key: 'products',
      value: 'Product Created'
    });
    res.push({
      key: 'products_data',
      value: 'Edited images'
    });
    res.push({
      key: 'products_resources',
      value: 'Edited resources'
    });
    res.push({
      key: 'products_urls',
      value: 'Edited URL\'s'
    });
    res.push({
      key: 'products_tags',
      value: 'Edited tags'
    });
    res.push({
      key: 'products_specs',
      value: 'Edited specifications'
    });
    res.push({
      key: 'products_categories',
      value: 'Edited categories'
    });
    res.push({
      key: 'products_resources_feedback',
      value: 'Created a feedback'
    });
    res.push({
      key: 'products_resources_feedback_fixed',
      value: 'Toggled feedback solved'
    });
    res.push({
      key: 'products_identifiers',
      value: 'Edited serial number'
    });
    res.push({
      key: 'notes',
      value: 'Edited description'
    });
    res.push({
      key: 'products_attachments',
      value: 'Edited additional files'
    });
    res.push({
      key: 'priority_type',
      value: 'Dynamic priority'
    });
    res.push({
      key: null,
      value: {
        'retailer_sub_category_id': 'sub-category',
        'is_archived': 'deleted',
        'material': 'material',
        'enabled': 'desktop enabled',
        'mobile_enabled': 'mobile enabled',
        'name': 'product name',
        'price': 'price',
        'reject_reason': 'reject reason',
        'resource_types': 'resource types',
        'retailer_category_id': 'category',
        'serial_number': 'serial number',
        'status': 'status',
        'visible': 'visible',
        'mobile_traffic': 'mobile traffic',
        'desktop_traffic': 'desktop traffic',
        'description': 'description',
        'currency': 'currency',
        'width': 'width dimension',
        'height': 'height dimension',
        'length': 'length dimension',
        'units': 'dimensions units',
        'due_date': 'due date',
        'products_mapping_naming_convention': 'mapping naming convention',
        'products_polygon_specifications': 'polygon specifications',
        'priority': 'priority',
        'priority_type': 'dynamic priority'
        // 'sub_category_id': 'sub category'
      }
    });
    return res;
  }

  public getDimensionsUnits(): Array<KeyValuePair> {
    return [
      { key: DimensionsUnits.INCH, value: 'inches' },
      { key: DimensionsUnits.CM, value: 'centimeters' }
    ];
  }

  public getDimensionsUnitsDictionary(): KeyValuePair {
    let res = {} as KeyValuePair;
    res[DimensionsUnits.INCH] = 'inches';
    res[DimensionsUnits.CM] = 'centimeters';
    return res;
  }

  public getPolyTypes(): Array<KeyValuePair> {
    return [
      { key: PolyType.LOW, value: 'low' },
      { key: PolyType.MID, value: 'mid' },
      { key: PolyType.HIGH, value: 'high' }
    ];
  }

  public getPolyShapeTypes(): Array<KeyValuePair> {
    return [
      { key: PolyShapeType.TRIANGULAR, value: 'triangular' },
      { key: PolyShapeType.QUADRANGULAR, value: 'quadrangular' },
    ];
  }

  public getProductPlaceholdersValues(): { [id: number]: { [id: string]: string } } {
    return {
      1: {
        resource_default: 'Main Image',
        resource_big: 'Big Image',
        resource_small: 'Small Image'
      },
      2: {
        resource_default: '3D Model',
        resource_big: 'Preview',
        resource_small: 'Thumbnail'
      },
      3: {
        resource_default: 'Video URL',
        resource_big: '360 High Resolution',
        resource_small: '360 Low Resolution'
      },
      4: {
        resource_default: 'Video URL',
        resource_big: 'Video Low Resolution',
        resource_small: 'Video High Resolution'
      }
    }
  }

  public getIconByTag(tag: MediaTag): string {
    switch (tag) {
      case MediaTag.IMAGE: {
        return '//cdn.hexa3d.io/hotlink-ok/icons/baseline-image.svg';
      }
      case MediaTag.MODEL: {
        return '//cdn.hexa3d.io/hotlink-ok/icons/baseline-3d_rotation-24px.svg';
      }
      case MediaTag.VIDEO: {
        return '//cdn.hexa3d.io/hotlink-ok/icons/video-camera.svg';
      }
      case MediaTag.IFRAME_RENDR: {
        return '//cdn.hexa3d.io/hotlink-ok/icons/360.png';
      }
    }
  }

  /**
   * Method returns the array of key-value pairs representing the ImplementationType enum
   * @returns
   */
  public getImplementationType(): Array<KeyValuePair> {
    return [
      { key: ImplementationType.API, value: 'API' },
      { key: ImplementationType.SNIPPET, value: 'Snippet' }
    ];
  }

  getCounries(): Array<KeyValuePair> {
    return JSON.parse(`[
      { "value": "Afghanistan", "key": "AF" },
      { "value": "Åland Islands", "key": "AX" },
      { "value": "Albania", "key": "AL" },
      { "value": "Algeria", "key": "DZ" },
      { "value": "American Samoa", "key": "AS" },
      { "value": "AndorrA", "key": "AD" },
      { "value": "Angola", "key": "AO" },
      { "value": "Anguilla", "key": "AI" },
      { "value": "Antarctica", "key": "AQ" },
      { "value": "Antigua and Barbuda", "key": "AG" },
      { "value": "Argentina", "key": "AR" },
      { "value": "Armenia", "key": "AM" },
      { "value": "Aruba", "key": "AW" },
      { "value": "Australia", "key": "AU" },
      { "value": "Austria", "key": "AT" },
      { "value": "Azerbaijan", "key": "AZ" },
      { "value": "Bahamas", "key": "BS" },
      { "value": "Bahrain", "key": "BH" },
      { "value": "Bangladesh", "key": "BD" },
      { "value": "Barbados", "key": "BB" },
      { "value": "Belarus", "key": "BY" },
      { "value": "Belgium", "key": "BE" },
      { "value": "Belize", "key": "BZ" },
      { "value": "Benin", "key": "BJ" },
      { "value": "Bermuda", "key": "BM" },
      { "value": "Bhutan", "key": "BT" },
      { "value": "Bolivia", "key": "BO" },
      { "value": "Bosnia and Herzegovina", "key": "BA" },
      { "value": "Botswana", "key": "BW" },
      { "value": "Bouvet Island", "key": "BV" },
      { "value": "Brazil", "key": "BR" },
      { "value": "British Indian Ocean Territory", "key": "IO" },
      { "value": "Brunei Darussalam", "key": "BN" },
      { "value": "Bulgaria", "key": "BG" },
      { "value": "Burkina Faso", "key": "BF" },
      { "value": "Burundi", "key": "BI" },
      { "value": "Cambodia", "key": "KH" },
      { "value": "Cameroon", "key": "CM" },
      { "value": "Canada", "key": "CA" },
      { "value": "Cape Verde", "key": "CV" },
      { "value": "Cayman Islands", "key": "KY" },
      { "value": "Central African Republic", "key": "CF" },
      { "value": "Chad", "key": "TD" },
      { "value": "Chile", "key": "CL" },
      { "value": "China", "key": "CN" },
      { "value": "Christmas Island", "key": "CX" },
      { "value": "Cocos (Keeling) Islands", "key": "CC" },
      { "value": "Colombia", "key": "CO" },
      { "value": "Comoros", "key": "KM" },
      { "value": "Congo", "key": "CG" },
      { "value": "Congo, The Democratic Republic of the", "key": "CD" },
      { "value": "Cook Islands", "key": "CK" },
      { "value": "Costa Rica", "key": "CR" },
      { "value": "Cote D'Ivoire", "key": "CI" },
      { "value": "Croatia", "key": "HR" },
      { "value": "Cuba", "key": "CU" },
      { "value": "Cyprus", "key": "CY" },
      { "value": "Czech Republic", "key": "CZ" },
      { "value": "Denmark", "key": "DK" },
      { "value": "Djibouti", "key": "DJ" },
      { "value": "Dominica", "key": "DM" },
      { "value": "Dominican Republic", "key": "DO" },
      { "value": "Ecuador", "key": "EC" },
      { "value": "Egypt", "key": "EG" },
      { "value": "El Salvador", "key": "SV" },
      { "value": "Equatorial Guinea", "key": "GQ" },
      { "value": "Eritrea", "key": "ER" },
      { "value": "Estonia", "key": "EE" },
      { "value": "Ethiopia", "key": "ET" },
      { "value": "Falkland Islands (Malvinas)", "key": "FK" },
      { "value": "Faroe Islands", "key": "FO" },
      { "value": "Fiji", "key": "FJ" },
      { "value": "Finland", "key": "FI" },
      { "value": "France", "key": "FR" },
      { "value": "French Guiana", "key": "GF" },
      { "value": "French Polynesia", "key": "PF" },
      { "value": "French Southern Territories", "key": "TF" },
      { "value": "Gabon", "key": "GA" },
      { "value": "Gambia", "key": "GM" },
      { "value": "Georgia", "key": "GE" },
      { "value": "Germany", "key": "DE" },
      { "value": "Ghana", "key": "GH" },
      { "value": "Gibraltar", "key": "GI" },
      { "value": "Greece", "key": "GR" },
      { "value": "Greenland", "key": "GL" },
      { "value": "Grenada", "key": "GD" },
      { "value": "Guadeloupe", "key": "GP" },
      { "value": "Guam", "key": "GU" },
      { "value": "Guatemala", "key": "GT" },
      { "value": "Guernsey", "key": "GG" },
      { "value": "Guinea", "key": "GN" },
      { "value": "Guinea-Bissau", "key": "GW" },
      { "value": "Guyana", "key": "GY" },
      { "value": "Haiti", "key": "HT" },
      { "value": "Heard Island and Mcdonald Islands", "key": "HM" },
      { "value": "Holy See (Vatican City State)", "key": "VA" },
      { "value": "Honduras", "key": "HN" },
      { "value": "Hong Kong", "key": "HK" },
      { "value": "Hungary", "key": "HU" },
      { "value": "Iceland", "key": "IS" },
      { "value": "India", "key": "IN" },
      { "value": "Indonesia", "key": "ID" },
      { "value": "Iran, Islamic Republic Of", "key": "IR" },
      { "value": "Iraq", "key": "IQ" },
      { "value": "Ireland", "key": "IE" },
      { "value": "Isle of Man", "key": "IM" },
      { "value": "Israel", "key": "IL" },
      { "value": "Italy", "key": "IT" },
      { "value": "Jamaica", "key": "JM" },
      { "value": "Japan", "key": "JP" },
      { "value": "Jersey", "key": "JE" },
      { "value": "Jordan", "key": "JO" },
      { "value": "Kazakhstan", "key": "KZ" },
      { "value": "Kenya", "key": "KE" },
      { "value": "Kiribati", "key": "KI" },
      { "value": "Korea, Democratic People'S Republic of", "key": "KP" },
      { "value": "Korea, Republic of", "key": "KR" },
      { "value": "Kuwait", "key": "KW" },
      { "value": "Kyrgyzstan", "key": "KG" },
      { "value": "Lao People'S Democratic Republic", "key": "LA" },
      { "value": "Latvia", "key": "LV" },
      { "value": "Lebanon", "key": "LB" },
      { "value": "Lesotho", "key": "LS" },
      { "value": "Liberia", "key": "LR" },
      { "value": "Libyan Arab Jamahiriya", "key": "LY" },
      { "value": "Liechtenstein", "key": "LI" },
      { "value": "Lithuania", "key": "LT" },
      { "value": "Luxembourg", "key": "LU" },
      { "value": "Macao", "key": "MO" },
      { "value": "Macedonia, The Former Yugoslav Republic of", "key": "MK" },
      { "value": "Madagascar", "key": "MG" },
      { "value": "Malawi", "key": "MW" },
      { "value": "Malaysia", "key": "MY" },
      { "value": "Maldives", "key": "MV" },
      { "value": "Mali", "key": "ML" },
      { "value": "Malta", "key": "MT" },
      { "value": "Marshall Islands", "key": "MH" },
      { "value": "Martinique", "key": "MQ" },
      { "value": "Mauritania", "key": "MR" },
      { "value": "Mauritius", "key": "MU" },
      { "value": "Mayotte", "key": "YT" },
      { "value": "Mexico", "key": "MX" },
      { "value": "Micronesia, Federated States of", "key": "FM" },
      { "value": "Moldova, Republic of", "key": "MD" },
      { "value": "Monaco", "key": "MC" },
      { "value": "Mongolia", "key": "MN" },
      { "value": "Montserrat", "key": "MS" },
      { "value": "Morocco", "key": "MA" },
      { "value": "Mozambique", "key": "MZ" },
      { "value": "Myanmar", "key": "MM" },
      { "value": "Namibia", "key": "NA" },
      { "value": "Nauru", "key": "NR" },
      { "value": "Nepal", "key": "NP" },
      { "value": "Netherlands", "key": "NL" },
      { "value": "Netherlands Antilles", "key": "AN" },
      { "value": "New Caledonia", "key": "NC" },
      { "value": "New Zealand", "key": "NZ" },
      { "value": "Nicaragua", "key": "NI" },
      { "value": "Niger", "key": "NE" },
      { "value": "Nigeria", "key": "NG" },
      { "value": "Niue", "key": "NU" },
      { "value": "Norfolk Island", "key": "NF" },
      { "value": "Northern Mariana Islands", "key": "MP" },
      { "value": "Norway", "key": "NO" },
      { "value": "Oman", "key": "OM" },
      { "value": "Pakistan", "key": "PK" },
      { "value": "Palau", "key": "PW" },
      { "value": "Palestinian Territory", "key": "PS" },
      { "value": "Panama", "key": "PA" },
      { "value": "Papua New Guinea", "key": "PG" },
      { "value": "Paraguay", "key": "PY" },
      { "value": "Peru", "key": "PE" },
      { "value": "Philippines", "key": "PH" },
      { "value": "Pitcairn", "key": "PN" },
      { "value": "Poland", "key": "PL" },
      { "value": "Portugal", "key": "PT" },
      { "value": "Puerto Rico", "key": "PR" },
      { "value": "Qatar", "key": "QA" },
      { "value": "Reunion", "key": "RE" },
      { "value": "Romania", "key": "RO" },
      { "value": "Russian Federation", "key": "RU" },
      { "value": "RWANDA", "key": "RW" },
      { "value": "Saint Helena", "key": "SH" },
      { "value": "Saint Kitts and Nevis", "key": "KN" },
      { "value": "Saint Lucia", "key": "LC" },
      { "value": "Saint Pierre and Miquelon", "key": "PM" },
      { "value": "Saint Vincent and the Grenadines", "key": "VC" },
      { "value": "Samoa", "key": "WS" },
      { "value": "San Marino", "key": "SM" },
      { "value": "Sao Tome and Principe", "key": "ST" },
      { "value": "Saudi Arabia", "key": "SA" },
      { "value": "Senegal", "key": "SN" },
      { "value": "Serbia and Montenegro", "key": "CS" },
      { "value": "Seychelles", "key": "SC" },
      { "value": "Sierra Leone", "key": "SL" },
      { "value": "Singapore", "key": "SG" },
      { "value": "Slovakia", "key": "SK" },
      { "value": "Slovenia", "key": "SI" },
      { "value": "Solomon Islands", "key": "SB" },
      { "value": "Somalia", "key": "SO" },
      { "value": "South Africa", "key": "ZA" },
      { "value": "South Georgia and the South Sandwich Islands", "key": "GS" },
      { "value": "Spain", "key": "ES" },
      { "value": "Sri Lanka", "key": "LK" },
      { "value": "Sudan", "key": "SD" },
      { "value": "Suriname", "key": "SR" },
      { "value": "Svalbard and Jan Mayen", "key": "SJ" },
      { "value": "Swaziland", "key": "SZ" },
      { "value": "Sweden", "key": "SE" },
      { "value": "Switzerland", "key": "CH" },
      { "value": "Syrian Arab Republic", "key": "SY" },
      { "value": "Taiwan, Province of China", "key": "TW" },
      { "value": "Tajikistan", "key": "TJ" },
      { "value": "Tanzania, United Republic of", "key": "TZ" },
      { "value": "Thailand", "key": "TH" },
      { "value": "Timor-Leste", "key": "TL" },
      { "value": "Togo", "key": "TG" },
      { "value": "Tokelau", "key": "TK" },
      { "value": "Tonga", "key": "TO" },
      { "value": "Trinidad and Tobago", "key": "TT" },
      { "value": "Tunisia", "key": "TN" },
      { "value": "Turkey", "key": "TR" },
      { "value": "Turkmenistan", "key": "TM" },
      { "value": "Turks and Caicos Islands", "key": "TC" },
      { "value": "Tuvalu", "key": "TV" },
      { "value": "Uganda", "key": "UG" },
      { "value": "Ukraine", "key": "UA" },
      { "value": "United Arab Emirates", "key": "AE" },
      { "value": "United Kingdom", "key": "GB" },
      { "value": "United States", "key": "US" },
      { "value": "United States Minor Outlying Islands", "key": "UM" },
      { "value": "Uruguay", "key": "UY" },
      { "value": "Uzbekistan", "key": "UZ" },
      { "value": "Vanuatu", "key": "VU" },
      { "value": "Venezuela", "key": "VE" },
      { "value": "Viet Nam", "key": "VN" },
      { "value": "Virgin Islands, British", "key": "VG" },
      { "value": "Virgin Islands, U.S.", "key": "VI" },
      { "value": "Wallis and Futuna", "key": "WF" },
      { "value": "Western Sahara", "key": "EH" },
      { "value": "Yemen", "key": "YE" },
      { "value": "Zambia", "key": "ZM" },
      { "value": "Zimbabwe", "key": "ZW" }
    ]`) as Array<KeyValuePair>;
  }

  getQaModes(): Array<KeyValuePair> {
    return [
      {key:1, value: 'Both'},
      {key:2, value: 'FM Only'},
      {key:3, value: 'QS Only'},
    ] as Array<KeyValuePair>;
  }

  private featchEnums() {
    this.isUpdating = true;
    this.rest.enums('get').subscribe(
      this.refresh.bind(this)
    );
  }

  private refresh(data: Array<SystemEnum>) {
    this.storage.set('enums', data);
    this.allEnums = data;
    this.isUpdating = false;
  }

  private getListByName(name): Array<SystemEnum> {
    if (!this.allEnums)
      this.allEnums = this.storage.get('enums');
    this.featchEnums();
    if (!this.allEnums) return null;
    return this.allEnums.filter(e => e.enum_name == name);
  }

  public getPolyTypesDictionary(): { [id: number]: KeyValuePoly } {
    let res = {} as { [id: number]: KeyValuePoly };
    res[PolyType.LOW] = {
      key: PolyType.LOW,
      value: {
        name: 'LOW',
        icon: 'https://cdn.hexa3d.io/hotlink-ok/icons/v2/low-poly.svg'
      }
    } as KeyValuePoly;
    res[PolyType.MID] = {
      key: PolyType.MID,
      value: {
        name: 'MID',
        icon: 'https://cdn.hexa3d.io/hotlink-ok/icons/v2/mid-poly.svg'
      }
    } as KeyValuePoly;
    res[PolyType.HIGH] = {
      key: PolyType.HIGH,
      value: {
        name: 'HIGH',
        icon: 'https://cdn.hexa3d.io/hotlink-ok/icons/v2/high-poly.svg'
      }
    } as KeyValuePoly;
    return res;
  }

  public getBusinessPlans(): Array<KeyValueAnyPair> {
    let res = [] as Array<KeyValueAnyPair>;
    res.push({
      key: BusinessPlan.PayPer3DAsset,
      value: 'pay per 3D asset'
    } as KeyValueAnyPair);
    res.push({
      key: BusinessPlan.PayPerAPICall,
      value: 'pay per API call'
    } as KeyValueAnyPair);
    // res[BusinessPlan.PayPer3DAsset] = {
    //   key: BusinessPlan.PayPer3DAsset,
    //   value: 'pay per 3D asset'
    // } as KeyValueAnyPair;
    // res[BusinessPlan.PayPerAPICall] = {
    //   key: BusinessPlan.PayPerAPICall,
    //   value: 'pay per API call'
    // } as KeyValueAnyPair;
    return res;
  }

  public getPaymentModels(): Array<KeyValueAnyPair> {
    let res = [] as Array<KeyValueAnyPair>;
    res.push({
      key: PaymentModel.Limited,
      value: 'monthly'
    } as KeyValueAnyPair);
    res.push({
      key: PaymentModel.Unlimited,
      value: 'SLA period'
    } as KeyValueAnyPair);
    // res[PaymentModel.Limited] = {
    //   key: PaymentModel.Limited,
    //   value: 'limited'
    // } as KeyValueAnyPair;
    // res[PaymentModel.Unlimited] = {
    //   key: PaymentModel.Unlimited,
    //   value: 'unlimited'
    // } as KeyValueAnyPair;
    return res;
  }

  public getCreditTypes(): Array<KeyValueAnyPair> {
    let res = [] as Array<KeyValueAnyPair>;
    res.push({
      key: CreditType.Amount,
      value: '$'
    } as KeyValueAnyPair);
    res.push({
      key: CreditType.Percentage,
      value: '%'
    });
    // res[CreditType.Amount] = {
    //   key: CreditType.Amount,
    //   value: 'amount'
    // } as KeyValueAnyPair;
    // res[CreditType.Percentage] = {
    //   key: CreditType.Percentage,
    //   value: 'percentage'
    // } as KeyValueAnyPair;
    return res;
  }

  public getAgreementBudgetTypes(): Array<KeyValueAnyPair> {
    let res = [] as Array<KeyValueAnyPair>;
    res.push({
      key: AgreementBudgetType.MONTHLY,
      value: 'Monthly'
    } as KeyValueAnyPair);
    res.push({
      key: AgreementBudgetType.SLA_PERIOD,
      value: 'SLA Period'
    } as KeyValueAnyPair);
    return res;
  }

  public getPriorities(): Array<KeyValuePair> {
    let res = [] as Array<KeyValuePair>;
    res.push({
      key: Priority.LOWEST,
      value: Priority[Priority.LOWEST]
    });
    res.push({
      key: Priority.LOW,
      value: Priority[Priority.LOW]
    });
    res.push({
      key: Priority.MEDIUM,
      value: Priority[Priority.MEDIUM]
    });
    res.push({
      key: Priority.HIGH,
      value: Priority[Priority.HIGH]
    });
    res.push({
      key: Priority.HIGHEST,
      value: Priority[Priority.HIGHEST]
    });
    return res;
  }

  public getPrioritiesDictionary(): { [id: number]: string } {
    let res = {} as { [id: number]: string };
    res[Priority.LOWEST] = Priority[Priority.LOWEST];
    res[Priority.LOW] = Priority[Priority.LOW];
    res[Priority.MEDIUM] = Priority[Priority.MEDIUM];
    res[Priority.HIGH] = Priority[Priority.HIGH];
    res[Priority.HIGHEST] = Priority[Priority.HIGHEST];
    return res;
  }

  public getDateFilters(): Array<KeyValuePair> {
    let res = [] as Array<KeyValuePair>;
    res.push({ key: "Creation date", value: "created_at" });
    res.push({ key: "Delivery date", value: "delivered_at" });
    res.push({ key: "Approved date", value: "approved_at" });
    res.push({ key: "Due date", value: "due_date" });
    return res;
  }

  getRenderStatus(render: ProductsRendersRequestStatus): RenderStatus {
    switch (render) {
      case ProductsRendersRequestStatus.CLIENT_FEEDBACK: {
        return RenderStatus.FEEDBACK;
      }
      case ProductsRendersRequestStatus.DELIVERED: {
        return RenderStatus.DELIVERED;
      }
      case ProductsRendersRequestStatus.IN_PROGRESS: {
        return RenderStatus.IN_PROGRESS;
      }
      case ProductsRendersRequestStatus.OFFLINE: {
        return RenderStatus.APPROVED_OFFLINE;
      }
      case ProductsRendersRequestStatus.ONLINE: {
        return RenderStatus.APPROVED_ONLINE;
      }
      case ProductsRendersRequestStatus.REQUESTED: {
        return RenderStatus.PENDING;
      }
      default: {
        return undefined;
      }
    }
  }
  public getRenderStatuses(): Array<KeyValuePair> {
    let res = [] as Array<KeyValuePair>;
    res.push({
      key: RenderStatus.FEEDBACK,
      value: ProductsRendersRequestStatus.CLIENT_FEEDBACK
    });
    res.push({
      key: RenderStatus.DELIVERED,
      value: ProductsRendersRequestStatus.DELIVERED
    });
    res.push({
      key: RenderStatus.IN_PROGRESS,
      value: ProductsRendersRequestStatus.IN_PROGRESS
    });
    res.push({
      key: RenderStatus.APPROVED_OFFLINE,
      value: ProductsRendersRequestStatus.OFFLINE
    });
    res.push({
      key: RenderStatus.APPROVED_ONLINE,
      value: ProductsRendersRequestStatus.ONLINE
    });
    res.push({
      key: RenderStatus.PENDING,
      value: ProductsRendersRequestStatus.REQUESTED
    });
    return res;

  }

  public getAllStatus(): Array<KeyValuePair> {
    let res = [] as Array<KeyValuePair>;
    res.push({
      key: ProductsStatus.CLIENT_FEEDBACK,
      value: ProductsStatus[ProductsStatus.CLIENT_FEEDBACK]
    });
    res.push({
      key: ProductsStatus.DELIVERED,
      value: ProductsStatus[ProductsStatus.DELIVERED]
    });
    res.push({
      key: ProductsStatus.IN_PROGRESS,
      value: ProductsStatus[ProductsStatus.IN_PROGRESS]
    });
    res.push({
      key: ProductsStatus.OFFLINE,
      value: ProductsStatus[ProductsStatus.OFFLINE]
    });
    res.push({
      key: ProductsStatus.ONLINE,
      value: ProductsStatus[ProductsStatus.ONLINE]
    });
    res.push({
      key: ProductsStatus.REJECTED,
      value: ProductsStatus[ProductsStatus.REJECTED]
    });
    res.push({
      key: ProductsStatus.REQUESTED,
      value: ProductsStatus[ProductsStatus.REQUESTED]
    });
    return res;
    
  }

  public toKeyValueArray(e): Array<KeyValueAnyPair>  {
   var keysArr = Object.values(e).filter((v) => isNaN(Number(v)));
   return keysArr.map( k => {return {value: k, key: e[k.toString()] }});
  }
}
