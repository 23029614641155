<!-- <div *ngIf="prService.iframeSrc" class="preview-iframe router-outlet-wrap" [ngClass]="{'on': prService.iframeSrc}">
  <button mat-icon-button color="accent" class="close pos-abs" (click)="prService.clearIframeSrc()">
    <i class="icon-close"></i>
  </button>
  <iframe [src]="prService.iframeSrc | safeWindow" frameborder="0"></iframe>
</div> -->
<div class="router-outlet-wrap" [ngClass]="{'on': prService.currentFeedbackEditId}">
  <router-outlet></router-outlet>
</div>
<div class="h-100">
  <div *ngIf="prodLoaded" class="main-part float-dir h-100">
    <div class="wrap h-100 pos-rel">
      <app-resource-dropdown *ngIf="prService.enabledResources.length"
                             class="resource-select"
                             [resources]="prService.enabledResources"
                             [selectedResource]="prService.selectedResource"
                             (selected)="onResourceChange($event)"
      ></app-resource-dropdown>
      <mat-tab-group class="no-min-w customized big-text no-marg-first h-100 product-request-icons"
        (selectedIndexChange)="prService.navigateToState($event)" [selectedIndex]="productService.initialState"
        *ngIf="hasModel" [disableRipple]="true">
        <mat-tab label="Settings">
          <app-product-settings class="disp-block settings"></app-product-settings>
        </mat-tab>
        <mat-tab label="Add Feedback">
          <app-feedback-creation class="disp-block" [product]="prService.request"
            [resource]="prService.enabledResources[prService.currentResourceIndex]"></app-feedback-creation>
        </mat-tab>
        <mat-tab label="Adjustments" [disabled]="!prService.adjustmentsModeAvailable">
          <app-viewer-iframe *ngIf="prService.enabledResources[prService.currentResourceIndex]?.resource_default" [viewer-url]="prService.enabledResources[prService.currentResourceIndex]?.resource_default"
            class="disp-block"></app-viewer-iframe>
        </mat-tab>
        <mat-tab label="Render" [disabled]="!prService.renderModeAvailable">
          <app-renderer *ngIf="prService.renderMode"
            [resource]="prService.enabledResources[prService.currentResourceIndex]" class="disp-block h-100"
            [retailer-id]="prService.request.retailers[prService.retailerIndex]?.id" [price]="prService.renderPrice"
            [product-id]="prService.request.id"></app-renderer>
        </mat-tab>
      </mat-tab-group>
      <app-product-settings class="disp-block h-100" *ngIf="!hasModel">
      </app-product-settings>
    </div>
  </div>
  <div class="side-part float-dir h-100" *ngIf="prService?.request?.retailers">
    <app-product-history *ngIf="prService?.request.id && !prService.adjustmentsMode" class="h-100 hide-sm"
      [products-audit]="prService.request.products_audit"
      [products-data]="prService.request.products_data"
      [products-attachments]="prService.request.products_attachments"
      [retailer]="prService.request.retailers[prService.retailerIndex]"></app-product-history>
    <app-asset-adjustments *ngIf="prService?.request.id && prService.adjustmentsMode && prService.iframeModel" class="h-100 hide-sm scrollbar"
      [created-at]="prService.enabledResources[prService.currentResourceIndex].created_at"
      [iframe-model]="prService.iframeModel" [resource]="prService.enabledResources[prService.currentResourceIndex]"
      (on-dimensions)="prService.onDimensions($event)" (change-src)="prService.changeSrc($event)">
    </app-asset-adjustments>
    <app-product-quote *ngIf="!prService?.request.id" class="h-100"></app-product-quote>
  </div>
</div>
