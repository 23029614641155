import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { User, UserQueryData } from '../user';
import { ApolloQueryResult } from '@apollo/client/core';

@Component({
    selector: 'app-social',
    standalone: true,
    imports: [],
    templateUrl: './social.component.html',
    styleUrl: './social.component.scss',
})
export class SocialComponent {
    constructor(
        private activatedRoute: ActivatedRoute,
        private auth: AuthService,
        private router: Router
    ) {}

    ngOnInit() {
        this.startSocialSignup();
    }

    startSocialSignup() {
        this.saveUserProfile();
        this.router.navigate(['/login']);
    }

    private saveUserProfile() {
        if (this.activatedRoute.snapshot.data['profile']) {
            const profile: User = (
                this.activatedRoute.snapshot.data[
                    'profile'
                ] as ApolloQueryResult<UserQueryData>
            ).data.users;
            this.auth.atachRolesToUser(profile);
            this.auth.storeUser(profile);
        }
    }
}
