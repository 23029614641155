<div class="external-resource-upload-item">
  <img [src]="imgSrc">
  <div class="resource-name align-self-center">{{resource.extension}}</div>
  <mat-icon *ngIf="resource.submitted" class="align-self-center uploaded-icon">check</mat-icon>
  <mat-icon *ngIf="resource.loading" class="align-self-center uploaded-icon blink">upload</mat-icon>

  <div class="hover-actions">
    <mat-icon (click)="onDelete()" *ngIf="!resource.submitted || roles.isSuperUserLoggedIn()">delete_outline</mat-icon>
    <mat-icon (click)="onPreview()">visibility</mat-icon>
  </div>
</div>
