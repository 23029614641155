import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(array: any[], field: string, desc?: boolean): Array<any> {
    if (!(array instanceof Array)) return array;
    array.sort((a: any, b: any) => {
      let val;
      if (a[field] < b[field]) {
        val = -1;
      } else if (a[field] > b[field]) {
        val = 1;
      } else {
        val = 0;
      }
      return desc ? val * -1 : val;

    });
    return array;
  }

}
