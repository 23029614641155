<div layout-padding>
    <div class="text-center">
      <!-- <h2 class="h1 title">
        broadcast a notification
      </h2>
    </div>
    <form [formGroup]="broadcastForm" (ngSubmit)="send()">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutAlign.xs="start" class="flex-wrap">
        <div fxFlex="100%" fxFlex.gt-sm="" class="text-center">
          <app-text-editor (textChange)="onTextChange($event)" placeholder="type your message here . . ."></app-text-editor>
          <mat-hint *ngIf="!broadcastForm.controls['notes'].valid && broadcastForm.dirty" class="warn" align="start">message is required</mat-hint>
          <input type="number" required matInput formControlName="notification_type" [hidden]="true">
        </div>
        <div fxFlex="100%" fxFlex.gt-sm="" class="text-center">
          <button type="submit" mat-icon-button color="primary" [disabled]="!broadcastForm.valid">
            <mat-icon>mic</mat-icon>
          </button>
        </div>
      </div>
    </form> -->
  
    <div class="text-center">
      <h2 class="h1 title">
        version update
      </h2>
    </div>
  
    <form [formGroup]="updateForm" (ngSubmit)="updateVersion()">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutAlign.xs="start" class="flex-wrap">
        <input type="number" formControlName="notification_type" [hidden]="true">
        <div fxFlex="100%" fxFlex.gt-sm="" class="text-center">
          <button type="submit" mat-icon-button color="primary">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>
    </form>
  
  </div>