import { Component } from '@angular/core';
import { ProductService } from '../product.service';
import { KeyValuePair } from 'src/app/shared/enums';

@Component({
  selector: 'app-products-report-generator',
  templateUrl: './products-report-generator.component.html',
  styleUrls: ['./products-report-generator.component.scss']
})
export class ProductsReportGeneratorComponent {
  public fields: Array<KeyValuePair>;
  constructor(
    public productService: ProductService
  ) {
    this.fields = [
      {
        key: true,
        value: {
          key: 'name',
          value: 'Name'
        }
      },
      {
        key: true,
        value: {
          key: 'serial_number',
          value: 'Serial Number'
        }
      },
      {
        key: true,
        value: {
          key: 'variation_id',
          value: 'Variation ID'
        }
      },
      {
        key: true,
        value: {
          key: 'created_at',
          value: 'Created at'
        }
      },
      {
        key: true,
        value: {
          key: 'approved_at',
          value: 'Approved at'
        }
      },
      {
        key: true,
        value: {
          key: 'approved_by',
          value: 'Approved by'
        }
      },
      {
        key: false,
        value: {
          key: 'api_calls',
          value: 'API Calls'
        }
      },
      {
        key: true,
        value: {
          key: 'products_urls',
          value: 'Products URLs'
        }
      },
      {
        key: true,
        value: {
          key: 'category',
          value: 'Category'
        }
      
      },
      {
        key: true,
        value: {
          key: 'sub_category',
          value: 'Sub category'
        }
      
      },
      {
        key: true,
        value: {
          key: 'products_tags',
          value: 'Tags'
        }
      
      },
      {
        key: true,
        value: {
          key: 'status',
          value: 'Status'
        }
     
      },
      {
        key: true,
        value: {
          key: 'days_since_status_change',
          value: 'In current status since'
        }
      
      },
      {
        key: true,
        value: {
          key: 'reject_reason',
          value: 'Rejection reason'
        }
      
      },
    
      {
        key: true,
        value: {
          key: 'pending_feedbacks',
          value: 'Pending feedbacks'
        }
      
      },
      {
        key: true,
        value: {
          key: 'pending_feedbacks_urls',
          value: 'Pending feedbacks URLs'
        }
      },
      {
        key: true,
        value: {
          key: 'batch_name',
          value: 'Batch name'
        }
      },
      {
        key: true,
        value: {
          key: 'cms_url',
          value: 'CMS URL'
        }
      }
    ];
  }

  generateReport() {
    this.productService.generateReport(this.fields.filter(f => f.key).map(f => f.value.key))
  }
}
