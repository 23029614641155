import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { BroadcasterService } from 'ng-broadcaster';
import { CanvasPaintState, Coordinates3D } from '../enums';
import { CanvasPaintEvent } from '../utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-canvas-paint',
  templateUrl: './canvas-paint.component.html',
  styleUrls: ['./canvas-paint.component.scss']
})
export class CanvasPaintComponent implements AfterViewInit, OnDestroy {
  static QUALITY = 0.92;
  @Input() image: string;
  @Input() width: number;
  @Input() height: number;
  // @Input() ratio: number;
  @Output() final: EventEmitter<string>;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('textinput') textinput: ElementRef;
  private context: any;
  private img: any;
  private boundingElement: any;
  private subs: Array<Subscription>;
  private isDragging: boolean;
  public events: Array<CanvasPaintEvent>;
  public eventsHistory: Array<CanvasPaintEvent>;
  public textToAdd: string;
  public state: CanvasPaintState;
  public color: string;
  public WRITE: CanvasPaintState;
  public lineWidth: number;
  constructor(private broadcaster: BroadcasterService) {
    this.WRITE = CanvasPaintState.WRITE;
    this.final = new EventEmitter<string>();
    this.events = new Array<CanvasPaintEvent>();
    this.eventsHistory = new Array<CanvasPaintEvent>();
    this.color = '#ff0000';
    this.subs = [];
    this.setState(CanvasPaintState.DRAW);
    this.lineWidth = 5;
  }

  ngAfterViewInit() {
    // this.context = this.canvas.nativeElement.getContext('2d', { desynchronized: true });
    this.context = this.canvas.nativeElement.getContext('2d');
    this.boundingElement = this.canvas.nativeElement.parentElement.parentElement;
    if (this.image) {
      this.img = new Image();
      let self = this;
      this.img.onload = () => {
        this.init.apply(self);
      };
      this.img.src = this.image;
    }
    else {
      this.init();
      this.emitFinalImage();
    }
  }

  emitFinalImage() {
    // requestAnimationFrame(() => {
    //   requestAnimationFrame(() => {
    //     setTimeout(() => {


          // var imgd = this.context.getImageData(0, 0, this.width, this.height),
          //   pix = imgd.data,
          //   newColor = { r: 255, g: 255, b: 255, a: 1 };

          // for (var i = 0, n = pix.length; i < n; i += 4) {
          //   var r = pix[i],
          //     g = pix[i + 1],
          //     b = pix[i + 2],
          //     a = pix[i + 3];

          //   // If its transparent then change it
          //   if (a == 0) {
          //     // Change the transparent to white.
          //     pix[i] = newColor.r;
          //     pix[i + 1] = newColor.g;
          //     pix[i + 2] = newColor.b;
          //     pix[i + 3] = newColor.a;
          //   }
          // }

          // this.context.putImageData(imgd, 0, 0);


          // this.canvas.nativeElement.toBlob((blob: Blob) => {
          //   let reader = new FileReader();
          //   reader.onloadend = () => {
          //     this.final.emit(reader.result as string);
          //   }
          //   reader.readAsDataURL(blob);
          // }, 'image/png', CanvasPaintComponent.QUALITY);
          this.final.emit(this.canvas.nativeElement.toDataURL('image/png', CanvasPaintComponent.QUALITY));
          // this.final.emit(this.canvas.nativeElement.toDataURL('image/jpeg', 0.95));
    //     });
    //   });
    //   // this.final.emit(this.canvas.nativeElement.toDataURL());
    // });
  }

  onMouseUp(e) {
    this.isDragging = false;
    this.emitFinalImage();
    if (this.state == CanvasPaintState.LINE || this.state == CanvasPaintState.DRAW) {
      let coo = this.getPositionFromEvent(e);
      this.addClick(coo);
      this.addClick(coo, false, CanvasPaintState.NOOP);
      this.redraw();
    }
  };

  init() {
    this.canvas.nativeElement.setAttribute('width', this.width);
    this.canvas.nativeElement.setAttribute('height', this.height);
    if (this.img) {
      this.drawImage();
    }
    this.canvas.nativeElement.addEventListener('mouseleave', (e) => { this.onMouseLeave.apply(this, [e]) }, false);
    this.canvas.nativeElement.addEventListener('mouseup', (e) => { this.onMouseUp.apply(this, [e]) }, false);
    this.canvas.nativeElement.addEventListener('touchend', (e) => { this.onMouseUp.apply(this, [e]) }, false);
    this.canvas.nativeElement.addEventListener('mousemove', (e) => { this.onMouseMove.apply(this, [e]) }, false);
    this.canvas.nativeElement.addEventListener('touchmove', (e) => { this.onMouseMove.apply(this, [e]) }, false);
    this.canvas.nativeElement.addEventListener('mousedown', (e) => { this.onMouseDown.apply(this, [e]) }, false);
    this.canvas.nativeElement.addEventListener('touchstart', (e) => { this.onMouseDown.apply(this, [e]) }, false);

    setTimeout(() => {
      // this.final.emit(this.canvas.nativeElement.toDataURL('image/png', CanvasPaintComponent.QUALITY));
      this.emitFinalImage();
    }, 600);
    this.subs.push(this.broadcaster.on('ctrlZ').subscribe(() => {
      if (!this.isInputFocused())
        this.undo();
    }));
    this.subs.push(this.broadcaster.on('ctrlY').subscribe(() => {
      if (!this.isInputFocused())
        this.redo();
    }));
  }

  isInputFocused() {
    if (document.activeElement) {
      if (document.activeElement.tagName.toLowerCase() == 'textarea' || document.activeElement.tagName.toLowerCase() == 'input')
        return true;
    }
    return false;
  }

  onMouseMove = (e) => {
    if (this.state != CanvasPaintState.WRITE && this.isDragging) {
      let coo = this.getPositionFromEvent(e);
      this.addClick(coo);
      this.redraw();
    }
    else if (this.state == CanvasPaintState.HORIZONTAL_LINE || this.state == CanvasPaintState.VERTICAL_LINE) {
      let coo = this.getPositionFromEvent(e);
      // let ce = this.events[this.events.length - 1];
      // this.addClick(coo);
      // this.addClick(coo, false, CanvasPaintState.NOOP);
      this.addClick(coo);
      // if (ce && ce.state == this.state && !ce.isDone) {
      //   ce.clickX = coo.x;
      //   ce.clickY = coo.y;
      // }
      // else {
      //   this.addClick(coo);
      // }
      this.redraw();
    }
  };

  onMouseLeave() {
    this.isDragging = false;
    this.emitFinalImage();
    // this.final.emit(this.canvas.nativeElement.toDataURL('image/png', CanvasPaintComponent.QUALITY));
  };

  undo() {
    if (this.events.length < 1) return;
    do {
      this.eventsHistory.push(this.events.pop());
    } while (this.events.length && this.events[this.events.length - 1].state != CanvasPaintState.NOOP)
    // while (this.events.length && this.events[this.events.length - 1].state != CanvasPaintState.NOOP) {
    //   this.eventsHistory.push(this.events.pop());
    // }
    this.redraw();
    this.emitFinalImage();
    // this.final.emit(this.canvas.nativeElement.toDataURL('image/png', CanvasPaintComponent.QUALITY));
    if (window.event && window.event.preventDefault)
      window.event.preventDefault();
  }

  redo() {
    if (this.eventsHistory.length < 1) return;
    this.events.push(this.eventsHistory.pop());
    while (this.eventsHistory.length && this.eventsHistory[this.eventsHistory.length - 1].state != CanvasPaintState.NOOP) {
      this.events.push(this.eventsHistory.pop());
    }
    this.redraw();
    this.emitFinalImage();
    // this.final.emit(this.canvas.nativeElement.toDataURL('image/png', CanvasPaintComponent.QUALITY));
    if (window.event && window.event.preventDefault)
      window.event.preventDefault();
  }

  toggleLine() {
    if (this.state == CanvasPaintState.LINE)
      this.setState(CanvasPaintState.DRAW);
    else
      this.setState(CanvasPaintState.LINE);
  }

  clearAll() {
    this.events = new Array<CanvasPaintEvent>();
    this.eventsHistory = new Array<CanvasPaintEvent>();
    this.redraw();
  }

  text() {
    this.setState(CanvasPaintState.WRITE);
    setTimeout(() => {
      this.textinput.nativeElement.focus();
    }, 300);
  }

  getPositionFromEvent = (e: any): Coordinates3D => {
    if (e.type == 'touchmove') {
      e.preventDefault();
      return {
        x: e.touches[0].pageX - this.canvas.nativeElement.getBoundingClientRect().left - window.scrollX,
        y: e.touches[0].pageY - this.boundingElement.getBoundingClientRect().top - window.scrollY
      } as Coordinates3D;
    }
    return {
      x: e.pageX - this.canvas.nativeElement.getBoundingClientRect().left - window.scrollX,
      y: e.pageY - this.boundingElement.getBoundingClientRect().top - window.scrollY - 40
    } as Coordinates3D;
  }

  isLineDragging(state: CanvasPaintState) {
    return this.isDragging && state == CanvasPaintState.LINE &&
      this.events[this.events.length - 1] &&
      this.events[this.events.length - 1].state ==
      CanvasPaintState.LINE && this.events[this.events.length - 1].isDragging;
  }

  isTextWriting(state: CanvasPaintState) {
    return state == CanvasPaintState.WRITE &&
      this.events[this.events.length - 1] &&
      this.events[this.events.length - 1].state ==
      CanvasPaintState.WRITE;
  }

  updateLast(state: CanvasPaintState) {
    return this.events.length && (this.isLineDragging(state) || this.isTextWriting(state) ||
      ((this.state == CanvasPaintState.HORIZONTAL_LINE || this.state == CanvasPaintState.VERTICAL_LINE) && !this.events[this.events.length - 1].isDone));
  }

  addClick(coo: Coordinates3D, dragging = this.isDragging, state = this.state, isChunk = !this.isDragging) {
    if (this.updateLast(state)) {
      this.events[this.events.length - 1].clickX = coo.x;
      this.events[this.events.length - 1].clickY = coo.y;
      this.events[this.events.length - 1].text = this.textToAdd;
      this.events[this.events.length - 1].lineWidth = this.lineWidth;
    }
    else {
      let newEvent = {
        clickX: coo.x,
        clickY: coo.y,
        isDragging: dragging,
        color: this.color,
        state: state,
        lineWidth: this.lineWidth,
        isChunk: isChunk,
        text: this.textToAdd
      } as CanvasPaintEvent;
      if (newEvent.state == CanvasPaintState.VERTICAL_LINE || newEvent.state == CanvasPaintState.HORIZONTAL_LINE) {
        this.events.push(
          {
            clickX: coo.x,
            clickY: coo.y,
            isDragging: false,
            color: this.color,
            state: CanvasPaintState.NOOP,
            lineWidth: this.lineWidth,
            isChunk: false,
            text: this.textToAdd
          } as CanvasPaintEvent
        );
      }
      this.events.push(newEvent);
    }
  }

  getFontSizeByLineWidth(lineWidth: number): number {
    return 8 + (2 * lineWidth);
  }

  setState(state: CanvasPaintState) {
    this.state = state;

    let ce = this.events[this.events.length - 1];
    if (ce) {
      if (ce.state == CanvasPaintState.HORIZONTAL_LINE || ce.state == CanvasPaintState.VERTICAL_LINE) {
        if (!ce.isDone)
          this.events.pop();
      }
    }
    this.redraw();
  }

  onMouseDown(e) {
    // if (this.state == CanvasPaintState.VERTICAL_LINE || this.state == CanvasPaintState.HORIZONTAL_LINE) {
    let ce = this.events[this.events.length - 1];
    if (ce)
      ce.isDone = true;
    // }
    let coo = this.getPositionFromEvent(e);
    this.addClick(coo);

    if (this.state == CanvasPaintState.WRITE) {
      if (this.textToAdd) {
        this.setState(CanvasPaintState.DRAW);
        delete this.textToAdd;
      }
      else {
        const fSize = this.getFontSizeByLineWidth(this.lineWidth);
        this.textinput.nativeElement.style.fontSize = fSize + 'px';
        this.textinput.nativeElement.style.lineHeight = fSize + 'px';
        setTimeout(() => {
          this.textinput.nativeElement.focus();
        }, 300);
      }
    }

    this.isDragging = true;
    this.redraw();
    this.emitFinalImage();
    // this.final.emit(this.canvas.nativeElement.toDataURL('image/png', CanvasPaintComponent.QUALITY));
  }

  drawImage() {
    let imgDim = {
      width: this.img.width,
      height: this.img.height
    };
    if (imgDim.width > this.width) {
      imgDim.height /= (imgDim.width / this.width);
      imgDim.width = this.width;
    }
    if (imgDim.height > this.height) {
      imgDim.width /= (imgDim.height / this.height);
      imgDim.height = this.height;
    }
    this.canvas.nativeElement.setAttribute('width', imgDim.width);
    this.canvas.nativeElement.setAttribute('height', imgDim.height);
    this.context.drawImage(this.img, 0, 0, imgDim.width, imgDim.height);
  }

  canvasHorizontalLines(context: any, fromx: number, tox: number, y: number, color: string) {
    context.beginPath();
    context.setLineDash([5, 5]);
    context.lineWidth = 2;
    context.moveTo(fromx, y);
    context.strokeStyle = color;
    context.lineTo(tox, y);
    context.stroke();
    context.setLineDash([]);
  }

  canvasVerticalLines(context: any, fromy: number, toy: number, x: number, color: string) {
    context.beginPath();
    context.setLineDash([5, 5]);
    context.lineWidth = 2;
    context.moveTo(x, fromy);
    context.strokeStyle = color;
    context.lineTo(x, toy);
    context.stroke();
    context.setLineDash([]);
  }

  canvasArrow(context: any, fromx: number, fromy: number, tox: number, toy: number, r: number, color: string) {
    let x_center = tox, y_center = toy, angle, x, y;

    context.beginPath();

    angle = Math.atan2(toy - fromy, tox - fromx)
    x = r * Math.cos(angle) + x_center;
    y = r * Math.sin(angle) + y_center;

    context.moveTo(x, y);

    angle += (1 / 3) * (2 * Math.PI)
    x = r * Math.cos(angle) + x_center;
    y = r * Math.sin(angle) + y_center;

    context.lineTo(x, y);

    angle += (1 / 3) * (2 * Math.PI);
    x = r * Math.cos(angle) + x_center;
    y = r * Math.sin(angle) + y_center;

    context.lineTo(x, y);

    context.closePath();

    context.fillStyle = color;
    context.fill();
  }

  redraw() {
    if (!this.context) return;
    this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height); // Clears the canvas
    if (this.img)
      this.drawImage();

    this.context.strokeStyle = this.color;
    this.context.lineJoin = 'round';
    this.context.lineWidth = this.lineWidth;
    this.context.shadowColor = '#fff';

    for (var i = 0; i < this.events.length; i++) {
      this.context.strokeStyle = this.events[i].color;
      this.context.lineWidth = this.events[i].lineWidth;

      this.context.beginPath();
      if (this.events[i].isChunk && this.events[i].state == CanvasPaintState.WRITE) {
        if (this.events[i].text) {
          this.context.font = this.getFontSizeByLineWidth(this.events[i].lineWidth) + 'px Arial';
          this.context.shadowOffsetX = 1;
          // this.context.shadowOffsetY = 2;
          this.context.fillStyle = this.events[i].color;
          // context.shadowBlur = 2;
          this.context.fillText(this.events[i].text, this.events[i].clickX, this.events[i].clickY);
        }
      }
      else if (this.events[i].isDragging) {
        this.context.shadowOffsetX = 0;
        this.context.shadowOffsetY = 0;
        if (this.events[i] && i)
          this.context.moveTo(this.events[i - 1].clickX, this.events[i - 1].clickY);
        else
          this.context.moveTo(this.events[i].clickX, this.events[i].clickY);
        this.context.lineTo(this.events[i].clickX, this.events[i].clickY);
        this.context.closePath();
        this.context.stroke();
      }
      else if (this.events[i].state == CanvasPaintState.LINE &&
        this.events[i].isChunk &&
        this.events[i - 1] &&
        this.events[i - 1].state == CanvasPaintState.LINE &&
        !this.events[i - 1].isChunk) {
        let dirIndex = i - 1;
        if (this.events[dirIndex - 1])
          dirIndex -= 1;
        this.canvasArrow(this.context, this.events[dirIndex].clickX, this.events[dirIndex].clickY,
          this.events[i].clickX, this.events[i].clickY, 12, this.events[i].color);
      }
      else if (this.events[i].state == CanvasPaintState.HORIZONTAL_LINE) {
        this.canvasHorizontalLines(this.context, 0, this.width, this.events[i].clickY, this.events[i].color);
      }
      else if (this.events[i].state == CanvasPaintState.VERTICAL_LINE) {
        const canvasActualRatio = this.canvas.nativeElement.getBoundingClientRect().width / this.width;
        const canvasActualHalf = (canvasActualRatio * (this.width / 2));
        if (canvasActualHalf < ((this.events[i].clickX * canvasActualRatio))) {
          this.canvasVerticalLines(this.context, 0, this.height, this.events[i].clickX, this.events[i].color);
          this.canvasVerticalLines(this.context, 0, this.height, this.events[i].clickX - canvasActualHalf, this.events[i].color);
        }
        else {
          this.canvasVerticalLines(this.context, 0, this.height, this.events[i].clickX, this.events[i].color);
          this.canvasVerticalLines(this.context, 0, this.height, this.events[i].clickX + canvasActualHalf, this.events[i].color);
        }

      }
    }
    // this.final.emit(this.canvas.nativeElement.toDataURL());
  }

  addVerticalLine() {
    this.setState(CanvasPaintState.VERTICAL_LINE);
  }

  addHorizontalLine() {
    this.setState(CanvasPaintState.HORIZONTAL_LINE);
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
