<h2 mat-dialog-title class="capitalize">insufficient funds</h2>
<mat-dialog-content class="pos-rel">
  <p class="text2">
    Your current company's budget is <b>{{budget | number }}$</b> while creating the requested 3D asset costs
    <b>{{price | number }}$</b>.
  </p>
  <p class="text2">
    Please add more funds to create this 3D asset by entering the desired amount and clicking the <b>ADD FUNDS</b> button.
  </p>
  <p class="text2 warn" *ngIf="!validRetailer.state">
    We are unable to charge you due-to missing mandatory information. Please visit your <a class="link1" target="_blank" routerLink="/settings/{{auth.user.id}}">profile</a> and fill out the missing fields.
  </p>
  <app-add-funds [retailer]="retailer"></app-add-funds>
  <button class="forfeit" mat-button mat-dialog-close>forfeit</button>
</mat-dialog-content>