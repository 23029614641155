import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SwUpdate, SwPush } from '@angular/service-worker';
import { BroadcasterService } from 'ng-broadcaster';
import { AuthService } from '../auth/auth.service';
import { RestService } from '../communication/rest.service';
import { StorageService } from 'ng-storage-service';
import { environment } from 'src/environments/environment';
import { BroadcasterNotificationType, BroadcasterNotification } from '../communication/broadcaster-notifications';

@Injectable({
  providedIn: 'root'
})
export class SwService {
  static VAPID_PUBLIC_KEY = 'BCe9aRSdvDjCciytiReI-3aJK1jJTQNfLLhEoQd72qRv5nukWIKdzJP6v_7-ues4rc6uHP4N-VG4JU784TZqND8';
  private deferredPrompt: any; // BeforeInstallPromptEvent
  private subscriptionRequested: boolean;
  public onPushNotification: Subject<any>;
  constructor(
    private swUpdate: SwUpdate,
    private broadcaster: BroadcasterService,
    private swPush: SwPush,
    private auth: AuthService,
    private rest: RestService,
    private storage: StorageService) {
    this.subscriptionRequested = false;
    if (environment.production) {
      window.addEventListener('beforeinstallprompt', (e) => {
        // console.log('beforeinstallprompt');
        // e.preventDefault();
        this.deferredPrompt = e;
        // return false;
      });

      this.swUpdate.activateUpdate().then(hasNewVersion => {
        if (hasNewVersion)
          this.onNewVersion();
      });
      this.swUpdate.checkForUpdate().then(hasNewVersion => {
        if (hasNewVersion)
          this.onNewVersion();
      });
      // this.swUpdate.available.subscribe(event => {
      //   this.onNewVersion();
      // });
      this.broadcaster.on('userRefreshed').subscribe(this.checkForUpdate.bind(this));
      this.broadcaster.on('onLogin').subscribe(this.onLogin.bind(this));
      if (this.auth.isloggedIn())
        this.subscribeToPush();
    }
    this.onPushNotification = new Subject<any>();
  }

  private onLogin() {
    this.subscribeToPush();
    let ps = this.storage.get('pushSubscription');
    if (ps)
      this.sendPushSubscription(ps);
  }

  private sendPushSubscription(ps: any) {
    // if (!this.auth.user.children)
    //   this.auth.user.children = {} as UserChildren;
    if (!this.auth.user.users_push_details)
      this.auth.user.users_push_details = [];
    let exist = false;
    for (var i = 0; i < this.auth.user.users_push_details.length; i++) {
      if (this.auth.user.users_push_details[i].auth == ps.keys.auth &&
        this.auth.user.users_push_details[i].endpoint == ps.endpoint &&
        this.auth.user.users_push_details[i].public_key == ps.keys.p256dh) {
        exist = true;
        break;
      }
    }
    if (!exist) {
      let obj = {
        // artist_user_id: this.auth.user.id,
        endpoint: ps.endpoint,
        auth: ps.keys.auth,
        public_key: ps.keys.p256dh
      };
      if (obj.endpoint && obj.auth && obj.public_key) {
        this.auth.user.users_push_details.push({
          artist_user_id: this.auth.user.id,
          endpoint: ps.endpoint,
          auth: ps.keys.auth,
          public_key: ps.keys.p256dh
        });

        this.rest.pushDetails('post', obj).subscribe(
          () => {
            this.auth.refreshUserDate();
          }
        )
      }
      else {
        console.warn('push request subscription - missing values:\n' + JSON.stringify(obj));
      }
    }
  }

  private subscribeToPush() {
    if (!environment.production || this.subscriptionRequested) return;
    this.subscriptionRequested = true;
    this.swPush.requestSubscription(
      {
        serverPublicKey: SwService.VAPID_PUBLIC_KEY
      }
    ).then(
      (pushSubscription: PushSubscription) => {
        let ps = null as any;
        try {
          ps = pushSubscription.toJSON();
        }
        catch (e) {
          ps = pushSubscription;
        }

        if (this.auth.isloggedIn()) {
          this.sendPushSubscription(ps);
        }
        else {
          this.storage.set('pushSubscription', ps);
        }
        // }

        // this.onPushNotification.next(pushSubscription);
      }
    )
  }

  private onNewVersion() {
    let data: BroadcasterNotification = {
      text: 'A newer version of HEXA\'s CMS is available',
      type: BroadcasterNotificationType.Info,
      action: 'REFRESH',
      callback: this.refresh,
      scope: this,
      autoDismiss: false
    };
    this.broadcaster.broadcast('notifyUser', data);
  }

  public checkForUpdate() {
    this.swUpdate.checkForUpdate();
  }

  public refresh() {
    window.location.reload();
  }

  public isAddToHomescreenEnable(): boolean {
    return !!this.deferredPrompt;
  }

  public addToHomescreen() {
    if (this.deferredPrompt !== undefined) {
      // The user has had a positive interaction with our app and Chrome
      // has tried to prompt previously, so let's show the prompt.
      this.deferredPrompt.prompt();

      // Follow what the user has done with the prompt.
      this.deferredPrompt.userChoice.then(function (choiceResult) {

        console.log(choiceResult.outcome);

        if (choiceResult.outcome == 'dismissed') {
          console.log('User cancelled home screen install');
        }
        else {
          console.log('User added to home screen');
        }

        // We no longer need the prompt.  Clear it up.
        this.deferredPrompt = null;
      });
    }
  }
}
