import { Component, OnInit } from '@angular/core';
import { ProductIdentifier } from '../product';
import { ProductRequestService } from '../product-request.service';

@Component({
  selector: 'app-product-identifiers',
  templateUrl: './product-identifiers.component.html',
  styleUrls: ['./product-identifiers.component.scss']
})
export class ProductIdentifiersComponent implements OnInit {

  constructor(
    public prService: ProductRequestService
  ) { }

  ngOnInit(): void {   
  }

  add(){
      let newitem = {} as ProductIdentifier;
      this.prService.request.products_identifiers.push(newitem);      
  }
  
  removeIdentifier(index: number) {
    if (index > 0){
      this.prService.request.products_identifiers.splice(index, 1); 
    }      
  } 
}
