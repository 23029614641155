<div fxFlex class="products-data" [ngClass]="{'pin-enabled': enabledPin}">
  <button mat-icon-button (click)="navLeft()" [hidden]="translateX == 0" class="nav left" color="accent" type="button">
    <i class="icon-left"></i>
  </button>
  <div class="scroller-wrap">
    <div class="scroller" [style.height]="height + 'px'" [ngClass]="{'imm': imm}" [style.width]="getScrollerWidth()"
      #scroller [style.transform]="'translateX(' + translateX + 'px)'">
      <div *ngFor="let image of images; let i = index;" class="image-data float-op-dir" [style.width]="width + 'px'" [style.height]="height + 'px'" [ngClass]="{'pinned': enabledPin && image.sort_index == 0}">
        <div class="overlay">
          <button class="delete-image" mat-icon-button (click)="deleteImage(i)">
            <mat-icon>delete_forever</mat-icon>
          </button>
          <button class="link-image" mat-icon-button (click)="viewImage(i)">
            <!-- <mat-icon>link</mat-icon> -->
             <i class="icon-eye material-icons"></i>
            
          </button>
        </div>
        <img [src]="image.small || image.big" alt="resource" class="center-me vertical-align" (error)="onLoadError(image)">
        <span class="pin" *ngIf="enabledPin">
          <button mat-icon-button class="v-sub" (click)="pin(i)">
            <i class="material-icons push_pin">push_pin</i>
          </button>
        </span>
      </div>
    </div>
  </div>
  <button mat-icon-button (click)="navRight()" [hidden]="isRightNavDisabled" class="nav right" color="accent" type="button">
    <i class="icon-right"></i>
  </button>
</div>