import { Pipe, PipeTransform } from '@angular/core';
import { UsersMailsSubscriptionsInterval } from './user';

@Pipe({
  name: 'emailNotificationsIntervals'
})
export class EmailNotificationsIntervalsPipe implements PipeTransform {

  transform(intervals: Array<UsersMailsSubscriptionsInterval>, mailCategoryId: number): Array<UsersMailsSubscriptionsInterval> {
    if (!intervals) return null;
    return intervals.filter(i => i.mail_category_id == mailCategoryId);
  }

}
