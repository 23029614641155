<div fxLayout="column" fxLayoutAlign="space-around" class="flex-wrap-wrap">
  <div fxFlex *ngFor="let c of categories" class="category-wrap content-va scrollbar">
    <h3 class="h2 gray2">{{c.category_name_org_lang}}</h3>
    <mat-form-field class="pos-rel">
      <span class="pointer pos-abs remove warn" (click)="deleteCategory(c)">x</span>
      <input matInput type="number" placeholder="price" [(ngModel)]="c.price">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="number" placeholder="video render price" [(ngModel)]="c.video_render_price">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="number" placeholder="image render price" [(ngModel)]="c.image_render_price">
    </mat-form-field>
    <div>
      <app-text-editor [click-img]="true" [show-html]="true" [show-asset]="true" (textChange)="onTextChange(c, $event)" placeholder="specifications" [init-value]="c.text"></app-text-editor>
    </div>
    <div fxLayout="row" [style.margin]="'1rem 0'">
      <div fxFlex="90">RENDER ONLY</div>
      <div fxFlex="10" fxLayoutAlign="center end">
        <app-toggle-checkbox ngDefaultControl [strong-val-check]="true" [on-val]="1" [off-val]="null" [(ngModel)]="c.process_type"></app-toggle-checkbox>
      </div>
    </div>
    <mat-accordion>
      <mat-expansion-panel (opened)="onOpen(c.id, anglesP)" *ngIf="activePanel[c.id]">
        <mat-expansion-panel-header>
          <mat-panel-title>
            render angles
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-retailers-renders-angles [config]="c.retailers_categories_renders_angles" [retailer-id]="retailerId"
          [category-id]="c.id" *ngIf="activePanel[c.id][anglesP]"></app-retailers-renders-angles>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="onOpen(c.id, categoriesP)" *ngIf="activePanel[c.id]">
        <mat-expansion-panel-header>
          <mat-panel-title>
            associate categories
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field *ngIf="activePanel[c.id][categoriesP]">
          <mat-select placeholder="Categories" [(ngModel)]="c.product_category_id">
            <mat-option *ngFor="let pc of pCategories" [value]="pc.id">
              {{pc.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-expansion-panel>
    </mat-accordion>
    <br>
    <button mat-raised-button mat-raised-button color="accent" (click)="save(c)" class="thinner marg-top">
      save
    </button>
    <br>
  </div>
</div>
<div>
  <h5 class="h2">add a new retailer category:</h5>
  <mat-form-field>
    <input matInput type="text" placeholder="new category name" [(ngModel)]="catToAdd.category_name_org_lang">
  </mat-form-field>
  <button mat-raised-button mat-raised-button color="accent" (click)="addNew()" class="thinner marg-top">
    add new
  </button>
</div>
