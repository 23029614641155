<div class="padd-sides scrollbar" fxLayoutGap="16px" fxLayout="column">

    <!-- Details Section -->
    <div fxLayout="row" class="details-wrap">
        <!-- Retailer Details Section -->
        <div fxFlex="50">
            <div fxLayout="column">
                <div fxLayout="row">
                    <div fxFlex="100">
                        <h2>Retailer Details</h2>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="60">
                        <app-retailers-autocomplete [placeholder]="'retailer'" class="disp-block"
                            [clear-on-select]="false"
                            (retailer-change)="onRetailerChange($event)"></app-retailers-autocomplete>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="60">
                        <app-retailer-batches [add-default]="true" (on-select)="setBatches($event)"
                        [multiple]="false" [retailer]="retailer"></app-retailer-batches>
                    </div>
                </div>
                <div fxLayout="column">
                    <div fxFlex="100">
                        <mat-checkbox [(ngModel)]="forceMeasurements">measurements NA</mat-checkbox>
                    </div>
                    <div fxFlex="100">
                        <mat-checkbox [(ngModel)]="visible">visible</mat-checkbox>
                    </div>
                    <div fxFlex="100">
                        <mat-checkbox [(ngModel)]="useApi">use API</mat-checkbox>
                    </div>
                </div>
                <div *ngIf="retailer">
                    <mat-form-field fxfill class="fx-fill no-bottom-border">
                        <mat-label>Tags</mat-label>
                        <mat-chip-grid #chipGrid aria-label="Enter tags" class="customized">
                          @for (t of products_tags; let i = $index ; track t) {
                            <mat-chip-row (removed)="removeTag(t, i)">
                              {{t.tag}}
                              <button matChipRemove aria-label="'remove ' + t.tag">
                                <mat-icon>cancel</mat-icon>
                              </button>
                            </mat-chip-row>
                          }
                        </mat-chip-grid>
                        <input placeholder="Add a new tag . . ."
                                #addTagInput
                                (focus)="onTagFocus()"
                                [matChipInputFor]="chipGrid"
                                (matChipInputTokenEnd)="addTag($event)"/>
                      </mat-form-field>
                </div>
            </div>
        </div>

        <!-- Scraper Source Section -->
        <div fxFlex="50">
            <div fxLayout="column">
                <div fxLayout="row">
                    <div fxFlex="100">
                        <h2>Scraper Source</h2>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="60">
                        <mat-radio-group fxFill #srcRadio [(ngModel)]="scraperSrc" fxLayout="column" fxLayoutGap="20px">

                            <mat-radio-button [value]="1" checked> URL's <span *ngIf="urls && scraperSrc == scraperSrcTypes.URL">({{urls.length}})</span></mat-radio-button>

                            <mat-form-field *ngIf="scraperSrc == scraperSrcTypes.URL" [floatLabel]="'never'">
                                <textarea matInput placeholder="paste URL's here" [(ngModel)]="urlsStr" (keyup)="parseUrls()"></textarea>
                            </mat-form-field>

                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <mat-radio-button [value]="2">Upload CSV file</mat-radio-button>
                                <app-file-upload [text]="'Browse'" *ngIf="scraperSrc == scraperSrcTypes.CSV" [round]="false" [drop-mode]="true" [miltiple]="false" (filesChange)="onFilesChange($event)" [accept]="'.csv'" [width]="100" [height]="30"></app-file-upload>
                            </div>

                            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="csvFileName && scraperSrc == scraperSrcTypes.CSV">
                                <div fxFlex="80">{{csvFileName}}</div>
                                <div fxFlex="20">
                                    <button class="delete-image" mat-icon-button (click)="removeCSVFile()">
                                    <mat-icon>delete_forever</mat-icon>
                                  </button>
                                </div>
                            </div>

                        </mat-radio-group>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- URL's list & Scrape button Section-->
    <div fxLayout="row" [fxLayoutAlign]="scraperSrc == scraperSrcTypes.URL ? 'start' : 'end'">

        <!-- URL's list (in case that scraper src set to 'URL') -->
        <div fxFlex="auto" *ngIf="scraperSrc == scraperSrcTypes.URL">
            <mat-list>
                <mat-list-item *ngFor="let url of urls"> {{url}} </mat-list-item>
            </mat-list>
        </div>

        <!-- Scrape Button -->
        <div fxFlex="none">
            <button [disabled]="!isValid()" mat-raised-button color="accent" class="min-button-width" (click)="scrape()">scrape</button>
        </div>

    </div>

</div>
