import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ResourceTypesService } from './resource-types.service';

@Injectable()
export class ResourceTypesResolve  {

    constructor(
        private resourceTypesService: ResourceTypesService
    ) { }

    resolve() {
        if (this.resourceTypesService.getCachedTypes())
            return this.resourceTypesService.getCachedTypes();
        return this.resourceTypesService.getTypes() as Observable<any>;
    }
}