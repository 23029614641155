import { Component, OnInit } from '@angular/core';
import { ProductService } from '../product.service';
import { RestService } from '../../communication/rest.service';
import { ProductMassEdit } from '../product';
import { BroadcasterNotification, BroadcasterNotificationType } from '../../communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { RolesHelperService } from 'src/app/auth/roles-helper.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-selected-products-actions',
  templateUrl: './selected-products-actions.component.html',
  styleUrls: ['./selected-products-actions.component.scss']
})
export class SelectedProductsActionsComponent implements OnInit {
  public isSU: boolean;
  constructor(
    public productService: ProductService,
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private roles: RolesHelperService
  ) {
    this.isSU = this.roles.isSuperUserOrObserverLoggedIn();
  }

  ngOnInit() {
  }


  approveAll() {
    if (!confirm('Are you sure you want to APPROVE ' + this.productService.totalSelected + ' products?')) return;
    let payload = {
      values: {
        status_id: 5
      },
      where: this.getWhere()
    } as ProductMassEdit;
    this.save(payload);
  }

  goLiveAll() {
    if (!confirm('Are you sure you want to APPROVE and GO LIVE with ' + this.productService.totalSelected + ' products?')) return;
    let payload = {
      values: {
        enabled: 1,
        mobile_enabled: 1
      },
      where: this.getWhere()
    } as ProductMassEdit;
    this.save(payload);
  }

  toggleEnabled(state: MatButtonToggleChange, prefix: string) {
    let msg = 'Are you sure you want to ' + (state.value == 1 ? 'ENABLE ' : 'DISABLE ') + this.productService.totalSelected + ' products';
    // if (prefix)
    //   msg = msg + ' on Mobile?';
    // else
    //   msg = msg + ' on Desktop?';
    msg += '?';
    if (!confirm(msg)) return;
    let p = {};
    // p[prefix + 'enabled'] = state.value;
    p['enabled'] = state.value;
    p['mobile_enabled'] = state.value;
    let payload = {
      values: p,
      where: this.getWhere()
    } as ProductMassEdit;
    this.save(payload);
  }

  // toggleIA(state: MatButtonToggleChange) {
  //   let msg = 'Are you sure you want to ' + (state.value == 1 ? 'ENABLE ' : 'DISABLE ') + this.getTotalSelected() + ' products for internally approved?';
  //   if (!confirm(msg)) return;
  //   let p = {
  //     'ia': state.value
  //   };
  //   let payload = {
  //     values: p,
  //     where: this.getWhere()
  //   } as ProductMassEdit;
  //   this.save(payload);
  // }

  getWhere() {
    return this.productService.getWhere();
  }

  delete() {
    if (!confirm('Are you sure you want to DELETE ' + this.productService.totalSelected + ' products?')) return;
    let payload = {
      values: {
        is_archived: true
      },
      where: this.getWhere()
    } as ProductMassEdit;
    this.save(payload);
  }

  restore() {
    if (!confirm('Are you sure you want to RESTORE ' + this.productService.totalSelected + ' products?')) return;
    let payload = {
      values: {
        is_archived: false
      },
      where: this.getWhere()
    } as ProductMassEdit;
    this.save(payload);
  }

  save(payload: ProductMassEdit) {
    this.productService.saveProductMassEdit(payload);
  }
}
