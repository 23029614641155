<mat-form-field fxFill class="no-padd">
  <input matInput aria-label="retailers" [matAutocomplete]="retailerAuto" [formControl]="retailerCtrl"
    #filterInput [placeholder]="placeholder">
  <mat-autocomplete #retailerAuto="matAutocomplete">
    <mat-option *ngFor="let retailer of filteredRetailers | async" [value]="retailer.name" (onSelectionChange)="onRetailerChange(retailer)">
      <img style="vertical-align:middle" aria-hidden src="{{retailer.logo_url}}" height="25" />
      <span>{{ retailer.name }}</span>
      <small class="italic"> ({{retailer.id}})</small>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>