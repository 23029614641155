<div class="resource-dropdown">
  <mat-form-field class="w-100 no-padd">
    <mat-label>Select Resource</mat-label>
    <mat-select [multiple]="multiple" [(ngModel)]="selectedResource"
      (selectionChange)="onSelectionChange($event)" (openedChange)="openedChange($event)">

      <mat-select-trigger *ngIf="selectedResource">
        <ng-container *ngIf="multiple; else singleSelectTrigger">
          <div class="selected-resource-item variation-name">
            <ng-container *ngFor="let resource of selectedResource; let i = index">
              <div>
                <span *ngIf="resource.poly_variation_name" class="">{{resource.poly_variation_name}}</span>
                <span *ngIf="!resource?.poly_variation_name"
                  class="capitalize">{{polyDictionary[resource.resource_poly_type].value.name.toLowerCase()}}
                  Poly</span>
                <span *ngIf="selectedResource.length > 1 && i !== (selectedResource.length - 1)">,</span>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #singleSelectTrigger>
          <div class="selected-resource-item variation-name resource-item space-between">
            <!-- <img class="va-middle" [src]="enums.getIconByTag(selectedResource.resource_type)" alt="resource"> -->

            <div>
              <span *ngIf="selectedResource.poly_variation_name">{{selectedResource.poly_variation_name}}</span>
              <span *ngIf="!selectedResource?.poly_variation_name"
                class="capitalize">{{polyDictionary[selectedResource.resource_poly_type].value.name.toLowerCase()}}
                Poly</span>
            </div>
            <span *ngIf="selectedResource.viewer_resource_type" class="resource-type sharpen1">
              {{viewerResourceType[selectedResource.viewer_resource_type]}}
            </span>
          </div>
        </ng-template>
      </mat-select-trigger>

      <mat-option *ngFor="let resource of resources" [value]="resource"
        [ngClass]="{'disabled': resource.resource_enabled == 0}">
        <div class="resource-item space-between">
           <img class="va-middle" *ngIf="resource.resource_type !== mediatagModel" [src]="enums.getIconByTag(resource.resource_type)" alt="resource"> 
          <img class="va-middle" *ngIf="resource.resource_type === mediatagModel && polyDictionary[resource.resource_poly_type]"
            [src]="polyDictionary[resource.resource_poly_type].value.icon"
            alt="{{polyDictionary[resource.resource_poly_type].value.name}}">
          <div class="flex-1 ellipsis">
            <div class="variation-name" [matTooltip]="resource.poly_variation_name" matTooltipPosition="above"
              showIfTruncated>
              <span *ngIf="resource.poly_variation_name; else placeHolder">{{resource.poly_variation_name}}</span>
              <ng-template #placeHolder><span
                  class="capitalize">{{polyDictionary[resource.resource_poly_type].value.name.toLowerCase()}}
                  Poly</span></ng-template>
            </div>
            <div class="creation-time">
              <span>{{ resource.created_at | moment : 'fromNow2'}}</span>
              &nbsp;|&nbsp;
              <span>{{polyDictionary[resource.resource_poly_type].value.name}} Poly</span>
            </div>
          </div>

          <span *ngIf="resource.viewer_resource_type" class="resource-type sharpen1">
            {{viewerResourceType[resource.viewer_resource_type]}}
          </span>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>