import { Injectable } from '@angular/core';
import { StorageService } from 'ng-storage-service';
import {EndpointsService} from '../communication/endpoints.service';
import { ResumableCDN, ResumableState, ResumableSubject } from './resumable';
import { Subject } from 'rxjs';
import { UtilsService } from './utils.service';
import { RestService } from '../communication/rest.service';
import {EndPoints} from '../communication/endpoints';
import * as tus from 'tus-js-client'


@Injectable({
  providedIn: 'root'
})
export class ResumableUploadService {
  static RETRAYS = 1;
  constructor(
    private storage: StorageService,
    private endpoints: EndpointsService,
    private utils: UtilsService,
    private rest: RestService
  ) { }

  public sourceFiles(file: Blob): Subject<ResumableSubject> {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.UPLOAD) + '/tus/upload/', file);
  }

  public fileWithProgress(file: Blob): Subject<ResumableSubject> {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.UPLOAD) + '/tus/upload/', file);
  }

  public async file(file: File, orgResolve?: any, orgReject?: any, attempt = 0): Promise<string> {
    return new Promise((resolve: any, reject: any) => {
      resolve = orgResolve || resolve;
      reject = orgReject || reject;
      this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.UPLOAD) + '/tus/upload/', file).subscribe(async (res: ResumableSubject) => {
        switch (res.state) {
          case ResumableState.ERROR: {
            if (attempt < ResumableUploadService.RETRAYS) {
              console.error('ResumableUploadService', `upload attempt failed, retray number ${attempt + 1}`);
              // this.clearCache();
              this.file(file, resolve, reject, attempt + 1);
            }
            else {
              console.warn(res);
              reject(res);
            }
            break;
          }
          case ResumableState.COMPLETE: {
            let payload = new ResumableCDN();
            // payload.compress = true;
            payload.uploaded_file_name = `${file.name}`;
            payload.uploaded_file_url = res.object.message;
            // resolve(res.object.message);
            try {
              const res = await this.utils.observableToPromise(this.rest.afterCdn('post', payload));
              resolve(res.url);
            }
            catch (e) {
              // The upload to storage has failed (alghout TUS probebly uploaded the file succesfully!).
              // We will try to upload the file again without TUS, directlly to the storage
              try {
                const url = await this.utils.uploadFile(file, false);
                resolve(url);
              }
              catch (e) {
                reject(e);
              }
            }
            break;
          }
        }
      });
    });
  }

  async getUrlFromBase64(base64: string, fileName?: string): Promise<string> {
    const name = fileName ? fileName : base64.indexOf('data:image') === -1 ? 'file' : base64.indexOf('data:image/png') === 0 ? 'image.png' : base64.indexOf('data:image/webp') ? 'image.webp' : 'image.jpeg';
    return await this.file(this.utils.dataURLtoFile(base64, name));
  }

  public clearCache() {
    if (this.storage.isSupported()) {
      for (let i in localStorage) {
        if (i.indexOf('tus-') > -1)
          this.storage.remove(i);
      }
    }
  }

  private executeRequest(endpoint: string, file: Blob): Subject<ResumableSubject> {
    let sub = new Subject<ResumableSubject>();
    let chunkSize = 1024 * 1024;

      let r = new tus.Upload(file,
        {
          endpoint: endpoint,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          chunkSize: chunkSize,
          metadata: {
            filename: file['name'] || 'blob',
            filetype: file.type
          },
          onError: (error: any) => {
            console.log("Failed because: " + error);
            sub.next({
              state: ResumableState.ERROR
            });
          },
          onProgress: (bytesUploaded: number, bytesTotal: number) => {
            let progress = Math.max((bytesUploaded / bytesTotal * 100), 0.01);
            sub.next({
              state: ResumableState.FILE_PROGRESS,
              object: {
                progress
              }
            });
          },
          onSuccess: () => {
            sub.next({
              state: ResumableState.COMPLETE,
              object: {
                message: r.url
              }
            });
          }
        });
      r.start();
  
    return sub;
  }

  public async base64ToURL(base64: string, fileName: string, mimeType: string, bucket = this.endpoints.getEndpointDomain('cdn').replace('https://', '')): Promise<string> {
    return new Promise((resolve: any, reject: any) => {
      fetch(base64)
      .then(res => res.blob())
      .then(async blob => {
        const file = new File([blob], fileName, { type: mimeType });
        try {
          resolve(await this.file(file, bucket));
        } catch (e) { reject(e); }
      })
    });
  }

  base64MimeType(encoded: string) {
    var result = null;
  
    if (typeof encoded !== 'string') {
      return result;
    }
  
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  
    if (mime && mime.length) {
      result = mime[1];
    }
  
    return result;
  }
}
