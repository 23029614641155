<h3 class="h2">add any additional files</h3>
<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4">
    <div fxFlex.gt-sm="48" fxFlex="100">
        <app-file-upload class="accent disp-ib" [round]="false" [drop-mode]="true" [icon]="'cloud_upload_outline'"
            [miltiple]="true" (filesChange)="onFilesChange($event)" [accept]="'*/*'" [width]="103" [height]="103"
            [ignore-base64]="true">
        </app-file-upload>
    </div>
    <div fxFlex.gt-sm="48" fxFlex="100">
        <p class="text3">
            You can add any source files you may have (2D / 3D / non visual)<br>
            as a reference that may assist us create a better 3D asset for you
        </p>
    </div>
</div>
<div *ngFor="let p of fileUploadsProgress | keyvalue" [hidden]="p.value == 100" class="upload def-dur disp-block">
    <p>{{p.value.key}}</p>
    <mat-progress-bar mode="determinate" [value]="p.value.value">
    </mat-progress-bar>
</div>
<div fxLayout="row" fxLayoutAlign="start start" class="pos-rel flex-wrap-wrap w-100 space-top" fxLayoutGap="4">
    <div fxFlex.gt-sm="21" fxFlex="100" *ngFor="let a of attachments; let i = index" class="item">
        <i class="pointer remove warn icon-close" (click)="remove(i)"></i>
        <a [href]="a.attachment" [download]="a.title" target="_blank" class="link1 no-ul disp-ib ellipsis">
            {{a.title}} <ng-container *ngIf="a.suffix">({{a.suffix}})</ng-container>
        </a>
    </div>
</div>