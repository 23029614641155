<div class="wrap scrollbar">
  <button mat-icon-button class="pos-abs add-retailer" (click)="createNewRetailer()">
    <mat-icon>add_circle_outline</mat-icon>
  </button>
  <button mat-icon-button class="pos-abs clear-retailer-cache" (click)="clearAllCarouselsCache()" color="warn">
    <mat-icon>refresh</mat-icon>
  </button>
  <div>
    <app-retailers-autocomplete [placeholder]="'search for retailers'" class="disp-block" [clear-on-select]="true"
      (retailer-change)="onRetailerChange($event)"></app-retailers-autocomplete>
  </div>
  <div *ngIf="fullRetailer">
    <button mat-icon-button color="warn" (click)="fullRetailer = null">
      <i class="icon-close"></i>
    </button>
    <button mat-icon-button color="warn" (click)="deleteRetailer(fullRetailer)">
      <mat-icon>delete</mat-icon>
    </button>
    <div fxLayout="row" fxLayoutAlign="space-around" class="flex-wrap-wrap settings-wrap">
      <div fxFlex="100" fxFlex.gt-sm="49" class="retailer-details scrollbar">
        <div class="padd">
          <h5 class="h2">retailer details:</h5>
          <app-retailer-profile [retailer]="fullRetailer" (on-change)="onRetailerEdit($event)"
            (on-valid)="onRetailerValid($event)"></app-retailer-profile>
          <button mat-raised-button color="accent" class="thinner min-button-width marg-top" (click)="save()"
            [disabled]="!isRetailerValid && !isGod">
            save details
          </button>
        </div>
      </div>
    </div>
    <mat-accordion *ngIf="fullRetailer.id" class="content-va">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            embedded tag
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-retailer-embed-tag class="disp-block" [retailer-id]="fullRetailer.id"></app-retailer-embed-tag>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            viewer params
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-viewer-params class="disp-block padd float-dir w-100" [retailer-id]="fullRetailer.id"
            [config]="fullRetailer.retailers_viewer_params">
          </app-viewer-params>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            settings
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-retailer-settings class="disp-block padd float-dir w-100" [settings]="fullRetailer.retailers_settings"
            [retailer-id]="fullRetailer.id"></app-retailer-settings>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            domains
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-retailer-domains [domains]="fullRetailer.retailers_domains" [paths]="fullRetailer.retailers_paths"
            [retailer-id]="fullRetailer.id" class="padd float-dir w-100"></app-retailer-domains>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            resource types
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-retailer-resource-types [config]="fullRetailer.retailers_resources_types"
            [retailer-id]="fullRetailer.id">
          </app-retailer-resource-types>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            business models and agreements
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div fxFill>
            <mat-form-field fxFill>
              <input matInput [(ngModel)]="retailer.sla_due_date" type="number" min="1" class="sla-input"
                placeholder="genaral SLA - assets (days)">
            </mat-form-field>
          </div>
          <div fxFill>
            <mat-form-field fxFill>
              <input matInput [(ngModel)]="retailer.sla_feedback_due_date" type="number" min="1" class="sla-input"
                placeholder="genaral SLA - feedbacks (days)">
            </mat-form-field>
          </div>
          <div fxFill>
            <button mat-raised-button [disabled]="retailer.sla_due_date < 1 || retailer.sla_feedback_due_date < 1" color="accent" class="thinner min-button-width" (click)="save()">
              save changes
            </button>
          </div>
          <app-business-models [config]="fullRetailer.retailers_agreements" [retailer-id]="fullRetailer.id">
          </app-business-models>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            source files types
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-retailer-source-files-types [config]="fullRetailer.retailers_source_files_types"
            [retailer-id]="fullRetailer.id"></app-retailer-source-files-types>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            integration code
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-retailer-config [config]="fullRetailer.retailers_configs" [retailer-id]="fullRetailer.id">
          </app-retailer-config>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            scraper code
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-retailer-scraper-config [config]="fullRetailer.retailers_scrapers_configs[0]"
            [retailer-id]="fullRetailer.id"></app-retailer-scraper-config>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            specifications
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-retailer-specs class="scrollbar" [config]="fullRetailer.retailers_specifications"
            [retailer-id]="fullRetailer.id">
          </app-retailer-specs>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            mapping naming conventions
          </mat-panel-title>
          <mat-panel-description>
            for example: <span class="text4">%product_name%_normal</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-retailer-mapping-naming-conventions class="disp-block"
            [config]="fullRetailer.retailers_mapping_naming_convention" [retailer-id]="fullRetailer.id">
          </app-retailer-mapping-naming-conventions>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            categories
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>

          <app-retailer-categories [config]="fullRetailer.retailers_categories" [retailer-id]="fullRetailer.id">
          </app-retailer-categories>

        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            sub categories
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>

          <app-retailer-sub-categories [config]="fullRetailer.retailers_sub_categories" [retailer-id]="fullRetailer.id"
            [retailer-categories]="fullRetailer.retailers_categories">
          </app-retailer-sub-categories>

        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            retailers renders settings
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-retailers-renders-settings [config]="fullRetailer.retailers_renders_settings"
            [retailer-id]="fullRetailer.id"></app-retailers-renders-settings>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            retailers renders angles
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-retailers-renders-angles [config]="fullRetailer.retailers_renders_angles"
            [retailer-id]="fullRetailer.id">
          </app-retailers-renders-angles>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            mesh naming conventions
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-retailer-mesh-names [config]="fullRetailer.retailers_mesh_conventions" [retailer-id]="fullRetailer.id">
          </app-retailer-mesh-names>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            retailers polygon specifications
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-retailers-polygon-spec [polySpecs]="fullRetailer.retailers_polygon_specifications"
            [retailerId]="fullRetailer.id"></app-retailers-polygon-spec>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            contracts
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-retailer-contracts [sla_due_date]="fullRetailer.sla_due_date" [sla_feedback_due_date]="fullRetailer.sla_feedback_due_date" [contracts]="fullRetailer.retailers_contracts"
            [batches]="fullRetailer.retailers_batches" [retailer-id]="fullRetailer.id"></app-retailer-contracts>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            implementation info
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-retailer-implementation-info [retailer]="fullRetailer">
          </app-retailer-implementation-info>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
