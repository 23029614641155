import { Injectable } from '@angular/core';
import { RetailerBatch, RetailersContracts } from './retailer';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class RetailerBatchesService {

    constructor() {
    }

    getDisplayName(b: RetailerBatch, c: Array<RetailersContracts>): string {
        let displayName = '';
        let contract;

        if (!c) {
            c = [];
        }
        for (let i = 0; i < c.length; i++) {
            if (c[i].id === b.contract_id) {
                contract = c[i].retailer_index;
                break;
            }
        }
        if(b.name) {
            displayName = `${b.name} - `;
        }

        displayName += `C#${contract}, B#${b.contract_index} - by ${(moment as any)(b.end_at).format('DD/MM/YYYY')} (${b.used_quota}/${b.total_models} models)`;

        return displayName;
    }
}
