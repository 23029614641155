import { Component, OnInit } from '@angular/core';
import { GlobalsService } from 'src/app/shared/globals.service';
import { AuthService } from '../../auth/auth.service';
import { ProductService } from '../../product/product.service';
import { RolesHelperService } from '../../auth/roles-helper.service';
import { Router } from '@angular/router';
import { NavigationLink } from '../../shared/globals';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(
    public globals: GlobalsService,
    public auth: AuthService,
    public productService: ProductService,
    public roles: RolesHelperService,
    private router: Router
  ) {
    
  }

  ngOnInit() {
  }

  goTo(evt: any, obj: NavigationLink) {
    evt.preventDefault();
    if (obj.params)
      this.router.navigate([obj.link, obj.params]);
    else
      this.router.navigate([obj.link]);
  }
}
