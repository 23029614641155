import { Injectable } from '@angular/core';
import { GraphqlService } from '../communication/graphql.service';
import { UtilsService } from '../shared/utils.service';
import { JobTypes } from './job-types';

@Injectable({
  providedIn: 'root'
})
export class JobTypesService {
  private _jobTypes: Array<JobTypes>;
  private fetching: boolean;
  private stock: Array<Function>;
  constructor(
    private utils: UtilsService,
    private gql: GraphqlService
  ) {
    this.stock = [];
  }

  async getJobTypes(): Promise<Array<JobTypes>> {
    return new Promise((resolve, reject) => {
      this.stock.push(resolve);
      if (this._jobTypes) {
        this.resolveAll();
        return;
      }
      if (!this.fetching) {
        this.gql.jobsTypes().subscribe((res) => {
          this._jobTypes = res.data.jobs_types;
          this.resolveAll();
        });
      }
    });
  }

  private resolveAll() {
    this.stock.forEach(f => f(this.utils.deepCopyByValue(this._jobTypes)));
    this.stock = [];
    this.fetching = false;
  }
}
