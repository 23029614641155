import { Component, Inject } from "@angular/core";
import { ImageDialog } from "../../product/product";
import { UtilsService } from "../utils.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public imageUrl: ImageDialog,
    private utils: UtilsService
  ) { }

  close(): void {
    this.dialogRef.close();
  }

  copyImage(): void {
    this.utils.copyClipboard(this.imageUrl.copyLink || this.imageUrl.url);
  }
}
