import { Component, OnChanges, OnDestroy, Input, Output, ViewChild, EventEmitter, ElementRef, SimpleChanges, AfterViewInit } from '@angular/core';
import { MessagesHandlerService, MessageRequest } from 'messages-handler'
import { ImageSet, MediaTag, ProductsResourcesAlignment, ProductsResourcesAlignmentConfig } from '../../product/product';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { RestService } from '../../communication/rest.service';
import { CombineImagesService } from '../combine-images.service';
import { BroadcasterService } from 'ng-broadcaster';
import { ProductService } from 'src/app/product/product.service';
import { ProductRequestService } from 'src/app/product/product-request.service';
import { UtilsService } from '../utils.service';
import { DimensionsOptions, PostMessage } from 'asset-adjustments';
import { environment } from 'src/environments/environment';
// import { AssetAdjustmentsHelperService } from '../asset-adjustments-helper.service';
// import { AssetAdjustmentsService } from 'asset-adjustments';

@Component({
  selector: 'app-model-image-compare',
  templateUrl: './model-image-compare.component.html',
  styleUrls: ['./model-image-compare.component.scss']
})
export class ModelImageCompareComponent implements AfterViewInit, OnDestroy, OnChanges {
  public screenshot: string;
  public finalImage: string;
  public viewerSrc: SafeResourceUrl;
  public width: number;
  public height: number;
  public alignment: ProductsResourcesAlignmentConfig;
  public emissiveIntensity: number;
  public hasEmissive: boolean;
  public hasDimensions: boolean;
  public dimensions: DimensionsOptions;
  // public paramsToAdd: Array<KeyValuePair>;
  private imageBase64: string;
  private ignoreImageBase64: boolean;
  private frameScreenshot: string;
  private timestemp: number;
  // private terminate: boolean;
  // private canvas: any;
  private onCombineImageSubscription: Subscription;
  private cropImage: string;
  private _hasScreenshot: boolean;
  // private serviceMapped: boolean;
  @Input('image') image: ImageSet;
  @Input('model') model: string;
  @Input() zoom: boolean;
  @Input('allow-screenshot') allowScreenshot: boolean;
  @Input('media-tag') mediaTag: MediaTag;
  @Output('on-image-reject') onImageReject: EventEmitter<string>;
  @ViewChild('wrap') mainWrap: ElementRef;
  @ViewChild('video') videoRef: ElementRef;
  // @ViewChild('iframe') iframeModel: ElementRef;
  constructor(
    private messagesHandlerService: MessagesHandlerService, private rest: RestService,
    private combineImages: CombineImagesService, private sanitizer: DomSanitizer,
    private broadcaster: BroadcasterService, public productService: ProductService,
    private utils: UtilsService, private prService: ProductRequestService,
    // private assetAdjustmentsHelperService: AssetAdjustmentsHelperService,
    // private assetAdjustmentsService: AssetAdjustmentsService
  ) {
    // this.emissiveIntensity = 1;
    this.onImageReject = new EventEmitter<string>();
    this.timestemp = new Date().getTime();
    this.onCombineImageSubscription = this.broadcaster.on('onCombineImage' + this.timestemp).subscribe(
      data => this.onCombineImage(data)
    );
    // this.paramsToAdd = [{
    //   key: 'frame-id',
    //   value: this.utils.generateUUID()
    // }];
  }

  ngAfterViewInit() {
    this.afterViewInit();
  }

  afterViewInit() {
    let isFallback = false;
    if (this.width === 0)
      isFallback = true;
    let cs = getComputedStyle(this.mainWrap.nativeElement);
    this.width = parseInt(cs.width.replace('px', ''));
    if (this.width < 100)
      this.width = Math.min(window.innerWidth, 1250);
    this.height = (window.innerHeight * 0.8) - 40;
    // if (this.mediaTag == this.productService.MODEL || this.mediaTag == this.productService.IFRAME_RENDR)
    //   this.model = this.utils.setUrlParam(this.model, 'frame-id', this.utils.generateUUID());
    this.viewerSrc = this.getSafeUrl(this.model);
    this.setAlignment();
    if (isNaN(this.width)) {
      if (isFallback)
        this.width = Math.min(window.innerWidth, 1250);
      else {
        this.width = 0;
        setTimeout(this.afterViewInit.bind(this), 500);
        return;
      }
    }
  }

  sendCurrentScreenshot() {
    this.onImageReject.emit(this.finalImage);
  }

  onFinalImage(img: string) {
    this.finalImage = img;
    this.sendCurrentScreenshot();
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onFixedImage(img: string) {
    this.cropImage = img;
  }

  private convertVideoFrame() {
    var canvas = document.createElement("canvas");
    canvas.style.position = 'absolute';
    canvas.width = this.videoRef.nativeElement.videoWidth;
    canvas.height = this.videoRef.nativeElement.videoHeight;
    document.body.appendChild(canvas);
    var ctx = canvas.getContext("2d");
    ctx.drawImage(this.videoRef.nativeElement, 0, 0);
    try {
      this.frameScreenshot = canvas.toDataURL('image/png');
      this.convertImageReady();
    } catch (e) {
      console.error(e);
    }
    canvas.parentElement.removeChild(canvas);
  }

  private convertRenderdImage() {
    let img = document.createElement('img');
    img.crossOrigin = 'anonymous';
    var canvas = document.createElement("canvas");
    canvas.style.position = 'absolute';
    document.body.appendChild(canvas);
    img.onload = () => {
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      try {
        this.frameScreenshot = canvas.toDataURL("image/png");
        this.convertImageReady();
      }
      catch (e) {
        this.convertImageFallback((data: string) => {
          this.frameScreenshot = 'data:image/png;base64,' + data;
          this.convertImageReady();
        });
      }
      finally {
        canvas.parentElement.removeChild(canvas);
      }
    };
    img.onerror = () => {
      this.convertImageFallback((data: string) => {
        this.frameScreenshot = 'data:image/png;base64,' + data;
        this.convertImageReady();
      });
      canvas.parentElement.removeChild(canvas);
    };

    img.src = this.model;
  }

  private convertImage() {
    const src = this.cropImage || this.image?.big;
    if (!src) {
      this.ignoreImageBase64 = true;
      this.convertImageReady();
      return;
    }
    let img = document.createElement('img');
    img.crossOrigin = 'anonymous';
    var canvas = document.createElement("canvas");
    canvas.style.position = 'absolute';
    document.body.appendChild(canvas);
    img.onload = () => {
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      try {
        this.imageBase64 = canvas.toDataURL("image/png");
        this.convertImageReady();
      }
      catch (e) {
        this.convertImageFallback((data: string) => {
          this.imageBase64 = 'data:image/png;base64,' + data;
          this.convertImageReady();
        });
      }
      finally {
        canvas.parentElement.removeChild(canvas);
      }
    };
    img.onerror = () => {
      this.convertImageFallback((data: string) => {
        this.imageBase64 = 'data:image/png;base64,' + data;
        this.convertImageReady();
      });
      canvas.parentElement.removeChild(canvas);
    };

    img.src = src;
  }

  private convertImageFallback(callback: Function) {
    var obj = {
      image_url: this.image,
      compress: false
    };
    this.rest.imageBase64Convert('POST', obj).subscribe(callback.bind(this),
      err => this.utils.httpErrorResponseHandler(err, 'failure parsing base64 image'));
  }

  private convertImageReady() {
    if (this.imageBase64 && this.frameScreenshot)
      this.combineImages.getCombineImage(this.imageBase64, this.frameScreenshot, this.timestemp);
    else if (this.ignoreImageBase64 && this.frameScreenshot)
      this.onCombineImage({ message: this.frameScreenshot });
  }

  private setCurrentScreenshot(obj: PostMessage) {
    let img = new Image();
    img.onload = () => {
      this.convertImage();
      // this.frameScreenshot = this.applyAlpha(img, this.opacity);
      this.frameScreenshot = this.applyAlpha(img, 1);
      if (this.ignoreImageBase64)
        this.convertImageReady();
      setTimeout(() => { this._hasScreenshot = true; });
    }
    img.src = obj.data;
  }

  // Will apply alpha if needed and also will fix the image size
  private applyAlpha(img: HTMLImageElement, opacity: number): string {
    let c = document.createElement('canvas');
    c.style.position = 'fixed';
    c.style.top = (window.innerHeight - 2) + 'px';
    c.style.opacity = '0.01';
    c.setAttribute('width', String(img.naturalWidth));
    c.setAttribute('height', String(img.naturalHeight));
    document.body.appendChild(c);
    let ctx = c.getContext('2d');
    ctx.globalAlpha = opacity;
    ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
    c.parentElement.removeChild(c);
    return c.toDataURL();
  }

  cancel() {
    delete this.screenshot;
    delete this._hasScreenshot;
    delete this.finalImage;
    // this.terminate = true;
  }

  private getCurrentScreenshotFromIframe() {
    let obj: MessageRequest = {
      actions: ['setCurrentScreenshot'],
      invoke: 'setCurrentScreenshot',
      scope: this,
      options: {
        invokeOnce: true,
        apply: true
      }
    };

    // register for response event
    this.messagesHandlerService.onPostMessageEvent(obj, this.messagesHandlerService.getReferrerByUrl(this.model));

    let frame = this.mainWrap.nativeElement.querySelector('iframe[src="' + this.model + '"]');
    // send request to viewer
    this.messagesHandlerService.postToChild({ action: 'getCurrentScreenshot' }, frame);
  }

  getCurrentScreenshot() {
    delete this.imageBase64;
    delete this.ignoreImageBase64;
    delete this.frameScreenshot;
    switch (this.mediaTag) {
      case MediaTag.MODEL: {
        this.getCurrentScreenshotFromIframe();
        break;
      }
      case MediaTag.IMAGE: {
        this.convertRenderdImage();
        this.convertImage();
        break;
      }
      case MediaTag.VIDEO: {
        this.convertVideoFrame();
        this.convertImage();
        break;
      }
      case MediaTag.IFRAME_RENDR: {
        this.getCurrentScreenshotFromIframe();
        break;
      }
    }
  }

  onCombineImage(data) {
    this.screenshot = data.message;
    if (this.screenshot) this._hasScreenshot = true;
    // this.canvas = data.canvas;
  }

  hasScreenshot() {
    return this.screenshot && this._hasScreenshot;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model) {
      this.hasEmissive = false;
      this.hasDimensions = false;
      this.model = changes.model.currentValue;
      this.viewerSrc = this.getSafeUrl(this.model);
    }
    if (changes.image)
      this.setAlignment();
    // for (let propName in changes) {
    //   if (propName == 'model') {
    //     this.model = changes[propName].currentValue;
    //     this.viewerSrc = this.getSafeUrl(this.model);
    //   }
    // }
  }

  setAlignment() {
    delete this.alignment;
    if (this.image && this.image.products_resources_alignments && this.image.products_resources_alignments.length) {
      let items = this.image.products_resources_alignments.filter(p => p.public && p.resource_id == this.prService.selectedResource.id);
      let a = this.utils.sortByDate(items, 'created_at', false)[0] as ProductsResourcesAlignment;
      if (!a)
        a = this.utils.sortByDate(this.image.products_resources_alignments.filter(p => p.resource_id == this.prService.selectedResource.id), 'created_at', false)[0] as ProductsResourcesAlignment;
      // this.setPositionByAlignment(a);
      this.alignment = a.config_json;
    }
  }

  onViewerMessage(obj: PostMessage) {
    switch (obj.action) {
      case 'hasEmissive': {
        if (obj.data) {
          this.hasEmissive = true;
        }
        break;
      }
      case 'setViewerFullyLoaded': {
        // Allow dimensions only for Crate & Barrel
        if (environment.crateRID === this.prService.request.retailer_id)
          this.hasDimensions = true;
        break;
      }
    }
  }

  toggleDim() {
    if (this.dimensions)
      delete this.dimensions;
    else {
      this.dimensions = {
        dimensionsUnits: this.prService.request.units
      };
    }
  }

  // setPositionByAlignment(alignment: ProductsResourcesAlignment) {
  //   if (alignment && this.iframeModel?.nativeElement) {
  //     if (!this.serviceMapped) {
  //       this.assetAdjustmentsHelperService.mapService(this.iframeModel, false);
  //       this.serviceMapped = true;
  //     }
  //     this.assetAdjustmentsService.setControlsPosition(alignment.config_json);
  //   }
  // }

  ngOnDestroy(): void {
    this.onCombineImageSubscription.unsubscribe();
    // this.assetAdjustmentsHelperService.unmapService();
  }

}
