<mat-form-field fxFill *ngIf="!disabled && users">
  <span class="pos-abs user-id h5">
    {{current?.id}}
    <!-- <img aria-hidden *ngIf="current?.avatar_url" src="{{current.avatar_url}}" height="15" /> -->
  </span>
  <mat-label>{{placeholder}}</mat-label>
  <input matInput aria-label="users" [matAutocomplete]="userAuto" [formControl]="userCtrl" #filterInput>
  <mat-autocomplete #userAuto="matAutocomplete" (optionSelected)="onSelectionChanged($event)" [displayWith]="displayWith">
    <mat-option *ngFor="let user of filteredItems | async" [value]="user">
      <img style="vertical-align:middle; margin-right: 16px;" aria-hidden *ngIf="user.avatar_url" src="{{user.avatar_url}}" height="25" />
      <mat-icon style="vertical-align:middle" *ngIf="!user.avatar_url" color="primary">account_circle</mat-icon>
      <span>{{ user.email }}</span>
      <small>({{user.id}})</small>
      <span class="gray"> {{ user.firstname }} {{ user.lastname }}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<mat-form-field fxFill *ngIf="disabled && current">
  <input matInput type="text" [disabled]="true" [value]="current.email + ' (' + current.id + ')'">
</mat-form-field>