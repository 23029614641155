<div layout-padding class="component-shadow" #wrap>
  <div class="overlay-wrap" [ngClass]="{'hide-below': hasScreenshot()}">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutAlign.xs="start">
      <div fxFlex="50" fxLayoutAlign="center center" fxLayoutAlign.xs="start" class="image-wrap">
        <app-image-hover-overlay [create-fix]="true" (on-fixed-image)="onFixedImage($event)" [height]="'40vh'"
          class="center-me" [image-set]="image" *ngIf="image && zoom"></app-image-hover-overlay>
        <img [src]="image.big" *ngIf="image && !zoom" class="center-me" alt="image to compare">
        <h4 *ngIf="!image">no image to display</h4>
      </div>
      <div fxFlex="50" fxLayoutAlign="center center" fxLayoutAlign.xs="start" class="model-wrap pos-rel">
        <app-asset-communication class="preview center-me pos-rel" [broadcast-materials]="1"
          (on-viewer-message)="onViewerMessage($event)"
          *ngIf="model && (mediaTag == productService.MODEL || mediaTag == productService.IFRAME_RENDR)"
          [emissive-intensity]="emissiveIntensity" [model-url]="model" [alignment]="alignment"
          [dimensions]="dimensions">
        </app-asset-communication>
        <div *ngIf="hasEmissive" class="emissive pos-abs">
          <div class="text2 text-center capitalize">
            emissive intensity
          </div>
          <mat-slider min="0" max="1" step="0.05" discrete>
            <input [(ngModel)]="emissiveIntensity" matSliderThumb>
          </mat-slider>
        </div>
        <div *ngIf="hasDimensions" class="scale pos-abs">
          <button mat-raised-button class="outline" type="button" (click)="toggleDim()" [ngClass]="{'active': !!dimensions}">
            <mat-icon>straighten</mat-icon>
            Scale
          </button>
        </div>
        <video [src]="viewerSrc" class="preview center-me pos-rel" *ngIf="model && mediaTag == productService.VIDEO"
          controls="true" autoplay muted loop playsinline #video crossorigin="anonymous" type="video/mp4">
          <source [src]="viewerSrc" type="video/mp4">
        </video>
        <img class="preview center-me pos-rel" *ngIf="model && mediaTag == productService.IMAGE" [src]="viewerSrc" />
        <h4 *ngIf="!model">no asset to display</h4>
        <button type="button" mat-icon-button (click)="getCurrentScreenshot()" class="camera-btn pos-abs"
          *ngIf="!finalImage && model && allowScreenshot">
          <i class="icon-camera"></i>
        </button>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutAlign.xs="start" *ngIf="hasScreenshot()">
    <div fxFlex fxLayoutAlign="center center" fxLayoutAlign.xs="start" class="canvas-paint-wrap pos-rel">
      <app-canvas-paint [image]="screenshot" (final)="onFinalImage($event)" [width]="width" [height]="height">
      </app-canvas-paint>
      <button type="button" mat-icon-button (click)="cancel()" class="clear-btn pos-abs">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>
