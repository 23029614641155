import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { User, UserRole, RetailerUser } from 'src/app/auth/user';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FileObject } from 'src/app/shared/file';
import { RestService } from '../../communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { RolesHelperService } from '../roles-helper.service';
import { Role } from 'ng-role-based-access-control';
import { Retailer } from 'src/app/retailer/retailer';
import { UtilsService } from 'src/app/shared/utils.service';
import { ResumableUploadService } from 'src/app/shared/resumable-upload.service';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnChanges {
  @Input() user: User;
  @Input() disabled: boolean;
  @Output('on-change') onChange: EventEmitter<User>;
  @Output('on-valid') onValid: EventEmitter<boolean>;
  public userForm: UntypedFormGroup;
  public personalLogo: string;
  public uiRoles: Array<Role>;
  public selectedRoles: Array<number>;
  public isSU: boolean;
  public canAssociateRetailers: boolean;
  private initialized: boolean;
  private ignoreChanges: boolean;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private rest: RestService,
    private broadcaster: BroadcasterService,
    public roles: RolesHelperService,
    private utils: UtilsService,
    private resumableUpload: ResumableUploadService,
    private router: Router,
    private auth: AuthService,
  ) {
    this.onChange = new EventEmitter<User>();
    this.onValid = new EventEmitter<boolean>();
    this.userForm = this.formBuilder.group({
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      email: [null, Validators.email],
      avatar_url: null,
      users_roles: [null, Validators.required]
    });
    this.uiRoles = this.roles.getAllUIRoles();
    this.selectedRoles = [];
    this.isSU = this.roles.isSuperUserOrObserverLoggedIn();
  }



  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    if (this.initialized)
      this.init();
    setTimeout(() => {
      this.ignoreChanges = true;
      if (this.disabled) {
        this.userForm.controls.firstname.disable();
        this.userForm.controls.lastname.disable();
        this.userForm.controls.email.disable();
      }
      else {
        this.userForm.controls.firstname.enable();
        this.userForm.controls.lastname.enable();
        this.userForm.controls.email.enable();
      }
      this.ignoreChanges = false;
    });
  }

  init() {
    this.initialized = true;
    this.selectedRoles = [];
    this.userForm = this.formBuilder.group({
      firstname: { value: this.user.firstname, disabled: this.disabled, validators: Validators.required },
      lastname: { value: this.user.lastname, disabled: this.disabled, validators: Validators.required },
      email: { value: this.user.email, disabled: this.disabled, validators: Validators.email },
      avatar_url: [this.user.avatar_url],
      users_roles: [this.user.users_roles, Validators.required]
    });
    for (let i in this.userForm.controls) {
      this.userForm.controls[i].setValue(this.user[i] ? String(this.user[i]) : null);
    }
    this.user.uiRoles = this.roles.getUIRoles(this.user.users_roles);
    for (let i = 0; i < this.uiRoles.length; i++) {
      let current = this.user.uiRoles.filter(c => c.id == this.uiRoles[i].id)[0];
      if (current) {
        this.selectedRoles.push(current.id);
      }
    }
    this.personalLogo = this.user.avatar_url;
    this.updateRoles();
    this.userForm.valueChanges.subscribe(() => {
      if (this.ignoreChanges) return;
      this.onChange.next(this.userForm.value);
      this.onValid.next(this.userForm.valid);
    });
    this.canAssociateRetailers = this.roles.doesUserHasPermission('associate retailers') && (this.user.retailers_users && !!this.user.retailers_users.length);
  }

  async onPPFilesChange(fileList: Array<FileObject>) {
    let file = fileList[0];
    if (!file) return;
    this.onValid.next(false);
    this.personalLogo = file.base64;
    try {
      this.personalLogo = await this.resumableUpload.file(file.file);
    } catch (e) {
      this.utils.httpErrorResponseHandler(e, 'failure uploading image');
    }
    this.onValid.next(true);
    // let fileUploadRequest = {
    //   file: file.base64,
    //   filename: file.name + '.' + file.suffix,
    //   compress: true
    // } as FileUploadRequest;
    // this.rest.cdn('post', fileUploadRequest).subscribe(
    //   data => {
    //     this.personalLogo = data['url'];
    //     this.userForm.controls['avatar_url'].setValue(this.personalLogo);
    //   },
    //   err => this.utils.httpErrorResponseHandler(err, 'failure uploading image'),
    //   () => this.onValid.next(true)
    // );
  }

  deleteImage() {
    delete this.personalLogo;
    this.userForm.controls['avatar_url'].setValue(null);
  }

  updateRoles() {
    let arr = [] as Array<UserRole>;
    const selectedRolesSet = new Set(this.selectedRoles);
    const selectedRoles = Array.from(selectedRolesSet);
    for (let i in selectedRoles) {
      arr.push({
        user_id: this.user.id,
        role_id: selectedRoles[i],
        roles: [
          {
            id: this.selectedRoles[i],
            name: this.uiRoles.find(ui => ui.id == selectedRoles[i]).name
          }
        ]
      });
    }
    this.userForm.controls['users_roles'].setValue(arr);
  }

  onRetailerChange(retailer: Retailer) {
    if (retailer) {
      if (this.user.retailers_users.find(ru => ru.retailer_id == retailer.id)) {
        let data: BroadcasterNotification = {
          text: `${this.user.firstname} already associate to ${retailer.name}`,
          type: BroadcasterNotificationType.Error,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
        return;
      }
      this.user.retailers_users.push({
        retailer_id: retailer.id,
        retailers: [{
          name: retailer.name,
          id: retailer.id,
          logo_url: retailer.logo_url
        } as Retailer]
      } as RetailerUser);
    }
  }

  removeRetailer(index: number) {
    this.user.retailers_users.splice(index, 1);
  }

  changePassword() {
    this.router.navigate(['/reset/password', this.auth.user.email, this.auth.user.token, 'true']);
  }
}
