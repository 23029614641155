import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyValuePure'
})
export class KeyValuePurePipe implements PipeTransform {

  transform(value: any, args?: any, options?: SortOptions): any {
    let keys = [];
    for (let key in value) {
      keys.push({key: key, value: value[key]});
    }
    options = options || {} as SortOptions;
    if (options.sortBy) {
      keys.sort(function (a, b) {
        var c, d;
        if (options.useGetTime) {
          c = new Date(a.value[options.sortBy]).getTime();
          d = new Date(b.value[options.sortBy]).getTime();
        }
        else {
          c = a.value[options.sortBy];
          d = b.value[options.sortBy];
        }
        if (options.isAsec)
          return c - d;
        return d - c;
      });
    }
    return keys;
  }

}

export interface SortOptions {
  sortBy: string;
  isAsec?: boolean;
  useGetTime?: boolean;
}