import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GraphqlService } from '../../communication/graphql.service';
import { ProductRequestService } from '../product-request.service';

@Injectable()
export class FeedbackEditResolve  {

    constructor(
        private gql: GraphqlService,
        private prService: ProductRequestService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot) {
        let fid = parseInt(route.params['fid']);
        this.prService.currentFeedbackEditId = fid;
        return this.gql.feedback(fid) as Observable<any>;
    }
}