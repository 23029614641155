import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { LightManagerComponent } from './light-manager/light-manager.component';

@NgModule({
  imports: [
    CommonModule, SharedModule
  ],
  declarations: [LightManagerComponent]
})
export class AssetAdjustmentsModule { }
