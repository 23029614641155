<div class="wrap" fxLayout="row" fxLayoutAlign="space-around center">
  <div fxFlex class="inner">
    <!-- <h2 class="rec-title pos-abs">Reset Password</h2> -->
    <form #recoveryForm="ngForm" (ngSubmit)="changePassword()">
      <div fxLayout="column" fxLayoutAlign="space-between center">
        <div fxFlex>
          <img alt="hexa 3d" class="logo" src="https://cdn.hexa3d.io/hotlink-ok/icons/logo/hexa_logo_cms.svg">
        </div>
        <div fxFlex>
          <div class="rec-title">Reset Password
          <div class="hint"> Insert your e-mail and we will send you recovery link</div>
        </div>
        </div>
        <div fxFill>
          <div>
            <mat-form-field fxFill>
              <input matInput placeholder="new password" [type]="inputType" [(ngModel)]="password" name="password" required #name="ngModel"
                pattern="^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$">
              <mat-hint [ngClass]="{'warn': recoveryForm.hasError('pattern','password') && recoveryForm.dirty}" align="start">
                <i class="material-icons">lock_outline</i>
                <div class="hint-indent">must contain special characters, numbers, small letters, capital</div>
                <div class="hint-indent">letters and at least 8 characters</div>
                <div *ngIf="recoveryForm.hasError('required','password') && recoveryForm.dirty" class="warn">
                  password is required
                </div>
              </mat-hint>
              <!-- <mat-hint *ngIf="recoveryForm.hasError('required','password') && recoveryForm.dirty" class="warn" align="start">
                password is required
              </mat-hint> -->
              <button class="pass-show pos-abs" mat-icon-button (click)="toggleSP($event)" [ngClass]="{'primary': inputType == 'text'}">
                <mat-icon>remove_red_eye</mat-icon>
                <!-- {{inputType == 'password' ? 'show' : 'hide'}} -->
              </button>
            </mat-form-field>
          </div>
        </div>
        <div fxFill>
          <div class="submit-wrap">
            <button class="prime_button  submit center-me min-button-width" mat-raised-button>update password</button>
          </div>
        </div>
        <div fxFill [hidden]="msg" class="pos-rel center-me">
          {{msg}}
          <div class="pos-abs text-center to-login">
            <a routerLink="/login" class="pointer">Back to Login</a>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>