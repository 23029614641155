import { Subject } from "rxjs";

export class ScrollHelper {
    static MIN_TIME_BETWEEN = 2000;
    private _element: HTMLElement;
    private _maxPercentage = 0.8;
    private _lastTS: number;
    private _onScrollElementBind: any;
    private _ro: ResizeObserver;
    private _onScrollThreshold: Subject<number>;
    constructor(element: HTMLElement) {
        if (!element) {
            console.warn('ScrollHelper.constructor', 'element is undefined');
            return;
        }
        this._element = element;
        this._lastTS = new Date().getTime();
        this._onScrollThreshold = new Subject<number>();
        this._onScrollElementBind = this.onScrollElement.bind(this);
        this._element.addEventListener('scroll', this._onScrollElementBind, { passive: true });
        if (typeof self.ResizeObserver !== 'undefined') {
            this._ro = new ResizeObserver((elements: Array<ResizeObserverEntry>) => {
                this.onScrollElement();
            });
            this._ro.observe(this._element);
        }
    }

    get onScrollThreshold() {
        return this._onScrollThreshold;
    }

    get maxPercentage() {
        return this._maxPercentage;
    }

    set maxPercentage(maxPercentage: number) {
        this._maxPercentage = maxPercentage;
    }

    onScrollElement() {
        let total = this._element.scrollHeight;
        let current = this._element.scrollTop + this._element.getBoundingClientRect().height;
        let position = current / total;
        if (position > this.maxPercentage) {
            const now = new Date().getTime();
            if (this.isAboveTS(now, this._lastTS, ScrollHelper.MIN_TIME_BETWEEN)) {
                this._onScrollThreshold.next(position);
                this._lastTS = now;
            }
            else {
                setTimeout(() => {
                    this.onScrollElement();
                }, now - this._lastTS);
            }
        }
    }

    private isAboveTS(present: number, past: number, maxMS: number) {
        return (present - past > maxMS);
    }

    public destroy() {
        this._onScrollThreshold.unsubscribe();
        this._element.removeEventListener('scroll', this._onScrollElementBind);
        if (this._ro) {
            this._ro.unobserve(this._element);
            this._ro.disconnect();
        }
    }
}