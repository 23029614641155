<div fxLayout="column" class="h-100" fxLayoutAlign="start">
    <div #frameWrap fxFlex class="flex-wrap">
        <div>
            <app-viewer-iframe *ngIf="resource?.resource_default" class="disp-block h-100"
                [viewer-url]="resource?.resource_default"></app-viewer-iframe>
            <app-asset-adjustments *ngIf="resource && iframeModel" [hide]="true" [created-at]="resource.created_at"
                [iframe-model]="iframeModel" [resource]="resource">
            </app-asset-adjustments>
        </div>
    </div>
    <div fxFlex="80px" class="controls">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="23.5">
                <mat-form-field fxFill class="no-padd hide-subscript">
                    <mat-label>width</mat-label>
                    <input matInput min="256" type="number" name="render_width" [(ngModel)]="width">
                </mat-form-field>
            </div>
            <div fxFlex="23.5">
                <mat-form-field fxFill class="no-padd hide-subscript">
                    <mat-label>height</mat-label>
                    <input matInput min="256" type="number" name="render_height"
                        [(ngModel)]="height">
                </mat-form-field>
            </div>
        <!-- </div>
        <div fxLayout="row" fxLayoutAlign="space-between center"> -->
            <div fxFlex="23.5" class="text-center">
                <button mat-raised-button class="second_button outline min-button-width float-op-dir" (click)="render(false)">
                    render sample
                </button>
            </div>
            <div fxFlex="23.5" class="text-center">
                <button mat-raised-button class="prime_button min-button-width float-dir" (click)="render(true)">
                    render for {{price}}$
                </button>
            </div>
        </div>
    </div>
</div>