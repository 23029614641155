<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around" class="flex-wrap-wrap">
  <mat-form-field fxFlex="50" fxFlex.gt-sm="20">
    <input type="text" matInput placeholder="background color" [(ngModel)]="config[0].background_color" name="theme-color">
  </mat-form-field>
  <mat-form-field fxFlex="50" fxFlex.gt-sm="20">
    <input type="number" matInput placeholder="duration" [(ngModel)]="config[0].duration" name="duration">
  </mat-form-field>
  <mat-form-field fxFlex="50" fxFlex.gt-sm="20">
    <input type="number" matInput placeholder="resolution width" [(ngModel)]="config[0].resolution_width" name="resolution_width">
  </mat-form-field>
  <mat-form-field fxFlex="50" fxFlex.gt-sm="20">
    <input type="number" matInput placeholder="resolution height" [(ngModel)]="config[0].resolution_height" name="resolution_height">
  </mat-form-field>
  <mat-form-field fxFlex="50" fxFlex.gt-sm="20">
    <input type="number" matInput placeholder="frames per second" [(ngModel)]="config[0].frames_per_second" name="fps">
  </mat-form-field>
  <mat-form-field fxFlex="50" fxFlex.gt-sm="20">
    <input type="number" matInput placeholder="image render price" [(ngModel)]="config[0].default_image_render_price" name="image_render_price">
  </mat-form-field>
  <mat-form-field fxFlex="50" fxFlex.gt-sm="20">
    <input type="number" matInput placeholder="video render price" [(ngModel)]="config[0].default_video_render_price" name="video_render_price">
  </mat-form-field>
  <mat-form-field fxFlex="50" fxFlex.gt-sm="20">
    <mat-select placeholder="product position" [(ngModel)]="config[0].product_position">
      <mat-option *ngFor="let pos of positions" [value]="pos.key">
        {{pos.value}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div fxFlex="50" fxFlex.gt-sm="20">
    product reflection
    <app-round-checkbox ngDefaultControl [(ngModel)]="config[0].product_reflection"></app-round-checkbox>
  </div>
  <div fxFlex="50" fxFlex.gt-sm="20">
    product shadow
    <app-round-checkbox ngDefaultControl [(ngModel)]="config[0].product_shadow"></app-round-checkbox>
  </div>
</div>
<div>
  <button mat-raised-button mat-raised-button color="accent" (click)="save()" class="thinner marg-top">
    save render settings
  </button>
</div>