import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RetailersAgreement, RetailerFilterOptions } from '../retailer';
import { RestService } from 'src/app/communication/rest.service';
import { GraphqlService } from 'src/app/communication/graphql.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-business-models',
  templateUrl: './business-models.component.html',
  styleUrls: ['./business-models.component.scss']
})
export class BusinessModelsComponent implements OnInit, OnChanges {
  @Input() config: Array<RetailersAgreement>;
  @Input('retailer-id') retailerId: number;
  constructor(
    private rest: RestService,
    private gql: GraphqlService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    this.config = this.utils.deepCopyByValue(this.config);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.retailerId)
      this.config = this.utils.deepCopyByValue(this.config);
  }

  add() {
    this.config.push({ retailer_id: this.retailerId } as RetailersAgreement);
  }

  delete(index: number) {
    let agreement = this.config[index];
    if (!confirm('Are you sure you want to delete this agreement forever?'))
      return;
    if (agreement.id) {
      let query = `/${agreement.id}`;
      this.rest.retailerAgreement('delete', null, query).subscribe(
        () => {
          this.config.splice(index, 1);
          this.refresh();
        },
        err => this.utils.httpErrorResponseHandler(err, 'failure deleting retailer agreement')
      );
    }
    else {
      this.config.splice(index, 1);
    }
  }

  refresh() {
    let options = {
      id: this.retailerId
    } as RetailerFilterOptions;
    this.gql.retailerForSettings(options).subscribe(
      r => this.config = this.utils.deepCopyByValue(r.data.retailers.retailers_agreements),
      err => this.utils.httpErrorResponseHandler(err, 'failure fetching retailer agreement')
    )
  }
}
