import { Component, OnInit } from '@angular/core';
import { ProductRequestService } from '../product-request.service';

@Component({
  selector: 'app-product-quote',
  templateUrl: './product-quote.component.html',
  styleUrls: ['./product-quote.component.scss']
})
export class ProductQuoteComponent implements OnInit {
  public price: number;
  constructor(
    public prService: ProductRequestService
  ) {
  }

  ngOnInit() {
  }

  fund() {
    this.createProduct();
  }

  getQuote() {
    this.createProduct();
  }

  createProduct() {
    // this.broadcaster.broadcast('createAssetRequest');
    this.prService.save();
  }
}
