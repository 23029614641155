 <div class="wrap pos-rel float-dir text-dir" id="users-summary">
  <button mat-mini-fab class="uppercase thin" (click)="toggleSelfProfile()" id="self-profile">
    {{getAcronyms(auth.user)}}
  </button>
  <a routerLink="/settings/{{user.id}}" *ngFor="let user of users" mat-mini-fab class="uppercase thin hide-sm users" (click)="toggleSelfProfile(false)">
    {{getAcronyms(user)}}
  </a>
  <button mat-mini-fab class="uppercase tertiary thin" *ngIf="usersCount" (click)="openAllUsersDialog()">
    +{{usersCount}}
  </button>
  <button mat-mini-fab class="uppercase thin outline top1" (click)="openInviteUser()">
    <mat-icon class="small">add</mat-icon>
  </button>
  <div class="user-wrap pos-abs box-shadow fade-in" *ngIf="showSelfProfile">
    <!-- id="users-summary-self-profile"> -->
    <div class="bottom-box"></div>
    <div class="triangle"></div>
    <app-user-card class="disp-block" [use-dialog]="false" [user]="auth.user"></app-user-card>
  </div>
</div>