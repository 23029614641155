<div class="wrap" fxLayout="row" fxLayoutAlign="space-around center" [ngClass]="screen + '-wrap'">
  <div fxFlex class="inner">
    <form [formGroup]="loginForm" (ngSubmit)="signup()" novalidate *ngIf="screen === 'signup'" class="scrollbar signup">
      <div fxLayout="column" fxLayoutAlign="space-between center">
        <div fxFlex>

          <img alt="hexa 3d" class="logo" src="https://cdn.hexa3d.io/hotlink-ok/icons/logo/hexa_logo_cms.svg">
        </div>
        <div fxFlex class="screen-name">
          Hello <span
            *ngIf="loginForm.controls['name'] && loginForm.controls['name'].value">{{loginForm.controls['name'].value}}!</span>
          <div class="sub-text"> Thank you for joining us,<br /> just a few more steps to create your account.</div>
        </div>
        <div fxFill class="signup-pass" >
          <mat-form-field fxFlexFill>
            <mat-label>Password</mat-label>
            <input [type]="isShowPassword ? 'text' : 'password'" matInput formControlName="password" id="input_1"
              pattern="^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$">
            <mat-hint *ngIf="loginForm.hasError('required','password') && loginForm.dirty" align="start">password is
              required</mat-hint>
            <mat-hint *ngIf="loginForm.hasError('pattern',['password']) && loginForm.dirty" align="start">must contain
              special
              characters, numbers, small letters, capital letters and at least 8 characters long</mat-hint>
              <span [ngClass]="{'hidden': !loginForm?.controls['password']?.value?.length}" class="eye-password material-icons pointer" (click)="toggShowPassword()">
                {{isShowPassword ?  "visibility" : "visibility_off"}}
                </span>
          </mat-form-field>
        </div>
        <div fxFill class="signup-confirm-pass">
          <mat-form-field fxFlexFill hideRequiredMarker
            [ngClass]="{'form-field-invalid mat-form-field-invalid ng-invalid': !isPasswordsMatch() && loginForm.dirty}">
            <mat-label>Confirm Password</mat-label>
            <input [type]="isShowConfirmPassword ? 'text' : 'password'" matInput formControlName="confirm_password" required>
            <mat-hint *ngIf="!isPasswordsMatch() && loginForm.dirty" align="start">password don't match</mat-hint>
            <span [ngClass]="{'hidden': !loginForm?.controls['confirm_password']?.value?.length}" class="eye-password material-icons pointer" (click)="isShowConfirmPassword = !isShowConfirmPassword">
              {{isShowConfirmPassword ?  "visibility" : "visibility_off"}}
              </span>
          </mat-form-field>
        </div>
        <div fxFill>
          <div class="register text-center">
            Already a member?
            <a routerLink="/login">Sign in</a>
          </div>
        </div>
        <div fxFill>
          <button mat-raised-button color="accent" class="prime_button submit center-me min-button-width" id="signin">
            sign up
          </button>
        </div>
      </div>
    </form>
    <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate *ngIf="screen === 'login'" class="scrollbar">
      <div fxLayout="column" fxLayoutAlign="space-between center">
        <div fxFlex>
          <img alt="hexa 3d" class="logo" src="https://cdn.hexa3d.io/hotlink-ok/icons/logo/hexa_logo_cms.svg">
        </div>
        <div fxFlex class="screen-name si">
          Sign In
        </div>
        <div fxFill>
          <app-sso-options></app-sso-options>
        </div>
        <div fxFill>
          <app-or-separator>or</app-or-separator>
        </div>
        <div fxFill>
          <div class="">
            <mat-form-field fxFill hideRequiredMarker>
              <mat-label>Email</mat-label>
              <input matInput type="email" formControlName="email"
                pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" required id="input_0" >
              <mat-hint *ngIf="loginForm.hasError('required','email') && loginForm.dirty" align="start">e-mail is
                required</mat-hint>
              <mat-hint *ngIf="loginForm.hasError('pattern','email') && loginForm.dirty" align="start">e-mail not valid
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div fxFill class="pos-rel">
          <mat-form-field fxFlexFill>
            <mat-label>Password</mat-label>
            <input [type]="isShowPassword ? 'text' : 'password'" matInput formControlName="password" id="input_1">
            <mat-hint *ngIf="loginForm.hasError('required','password') && loginForm.dirty" align="start">password is
              required</mat-hint>
                <span [ngClass]="{'hidden': !loginForm?.controls['password']?.value?.length}" class="eye-password material-icons pointer" (click)="toggShowPassword()">
                  {{isShowPassword ?  "visibility" : "visibility_off"}}
                  </span>
          </mat-form-field>
        </div>
        <div fxFill>
          <div class="register text-center">
            First time here?
            <a routerLink="/register">Sign up</a>
          </div>
        </div>
        <div fxFill>
          <button mat-raised-button color="accent" class="prime_button submit center-me min-button-width" id="signin">
            sign in
          </button>
          <br>
        </div>
      </div>
    </form>
    <form [formGroup]="fpForm" (ngSubmit)="resetPassword()" novalidate *ngIf="screen === 'forgotpassword'"
      class="scrollbar forgotpassword">
      <div fxLayout="column" fxLayoutAlign="space-between center">
        <div fxFlex>
          <div class="back-button pointer" (click)="goBack()">
            <span class="material-icons back-icon">arrow_back_ios</span><span>Back</span>
          </div>
          <img alt="hexa 3d" class="logo" src="https://cdn.hexa3d.io/hotlink-ok/icons/logo/hexa_logo_cms.svg">
        </div>
        <div class="reset-password" fxFlex>
          Reset Password
          <div class="sub-text"> Insert your email and we will send you a recovery link</div>
        </div>
        <div fxFill>
          <mat-form-field fxFill hideRequiredMarker>
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email"
              pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" required>
            <mat-hint *ngIf="fpForm.hasError('required','email') && fpForm.dirty">e-mail is required</mat-hint>
            <mat-hint *ngIf="fpForm.hasError('pattern','email') && fpForm.dirty">e-mail not valid</mat-hint>
          </mat-form-field>
        </div>
        <div fxFill>
          <button [disabled]="!fpForm.dirty || fpForm.hasError('required','email') || fpForm.hasError('pattern','email')" mat-raised-button class="prime_button submit center-me min-button-width fp-submit">
            send recovery link
          </button>
        </div>
      </div>
    </form>
    <form [formGroup]="loginForm" (ngSubmit)="signup()" novalidate *ngIf="screen === 'reset'" class="reset scrollbar">
      <div fxLayout="column" fxLayoutAlign="space-between center">
        <div fxFlex>
          <!-- <div class="back-button pointer" (click)="goBack()">
            <span class="material-icons back-icon">arrow_back_ios</span><span>Back</span>
          </div> -->
          <img alt="hexa 3d" class="logo" src="https://cdn.hexa3d.io/hotlink-ok/icons/logo/hexa_logo_cms.svg">
        </div>
        <div class="reset-password" fxFlex>
          Reset Password
                  <div class="sub-text">   <!-- Your new password must be different from previous used passwords--></div>
        </div>
        <div fxFill class="signup-pass" *ngIf="enforceOldPass">
          <mat-form-field fxFlexFill>
            <mat-label>Old Password</mat-label>
            <input [type]="isShowPassword ? 'text' : 'password'" matInput formControlName="old_password" id="input_0">
            <mat-hint *ngIf="loginForm.hasError('required','old_password') && loginForm.dirty" align="start">Old Password is required</mat-hint>
            <span [ngClass]="{'hidden': !loginForm?.controls['old_password']?.value?.length}" class="eye-password material-icons pointer" (click)="toggShowPassword()">
              {{isShowPassword ?  "visibility" : "visibility_off"}}
            </span>
          </mat-form-field>
        </div>
        <div fxFill class="signup-pass">
          <mat-form-field fxFlexFill>
            <mat-label>New Password</mat-label>
            <input [type]="isShowPassword ? 'text' : 'password'" matInput formControlName="password" id="input_1"
              pattern="^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$">
            <mat-hint *ngIf="loginForm.hasError('required','password') && loginForm.dirty" align="start">Password is
              required</mat-hint>
            <mat-hint *ngIf="loginForm.hasError('pattern',['password']) && loginForm.dirty" align="start">Must contain
              special
              characters, numbers, small letters, capital letters and at least 8 characters long</mat-hint>
              <span [ngClass]="{'hidden': !loginForm?.controls['password']?.value?.length}" class="eye-password material-icons pointer" (click)="toggShowPassword()">
                {{isShowPassword ?  "visibility" : "visibility_off"}}
                </span>
          </mat-form-field>
        </div>
        <div fxFill>
           <mat-form-field fxFlexFill  hideRequiredMarker
            [ngClass]="{'form-field-invalid mat-form-field-invalid ng-invalid': !isPasswordsMatch() && loginForm.dirty}">
            <mat-label>Confirm Password</mat-label>
            <input  [type]="isShowConfirmPassword ? 'text' : 'password'" matInput formControlName="confirm_password" required>
            <mat-hint *ngIf="!isPasswordsMatch() && loginForm.dirty" align="start">Password don't match</mat-hint>
            <span [ngClass]="{'hidden': !loginForm?.controls['confirm_password']?.value?.length}" class="eye-password material-icons pointer" (click)="isShowConfirmPassword = !isShowConfirmPassword">
              {{isShowConfirmPassword ?  "visibility" : "visibility_off"}}
              </span>
          </mat-form-field>
        </div>
        <div fxFill>
          <div class="register text-center" [ngClass]="{'enforce-old-pass': enforceOldPass}">
            Changed your mind?
            <a routerLink="/login">Sign in</a>
          </div>
        </div>
        <div fxFill>
          <button mat-raised-button class="prime_button center-me min-button-width fp-submit2"
            [disabled]="!fpForm.valid || !loginForm.dirty">
            update password
          </button>
          <div class="hint text-center">
            Changed your mind?
            <a routerLink="/login">Sign in</a>
          </div>
        </div>
        <div [hidden]="!fPmeg" class="hint fp-msg pos-abs">{{fPmeg}}</div>
      </div>
    </form>
    <form [formGroup]="fpForm" (ngSubmit)="confirmReset()" novalidate *ngIf="screen === 'update'" class="scrollbar">
      <div fxLayout="column" fxLayoutAlign="space-between center">
        <div fxFlex>
          <img alt="hexa 3d" class="logo" src="https://cdn.hexa3d.io/hotlink-ok/icons/logo/hexa_logo_cms.svg">
        </div>
        <div class="reset-password" fxFlex>
          Reset QR code
        </div>
        <div class="centertext" fxFill>
          <div> Your request to reset the QR code was approved. </div>
          <div>When confirming, you will be required to scan a new QR code once signing in.</div>
        </div>
        <div fxFill>
          <button mat-raised-button class="prime_button center-me min-button-width fp-submit"
            [disabled]="!fpForm.valid">
            confirm reset
          </button>
          <div class="hint text-center">
           Changed your mind?
            <a routerLink="/login">Sign in</a>
          </div>
        </div>
        <div fxFill>
        </div>
        <div [hidden]="!fPmeg" class="hint fp-msg pos-abs">{{fPmeg}}</div>
      </div>
    </form>
    <form [formGroup]="fpForm" (ngSubmit)="enterCode()" novalidate *ngIf="screen === 'qr'" class="scrollbar">
      <div fxLayout="column" fxLayoutAlign="space-around center">
        <div fxFlex>
          <div class="back-button pointer" (click)="goBack()">
            <span class="material-icons back-icon">arrow_back_ios</span><span>Back</span>
          </div>
          <img alt="hexa 3d" class="logo" src="https://cdn.hexa3d.io/hotlink-ok/icons/logo/hexa_logo_cms.svg">
        </div>
        <div class="screen-title text-center" fxFlex>
          Scan the QR code with an authentication app on your phone.
        </div>
        <div fxFill class="text-center">
          <img class="qr-img" [src]="qrImg">
        </div>
        <div fxFill>
          <button mat-raised-button class="prime_button center-me min-button-width qr-submit">
            next
          </button>
          <div class="hint text-center link pointer">
            <div (click)="qrPopup(1)">Can't scan the QR code?</div>
          </div>
        </div>
        <!-- <div [hidden]="!fPmeg" class="hint fp-msg pos-abs">{{fPmeg}}</div> -->
      </div>
    </form>
    <form [formGroup]="loginForm" (ngSubmit)="sendCode()" novalidate *ngIf="screen === 'qrcode'" class="scrollbar">
      <div fxLayout="column" fxLayoutAlign="space-between center">
        <div fxFlex>
          <div class="back-button pointer" (click)="goBack()">
            <span class="material-icons back-icon">arrow_back_ios</span><span>Back</span>
          </div>
          <img alt="hexa 3d" class="logo" src="https://cdn.hexa3d.io/hotlink-ok/icons/logo/hexa_logo_cms.svg">
        </div>
        <div fxFlex class="screen-title text-center">
          Enter the code that appears in the authentication app on your phone
          <mat-hint align="start">
            <div class="sub-title">The code is valid for the next 30 seconds</div>
          </mat-hint>
        </div>
        <div fxFill class="code_input">
          <mat-form-field fxFill>
            <mat-label>Type Code</mat-label>
            <input matInput type="number" formControlName="code" autofocus>
          </mat-form-field>
        </div>
        <div fxFill>
          <button mat-raised-button class="prime_button center-me min-button-width fp-submit"
            [disabled]="!fpForm.valid">
            Sign In
          </button>
        </div>
        <div (click)="qrPopup(2)" class="hint link pointer">Didn't get a code?</div>
      </div>
    </form>
    <form novalidate *ngIf="screen === 'mobile'" class="scrollbar">
      <div fxLayout="column" fxLayoutAlign="space-between center">
        <div fxFlex>
          <img alt="hexa 3d" class="logo" src="https://cdn.hexa3d.io/hotlink-ok/icons/logo/hexa_logo_cms.svg">
        </div>
        <div fxFlex class="screen-title text-center">
          A Device Change Is Needed

        </div>
        <div>
          <div class="centertext" fxFill>
            <div> You can only access this email via desktop. </div>
          </div>
        </div>

      </div>
    </form>
  </div>

</div>
<div class="down">
  <span [ngClass]="{'grayout': screen === 'forgotpassword' || screen === 'signup' || screen === 'reset'}" class="pointer" (click)="toggleFP(screen !== 'forgotpassword' ? 'forgotpassword' : 'login')">
    {{  (screen === 'forgotpassword' || screen === 'qr' || screen === 'reset') ? 'Reset Password' : 'Forgot password?'}}
  </span>
  <span class="pointer privacy" (click)="showPrivacy()">Privacy policy</span>
  <span class="float-op-dir pointer" (click)="showTerms()">Terms & conditions</span>
</div>
<!--<br>
<br>
<br>
 <div>{{backStack | json}}</div>
<span style="padding: 10px;" *ngFor="let item of screens" (click)="goto(item)">{{item}}</span> -->
