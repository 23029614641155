import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DropZoneFile, DropZoneFileValidatorFunc } from './drop-zone.model';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'app-drop-zone',
  templateUrl: './drop-zone.component.html',
  styleUrls: ['./drop-zone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropZoneComponent implements OnDestroy{
  @Output() fileChange = new EventEmitter<DropZoneFile>();
  @Output() fileNotAccepted = new EventEmitter<null>();
  @Input() multiple: boolean;
  @Input() accept: string[];
  @Input() disabled: boolean;
  @Input() background: boolean;
  @Input('css-class') cssClass: string;
  @Input() fileValidator: DropZoneFileValidatorFunc;
  private _destroy$ = new Subject<void>();
  private _destroyWhenDone = false;
  private _counter = 0;

  constructor(private utils: UtilsService) {}

  ngOnDestroy(): void {
    if (!this._counter)
      this._destroy$.next();
    else
      this._destroyWhenDone = true;
  }

  private onFileLoaded(file: DropZoneFile): boolean {
    let sent = false;
    if (!this.fileValidator || this.fileValidator(file)) {
      this.fileChange.emit(file);
      sent = true;
    }
    this._counter--;
    if (this._destroyWhenDone && !this._counter)
        this._destroy$.next();
    return sent;
  }

  public onFilesChange(event: Event): void {
    const files: File[] = Array.from((event.target as HTMLInputElement).files).filter(file =>
      this.accept.some((accept) => accept === file.type || accept === this.getFileNameExtension(file.name)));
      this._counter += files.length;
    let succeeded = 0;
    files.forEach(file => {
      const reader = new FileReader();
      fromEvent(reader, 'load').pipe(take(1), takeUntil(this._destroy$)).subscribe((res) => {
        const extension = this.getFileNameExtension(file.name);
        const name = this.getFileName(file.name);
        const dropZoneFile: DropZoneFile = {
          name,
          file,
          base64: (res.target as FileReader).result.toString(),
          extension
        };
        if (this.onFileLoaded(dropZoneFile))
          succeeded++;
        if (!this._counter && !succeeded)
          this.fileNotAccepted.emit();
      });
      reader.readAsDataURL(file);
    });
  }

  private getFileNameExtension(fileName: string): string {
    return this.utils.getFileNameExtension(fileName);
  }

  private getFileName(fileName: string): string {
    return this.utils.getFileName(fileName);
  }
}
