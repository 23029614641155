import { Component, AfterViewInit, Input, OnChanges, ViewChild } from '@angular/core';
import { RetailersAgreement, BusinessPlan, PaymentModel } from '../retailer';
import { KeyValueAnyPair } from 'src/app/shared/enums';
import { EnumsService } from 'src/app/shared/enums.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/shared/utils.service';
import { MatInput } from '@angular/material/input';
import { RestService } from 'src/app/communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';

@Component({
  selector: 'app-business-model',
  templateUrl: './business-model.component.html',
  styleUrls: ['./business-model.component.scss']
})
export class BusinessModelComponent implements AfterViewInit, OnChanges {
  @Input() config: RetailersAgreement;
  @Input('retailer-id') retailerId: number;
  @ViewChild('startPickerInput', {
    read: MatInput,
    static: false
  }) startPickerInput: any;
  @ViewChild('endPickerInput', {
    read: MatInput,
    static: false
  }) endPickerInput: any;
  public allBusinessPlans: Array<KeyValueAnyPair>;
  public allPaymentModel: Array<KeyValueAnyPair>;
  public allCreditType: Array<KeyValueAnyPair>;
  public allAgreementBudgetTypes: Array<KeyValueAnyPair>;
  public form: UntypedFormGroup;
  public PayPer3DAsset: BusinessPlan;
  public PayPerAPICall: BusinessPlan;
  public Unlimited: PaymentModel;
  // private subs: Array<Subscription>;
  constructor(
    private enums: EnumsService,
    private formBuilder: UntypedFormBuilder,
    private utils: UtilsService,
    private rest: RestService,
    private broadcaster: BroadcasterService
  ) {
    this.PayPer3DAsset = BusinessPlan.PayPer3DAsset;
    this.PayPerAPICall = BusinessPlan.PayPerAPICall;
    this.Unlimited = PaymentModel.Unlimited;
    this.allBusinessPlans = this.enums.getBusinessPlans();
    this.allPaymentModel = this.enums.getPaymentModels();
    this.allCreditType = this.enums.getCreditTypes();
    this.allAgreementBudgetTypes = this.enums.getAgreementBudgetTypes();
    this.form = this.formBuilder.group({
      retailer_id: null,
      id: null,
      business_plan_id: null,
      start_at: [null, Validators.required],
      end_at: [null, Validators.required],
      price_per_api_call: [null, Validators.required],
      payment_model: [null, Validators.required],
      agreement_limit: [null, Validators.required],
      monthly_credit: [null, Validators.required],
      credit_type: [null, Validators.required],
      agreement_budget_type: [null, Validators.required],
      agreement_budget: [null, Validators.required],
      fixed_payment: [null, Validators.required],
      minimum_monthly_assets: [null, Validators.required],
      maximum_assets_download: [null, Validators.required],
      is_poc: [false, Validators.required]
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setDefaults();
    });
  }

  ngOnChanges() {
    this.setDefaults();
  }

  setDefaults() {
    this.form.controls.retailer_id.setValue(this.retailerId);
    if (this.config) {
      this.config = this.utils.deepCopyByValue(this.config);
      const config = this.config;
      let start = config.start_at;
      if (start && this.startPickerInput) {
        this.startPickerInput.value = new Date(start);
      }
      let end = config.end_at;
      if (end && this.endPickerInput) {
        this.endPickerInput.value = new Date(end);
      }
      this.form.controls.id.setValue(config.id);
      this.form.controls.credit_type.setValue(config.credit_type);
      this.form.controls.is_poc.setValue(config.is_poc);
      this.form.controls.maximum_assets_download.setValue(config.maximum_assets_download);
      this.form.controls.minimum_monthly_assets.setValue(config.minimum_monthly_assets);
      this.form.controls.monthly_credit.setValue(config.monthly_credit);
      this.form.controls.payment_model.setValue(config.payment_model);
      this.form.controls.price_per_api_call.setValue(config.price_per_api_call);
      this.form.controls.agreement_limit.setValue(config.agreement_limit);
      this.form.controls.business_plan_id.setValue(config.business_plan_id);
      this.form.controls.agreement_budget_type.setValue(config.agreement_budget_type);
      this.form.controls.agreement_budget.setValue(config.agreement_budget);
      this.form.controls.fixed_payment.setValue(config.fixed_payment);
    }
    else {
      this.startPickerInput.value = null;
      this.endPickerInput.value = null;
      this.form.controls.id.setValue(null);
      this.form.controls.credit_type.setValue(null);
      this.form.controls.is_poc.setValue(null);
      this.form.controls.maximum_assets_download.setValue(null);
      this.form.controls.minimum_monthly_assets.setValue(null);
      this.form.controls.monthly_credit.setValue(null);
      this.form.controls.payment_model.setValue(null);
      this.form.controls.price_per_api_call.setValue(null);
      this.form.controls.agreement_limit.setValue(null);
      this.form.controls.business_plan_id.setValue(null);
      this.form.controls.agreement_budget_type.setValue(null);
      this.form.controls.agreement_budget.setValue(null);
      this.form.controls.fixed_payment.setValue(null);
    }
    // this.requiredFormats = this.config.map(i => i.resource_type_id);
  }

  save() {
    // this.config = [];
    // this.requiredFormats.forEach(rf => {
    //   this.config.push(
    //     {
    //       resource_type_id: rf,
    //       retailer_id: this.retailerId
    //     } as RetailersResourceFileType
    //   );
    // });
    // let config = this.config[0];
    this.form.controls.retailer_id.setValue(this.retailerId);
    let config = this.form.getRawValue();
    if (this.startPickerInput.value)
      config.start_at = this.startPickerInput.value.getTime();
    if (this.endPickerInput.value)
      config.end_at = this.endPickerInput.value.getTime();
    let query = '';
    if (config.id)
      query = `/${config.id}`;
    query += '?rid=' + this.retailerId;
    this.rest.retailerAgreement(config.id ? 'put' : 'post', config, query).subscribe(
      data => {
        this.config[0] = data as RetailersAgreement;
        let res: BroadcasterNotification = {
          text: 'retailer agreement updated successfully',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', res);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure updating retailer agreement')
    );
  }
}
