<ul>
    <li class="indentifiers-row no-padd-children" *ngFor="let identifier of prService.request.products_identifiers; let i = index"
        fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-around center">
        <div fxFlex.gt-sm="21" fxFlex="100">
            <span class="identifier-index">{{i + 1}}</span>
            <mat-form-field fxFill>
                <mat-label>Serial Number</mat-label>
                <input matInput type="text"
                    name="prService.request.serial_number{{i}}" [(ngModel)]="identifier.serial_number">
            </mat-form-field>
        </div>
        <div fxFlex.gt-sm="21" fxFlex="100">
            <mat-form-field fxFill>
                <mat-label>Identifier Type</mat-label>
                <input matInput type="text"
                    name="prService.request.identifier_type{{i}}" [(ngModel)]="identifier.identifier_type">
            </mat-form-field>
        </div>
        <div fxFlex.gt-sm="21" fxFlex="100">
            <mat-form-field fxFill>
                <mat-label>Variation ID</mat-label>
                <input matInput type="text"
                    name="prService.request.variation_id{{i}}" [(ngModel)]="identifier.variation_id">
            </mat-form-field>
        </div>
        <div fxFlex.gt-sm="13" fxFlex="100" class="text-center"
        [ngClass]="{'no-poly-add': i != prService.request.products_identifiers.length - 1}">
            <button mat-icon-button class="float-op-dir invert add-remove" (click)="removeIdentifier(i)"
            color="warn" *ngIf="i > 0">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-icon-button class="float-op-dir invert add-remove" (click)="add()"
            *ngIf="i == 0">
            <mat-icon>add</mat-icon>
          </button>
        </div>
    </li>
</ul>