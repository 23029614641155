import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductsManagerComponent } from './products-manager/products-manager.component';
import { ProductsResolve } from './products-manager/products-manager.resolve';
import { ProductResolve } from './product-request/product-request.resolve';
import { RetailerResolve } from 'src/app/retailer/retailer.resolve';
import { RefineResultsComponent } from './refine-results/refine-results.component';
import { RetailerCategoriesTreeComponent } from 'src/app/retailer/retailer-categories-tree/retailer-categories-tree.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { ProductQuickViewComponent } from './product-quick-view/product-quick-view.component';
import { ProductRequestComponent } from './product-request/product-request.component';
import { ProductPreviewComponent } from './product-preview/product-preview.component';
import { ProductSettingsComponent } from './product-settings/product-settings.component';
import { ProductShowcaseComponent } from './product-showcase/product-showcase.component';
import { ProductHistoryComponent } from './product-history/product-history.component';
import { RetailerProductResolve } from '../retailer/retailer-product.resolve';
import { FeedbackCreationComponent } from './feedback-creation/feedback-creation.component';
import { ProductQuoteComponent } from './product-quote/product-quote.component';
import { SelectedProductsActionsComponent } from './selected-products-actions/selected-products-actions.component';
import { FeedbackEditComponent } from './feedback-edit/feedback-edit.component';
import { FeedbackEditResolve } from './feedback-edit/feedback-edit.resolve';
import { ProductLineComponent } from './product-line/product-line.component';
import { ResourceEmbeddedComponent } from './resource-embedded/resource-embedded.component';
import { DuplicateProductComponent } from './duplicate-product/duplicate-product.component';
import { GifgenResolve } from './product-request/gifgen.resolve';
import { ResourceTypesResolve } from '../shared/resource-types.resolve';
import { InsufficientFundsComponent } from './insufficient-funds/insufficient-funds.component';
import { ProductsReportGeneratorComponent } from './products-report-generator/products-report-generator.component';
import { ProductsAttachmentsComponent } from './products-attachments/products-attachments.component';
import { BatchDownloaderComponent } from './batch-downloader/batch-downloader.component';
import { ProductIdentifiersComponent } from './product-identifiers/product-identifiers.component';
import { ProductsAuditComponent } from './products-audit/products-audit.component';
import { RetailerSettingsResolve } from '../retailer/retailer-settings.resolve';
import { ExternalResourceUploadItemComponent } from './external-resource-upload-item/external-resource-upload-item.component';
import { PreviewResourcesComponent } from './preview-files/preview-resources.component';
import { DuplicateProductExternalComponent } from './duplicate-product-external/duplicate-product-external.component';

@NgModule({
    imports: [
        CommonModule, SharedModule
    ],
    declarations: [ProductsManagerComponent, RefineResultsComponent, RetailerCategoriesTreeComponent, ProductCardComponent,
        ProductQuickViewComponent, ProductRequestComponent, ProductPreviewComponent, ProductSettingsComponent,
        ProductShowcaseComponent, ProductHistoryComponent, FeedbackCreationComponent, ProductQuoteComponent,
        SelectedProductsActionsComponent, FeedbackEditComponent, ProductLineComponent, ResourceEmbeddedComponent,
        DuplicateProductComponent,
        InsufficientFundsComponent,
        ProductsReportGeneratorComponent,
        ProductsAttachmentsComponent,
        BatchDownloaderComponent,
        ProductIdentifiersComponent,
        ProductsAuditComponent,
        ExternalResourceUploadItemComponent,
        PreviewResourcesComponent,
        DuplicateProductExternalComponent],
    providers: [ProductsResolve, RetailerResolve, RetailerSettingsResolve, ProductResolve, RetailerProductResolve, FeedbackEditResolve,
        GifgenResolve, ResourceTypesResolve]
})
export class ProductModule { }
