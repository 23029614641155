import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Product, SingleProductQuery, ProductPut, MediaTag, ProductResource } from '../product';
import { ProductService } from '../product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcasterService } from 'ng-broadcaster';
import { ApiCallOptions } from '../../shared/utils';
import { Subscription } from 'rxjs';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { UtilsService } from 'src/app/shared/utils.service';
import { EnumsService } from 'src/app/shared/enums.service';
import { RolesHelperService } from 'src/app/auth/roles-helper.service';

@Component({
  selector: 'app-product-preview',
  templateUrl: './product-preview.component.html',
  styleUrls: ['./product-preview.component.scss']
})
export class ProductPreviewComponent implements OnInit, OnDestroy {
  public product: Product;
  public showBottom: boolean;
  public currentResourceIndex: number;
  public asset: SafeResourceUrl;
  public resources: Array<ProductResource>;
  public mediaTag: MediaTag;
  private sub: Subscription;
  constructor(
    private activatedRoute: ActivatedRoute,
    public productService: ProductService,
    private router: Router,
    private broadcaster: BroadcasterService,
    private location: Location,
    private sanitizer: DomSanitizer,
    private utils: UtilsService,
    public enums: EnumsService,
    private roles: RolesHelperService
  ) {
    let p = this.activatedRoute.snapshot.data['product'] as SingleProductQuery;
    this.product = this.productService.getProductsFromProductQuery([p.data.products])[0];
    this.product.UI = this.productService.getProdutUI(this.product, { improviseImageSet: false });
    this.productService.editItem = this.product;
  }

  ngOnInit() {
    if (this.product.is_archived)
      this.showBottom = false;
    else if (this.product.status_id == 5 || this.product.status_id == 6)
      this.showBottom = false;
    else
      this.showBottom = true;

    this.sub = this.broadcaster.on('esc').subscribe(this.close.bind(this));
    this.currentResourceIndex = 0;
    this.product = this.utils.deepCopyByValue(this.product);
    this.resources = [];
    if (this.product && this.product.products_resources && this.product.products_resources.length > 0) {
      for (let i = 0; i < this.product.products_resources.length; i++) {
        if (this.product.products_resources[i].resource_enabled || this.roles.isSuperUserLoggedIn() ||
        (this.productService.isResourceRender(this.product.products_resources[i]) &&
        !this.product.external_provider)) {
          this.resources.push(this.product.products_resources[i]);
        }
      }
    }
    // this.resources = this.utils.deepCopyByValue(this.product.products_resources.filter(pr => pr.resource_enabled));
    this.resources.forEach(r =>
      r.icon = this.enums.getIconByTag(this.utils.getMediaTagByResource(r))
    );
    this.setAsset(this.currentResourceIndex);
    // this.product.products_resources[this.currentResourceIndex];
  }

  setAsset(index: number) {
    this.currentResourceIndex = index;
    this.setMediaTag();
    if (this.resources.length) {
      switch (this.mediaTag) {
        case MediaTag.MODEL: {
          this.asset = this.sanitizer.bypassSecurityTrustResourceUrl(this.utils.setUrlParam(this.resources[this.currentResourceIndex].resource_default, 'theme-color', '#546994'));
          break;
        }
        case MediaTag.IFRAME_RENDR: {
          this.asset = this.sanitizer.bypassSecurityTrustResourceUrl(this.resources[this.currentResourceIndex].resource_big || this.resources[this.currentResourceIndex].resource_small);
          break;
        }
        default: {
          this.asset = this.sanitizer.bypassSecurityTrustResourceUrl(this.resources[this.currentResourceIndex].resource_default);
          break;
        }
      }
    }
    else
      delete this.asset;
  }

  setMediaTag() {
    this.mediaTag = this.utils.getMediaTagByResource(this.resources[this.currentResourceIndex]);
  }

  close() {
    if (!window.history.state || window.history.state.navigationId == 1)
      this.router.navigate(['/products']);
    else {
      if (this.location.back)
        this.location.back();
      else
        history.go(-1);
    }
  }

  feedback() {
    let state = {
      state: 'feedback'
    };
    this.router.navigate(['/product', this.product.id, state]);
  }

  onApprove(item: ProductPut, success: boolean) {
    if (success) {
      this.close();
      if (!item.status_changed) {
        let data: BroadcasterNotification = {
          text: `product successfully APPROVED but his status HASEN'T changed! reason: ${item.status_reason}`,
          type: BroadcasterNotificationType.Info,
          action: 'OK',
          duration: 20000
        }
        this.broadcaster.broadcast('notifyUser', data);
      }
    }
  }

  approve(goLive: boolean, render?: boolean) {
    if (!confirm('Are you sure you want to APPROVE this product' + (goLive ? ' and GO LIVE' : '') + '?')) return;
    let options = new ApiCallOptions();
    options.callback = this.onApprove.bind(this);
    if (goLive) {
      this.product.enabled = 1;
      this.product.mobile_enabled = 1;
      options.successMessage = 'item is ONLINE on Desktop and Mobile';
    }
    else {
      this.product.status_id = 5;
      options.successMessage = 'item is OFFLINE';
    }
    options.callback = () => {
      this.productService.searchByQuery(true);
      if (render) {
        // this.rest.productRender('post', this.request.products_renders_requests).subscribe(
        //   res => {
        //     let data: BroadcasterNotification = {
        //       text: 'render request created',
        //       type: BroadcasterNotificationType.Success,
        //       action: 'OK'
        //     };
        //     this.broadcaster.broadcast('notifyUser', data);
        //     this.renderState = RenderState.CREATING;
        //     this.refresh();
        //   },
        //   err => {
        //     let data: BroadcasterNotification = {
        //       text: (err && err._body) ? err._body : 'failure requesting render',
        //       type: BroadcasterNotificationType.Error,
        //       action: 'OK'
        //     };
        //     this.broadcaster.broadcast('notifyUser', data);
        //   }
        // );
      }
    };
    this.productService.save(this.product, options);
  }

  ngOnDestroy() {
    delete this.productService.editItem;
    this.sub.unsubscribe();
  }
}
