<div>
    <p *ngIf="config?.length">existing:</p>
    <ul>
        <li *ngFor="let p of config">
            <button mat-icon-button color="warn" (click)="remove(p.viewer_param_key)">
                <mat-icon>delete</mat-icon>
            </button>
            {{p.viewer_param_key}}: {{p.viewer_param_value}}
        </li>
    </ul>
    <mat-form-field>
        <input matInput type="text" placeholder="parameter name" [(ngModel)]="keyVal.key">
    </mat-form-field>
    <mat-form-field>
        <input matInput type="text" placeholder="parameter value" [(ngModel)]="keyVal.value">
    </mat-form-field>
    <!-- <mat-form-field>
        <input matInput type="text" placeholder="parameter to delete" [(ngModel)]="del">
    </mat-form-field> -->
    <button mat-button (click)="save()">
        save
    </button>
</div>